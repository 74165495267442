import React, { useContext, useState, useEffect } from "react";
import "./Alertas.css";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import { getUser, verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import { aprovaAlerta, atribuirAlerta, getAlerta, getAlertaPorStatus, getAlertaTodos, reprovaAlerta, resolucaoAlerta } from "../../../services/ModuloGerenciador/Alerta";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import ModalVincular from "../../../components/ModalVincular/ModalVincular.tsx";
import ModalSolucao from "../../../components/ModalSolucao/ModalSolucao.tsx";
import { FormControl } from "@mui/material";
import Select from 'react-select';

function Alertas(props) {
    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [dadosAlerta, setDadosAlerta] = useState({});

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [textoSolucao, setTextoSolucao] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [listaUsuarios, setListaUsuario] = useState([]);

    const [usuarioId, setUsuarioId] = useState();

    const [alertaId, setAlertaId] = useState();

    const [nomeUsuario, setNomeUsuario] = useState();

    const [statusFiltro, setStatusFiltro] = useState();

    const [statusFiltroObject, setStatusFiltroObject] = useState();

    const opcoes = [
        {
            'opcao': 'Aberto',
            'valor': '1'
        },
        {
            'opcao': 'Em Andamento',
            'valor': '2'
        },
        {
            'opcao': 'Resolvido',
            'valor': '3'
        },
        {
            'opcao': 'Aprovado',
            'valor': '4'
        },
        {
            'opcao': 'Reprovado',
            'valor': '5'
        },
        {
            'opcao': 'Todos',
        }
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '180px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const clickGerar = () => {
        atribuirUsuario();
    };

    const atribuirUsuario = async () => {
        await atribuirAlerta(dadosUsuarioRedux.token, alertaId, usuarioId).then((result) => {
            handleCloseGerar();
            buscarAlertas();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const [openGerar, setOpenGerar] = useState(false);

    const handleCloseGerar = (value) => {
        setOpenGerar(false);
    };

    const registrarSolucao = () => {
        registraSolucao();
    };

    const registraSolucao = async () => {
        const post = {
            idAlerta: alertaId,
            justificativa: textoSolucao
        }
        await resolucaoAlerta(dadosUsuarioRedux.token, post).then((result) => {
            handleCloseSolucao();
            buscarAlertas();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const aprovarAlerta = async () => {
        const post = {
            idAlerta: alertaId,
            justificativa: textoSolucao
        }
        await aprovaAlerta(dadosUsuarioRedux.token, post).then((result) => {
            handleCloseSolucao2();
            buscarAlertas();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const reprovarAlerta = async () => {
        const post = {
            idAlerta: alertaId,
            justificativa: textoSolucao
        }
        await reprovaAlerta(dadosUsuarioRedux.token, post).then((result) => {
            handleCloseSolucao3();
            buscarAlertas();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const [openSolucao, setOpenSolucao] = useState(false);

    const handleCloseSolucao = (value) => {
        setOpenSolucao(false);
    };
    const [openSolucao2, setOpenSolucao2] = useState(false);

    const handleCloseSolucao2 = (value) => {
        setOpenSolucao2(false);
    };
    const [openSolucao3, setOpenSolucao3] = useState(false);

    const handleCloseSolucao3 = (value) => {
        setOpenSolucao3(false);
    };

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);

    const buscarAlertas = async () => {
        setLoading(true);
        await getAlerta(dadosUsuarioRedux.token).then((result) => {
            setDadosAlerta(result.data);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarAlertasPorStatus = async () => {
        setLoading(true);
        await getAlertaPorStatus(dadosUsuarioRedux.token, statusFiltro).then((result) => {
            setDadosAlerta(result.data);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (statusFiltro === 'Todos' || statusFiltro === undefined) {
            buscarAlertas();
        } else { 
            buscarAlertasPorStatus();
        }
    }, [statusFiltro]);

    const buscarUsuarios = async () => {
        await getUser(dadosUsuarioRedux.token).then((result) => {
            setListaUsuario(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        buscarAlertas();
        buscarUsuarios();
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoAprovacao, setSemAutorizacaoAprovacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Alerta Geral') {
                    setSemAutorizacao(false);
                }
                if (element === 'Alerta Aprovação') {
                    setSemAutorizacaoAprovacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Alerta Geral') {
                        setSemAutorizacao(false);
                    }
                    if (dado.regras.regra === 'Alerta Aprovação') {
                        setSemAutorizacaoAprovacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Alertas" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Alertas" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <h2 style={{ textAlign: 'center', marginTop: 10 }}>Alertas</h2>
                                <div style={{ marginTop: 25 }} className="flex">
                                    <div>
                                        <p style={{ textAlign: 'center' }} className="input-label-emb" htmlFor="descricao">Filtrar por Status:</p>
                                        <FormControl sx={{ width: 180 }}>
                                            <Select
                                                value={statusFiltroObject}
                                                options={opcoesFrota}
                                                formatOptionLabel={labelFrota}
                                                styles={estiloFrota}
                                                placeholder=""
                                                noOptionsMessage={() => "Não há mais opções"}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        setStatusFiltro(selectedOption.valor);
                                                        setStatusFiltroObject(selectedOption);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="container-alertas" style={{
                                    gridTemplateColumns: `repeat(${Math.min(dadosAlerta.length, 3)}, 1fr)`,
                                }}>
                                    {dadosAlerta.map((dado) => (
                                        <div className="card-alerta">
                                            <div className="div-data-status">
                                                {dado.status === 1 && (
                                                    <b>Aberto</b>
                                                )}
                                                {dado.status === 2 && !dado.textoReprovacao && (
                                                    <b>Em Andamento</b>
                                                )}
                                                {dado.status === 2 && dado.textoReprovacao && (
                                                    <b>Reprovado</b>
                                                )}
                                                {dado.status === 3 && (
                                                    <b>Resolvido</b>
                                                )}
                                                {dado.status === 4 && (
                                                    <b>Aprovado</b>
                                                )}
                                                <b>{formatarData(dado.dataCriada)}</b>
                                            </div>
                                            <div className="textos-alerta">
                                                <span>{dado.textoAlerta}</span>
                                                {dado.textoReprovacao && (
                                                    <span>Texto Reprovação: {dado.textoReprovacao}</span>
                                                )}
                                                {dado.aprovacao && (
                                                    <span>Texto Aprovação: {dado.aprovacao}</span>
                                                )}
                                                {dado.resolucao && (
                                                    <span>Texto Resolução: {dado.resolucao}</span>
                                                )}
                                            </div>
                                            <div className="flex-column" style={{ gap: 10, width: '100%', padding: 5 }}>
                                                <div className="flex" style={{ gap: 10, width: '100%', justifyContent: 'flex-start' }}>
                                                    {dado.usuarioResponsavelAprovacao && (
                                                        <span>Aprovação: {dado.usuarioResponsavelAprovacao}</span>
                                                    )}
                                                    {dado.usuarioResponsavelResolucao && (
                                                        <span>Resolução: {dado.usuarioResponsavelResolucao}</span>
                                                    )}
                                                </div>
                                                <div className="flex" style={{ gap: 10, width: '100%' }}>
                                                    {dado.status === 1 && (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            titulo="Atribuir Usuário"
                                                            cor="#0946B8"
                                                            width='190px'
                                                            valor={values}
                                                            onClick={() => { setOpenGerar(true); setAlertaId(dado.alertaId); }}
                                                        />
                                                    )}
                                                    {dado.usuario && dado.status === 2 && dado.usuarioResponsavelResolucao === dadosUsuarioRedux.usuario.usuario && (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            titulo="Adicionar Solução"
                                                            cor="#0946B8"
                                                            width='190px'
                                                            valor={values}
                                                            onClick={() => { setOpenSolucao(true); setAlertaId(dado.alertaId); }}
                                                        />
                                                    )}
                                                    {dado.status === 3 && !semAutorizacaoAprovacao && (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            titulo="Aprovar"
                                                            cor="#0946B8"
                                                            width='190px'
                                                            valor={values}
                                                            onClick={() => { setOpenSolucao2(true); setAlertaId(dado.alertaId); }}
                                                        />
                                                    )}
                                                    {dado.status === 3 && !semAutorizacaoAprovacao && (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            titulo="Reprovar"
                                                            cor="#0946B8"
                                                            width='190px'
                                                            valor={values}
                                                            onClick={() => { setOpenSolucao3(true); setAlertaId(dado.alertaId); }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                    <ModalVincular
                                        titulo="Atribuir Usuário"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={openGerar}
                                        onClose={handleCloseGerar}
                                        onClick={clickGerar}
                                        listaUsuarios={listaUsuarios}
                                        setUsuarioId={setUsuarioId}
                                        setNomeUsuario={setNomeUsuario}
                                        referenciaId="usuario"
                                        referenciaItem="usuario"
                                        id="idUsuario"
                                    />
                                    <ModalSolucao
                                        titulo="Informar Resolução"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        tituloInput="Solução:"
                                        open={openSolucao}
                                        onClose={handleCloseSolucao}
                                        onClick={registrarSolucao}
                                        setTextoSolucao={setTextoSolucao}
                                    />
                                    <ModalSolucao
                                        titulo="Informar Motivo Aprovação"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        tituloInput="Aprovador Por:"
                                        open={openSolucao2}
                                        onClose={handleCloseSolucao2}
                                        onClick={aprovarAlerta}
                                        setTextoSolucao={setTextoSolucao}
                                    />
                                    <ModalSolucao
                                        titulo="Informar Motivo Reprovação"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        tituloInput="Reprovado Por:"
                                        open={openSolucao3}
                                        onClose={handleCloseSolucao3}
                                        onClick={reprovarAlerta}
                                        setTextoSolucao={setTextoSolucao}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Alertas;