import React, { useContext } from "react";
import "./Feedback.css";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import CoresRedux from "../../../contexts/Cor";
import logo from '../../../images/C1010_azul.png';
import { motion } from "framer-motion";

function Feedback() {
    const { dadosCoresRedux } = useContext(CoresRedux);

    const contato = {
        email: "c1010sistemas@gmail.com",
        telefone: "(45) 9 9959-8011"
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} 
            className="fundo" 
            style={{ backgroundColor: dadosCoresRedux.fundoPadrao }}
        >
            <NavbarV3 selecionado='Feedback' /> 
            <div className="principal-cards-feedback">
                {/* About us */}
                <div className="cardFeedback">
                    <h3>Sobre nós</h3>
                    <div className="TextoCard">
                        <p>A C1010 é uma empresa que busca maximizar a tecnologia e inovação. 
                        Nossa missão é transformar ideias em soluções digitais eficientes, 
                        personalizadas para atender às necessidades de nossos clientes.</p>
                    </div>
                </div>

                {/* Contact */}
                <div className="cardFeedback">
                    <h3>Entre em Contato</h3>
                    <div className="TextoCardContato">
                        <p>Para contatar nosso suporte</p>
                        <p><strong>Telefone:</strong> {contato.telefone}</p>
                        <p><strong>Email:</strong> {contato.email}</p>
                    </div>
                </div>

                {/* System Version */}
                <div className="cardFeedback">
                    <h3>Atualizações do Sistema</h3>
                    <div className="TextoCard">
                        <p>Nesta Versão adicionamos o card de Somatório da Quilometragem e melhoramos o servidor!</p>
                    </div>
                    <p className="versao">Versão Atual: 1.60</p>
                </div>
            </div>
        </motion.div>
    );
}

export default Feedback;
