import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import './EditarUsuario.css'
import Box from '@mui/material/Box';
import { InputLabel } from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import CoresRedux from "../../../contexts/Cor";
import RequestControllerRedux from "../../../contexts/RequestController";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx"
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx"
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { useNavigate } from "react-router-dom";
import { alterarSenhaDentroSistema } from "../../../services/ModuloGerenciador/Solicitacao";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import { motion } from "framer-motion";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function EditarUsuario() {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [values, setValues] = useState([]);

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [passou, setPassou] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
        setShowPassword(!showPassword);
    };

    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
        setShowNewPassword(!showNewPassword);
    };

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPasswordConfirm = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const navigate = useNavigate();

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    const mandaCodigo = async () => {
        if (values.novaSenha === values.confirmeNovaSenha && values.usuario == dadosUsuarioRedux.usuario.usuario) {
            setLoading(true);
            await alterarSenhaDentroSistema(values.usuario, values.senhaAtual, values.novaSenha, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    navigate("/");
                    setDadosRequestControllerRedux("Email Senha Codigo");
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro("Senhas devem ser iguais e ou você deve estar logado com o seu usuário");
            setOpenToastErro(true)
        }
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Mudar Senha" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV3 selecionado="Mudar Senha" />
                    {!loading ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="containerEdita"
                        >
                            <p>Redefinir a Senha</p>
                            <div className="form">
                                <p className="text">Informe as informações abaixo para definir sua senha!</p>
                                <hr></hr>
                                <Box display={'flex'} justifyContent={'center'} className="box">
                                    <div className="principal-edit-user">
                                        <p className="input-label-emb" htmlFor="descricao">Usuario:</p>
                                        <input
                                            id="email"
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, usuario: e.target.value }))} />
                                        <p className="input-label-emb" htmlFor="descricao">Senha Atual:</p>
                                        {/* <input
                                            id="email"
                                            type="password"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, senhaAtual: e.target.value }))} />
                                        <p className="input-label-emb" htmlFor="descricao">Nova Senha:</p> */}
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <input
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.senhaAtual}
                                                onChange={(e) => setValues(current => ({ ...current, senhaAtual: e.target.value }))}
                                                className="input-emb input-cad"
                                                style={{
                                                    paddingRight: '40px',
                                                    width: '350px',
                                                    height: '40px',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                            <div
                                                onClick={handleClickShowPassword}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '10px',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </div>
                                        </div>
                                        <p className="input-label-emb" htmlFor="descricao">Nova Senha:</p>
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <input
                                                type={values.showNewPassword ? 'text' : 'password'}
                                                value={values.novaSenha}
                                                onChange={(e) => setValues(current => ({ ...current, novaSenha: e.target.value }))}
                                                className="input-emb input-cad"
                                                style={{
                                                    paddingRight: '40px',
                                                    width: '350px',
                                                    height: '40px',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                            <div
                                                onClick={handleClickShowNewPassword}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '10px',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </div>
                                        </div>
                                        <p className="input-label-emb" htmlFor="descricao">Confirme a nova senha:</p>
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <input
                                                type={values.showPasswordConfirm ? 'text' : 'password'}
                                                value={values.confirmeNovaSenha}
                                                onChange={(e) => setValues(current => ({ ...current, confirmeNovaSenha: e.target.value }))}
                                                className="input-emb input-cad"
                                                style={{
                                                    paddingRight: '40px',
                                                    width: '350px',
                                                    height: '40px',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                            <div
                                                onClick={handleClickShowPasswordConfirm}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '10px',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                            <Box display={'flex'} className="botoes-edit-user">
                                <CustomButtonV2
                                    classCss="custom-button-cadastrar"
                                    classText="button-texto-cadastrar"
                                    icone="iconeSave"
                                    titulo="Enviar"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => {
                                        mandaCodigo();
                                    }}
                                />
                                <CustomButtonV2
                                    classCss="custom-button-cancelar"
                                    classText="button-texto-cancelar"
                                    titulo="Cancelar"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                />
                            </Box>
                        </motion.div>
                    ) : (
                        <LoadingComponent />
                    )}
                    <div>
                        <ToastSucesso
                            texto={textoSucesso}
                            open={openToastSucesso}
                            onClose={handleCloseToastSucesso}
                        />
                        <ToastErro
                            texto={textoErro}
                            open={openToastErro}
                            onClose={handleCloseToastErro}
                        />
                    </div>
                </div >
            )
            }
        </motion.div>
    );
}

export default EditarUsuario;