import React, { useState, useContext } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import "./CustomButtonV2.css";
import styled2 from "styled-components";
import iconeSave from "../../images/iconeSave.png";
import iconeEdit from "../../images/iconeEdit.png";
import iconeDelete from "../../images/iconeDelete.png";
import Tooltip from '@mui/material/Tooltip';

function CustomButtonV2(props) {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#fb0000'),
        backgroundColor: props.cor,
        '&:hover': {
            backgroundColor: props.cor,
        },
    }));

    const TextP = styled2.p`     
        color: ${props.corTexto};
    `

    return (
        <>
            {
                props.disabled ? (
                    <Tooltip title={<p>Carregando...</p>}>
                        <button onClick={props.onClick} className="custom-button-cadastrar-gray" variant="contained" disabled={props.disabled}>
                            {props.icone === 'iconeSave' && (
                                <img src={iconeSave} />
                            )}
                            {props.icone === 'iconeEdit' && (
                                <img src={iconeEdit} />
                            )}
                            {props.icone === 'iconeDelete' && (
                                <img src={iconeDelete} />
                            )}
                            <TextP className={props.classText}>
                                {props.titulo}
                            </TextP>
                        </button>
                    </Tooltip>
                ) : (
                    <button style={{width: props.width, marginBottom: props.marginBottom}} onClick={props.onClick} className={props.classCss} variant="contained" disabled={props.disabled}>
                        {props.icone === 'iconeSave' && (
                            <img src={iconeSave} />
                        )}
                        {props.icone === 'iconeEdit' && (
                            <img src={iconeEdit} />
                        )}
                        {props.icone === 'iconeDelete' && (
                            <img src={iconeDelete} />
                        )}
                        <TextP className={props.classText}>
                            {props.titulo}
                        </TextP>
                    </button>
                )
            }
        </>
    )
}

export default CustomButtonV2;