import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import { useNavigate } from 'react-router-dom';
import TabelaRedux from "../../../../contexts/Tabela";
import { cadastraEmpresaSub, getEmpresaSub, getEmpresaSubPorId, deleteEmpresaSubPorId, editaEmpresaSubPorId } from "../../../../services/ModuloGerenciador/EmpresaSub";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import Tabela from "../../../../components/Tabela/Tabela";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import MenuItem from '@mui/material/MenuItem';
import { getCidadeEstadoComSigla, getCidadeEstadoPorId } from "../../../../services/ModuloGerenciador/CidadeEstado";
import "./EmpresaSubsidiaria.css";
import { cnpjMask } from '../../../../regex/cnpjMask'
import Text from "../../../../components/Text/Text";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getEmpresaSubDependencias, getRegras } from "../../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { motion } from "framer-motion";
import { FormControl } from "@mui/material";
import ReactSelect from 'react-select';
import { cpfMask } from "../../../../regex/cpfMask";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import ClearIcon from '@mui/icons-material/Clear';

function EmpresaSubsidiaria(props) {

    const opcoes = [
        {
            'opcao': 'Simples nacional',
        },
        {
            'opcao': 'Lucro presumido',
        },
        {
            'opcao': 'Lucro Real',
        },
    ];

    const labelRegime = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesRegime = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloRegime = {
        control: (provided) => ({
            ...provided,
            minHeight: '64px',
            border: 'groove',
        }),
    };

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [listaCidades, setListaCidades] = useState([]);

    const [cidadeCompleta, setCidadeCompleta] = useState([]);

    const [valuesFiltro, setValuesFiltro] = useState({});

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const navigate = useNavigate();

    const [openUsuario, setOpenUsuario] = useState(false);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [cnpjMascara, setCnpjMascara] = useState("");

    const [cidadeObjeto, setCidadeObjeto] = useState([]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const buscarDependencias = async () => {
        setLoading(true);
        await getEmpresaSubDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data.cidadeEstados);
            setDadosTabela(result.data.empresaSubs);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCidadeEstado = async () => {
        await getCidadeEstadoComSigla(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEmpresa = async () => {
        setLoading(true);
        await getEmpresaSub(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherDadosEditando = async (result) => {
        let regimeTributario;
        if (result.data.regimeTributario === 1) {
            regimeTributario = "Simples nacional";
        } else if (result.data.regimeTributario === 2) {
            regimeTributario = "Lucro presumido"
        } else if (result.data.regimeTributario === 3) {
            regimeTributario = "Lucro real"
        }
        const regimeTributarioSelecionado = opcoesRegime.find(option =>
            option.value === regimeTributario
        );
        if (regimeTributarioSelecionado) {
            setValuesSelect(current => ({ ...current, regimeTributario: regimeTributarioSelecionado }));
        }
        const cidadeSelecionado = listaCidades.find(option =>
            option.idCidadeEstado === result.data.cidadeEstadoId
        );
        if (cidadeSelecionado) {
            setValuesSelect(current => ({ ...current, cidadeEstadoId: cidadeSelecionado }));
        }
    };

    const buscarEmpresaPorID = async () => {
        await getEmpresaSubPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    setValues(result.data);
                    setValidator(current => ({ ...current, cnpj: "true" }));
                    setValidator(current => ({ ...current, nome: "true" }));
                    setValidator(current => ({ ...current, cidadeEstadoId: "true" }));
                    setCidadeObjeto(result.data.cidadeEstado);
                    setCnpjMascara(cnpjMask(result.data.cnpj))
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const buscarEmpresaPorIDModal = async (id) => {
        await getEmpresaSubPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result);
                setValues(result.data);
                setCidadeObjeto(result.data.cidadeEstado);
                setCnpjMascara(cnpjMask(result.data.cnpj))
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const cadastrar = async () => {
        if ((values.nome !== "" || values.cnpj !== "" || values.cidadeEstadoId !== "") &&
            (validator.nome === "true" && validator.cnpj === "true" && validator.cidadeEstadoId === "true")) {
            setLoading(true);
            await cadastraEmpresaSub(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarEmpresa();
                    setDadosRequestControllerRedux("Fechar Modal Empresa");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.nome !== "" || values.cnpj !== "" || values.cidadeEstadoId !== "") &&
            (validator.nome === "true" && validator.cnpj === "true" && validator.cidadeEstadoId === "true")) {
            setLoading(true);
            await editaEmpresaSubPorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarEmpresa();
                    setTabelaRedux({});
                    setTextoSucesso("Editado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    setCnpjMascara('');
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const deletarEmpresa = async () => {
        setLoading(true)
        await deleteEmpresaSubPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarEmpresa();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCidadeEstadoPorId = async () => {
        await getCidadeEstadoPorId(values.cidadeEstadoId).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(current => ({ ...current, cidadeOBJ: result.data }))
                }
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, cnpj: "false" }));
        setValidator(current => ({ ...current, nome: "false" }));
        setValidator(current => ({ ...current, cidadeEstadoId: "false" }));
        if (editando) {
            setValidator(current => ({ ...current, cnpj: "true" }));
            setValidator(current => ({ ...current, nome: "true" }));
            setValidator(current => ({ ...current, cidadeEstadoId: "true" }));
        }
    }, [loading]);

    const handleChangeCnpj = (event) => {
        setValues(current => ({ ...current, cnpj: event.target.value }));
        setCnpjMascara(cnpjMask(event.target.value));
    }

    useEffect(() => {
        if (editando) {
            buscarEmpresaPorID();
        }
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [editando]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [telaCadastro]);

    useEffect(() => {
        buscarDependencias();
    }, []);

    useEffect(() => {
        if (props.idAtual) {
            buscarEmpresaPorIDModal(props.idAtual);
        }
    }, [listaCidades]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Uso Comum') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Uso Comum') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    const [valuesSelect, setValuesSelect] = useState([]);

    const labelCidadeEstado = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.cidade} - {data.estado}
            {valuesSelect.cidadeEstadoId === data &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearCidade();
                    }} />
            }
        </div>
    );

    const clearCidade = () => {
        setValues((current) => ({
            ...current,
            cidadeEstadoId: null,
        }));
        setValuesSelect(current => ({ ...current, cidadeEstadoId: '' }));
        setValidator((current) => ({
            ...current,
            cidadeEstadoId: "false",
        }));
    };

    const estiloCidadeEstado = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '270px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const opcoesCidadeEstado = listaCidades.map((data) => ({
        value: data.idCidadeEstado,
        label: `${data.cidade} - ${data.estado}`,
        ...data
    }));

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Empresas Subsidiárias" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Empresas Subsidiárias" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Empresa Subsidiária</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idEmpresaSub"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.EmpresaSub}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Cadastro de Empresa / "}</p>
                                                        <p className="indicador-emb">{"Dados Empresa"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Empresa" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    id="nome"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Ex: TS"
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">CPF/CNPJ:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        let valorMascara = e.target.value;
                                                                        if (e.target.value.length <= 14) {
                                                                            valorMascara = cpfMask(e.target.value);
                                                                        } else if (e.target.value.length > 13) {
                                                                            valorMascara = cnpjMask(e.target.value);
                                                                        }
                                                                        setValues(current => ({ ...current, cnpj: valorMascara }));
                                                                        setValidator(current => ({ ...current, cnpj: "true" }));
                                                                    }}
                                                                    placeholder="Ex: 00.000.000/0000-00"
                                                                    value={values.cnpj}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade/UF:</p>
                                                                <FormControl sx={{ width: 270 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.cidadeEstadoId}
                                                                        isDisabled={props.desabilitar}
                                                                        options={opcoesCidadeEstado}
                                                                        formatOptionLabel={labelCidadeEstado}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: selectedOption.idCidadeEstado,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, cidadeEstadoId: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Regime Tributário:</p>
                                                                <FormControl sx={{ width: 270 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.regimeTributario}
                                                                        options={opcoesRegime}
                                                                        formatOptionLabel={labelRegime}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                if (selectedOption.value === "Simples nacional") {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        regimeTributario: 1,
                                                                                    }));
                                                                                } else if (selectedOption.value === "Lucro presumido") {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        regimeTributario: 2,
                                                                                    }));
                                                                                } else if (selectedOption.value === "Lucro real") {
                                                                                    setValues((current) => ({
                                                                                        ...current,
                                                                                        regimeTributario: 3,
                                                                                    }));
                                                                                }
                                                                                setValuesSelect(current => ({ ...current, regimeTributario: selectedOption }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.cnpj !== "" && values.cidadeEstadoId !== "")
                                                                                    && (validator.nome === "true" && validator.cnpj === "true" && validator.cidadeEstadoId === "true")) {
                                                                                    cadastrar();
                                                                                    setCnpjMascara('');
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.cnpj !== "" && values.cidadeEstadoId !== "")
                                                                                    && (validator.nome === "true" && validator.cnpj === "true" && validator.cidadeEstadoId === "true")) {
                                                                                    cadastrar();
                                                                                    setCnpjMascara('');
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setCnpjMascara(''); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarEmpresa}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default EmpresaSubsidiaria;