import React, { useContext, useState, useEffect } from "react";
import "./DashboardOcorrencias.css";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import { getOcorrenciaSearch } from "../../../../services/ModuloGerenciador/Search";
import { motion } from "framer-motion";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { getRegras } from "../../../../services/ModuloGerenciador/Regras";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import TabelaOcorrencias from "../../../../components/TabelaOcorrencias/TabelaOcorrencias";
import TabelaOcorrenciasDash from "../../../../components/TabelaOcorrenciasDash/TabelaOcorrenciasDash";

function DashboardOcorrencias(props) {

    const itensFiltro = [
        {
            'nomeColuna': 'Ocorrência',
            'field': 'ocorrencia'
        },
        {
            'nomeColuna': 'Tipo Ocorrência',
            'field': 'tipoOcorrencia'
        },
        {
            'nomeColuna': 'Sub Tipo Ocorrência',
            'field': 'subTipoOcorrencia'
        },
        {
            'nomeColuna': 'Data Ocorrência',
            'field': 'dataOcorrencia'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
    ];

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [loading, setLoading] = useState(true);

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoErro, setTextoErro] = useState("");

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const [dadosTabelaCopiaFiltro, setDadosTabelaCopiaFiltro] = useState([]);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const converterData = (data) => {
        const parts = data.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const buscarSearch = async (ordenarPor) => {
        setLoading(true);
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        const data1 = converterData(primeiroDia);
        const data2 = converterData(ultimoDia);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getOcorrenciaSearch(dadosUsuarioRedux.token, pesquisaFormatada, valuesPesquisa.embarqueInicio ? valuesPesquisa.embarqueInicio : data1, valuesPesquisa.embarqueFinal ? valuesPesquisa.embarqueFinal : data2, ordenarPor).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setDadosTabelaCopiaFiltro(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopiaFiltro);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    useEffect(() => {
        buscarSearch();
    }, []);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, []);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Dashboard') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Dashboard') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Ocorrências" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Ocorrências" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="dash-ocorrencias">
                                    <FiltroV2
                                        classe='div-filtrar-embarque-v2'
                                        classOption='select-options'
                                        itensFiltro={itensFiltro}
                                        valuesPesquisa={valuesPesquisa}
                                        setValuesPesquisa={setValuesPesquisa}
                                        buscarSearch={buscarSearch}
                                        limpar={limpar}
                                    />
                                    <TabelaOcorrenciasDash
                                        colunas={dadosTabelaRedux.OcorrenciaEmbarque}
                                        dados={dadosTabela}
                                        buscarSearch={buscarSearch} />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}
export default DashboardOcorrencias;