import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import './AlteraSenha.css'
import Box from '@mui/material/Box';
import { InputLabel, Input } from "@mui/material";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CoresRedux from "../../../../contexts/Cor";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx"
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx"
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { useNavigate } from "react-router-dom";
import { alterarSenha, mandarCodigoAlterarSenha } from "../../../../services/ModuloGerenciador/Solicitacao";
import RequestControllerRedux from "../../../../contexts/RequestController";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Text from "../../../../components/Text/Text";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";

function AlteraSenha() {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchTerm = searchParams.get('codigo') || '';

    const handleSearch = (value) => {
        const codigo = value;

        if (codigo) {
            setSearchParams({ codigo });
        } else {
            setSearchParams({});
        }
    }

    useEffect(() => {
        setValues(current => ({ ...current, codigo: searchTerm }));
    }, [searchTerm]);

    const [values, setValues] = useState([]);

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [passou, setPassou] = useState(false);

    const navigate = useNavigate();

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    }

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    const enviar = async () => {
        setLoading(true);
        if (values.senha === values.confirmarSenha) {
            if (values.senha != '12345678' && values.confirmarSenha != '12345678') {
                if (values.senha.length >= 8 && values.confirmarSenha.length >= 8) {
                    await alterarSenha(values.senha, values.codigo, dadosUsuarioRedux.token).then((result) => {
                        if (result.status === 200) {
                            setLoading(false);
                            setTextoSucesso("Senha Alterada Com Sucesso");
                            setOpenToastSucesso(true);
                            navigate("/")
                            setDadosRequestControllerRedux("Alteracao Senha");
                        }
                    }).catch((error) => {
                        if (error.response.status != 200) {
                            setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                        } else {
                            setTextoErro(error.response.data);
                        }
                        setOpenToastErro(true);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                    setTextoErro("Senha deve ser maior que 8 caracteres");
                    setOpenToastErro(true);
                }
            } else {
                setLoading(false);
                setTextoErro("Senha deve ser diferente que 12345678");
                setOpenToastErro(true);
            }
        } else {
            setLoading(false);
            setTextoErro("Senhas devem ser iguais");
            setOpenToastErro(true);

        }
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, []);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Email Senha Codigo") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Seu código foi enviado para o seu email");
            setOpenToastSucesso(true);
        }
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Mudar Senha" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV3 selecionado="Mudar Senha" />
                    {!loading ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="containerEdita"
                        >
                            <p>Redefinir a Senha</p>
                            <div className="form">
                                <hr></hr>
                                <div className="container-edita">
                                    <div className="grid-item">
                                        <InputLabel htmlFor="email">Código</InputLabel>
                                        <input
                                            id="email"
                                            type="text"
                                            className="input-text"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                                setValues(current => ({ ...current, codigo: e.target.value }));
                                            }} />
                                    </div>
                                    <div className="grid-item">
                                        <InputLabel htmlFor="email"><Text texto="Nova Senha" /></InputLabel>
                                        <Input className="input-text-senha" id="standard-adornment-password" onChange={(e) => setValues(current => ({ ...current, senha: e.target.value }))}
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.senha}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toque Para Ver"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>}></Input>
                                    </div>
                                    <div className="grid-item">
                                        <InputLabel htmlFor="email"><Text texto="Confirmar Senha" /></InputLabel>
                                        <Input className="input-text-senha" id="standard-adornment-password" onChange={(e) => setValues(current => ({ ...current, confirmarSenha: e.target.value }))}
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            value={values.confirmarSenha}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toque Para Ver"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>}></Input>
                                    </div>
                                </div>
                            </div>
                            <Box display={'flex'} className="botoes">
                                <CustomButton
                                    classCss="custom-button-margin"
                                    titulo="Voltar"
                                    cor={dadosCoresRedux.botaoPadrao}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                />
                                <CustomButton
                                    classCss="custom-button-margin"
                                    titulo="Enviar"
                                    cor={dadosCoresRedux.botaoPadrao}
                                    onClick={() => {
                                        enviar();
                                    }}
                                />
                            </Box>
                        </motion.div>
                    ) : (
                        <LoadingComponent />
                    )}
                    <div>
                        <ToastSucesso
                            texto={textoSucesso}
                            open={openToastSucesso}
                            onClose={handleCloseToastSucesso}
                        />
                        <ToastErro
                            texto={textoErro}
                            open={openToastErro}
                            onClose={handleCloseToastErro}
                        />
                    </div>
                </div >
            )
            }
        </motion.div>
    );
}

export default AlteraSenha;