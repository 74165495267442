import { api } from "./UrlPadrao"


export const getDiariaTodos = async (token) => {
  return api.get("Diarias/Todos",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const cadastraDiaria = async (diaria, token) => {
  return api.post("Diarias",
    diaria
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const calcularDiarias = async (diaria, token) => {
  return api.post("Diarias/calcular-diarias",
    diaria
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const criarDiarias = async (diaria, token) => {
  return api.post("Diarias/criar-diarias",
    diaria
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getDiariaGrupoCliente = async (id, token) => {
  return api.get("Diarias/grupo/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getDiariaPorId = async (id, token) => {
  return api.get("Diarias/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteDiariaPorId = async (id, token) => {
  return api.delete("Diarias/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaDiariaPorId = async (diaria, token) => {
  return api.put("Diarias/" + diaria.id,
    diaria,
  {
    headers: {
      'Authorization': token
    }
  })
}