import React, { useState, createContext } from 'react';

const valorInicial = {
    'CidadeEstado': [
        {
            'nomeColuna': 'Cidade',
            'field': 'cidade'
        },
        {
            'nomeColuna': 'Estado',
            'field': 'estado'
        },
        {
            'nomeColuna': 'Abreviação',
            'field': 'abreviacao'
        },
    ],
    'Embalagem': [
        {
            'nomeColuna': 'Embalagem',
            'field': 'nome'
        },
    ],
    'Cliente': [
        {
            'nomeColuna': 'Nome Fantasia',
            'field': 'nomeFantasia'
        },
        {
            'nomeColuna': 'Razão Social',
            'field': 'razaoSocial'
        },
        {
            'nomeColuna': 'CNPJ',
            'field': 'cnpj'
        },
        {
            'nomeColuna': 'Endereço',
            'field': 'endereco',
            'objetoEndereco': 'true'
        },
        {
            'nomeColuna': 'Apelido',
            'field': 'apelido'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'diFreteFiscal'
        },
        {
            'nomeColuna': 'Pedágio',
            'field': 'diPedagio'
        },
        {
            'nomeColuna': 'Classificação/Descarga',
            'field': 'diClassificacao'
        },
        {
            'nomeColuna': 'Usuário',
            'field': 'usuarioClientes',
            'objetoUsuarios': 'true'
        },
    ],
    'Embarque': [
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Eixos',
            'field': 'numeroEixos',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
        {
            'nomeColuna': 'Entregas',
            'field': 'nEntregas'
        },
        {
            'nomeColuna': 'Comprovante',
            'field': 'comprovante'
        },
        {
            'nomeColuna': 'Check-in',
            'field': 'checkInFeito'
        },
        {
            'quebraColunaEmbarque': false
        },
    ],
    'ModeloEmbarque': [
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Eixos',
            'field': 'numeroEixos',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
        {
            'nomeColuna': 'Entregas',
            'field': 'nEntregas'
        },
        {
            'nomeColuna': 'Comprovante',
            'field': 'comprovante'
        },
        {
            'nomeColuna': 'Check-in',
            'field': 'checkInFeito'
        },
        {
            'quebraColunaEmbarque': false
        },
    ],
    'EmpresaSub': [
        {
            'nomeColuna': 'Empresa',
            'field': 'nome'
        },
        {
            'nomeColuna': 'CNPJ',
            'field': 'cnpj'
        },
        {
            'nomeColuna': 'Cidade/UF',
            'field': 'cidade',
            'objetoUnidadeEmbarque': 'true'
        },
        {
            'nomeColuna': 'Regime Tributário',
            'field': 'regimeTributario'
        }
    ],
    'Filial': [
        {
            'nomeColuna': 'Filial',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Localização',
            'field': 'complementoLocalizacao'
        },
        {
            'nomeColuna': 'Responsavel',
            'field': 'responsavel'
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefone'
        },
        {
            'nomeColuna': 'E-mail',
            'field': 'email'
        },
        {
            'nomeColuna': 'Cidade/UF',
            'field': 'cidade',
            'objetoUnidadeEmbarque': 'true'
        },
    ],
    'Motivo': [
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ],
    'MeioPagamento': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        }
    ],
    'Mercadoria': [
        {
            'nomeColuna': 'Mercadoria',
            'field': 'nome'
        },
    ],
    'Motorista': [
        {
            'nomeColuna': 'Nome Motorista',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Número Documento',
            'field': 'numeroDocumento'
        },
        {
            'nomeColuna': 'Tipo Documento',
            'field': 'tipoDocumento'
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefone'
        },
        {
            'nomeColuna': 'Pessoa Emergência',
            'field': 'nomeEmergencia'
        },
        {
            'nomeColuna': 'Telefone Emergência',
            'field': 'telefoneEmergencia'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Usuário',
            'field': 'usuarioClientes',
            'objetoUsuarios': 'true'
        },
        {
            'nomeColuna': 'Bloqueado',
            'field': 'bloqueado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ],
    'Notificacao': [
        {
            'nomeColuna': 'Título',
            'field': 'titulo'
        },
        {
            'nomeColuna': 'Mensagem',
            'field': 'mensagem'
        },
        {
            'nomeColuna': 'Ação',
            'field': 'acao'
        },
        {
            'nomeColuna': 'Número de Visualizações',
            'field': 'numeroVisualizacoes'
        },
        {
            'nomeColuna': 'Data',
            'field': 'data'
        }
    ],
    'Pedido': [
        {
            'nomeColuna': 'N Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'tipoRota'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Permitir Alterar Frete Fiscal',
            'field': 'permiteAlterarFreteFiscal'
        },
        {
            'nomeColuna': 'Valor Viagem',
            'field': 'valorEmbarque',
            'objetoValorEmbarque': 'true'
        },
        {
            'nomeColuna': 'ICMS Embutido',
            'field': 'icmsEmbutido'
        },
        {
            'nomeColuna': 'ICMS Embarcador',
            'field': 'icmsEmbarcador'
        },
        {
            'nomeColuna': 'Frete Normal',
            'field': 'freteNormal'
        },
        {
            'quebraColunaPedido': false
        },
    ],
    'Proprietario': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'CNPJ',
            'field': 'cnpj'
        },
        {
            'nomeColuna': 'Usuário',
            'field': 'usuarioClientes',
            'objetoUsuarios': 'true'
        },
    ],
    'Rota': [
        {
            'nomeColuna': 'Rota',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino'
        },
    ],
    'Solicitacoes': [
        {
            'nomeColuna': 'Usuário',
            'field': 'usuario'
        },
        {
            'nomeColuna': 'Email',
            'field': 'email'
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefone'
        },
        {
            'nomeColuna': 'Nascimento',
            'field': 'nascimento'
        },
        {
            'nomeColuna': 'Aceitar',
            'field': 'aceitar'
        },
        {
            'nomeColuna': 'Recusar',
            'field': 'recusar'
        },
    ],
    'Status': [
        {
            'nomeColuna': 'Status',
            'field': 'nome'
        },
    ],
    'TaxaIcms': [
        {
            'nomeColuna': 'Taxa ICMS',
            'field': 'taxa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'UF Origem',
            'field': 'ufOrigem'
        },
        {
            'nomeColuna': 'UF Destino',
            'field': 'ufDestino'
        },
    ],
    'UnidadeEmbarque': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Cidade',
            'field': 'usuario',
            'objetoUnidadeEmbarque': 'true'
        },
    ],
    'UsuarioGeren': [
        {
            'nomeColuna': 'Nome',
            'field': 'usuario'
        },
        {
            'nomeColuna': 'Senha',
            'field': ''
        },
        {
            'nomeColuna': 'E-Mail',
            'field': 'email'
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefone'
        },
        {
            'nomeColuna': 'Data Nascimento',
            'field': 'nascimento'
        },
        {
            'nomeColuna': 'Permissões',
            'field': 'permissoes'
        },
        {
            'nomeColuna': 'Último Login',
            'field': 'ultimoLogin'
        },
        {
            'nomeColuna': 'Último Logout',
            'field': 'ultimoLogout'
        },
    ],
    'Veiculo': [
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'Motorista2',
            'field': 'motorista2'
        },
        {
            'nomeColuna': 'Eixos',
            'field': 'numeroEixos'
        },
        {
            'nomeColuna': 'Tipo Veículo APISUL',
            'field': 'tipoVeiculo'
        },
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria',
            'objetoTipoCarroceria': 'true',
        },
        {
            'nomeColuna': 'Tipo Veículo',
            'field': 'tipoVeiculoConjuntos',
            'objetoTipoVeiculo': 'true',
        },
        {
            'nomeColuna': 'Ativo Diária',
            'field': 'ativoDiaria'
        },
        {
            'nomeColuna': 'Cidade',
            'field': 'cidadeEstado',
            'objetoCidadeEstado': 'true',
        },
        {
            'nomeColuna': 'Data Última Localização',
            'field': 'dataAtualizacaoLocalizacao'
        }
    ],
    'Caminhao': [
        {
            'nomeColuna': 'Placa',
            'field': 'placa'
        },
        {
            'nomeColuna': 'Ano',
            'field': 'ano'
        },
        {
            'nomeColuna': 'Renavam',
            'field': 'renavam'
        },
        {
            'nomeColuna': 'Marca',
            'field': 'marca'
        },
        {
            'nomeColuna': 'Modelo',
            'field': 'modelo'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Eixos',
            'field': 'numeroEixos'
        },
    ],
    'Carreta': [
        {
            'nomeColuna': 'Placa',
            'field': 'placa'
        },
        {
            'nomeColuna': 'Ano',
            'field': 'ano'
        },
        {
            'nomeColuna': 'Renavam',
            'field': 'renavam'
        },
        {
            'nomeColuna': 'Marca',
            'field': 'marca'
        },
        {
            'nomeColuna': 'Modelo',
            'field': 'modelo'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Eixos',
            'field': 'numeroEixos'
        },
    ],
    'Avisos': [
        {
            'nomeColuna': 'Mensagem',
            'field': 'mensagem'
        },
        {
            'nomeColuna': 'Data',
            'field': 'data'
        },
    ],
    'TipoCheckagem': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Notificar Automatico',
            'field': 'notificarAutomatico'
        },
        {
            'nomeColuna': 'Tipo Notificação',
            'field': 'tipoNotificacao'
        },
        {
            'nomeColuna': 'Notificar Data',
            'field': 'notificarACadaData'
        },
        {
            'nomeColuna': 'Notificar KM',
            'field': 'notificarACadaKM'
        },
    ],
    'Checkagem': [
        {
            'nomeColuna': 'Dados',
            'field': 'dados'
        },
        {
            'nomeColuna': 'Data',
            'field': 'dataCheckagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Local',
            'field': 'local'
        },
        {
            'nomeColuna': 'Tipo Manutenção',
            'field': 'tipoManutencao'
        },
        {
            'nomeColuna': 'Proxima Revisão Data',
            'field': 'proximaRevisaoData'
        },
        {
            'nomeColuna': 'Proxima Revisão KM',
            'field': 'proximaRevisaoKM'
        },
        {
            'nomeColuna': 'Tipo Check',
            'field': 'tipoCheck'
        },
    ],
    'TipoRastreamento': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'IP',
            'field': 'ip'
        },
    ],
    'Rastreamento': [
        {
            'nomeColuna': 'Usuário Rastreamento',
            'field': 'usuarioRastreamento'
        },
        {
            'nomeColuna': 'Senha',
            'field': 'senha'
        },
        {
            'nomeColuna': 'Tipo Veículo',
            'field': 'tipoVeiculo'
        },
        {
            'nomeColuna': 'Tipo Rastreamento',
            'field': 'tipoRastreamento',
            'objetoRastreamento': 'true'
        },
        {
            'nomeColuna': 'Veículo',
            'field1': 'carreta',
            'field2': 'caminhao',
            'objetoVeiculo': 'true'
        },
        {
            'nomeColuna': 'Fabricante Rastreamento',
            'field': 'fabricanteRastreamento'
        },
        {
            'nomeColuna': 'Número Rastreador',
            'field': 'numeroRastreador'
        },
    ],
    'GrupoClientes': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
    ],
    'Cockpit': [
        {
            'nomeColuna': 'Projetado',
            'field': 'projetado'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino'
        },
        {
            'nomeColuna': 'Grupo Cliente',
            'field': 'grupoCliente',
            'objetoGrupoCliente': 'true'
        },
    ],
    'GrupoComClientes': [
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'objetoCliente': 'true'
        },
        {
            'nomeColuna': 'Grupo Cliente',
            'field': 'grupoCliente',
            'objetoGrupoCliente': 'true'
        },
    ],
    'GrupoPrecificacao': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
    ],
    'ValorEmbarque': [
        {
            'nomeColuna': 'Eixos',
            'field': 'eixos'
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Valor Eixo',
            'field': 'valorEixo'
        },
        {
            'nomeColuna': 'Adicional Carga Descarga',
            'field': 'adicionalCargaDescarga'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Descarga',
            'field': 'descarga'
        },
        {
            'nomeColuna': 'Pedágio',
            'field': 'pedagio'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota',
            'objetoRota': 'true',
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'objetoCliente': 'true'
        },
        {
            'nomeColuna': 'Grupo Precificação',
            'field': 'grupoPrecificacao',
            'objetoGrupoPrecificacao': 'true'
        },
    ],
    'MotivoOnTime': [
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ],
    'GrupoCarga': [
        {
            'nomeColuna': 'Nome Grupo',
            'field': 'nomeGrupo'
        },
    ],
    'GrupoCargaVinculo': [
        {
            'nomeColuna': 'Grupo Carga',
            'field': 'grupoCarga',
            'objetoGrupoCarga': 'true'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista',
            'objetoMotorista': 'true'
        },
        {
            'nomeColuna': 'Proprietário',
            'field': 'proprietario',
            'objetoProprietario': 'true'
        },
    ],
    'Cotacao': [
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidadeEmbarque',
            'objetoUnidadeEmbarqueNome': 'true'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota',
            'objetoRota': 'true'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'objetoCliente': 'true'
        },
        {
            'nomeColuna': 'Valor',
            'field': 'valor',
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms',
        },
        {
            'nomeColuna': 'Pedágio',
            'field': 'pedagio',
        },
        {
            'nomeColuna': 'Distância em KM',
            'field': 'distanciaKm',
        },
        {
            'nomeColuna': 'Valor por KM',
            'field': 'valorPorKm',
        },
        {
            'nomeColuna': 'Valor Desejavel Motorista',
            'field': 'valorDesejavelMotorista',
        },
        {
            'nomeColuna': 'Descrição Rota',
            'field': 'descricaoRota',
        },
        {
            'nomeColuna': 'Foi Carregado',
            'field': 'foiCarregado',
        },
        {
            'nomeColuna': 'Tipo Veiculo',
            'field': 'tipoVeiculo',
            'objetoTipoVeiculo': 'true'
        },
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'cotacaoCarroceria',
            'objetoTipoCarroceria': 'true'
        },
        {
            'nomeColuna': 'Segmento Cliente',
            'field': 'segmentoCliente',
            'objetoSegmentoCliente': 'true'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivoCotacao',
            'objetoMotivo': 'true'
        },
        {
            'nomeColuna': 'Data Cotação',
            'field': 'dataCotacao',
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel',
        },
        {
            'nomeColuna': 'Nome',
            'field': 'nomeContato',
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefoneContato',
        },
        {
            'nomeColuna': 'Email',
            'field': 'emailContato',
        },
        {
            'nomeColuna': 'Obsevação',
            'field': 'observacao',
        },
    ],
    'TipoVeiculo': [
        {
            'nomeColuna': 'Nome Tipo Veículo',
            'field': 'nome'
        },
    ],
    'TipoCarroceria': [
        {
            'nomeColuna': 'Nome Tipo Carroceria',
            'field': 'nome'
        },
    ],
    'MotivoCotacao': [
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ],
    'SegmentoCliente': [
        {
            'nomeColuna': 'Segmento Cliente',
            'field': 'nome'
        },
    ],
    'Ocorrencia': [
        {
            'nomeColuna': 'Ocorrência',
            'field': 'tipo'
        },
    ],
    'TipoOcorrencia': [
        {
            'nomeColuna': 'Tipo Ocorrência',
            'field': 'nome'
        },
    ],
    'SubTipoOcorrencia': [
        {
            'nomeColuna': 'Sub Tipo Ocorrência',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Tipo Ocorrência',
            'field': 'tipoOcorrencia',
            'objetoTipoOcorrencia': 'true'
        },
    ],
    'OcorrenciaEmbarque': [
        {
            'nomeColuna': ' Ocorrência',
            'field': 'ocorrencia'
        },
        {
            'nomeColuna': 'Sub Tipo Ocorrência',
            'field': 'tipoOcorrencia'
        },
        {
            'nomeColuna': 'Data Ocorrência',
            'field': 'dataOcorrencia'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
    ],
    'Filiais': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Sábado Dia Útil',
            'field': 'sabadoDiaUtil'
        },
    ],
    'Sorteio': [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Apenas CPF',
            'field': 'apenasCpf'
        },
        {
            'nomeColuna': 'Cupons',
            'field': 'cupoms'
        },
        {
            'nomeColuna': 'Cupons Por Viagem',
            'field': 'cuponsPorViagem'
        },
        {
            'nomeColuna': 'Data Sorteio',
            'field': 'dataSorteio'
        },
        {
            'nomeColuna': 'Encerrado',
            'field': 'encerrado'
        },
        {
            'nomeColuna': 'Fim Busca',
            'field': 'fimBusca'
        },
        {
            'nomeColuna': 'Início Busca',
            'field': 'inicioBusca'
        },
        {
            'nomeColuna': 'Motoristas',
            'field': 'motoristas'
        },
        {
            'nomeColuna': 'Número KM por Cupom',
            'field': 'numerosKmPorCupom'
        },
        {
            'nomeColuna': 'Proprietários',
            'field': 'proprietarios'
        },
        {
            'nomeColuna': 'Sorteados',
            'field': 'sorteados'
        },
    ],
    'ConfiguracaoEmpresa': [
        {
            'nomeColuna': 'Tempo Diária em Minutos',
            'field': 'tempoDiariaMinutos'
        },
        {
            'nomeColuna': 'Grupo Cliente',
            'field': 'grupoCliente',
            'objetoGrupoCliente': 'true'
        },
        {
            'nomeColuna': 'Diária Viagem',
            'field': 'diariaEmbarque'
        },
        {
            'nomeColuna': 'Diária Entrega',
            'field': 'diariaEntrega'
        },
        {
            'nomeColuna': 'Soma Diária',
            'field': 'somaDiarias'
        },
    ],
    'Opcoes': [
        {
            'opcao': 'Sim',
        },
        {
            'opcao': 'Não',
        },
    ],
    'Contexto': [
        {
            'nome': 'Toledo',
        },
        {
            'nome': 'Glória de Dourados',
        },
        {
            'nome': 'Paranavaí',
        },
        {
            'nome': 'Rio Verde',
        },
    ],
};

const NomeTabela = createContext();

export default NomeTabela;

export const NomeTabelaRedux = ({ children }) => {
    const [dadosTabelaRedux, setDadosTabelaRedux] = useState(valorInicial);

    return (
        <NomeTabela.Provider value={{
            dadosTabelaRedux,
            setDadosTabelaRedux,
        }}>
            {children}
        </NomeTabela.Provider>
    );
};