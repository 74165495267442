import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import "./GerenciamentoPedido.css";
import { cadastraPedido, editaPedidoPorId, getPedidoPorId, deletePedidoPorId, getHistoricoPedido, getNumeroSeguintePedido, getPedidoExiste, getPedidoAprovacao, aprovarPedido, reprovarPedido, aprovarPedidoLista } from "../../../services/ModuloGerenciador/Pedido.js";
import { getRegras, getPedidosGeral, getDependenciasPedidos } from "../../../services/ModuloGerenciador/Geral.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import CoresRedux from "../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ModalHistoricoEdicao from "../../../components/ModalHistoricoEdicao/ModalHistoricoEdicao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import Select from 'react-select';
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getTaxaIcmsPorUf } from "../../../services/ModuloGerenciador/TaxaIcms.js";
import { valorMask } from '../../../regex/valorMask.js';
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import { FormControl } from "@mui/material";
import RequestControllerRedux from "../../../contexts/RequestController.js";
import InfoIcon from '@mui/icons-material/Info';
import { motion } from "framer-motion";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getPedidoSearch } from "../../../services/ModuloGerenciador/Search.js";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2.js";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import { buscaKmCoordenadas } from "../../../services/ModuloRastreamento/RotaGoogle.js";
import CloseIcon from "../../../components/CloseIcon/CloseIcon.js";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro.js";
import LoadingBar from "../../../components/LoadingBar/LoadingBar.js";
import NewIcon from "../../../components/NewIcon/NewIcon.js";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import ClearIcon from '@mui/icons-material/Clear';
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import { useLocation } from "react-router-dom";
import FiltrosRedux from "../../../contexts/Filtros.js";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";

function GerenciamentoPedido(props) {

    const itensFiltro = [
        {
            'nomeColuna': 'N Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'tipoRota'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
    ];

    const colunasHistorico = [
        {
            'nomeColuna': 'N Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'tipoRota'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Data Alteração',
            'field': 'data'
        },
        {
            'nomeColuna': 'Usuário',
            'field': 'usuario'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const [loadingCadastro, setLoadingCadastro] = useState(false);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [clientesKm, setValuesClientesKm] = useState([]);

    const [icmsCopia, setIcmsCopia] = useState();

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const [openCadastroRota, setOpenCadastroRota] = useState(false);

    const [openCadastroCliente, setOpenCadastroCliente] = useState(false);

    const [openHistoricoEdicao, setOpenHistoricoEdicao] = useState(false);

    const [disablePedido, setDisablePedido] = useState(false);

    const [openAprovar, setOpenAprovar] = useState(false);

    const handleCloseAprovar = (value) => {
        setOpenAprovar(false);
    };

    const [openAprovarTodos, setOpenAprovarTodos] = useState(false);

    const handleCloseAprovarTodos = (value) => {
        setOpenAprovarTodos(false);
    };

    const [openReprovar, setOpenReprovar] = useState(false);

    const handleCloseReprovar = (value) => {
        setOpenReprovar(false);
    };

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const handleCloseCadastroRota = (value) => {
        if (idAtual) {
            setOpenCadastroRota(false);
        } else {
            setLoadingSelectRota(true);
            buscarDependenciasCadastroSemLoading();
            setOpenCadastroRota(false);
        }
    };

    const handleCloseCadastroCliente = (value) => {
        if (idAtual) {
            setOpenCadastroCliente(false);
        } else {
            setLoadingSelectCliente(true);
            buscarDependenciasCadastroSemLoading();
            setOpenCadastroCliente(false);
        }
    };

    const handleCloseHistoricoEdicao = (value) => {
        setOpenHistoricoEdicao(false);
    }

    const [embarcador, setEmbarcador] = useState([]);

    const [taxaIcms, setTaxaIcms] = useState();

    const [rota, setRota] = useState([]);

    const [valoresHistorico, setValoresHistorico] = useState([]);

    const [clientes, setListaClientes] = useState([]);

    const [listaRotas, setListaRotas] = useState([]);

    const [listaValores, setListaValores] = useState([]);

    const [loadingSelectRota, setLoadingSelectRota] = useState(false);

    const [loadingSelectCliente, setLoadingSelectCliente] = useState(false);

    const [openCadastroValorEmbarque, setOpenCadastroValorEmbarque] = useState(false);

    const [loadingSelectValorEmbarque, setLoadingSelectValorEmbarque] = useState(false);

    const [pedidoValido, setPedidoValido] = useState(true);

    const [tipoRotaValido, setTipoRotaValido] = useState(true);

    const [freteFiscalValido, setFreteFiscalValido] = useState(true);

    const [pedagioValido, setPedagioValido] = useState(true);

    const [kmValido, setKMValido] = useState(true);

    const [clienteValido, setClienteValido] = useState(true);

    const [classificacaoValido, setClassificacaoValido] = useState(true);

    const [embarcadorValido, setEmbarcadorValido] = useState(true);

    const [destinatarioValido, setDestinatarioValido] = useState(true);

    const [freteMotoristaValido, setFreteMotoristaValido] = useState(true);

    const customStylesErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const handleCloseCadastroValorEmbarque = (value) => {
        if (idAtual) {
            setOpenCadastroValorEmbarque(false);
        } else {
            setLoadingSelectValorEmbarque(true);
            buscarDependenciasCadastroSemLoading();
            setOpenCadastroValorEmbarque(false);
        }
    };

    const [idAtual, setIdAtual] = useState();

    const [disable, setDisable] = useState(false);

    const [diFreteFiscal, setDiFreteFiscal] = useState(false);

    const [diPedagio, setDiPedagio] = useState(false);

    const [diClassificacao, setDiClassificacao] = useState(false);

    const buscarPedidos = async () => {
        setLoading(true);
        setLoadingCadastro(true);
        await getPedidoAprovacao(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setLoadingCadastro(false);
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
            if (props.telaCadastro != true) {
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const aprovaPedido = async () => {
        await aprovarPedido(dadosUsuarioRedux.token, tabelaRedux.colunaSelecionada).then((result) => {
            buscarPedidos();
            setTextoSucesso("Pedido aprovado com sucesso.");
            setOpenToastSucesso(true);
            setValues({});
            setDadosTabela(dadosTabelaCopia);
            setValuesSelect({});
            setTelaCadastro(false);
            setEditando(false);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const aprovaPedidoTodos = async () => {
        await aprovarPedidoLista(dadosUsuarioRedux.token, listaSelecionados).then((result) => {
            buscarPedidos();
            setTextoSucesso("Pedido aprovado com sucesso.");
            setOpenToastSucesso(true);
            setValues({});
            setDadosTabela(dadosTabelaCopia);
            setValuesSelect({});
            setTelaCadastro(false);
            setEditando(false);
            setTabelaRedux({});
            setListaSelecionados([]);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const reprovaPedido = async () => {
        await reprovarPedido(dadosUsuarioRedux.token, tabelaRedux.colunaSelecionada).then((result) => {
            buscarPedidos();
            setTextoSucesso("Pedido reprovado com sucesso.");
            setOpenToastSucesso(true);
            setValues({});
            setDadosTabela(dadosTabelaCopia);
            setValuesSelect({});
            setTelaCadastro(false);
            setEditando(false);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getPedidoSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }


    const buscarNumeroPedido = async () => {
        await getNumeroSeguintePedido(dadosUsuarioRedux.token).then((result) => {
            setValidator(current => ({ ...current, validatorPedido: "true" }));
            setValues(current => ({ ...current, pedido: result.data }));
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependencias = async () => {
        setLoading(true);
        setLoadingCadastro(true);
        await getPedidosGeral(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setLoadingCadastro(false);
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
            if (props.telaCadastro != true) {
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependenciasCadastro = async () => {
        setLoadingSelectRota(true);
        setLoadingSelectCliente(true);
        setLoadingSelectValorEmbarque(true);
        await getDependenciasPedidos(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            filtraClientes();
            setListaRotas(result.data.rotas);
            setListaValores(result.data.valoresEmbarque);
            setTabelaRedux({});
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
            if (props.telaCadastro === true) {
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
        });
    }

    const buscarDependenciasCadastroSemLoading = async () => {
        await getDependenciasPedidos(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            filtraClientes();
            setListaRotas(result.data.rotas);
            setDadosTabela(result.data.pedidos);
            setListaValores(result.data.valoresEmbarque);
            setTabelaRedux({});
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectValorEmbarque(false);
        });
    }

    const [dadosFiltradosClientes, setDadosFiltradosClientes] = useState([]);

    const filtraClientes = () => {
        var dadosfiltraclientes = clientes;
        dadosfiltraclientes.sort(function (a, b) {
            if (a.nomeFantasia < b.nomeFantasia) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltradosClientes(dadosfiltraclientes);
    }

    const dadosOrdenadosClientes = dadosFiltradosClientes;

    useEffect(() => {
        if (!loading) {
            filtraClientes();
        }
    }, [loading]);

    useEffect(() => {
        filtraClientes();
    }, [clientes]);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal Cliente") {
            handleCloseCadastroCliente();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Rota") {
            handleCloseCadastroCliente();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    const buscarTaxaIcms = async (ufOrigem, ufDestino) => {
        await getTaxaIcmsPorUf(dadosUsuarioRedux.token, ufOrigem, ufDestino).then((result) => {
            const taxaIcmsAqui = result.data[0].taxa;
            if (taxaIcmsAqui === 0) {
                setTaxaIcms(taxaIcmsAqui);
                setValues(current => ({ ...current, icms: '0' }));
            } else {
                const taxaDividida = taxaIcmsAqui / 100;
                setTaxaIcms(taxaDividida);
            }
        }).catch(() => {
            setTextoErro('Rota não Possui Taxa de Icms.');
            setTaxaIcms();
            setValues(current => ({ ...current, icms: '0.00' }));
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarKm = async () => {
        await buscaKmCoordenadas(clientesKm, dadosUsuarioRedux.token).then((result) => {
            setValues(current => ({ ...current, km: result.data.toString() }));
            setValidator(current => ({ ...current, validatorKM: "true" }));
            setTextoSucesso(`Distância calculada com sucesso: ${result.data.toString()} km`);
            setOpenToastSucesso(true);
        }).catch(() => {
            setTextoErro('Embarcador ou Destinatário não possui endereço cadastrado para calcular o Km.');
            setOpenToastErro(true);
            setLoading(false);
        });;
    }

    const verificaPedidoExiste = async () => {
        await getPedidoExiste(dadosUsuarioRedux.token, values.pedido, values.tipoRota, values.cliente, values.embarcador, values.destinatairo).then((result) => {
            setTextoSucesso(result.data);
            setOpenToastSucesso(true);
        }).catch((error) => {
            setTextoErro(error.response.data);
            setOpenToastErro(true);
        });
    }

    useEffect(() => {
        if (clientesKm.idClienteOrigem && clientesKm.idClienteDestino) {
            buscarKm();
        }
    }, [clientesKm])

    useEffect(() => {
        if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
            setValues(current => ({ ...current, icms: '0' }));
        } else {
            if (taxaIcms != undefined) {
                calcular(values.freteFiscal);
            } else {
                setValues(current => ({ ...current, icms: '' }));
            }
        }
    }, [taxaIcms, values.cidadeDestino]);

    const buscarHistoricoPedido = async (codigo) => {
        await getHistoricoPedido(codigo, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setValoresHistorico(result.data);
                setOpenHistoricoEdicao(true);
            }
        }).catch((error) => {
            setTextoErro(error.response.data);
            setOpenToastErro(true);
        })
    }

    const calcular = () => {
        setValidator(current => ({ ...current, validatorFreteFiscal: "true" }));
        setValidator(current => ({ ...current, validatorClassificacao: "true" }));
        setValidator(current => ({ ...current, validatorPedagio: "true" }));
        setFreteFiscalValido(true);
        setClassificacaoValido(true);
        setPedagioValido(true);
        if (values.freteFiscal == undefined) {
            values.freteFiscal = '0';
        }
        if (values.classificacao == undefined) {
            values.classificacao = '0';
        }
        if (values.pedagio == undefined) {
            values.pedagio = '0';
        }
        let taxaFreteFicasl = 0;
        let taxaPedagio = 0;
        let taxaClassificacao = 0;
        let taxaFreteFiscalReplace = 0;
        if (values.freteFiscal != '0,00' && values.freteFiscal != undefined) {
            taxaFreteFicasl = values.freteFiscal.replace('.', '');
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        } else {
            taxaFreteFicasl = values.freteFiscal;
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        }
        let taxaPedagioReplace = 0;
        if (diPedagio === true) {
            if (values.pedagio != '0,00' && values.pedagio != undefined) {
                taxaPedagio = values.pedagio.replace('.', '');
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            } else {
                taxaPedagio = values.pedagio;
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            }
        }
        let taxaClassificacaoReplace = 0;
        if (diClassificacao === true) {
            if (values.classificacao != '0,00' && values.classificacao != undefined) {
                taxaClassificacao = values.classificacao.replace('.', '');
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            } else {
                taxaClassificacao = values.classificacao;
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            }
        }
        let somatoriaGeral = parseFloat(taxaFreteFiscalReplace) + parseFloat(taxaPedagioReplace) + parseFloat(taxaClassificacaoReplace);
        const somatoriaGeralString = somatoriaGeral.toString();
        const taxaCalculada = ((somatoriaGeralString.replace(',', '.') / (1 - taxaIcms)) * taxaIcms);
        const taxaFormatada = taxaCalculada.toFixed(2).toString();
        const taxaFReplace = taxaFormatada.replace('.', ',');
        setValues(current => ({ ...current, icms: taxaFReplace }));
        setIcmsCopia(taxaFReplace);
    }

    useEffect(() => {
        calcular();
    }, [values.freteFiscal, values.cliente, values.pedagio, values.classificacao, diClassificacao, diFreteFiscal, diPedagio]);

    const cadastrar = async () => {
        if ((values.icms === undefined || values.icms === 'NaN' || values.icms === NaN || values.icms === null
            || values.icms === 'SemTaxa' || values.pedido === undefined || values.icms === "" || values.freteFiscal === ""
            || values.pedido === "" || values.rota === "" || values.pedagio === "" || values.km === "" || values.cliente === ""
            || values.classificacao === "" || values.embarcador === "" || values.destinatario === "" || values.pedido === "" || values.freteMotorista === "")
            && (validator.validatorPedido === "false" && validator.validatorTipoRota === "false" && validator.validatorFreteFiscal === "false" &&
                validator.validatorPedagio === "false" && validator.validatorKM === "false" &&
                validator.validatorCliente === "false" && validator.validatorClassificacao === "false" && validator.validatorEmbarcador === "false" && validator.validatorDestinatario === "false" &&
                validator.validatorFreteMotorista === "false")) {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        } else {
            if (values.freteFiscal.split(',').length === 1) {
                values.freteFiscal = values.freteFiscal + ",00"
            }
            if (values.pedagio.split(',').length === 1) {
                values.pedagio = values.pedagio + ",00"
            }
            if (values.classificacao.split(',').length === 1) {
                values.classificacao = values.classificacao + ",00"
            }
            if (values.freteMotorista.split(',').length === 1) {
                values.freteMotorista = values.freteMotorista + ",00"
            }
            if (values.icms === '0.00' || values.icms === undefined || values.icms === null || values.icms === 'SemTaxa' || values.icms === "") {
                setTextoErro('Não há taxa no pedido!');
                setOpenToastErro(true);
                return;
            }
            values.pedido = parseInt(values.pedido);
            if (values.observacao === "" || values.observacao === null || !values.observacao) {
                setValues(current => ({ ...current, observacao: "" }));
            }
            setValues(current => ({ ...current, icms: values.icms.toString() }));
            await cadastraPedido(values, dadosUsuarioRedux.token).then((result) => {
                setLoading(true);
                if (result.status === 200) {
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    buscarPedidos();
                    setDadosRequestControllerRedux("Fechar Modal Pedido");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
            });
        }
    }

    const editar = async () => {
        if ((values.icms === undefined || values.icms === 'NaN' || values.icms === NaN || values.icms === null
            || values.icms === 'SemTaxa' || values.pedido === undefined || values.icms === "" || values.freteFiscal === ""
            || values.pedido === "" || values.rota === "" || values.pedagio === "" || values.km === "" || values.cliente === ""
            || values.classificacao === "" || values.embarcador === "" || values.destinatario === "" || values.pedido === "" || values.freteMotorista === "")
            && (validator.validatorPedido === "false" && validator.validatorTipoRota === "false" && validator.validatorFreteFiscal === "false" &&
                validator.validatorPedagio === "false" && validator.validatorKM === "false" &&
                validator.validatorCliente === "false" && validator.validatorClassificacao === "false" && validator.validatorEmbarcador === "false" && validator.validatorDestinatario === "false" &&
                validator.validatorFreteMotorista === "false")) {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        } else {
            if (values.freteFiscal.split(',').length === 1) {
                values.freteFiscal = values.freteFiscal + ",00"
            }
            if (values.pedagio.split(',').length === 1) {
                values.pedagio = values.pedagio + ",00"
            }
            if (values.classificacao.split(',').length === 1) {
                values.classificacao = values.classificacao + ",00"
            }
            if (values.freteMotorista.split(',').length === 1) {
                values.freteMotorista = values.freteMotorista + ",00"
            }
            if (values.icms === '0.00' || values.icms === undefined || values.icms === null || values.icms === 'SemTaxa' || values.icms === "") {
                setTextoErro('Não há taxa no pedido!');
                setOpenToastErro(true);
                return;
            }
            values.pedido = parseInt(values.pedido);
            if (values.observacao === "" || values.observacao === null || !values.observacao) {
                setValues(current => ({ ...current, observacao: "" }));
            }
            setValues(current => ({ ...current, icms: values.icms.toString() }));
            setLoading(true);
            await editaPedidoPorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarPedidos();
                    } else {
                        buscarSearch();
                    }
                    setTabelaRedux({});
                    setTextoSucesso("Editado Com Sucesso");
                    setOpenToastSucesso(true);
                    setEditando(false);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        }
    }

    const preencherDadosEditando = async (result) => {
        const tipoRotaSelecionada = opcoesRota.find(option =>
            option.nome === result.data.tipoRota
        );
        if (tipoRotaSelecionada) {
            setValuesSelect(current => ({ ...current, tipoRota: tipoRotaSelecionada }));
            setValues(current => ({ ...current, rotaId: tipoRotaSelecionada.idRota }));
        }
        const clienteSelecionado = clientes.find(option => option.nomeFantasia === result.data.cliente || option.apelido === result.data.cliente);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                cliente: {
                    value: clienteSelecionado.nomeFantasia,
                    label: `${clienteSelecionado.nomeFantasia} | ${clienteSelecionado.apelido ? clienteSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}`,
                    id: clienteSelecionado.idCliente
                }
            }));
            setValues(current => ({ ...current, clienteId: clienteSelecionado.idCliente }));
            setValuesSelect(current => ({ ...current, labelCliente: `${clienteSelecionado.nomeFantasia} | ${clienteSelecionado.apelido ? clienteSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}` }));
        }
        const destinatarioSelecionado = clientes.find(option => option.nomeFantasia === result.data.destinatario || option.apelido === result.data.destinatario);
        if (destinatarioSelecionado) {
            setValuesSelect(current => ({
                ...current,
                destinatario: {
                    value: destinatarioSelecionado.nomeFantasia,
                    label: `${destinatarioSelecionado.nomeFantasia} | ${destinatarioSelecionado.apelido ? destinatarioSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}`,
                    id: destinatarioSelecionado.idCliente
                }
            }));
            setValues(current => ({ ...current, destinatarioId: destinatarioSelecionado.idCliente }));
            setValuesSelect(current => ({ ...current, labelDestinatario: `${destinatarioSelecionado.nomeFantasia} | ${destinatarioSelecionado.apelido ? destinatarioSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}` }));
        }
        const embarcadorSelecionado = clientes.find(option => option.nomeFantasia === result.data.embarcador || option.apelido === result.data.embarcador);
        if (embarcadorSelecionado) {
            setValuesSelect(current => ({
                ...current,
                embarcador: {
                    value: embarcadorSelecionado.nomeFantasia,
                    label: `${embarcadorSelecionado.nomeFantasia} | ${embarcadorSelecionado.apelido ? embarcadorSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}`,
                    id: embarcadorSelecionado.idCliente
                }
            }));
            setValues(current => ({ ...current, embarcadorId: embarcadorSelecionado.idCliente }));
            setValuesSelect(current => ({ ...current, labelEmbarcador: `${embarcadorSelecionado.nomeFantasia} | ${embarcadorSelecionado.apelido ? embarcadorSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}` }));
        }
        const valorEmbarqueSelecionado = listaValores.find(option => option.idValorEmbarque === result.data.valorEmbarqueId);
        if (valorEmbarqueSelecionado) {
            setValuesSelect(current => ({
                ...current,
                valorEmbarqueId: {
                    value: valorEmbarqueSelecionado.rota.nome,
                    label: valorEmbarqueSelecionado.rota.nome,
                    id: valorEmbarqueSelecionado.idValorEmbarque
                }
            }));
            setValues(current => ({ ...current, valorEmbarqueId: valorEmbarqueSelecionado.idValorEmbarque }));
        }
    };

    const buscarPedidoPorId = async (flag) => {
        if (editando) setLoading(true);
        await getPedidoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    buscarTaxaIcms(result.data.embarcadorEstado, result.data.destinatarioEstado);
                    setIcmsCopia(result.data.icms);
                    setValues(result.data);
                    await preencherDadosEditando(result);
                    setLoading(false);
                } else if (flag) {
                    buscarHistoricoPedido(result.data.pedido)
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarPedidoPorIdModal = async (id) => {
        if (editando) setLoading(true);
        await getPedidoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result);
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const deletarPedidoPorId = async () => {
        setLoading(true);
        await deletePedidoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarPedidos();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const setEmbutido = (event) => {
        if (event === true) {
            setValues(current => ({ ...current, icms: '0,00' }));
        } else {
            setValues(current => ({ ...current, icms: icmsCopia }));
        }
    };

    useEffect(() => {
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, validatorPedido: "false" }));
        setValidator(current => ({ ...current, validatorTipoRota: "false" }));
        setValidator(current => ({ ...current, validatorKM: "false" }));
        setValidator(current => ({ ...current, validatorCliente: "false" }));
        setValidator(current => ({ ...current, validatorEmbarcador: "false" }));
        setValidator(current => ({ ...current, validatorDestinatario: "false" }));
        setValidator(current => ({ ...current, validatorFreteMotorista: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            setDisablePedido(true);
            buscarPedidoPorId();
        } else {
            setDisablePedido(false);
        }
    }, [editando]);


    useEffect(() => {
        buscarPedidos();
        buscarDependenciasCadastro();
    }, []);

    useEffect(() => {
        if (props.idAtual) {
            buscarPedidoPorIdModal(props.idAtual);
        }
    }, [clientes, listaRotas]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoFreteFiscal, setSemAutorizacaoFreteFiscal] = useState(true);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === 'Gerenciamendo Pedido') {
                    setSemAutorizacao(false);
                }
                if (dado.regras.regra === 'Permitir Alterar Frete Fiscal Viagem') {
                    setSemAutorizacaoFreteFiscal(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!editando && telaCadastro) {
            buscarNumeroPedido();
        }
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [telaCadastro]);

    const opcoesRota = listaRotas.map((data) => ({
        value: data.nome,
        label: data.nome,
        ...data
    }));

    const estiloRota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    const labelRota = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.nome}
            {values.tipoRota !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idRota);
                        setDisable(true);
                        setOpenCadastroRota(true);
                    }}
                />
            }
        </div>
    );

    const opcoesCliente = dadosOrdenadosClientes.map((data) => ({
        value: data.nomeFantasia,
        label: `${data.nomeFantasia} | ${data.apelido ? data.apelido : 'Favor, cadastrar apelido de Cliente'}`,
        ...data
    }));

    const labelCliente = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.label}
            {valuesSelect.labelCliente !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idCliente);
                        setDisable(true);
                        setOpenCadastroCliente(true);
                    }}
                />
            }
        </div>
    );

    const opcoesEmbarcador = dadosOrdenadosClientes.map((data) => ({
        value: data.nomeFantasia,
        label: `${data.nomeFantasia} | ${data.apelido ? data.apelido : 'Favor, cadastrar apelido de Cliente'}`,
        ...data
    }));

    const labelEmbarcador = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.label}
            {valuesSelect.labelEmbarcador !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idCliente);
                        setDisable(true);
                        setOpenCadastroCliente(true);
                    }}
                />
            }
        </div>
    );

    const opcoesDestinatario = dadosOrdenadosClientes.map((data) => ({
        value: data.nomeFantasia,
        label: `${data.nomeFantasia} | ${data.apelido ? data.apelido : 'Favor, cadastrar apelido de Cliente'}`,
        ...data
    }));

    const labelDestinatario = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.label}
            {valuesSelect.labelDestinatario !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idCliente);
                        setDisable(true);
                        setOpenCadastroCliente(true);
                    }}
                />
            }
        </div>
    );

    const clearRota = () => {
        setTipoRotaValido(true);
        setValues((current) => ({
            ...current,
            rotaId: null,
            tipoRota: ''
        }));
        setValuesSelect(current => ({ ...current, tipoRota: '' }));
        setValidator((current) => ({
            ...current,
            validatorTipoRota: "false",
        }));
    };

    const clearCliente = () => {
        setClienteValido(false);
        setValuesSelect(current => ({ ...current, cliente: '' }));
        setValuesSelect(current => ({ ...current, labelCliente: '' }))
        setValues(current => ({ ...current, cliente: '' }));
        setValues(current => ({ ...current, clienteId: null }));
        setValidator(current => ({ ...current, validatorCliente: "false" }));
    };

    const clearEmbarcador = () => {
        setEmbarcadorValido(false);
        setValuesSelect(current => ({ ...current, embarcador: '' }));
        setValuesSelect(current => ({ ...current, labelEmbarcador: '' }))
        setValues(current => ({ ...current, embarcador: '' }));
        setValues(current => ({ ...current, embarcadorId: null }));
        setValuesClientesKm(current => ({ ...current, idClienteOrigem: '' }));
        setValidator(current => ({ ...current, validatorEmbarcador: "false" }));
    };

    const clearDestinatario = () => {
        setDestinatarioValido(false);
        setValuesSelect(current => ({ ...current, destinatario: '' }));
        setValuesSelect(current => ({ ...current, labelDestinatario: '' }))
        setValues(current => ({ ...current, destinatario: '' }));
        setValues(current => ({ ...current, destinatarioId: null }));
        setValuesClientesKm(current => ({ ...current, idClienteDestino: '' }));
        setValidator(current => ({ ...current, validatorDestinatario: "false" }));
    };

    const clearValorEmbarque = () => {
        setValuesSelect(current => ({ ...current, valorEmbarqueId: null }));
        setValues(current => ({ ...current, valorEmbarqueId: '' }));
    };

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    const [listaSelecionados, setListaSelecionados] = useState([]);

    const setLista = (checked, idPedido) => {
        setListaSelecionados((prevState) => {
            if (checked) {
                return [...prevState, idPedido];
            } else {
                return prevState.filter((id) => id !== idPedido);
            }
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Gerenciamento de Pedido" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Gerenciamento de Pedido" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-embarque-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Gerenciamento de Pedidos</p>
                                                </div>
                                            </div>
                                            {/* <div className="filtro-embarque-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-pedido'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div> */}
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idPedido"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Pedido}
                                                dados={dadosTabela}
                                                nome="pedido"
                                                nomeTable="Pedido"
                                                setLista={setLista}
                                            />
                                            <div className="container-botoes-emb">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        width='200px'
                                                        titulo="Aprovar Múltiplos"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (listaSelecionados.length > 0) {
                                                                setOpenAprovarTodos(true);
                                                            } else {
                                                                setTextoErro("Selecione pelo menos um pedido");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Reprovar"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenReprovar(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Visualizar"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    {/* <CustomButtonV2
                                                        classCss="custom-button-historico"
                                                        classText="button-texto-user"
                                                        titulo="Histórico"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                buscarPedidoPorId(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Pedido / "}</p>
                                                        <p className="indicador-emb">{"Visualização de Pedido"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Visualização de Pedido" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({});
                                                                setDadosTabela(dadosTabelaCopia);
                                                                setValuesSelect({});
                                                                setTelaCadastro(false);
                                                                setEditando(false);
                                                                setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="coluna-3-emb">
                                                        <div className={pedidoValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Pedido:</p>
                                                                <input
                                                                    type="number"
                                                                    id="pedido"
                                                                    className={!pedidoValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '206px' }}
                                                                    disabled={disablePedido || props.desabilitar}
                                                                    onChange={(e) => {
                                                                        setPedidoValido(true);
                                                                        setValues(current => ({ ...current, pedido: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorPedido: "true" }));
                                                                    }}
                                                                    onBlur={(e) => { verificaPedidoExiste() }}
                                                                    value={values.pedido} />
                                                                {!pedidoValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        {
                                                            loadingSelectRota ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={tipoRotaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Rota:</p>
                                                                        <FormControl sx={{ width: 270 }}>
                                                                            <Select
                                                                                onBlur={(e) => { verificaPedidoExiste() }}
                                                                                value={valuesSelect.tipoRota}
                                                                                isDisabled={true}
                                                                                options={opcoesRota}
                                                                                formatOptionLabel={labelRota}
                                                                                styles={tipoRotaValido ? estiloRota : customStylesErro}
                                                                                placeholder=""
                                                                                noOptionsMessage={() => "Não há mais opções"}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) {
                                                                                        setTipoRotaValido(true);
                                                                                        setValues((current) => ({
                                                                                            ...current,
                                                                                            rotaId: selectedOption.idRota,
                                                                                            tipoRota: selectedOption.nome,
                                                                                        }));
                                                                                        buscarTaxaIcms(selectedOption.estadoOrigem, selectedOption.estadoDestino);
                                                                                        setValuesSelect(current => ({ ...current, tipoRota: selectedOption }));
                                                                                        setValidator((current) => ({
                                                                                            ...current,
                                                                                            validatorTipoRota: "true",
                                                                                        }));
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                        {!tipoRotaValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroRota(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        <div className={freteFiscalValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Frete Fiscal:</p>
                                                                <input
                                                                    type="text"
                                                                    id="frete"
                                                                    className={!freteFiscalValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setFreteFiscalValido(true);
                                                                        setValues(current => ({ ...current, freteFiscal: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorFreteFiscal: "true" }));
                                                                        if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                                            setValues(current => ({ ...current, icms: '0' }));
                                                                        } else {
                                                                            if (taxaIcms === undefined) {
                                                                                setValues(current => ({ ...current, icms: '' }));
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={valorMask(values.freteFiscal)}
                                                                    disabled={true}
                                                                />
                                                                {!freteFiscalValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">ICMS:</p>
                                                                <input
                                                                    type="text"
                                                                    id="icms"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, icms: e.target.value }));
                                                                    }}
                                                                    value={
                                                                        values.cliente ? values.icms : 'Informe o Cliente'
                                                                    }
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-3-emb">
                                                        <div className={pedagioValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Pedágio:</p>
                                                                <input
                                                                    type="text"
                                                                    id="pedagio"
                                                                    className={!pedagioValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '186px' }}
                                                                    onChange={(e) => {
                                                                        setPedagioValido(true);
                                                                        setValues(current => ({ ...current, pedagio: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorPedagio: "true" }));
                                                                        if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                                            setValues(current => ({ ...current, icms: '0' }));
                                                                        } else {
                                                                            if (taxaIcms === undefined) {
                                                                                setValues(current => ({ ...current, icms: '' }));
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={valorMask(values.pedagio)}
                                                                    disabled={true}
                                                                />
                                                                {!pedagioValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        {
                                                            loadingSelectCliente ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={clienteValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                                                        <FormControl sx={{ width: 250 }}>
                                                                            <Select
                                                                                onBlur={(e) => { verificaPedidoExiste() }}
                                                                                value={valuesSelect.cliente}
                                                                                isDisabled={true}
                                                                                options={opcoesCliente}
                                                                                formatOptionLabel={labelCliente}
                                                                                styles={clienteValido ? estiloRota : customStylesErro}
                                                                                placeholder=""
                                                                                noOptionsMessage={() => "Não há mais opções"}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) {
                                                                                        setClienteValido(true);
                                                                                        if (selectedOption.diFreteFiscal === true) {
                                                                                            setDiFreteFiscal(true);
                                                                                        } else {
                                                                                            setDiFreteFiscal(false);
                                                                                        }
                                                                                        if (selectedOption.diClassificacao === true) {
                                                                                            setDiClassificacao(true);
                                                                                        } else {
                                                                                            setDiClassificacao(false);
                                                                                        }
                                                                                        if (selectedOption.diPedagio === true) {
                                                                                            setDiPedagio(true);
                                                                                        } else {
                                                                                            setDiPedagio(false);
                                                                                        }
                                                                                        setValuesSelect(current => ({ ...current, cliente: selectedOption }));
                                                                                        setValuesSelect(current => ({ ...current, labelCliente: `${selectedOption.nomeFantasia} | ${selectedOption.apelido ? selectedOption.apelido : 'Favor, cadastrar apelido de Cliente'}` }))
                                                                                        if (selectedOption.apelido === null) {
                                                                                            setValues(current => ({ ...current, cliente: selectedOption.nomeFantasia }));
                                                                                        } else {
                                                                                            setValues(current => ({ ...current, cliente: selectedOption.apelido }));
                                                                                        }
                                                                                        setValues(current => ({ ...current, clienteId: selectedOption.idCliente }));
                                                                                        setValidator(current => ({ ...current, validatorCliente: "true" }));
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                        {!clienteValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectCliente ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={embarcadorValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Embarcador:</p>
                                                                        <FormControl sx={{ width: 250 }}>
                                                                            <Select
                                                                                onBlur={(e) => { verificaPedidoExiste() }}
                                                                                value={valuesSelect.embarcador}
                                                                                isDisabled={true}
                                                                                options={opcoesEmbarcador}
                                                                                formatOptionLabel={labelEmbarcador}
                                                                                styles={embarcadorValido ? estiloRota : customStylesErro}
                                                                                placeholder=""
                                                                                noOptionsMessage={() => "Não há mais opções"}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) {
                                                                                        setEmbarcadorValido(true);
                                                                                        setValuesSelect(current => ({ ...current, embarcador: selectedOption }));
                                                                                        setValuesSelect(current => ({ ...current, labelEmbarcador: `${selectedOption.nomeFantasia} | ${selectedOption.apelido ? selectedOption.apelido : 'Favor, cadastrar apelido de Cliente'}` }))
                                                                                        if (selectedOption.apelido === null) {
                                                                                            setValues(current => ({ ...current, embarcador: selectedOption.nomeFantasia }));
                                                                                        } else {
                                                                                            setValues(current => ({ ...current, embarcador: selectedOption.apelido }));
                                                                                        }
                                                                                        setValues(current => ({ ...current, embarcadorId: selectedOption.idCliente }));
                                                                                        setValuesClientesKm(current => ({ ...current, idClienteOrigem: selectedOption.idCliente }));
                                                                                        setValidator(current => ({ ...current, validatorEmbarcador: "true" }));
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                        {!embarcadorValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectCliente ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={destinatarioValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Destinatário:</p>
                                                                        <FormControl sx={{ width: 250 }}>
                                                                            <Select
                                                                                onBlur={(e) => { verificaPedidoExiste() }}
                                                                                value={valuesSelect.destinatario}
                                                                                isDisabled={true}
                                                                                options={opcoesDestinatario}
                                                                                formatOptionLabel={labelDestinatario}
                                                                                styles={destinatarioValido ? estiloRota : customStylesErro}
                                                                                placeholder=""
                                                                                noOptionsMessage={() => "Não há mais opções"}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) {
                                                                                        setDestinatarioValido(true);
                                                                                        setValuesSelect(current => ({ ...current, destinatario: selectedOption }));
                                                                                        setValuesSelect(current => ({ ...current, labelDestinatario: `${selectedOption.nomeFantasia} | ${selectedOption.apelido ? selectedOption.apelido : 'Favor, cadastrar apelido de Cliente'}` }))
                                                                                        if (selectedOption.apelido === null) {
                                                                                            setValues(current => ({ ...current, destinatario: selectedOption.nomeFantasia }));
                                                                                        } else {
                                                                                            setValues(current => ({ ...current, destinatario: selectedOption.apelido }));
                                                                                        }
                                                                                        setValues(current => ({ ...current, destinatarioId: selectedOption.idCliente }));
                                                                                        setValuesClientesKm(current => ({ ...current, idClienteDestino: selectedOption.idCliente }));
                                                                                        setValidator(current => ({ ...current, validatorDestinatario: "true" }));
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                        {!destinatarioValido && <div className="error-text">Obrigatório!</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="coluna-3-emb">
                                                        <div className={kmValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                <input
                                                                    type="text"
                                                                    id="km"
                                                                    className={!kmValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        setKMValido(true);
                                                                        setValues(current => ({ ...current, km: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorKM: "true" }));
                                                                    }}
                                                                    value={valorMask(values.km)}
                                                                    disabled={true}
                                                                />
                                                                {!kmValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className={classificacaoValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Classificação/Descarga:</p>
                                                                <input
                                                                    type="text"
                                                                    className={!classificacaoValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '210px' }}
                                                                    id="classificacao"
                                                                    onChange={(e) => {
                                                                        setClassificacaoValido(true);
                                                                        setValues(current => ({ ...current, classificacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorClassificacao: "true" }));
                                                                        if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                                            setValues(current => ({ ...current, icms: '0' }));
                                                                        } else {
                                                                            if (taxaIcms === undefined) {
                                                                                setValues(current => ({ ...current, icms: '' }));
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={valorMask(values.classificacao)}
                                                                    disabled={true}>
                                                                </input>
                                                                {!classificacaoValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className={freteMotoristaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Frete Motorista:</p>
                                                                <input
                                                                    type="text"
                                                                    className={!freteMotoristaValido ? 'validacao-erro' : 'input-emb'}
                                                                    style={{ width: '210px' }}
                                                                    id="freteMotorista"
                                                                    onChange={(e) => {
                                                                        setFreteMotoristaValido(true);
                                                                        setValues(current => ({ ...current, freteMotorista: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorFreteMotorista: "true" }));
                                                                    }}
                                                                    value={valorMask(values.freteMotorista)}
                                                                    disabled={true}>
                                                                </input>
                                                                {!freteMotoristaValido && <div className="error-text">Obrigatório!</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {semAutorizacaoFreteFiscal ? (
                                                                    <></>
                                                                ) : (
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            disabled={true}
                                                                            checked={values.permiteAlterarFreteFiscal}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked === true) {
                                                                                    setValues(current => ({ ...current, permiteAlterarFreteFiscal: true }));
                                                                                }
                                                                                else {
                                                                                    setValues(current => ({ ...current, permiteAlterarFreteFiscal: false }));
                                                                                }
                                                                            }}
                                                                        />} label={'Permite Alterar Frete Fiscal'} />
                                                                )
                                                                }
                                                                <FormControlLabel control={
                                                                    <Checkbox
                                                                        checked={values.icmsEmbutido}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked === true) {
                                                                                setEmbutido(true);
                                                                                setValues(current => ({ ...current, icmsEmbutido: true }));
                                                                            }
                                                                            else {
                                                                                setEmbutido(false);
                                                                                setValues(current => ({ ...current, icmsEmbutido: false }));
                                                                            }
                                                                        }}
                                                                    />} label={'ICMS Embutido'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="coluna-3-emb">
                                                        {
                                                            loadingSelectValorEmbarque ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Valor Viagem:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={220}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, valorEmbarqueId: e }));
                                                                                setValues(current => ({ ...current, valorEmbarqueId: e.id }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroValorEmbarque(true);
                                                                            }}
                                                                            disabled={true}
                                                                            clearSelection={clearValorEmbarque}
                                                                            onInfoIconClick={setIdAtual}
                                                                            pequeno={true}
                                                                            id="idValorEmbarque"
                                                                            value={valuesSelect.valorEmbarqueId}
                                                                            titulo="ValorEmbarque"
                                                                            dadosSelect={listaValores}
                                                                            referenciaId="rota"
                                                                            referenciaItem="rota"
                                                                            referenciaItem2="nome" />
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroValorEmbarque(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '820px' }}
                                                                    id="observacao"
                                                                    onChange={(e) => setValues(current => ({ ...current, observacao: e.target.value }))}
                                                                    value={values.observacao}
                                                                    disabled={true}>
                                                                </input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Frete Normal:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    id="freteNormal"
                                                                    value={values.freteNormal}
                                                                    disabled={true}>
                                                                </input>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <FormControlLabel control={
                                                                    <Checkbox
                                                                        checked={values.icmsEmbarcador}
                                                                        disabled={true}
                                                                    />} label={'ICMS Embarcador'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-pedido">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Aprovar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenAprovar(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Reprovar"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenReprovar(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Cancelar"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => { setValues({}); setDadosTabela(dadosTabelaCopia); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarPedidoPorId}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Aprovar o Pedido? "
                                        texto="Ao confirmar, o pedido será aprovado e será exibido na lista."
                                        textoBotao="Sim"
                                        open={openAprovar}
                                        onClose={handleCloseAprovar}
                                        onClick={aprovaPedido}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Aprovar Todos os Pedidos? "
                                        texto="Ao confirmar, os pedidos serão aprovados e exibidos na lista."
                                        textoBotao="Sim"
                                        open={openAprovarTodos}
                                        onClose={handleCloseAprovarTodos}
                                        onClick={aprovaPedidoTodos}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Reprovar o Pedido? "
                                        texto="Ao reprovar um pedido, ele não ficara disponivel para ser utilizado."
                                        textoBotao="Sim"
                                        open={openReprovar}
                                        onClose={handleCloseReprovar}
                                        onClick={reprovaPedido}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroRota="sim"
                                        open={openCadastroRota}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroRota}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCliente="sim"
                                        open={openCadastroCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCliente}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroValorEmbarque="sim"
                                        open={openCadastroValorEmbarque}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroValorEmbarque}
                                    />
                                    <ModalHistoricoEdicao
                                        valueTabela={tabelaRedux}
                                        valueDados={valoresHistorico}
                                        valueUnico={values}
                                        open={openHistoricoEdicao}
                                        onClose={handleCloseHistoricoEdicao}
                                        titulo="Histórico"
                                        classeSelecionado="idPedido"
                                        colunas={colunasHistorico}
                                        nome="pedido"
                                        nomeTable="Pedido"
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div >
    );
}

export default GerenciamentoPedido;