import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraPedido = async (data, token) => {
  return api.post("pedido",
    data
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedido = async (token) => {
  return api.get("pedido",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedidoAprovacao = async (token) => {
  return api.get("Pedido/BuscarPedidosAguardandoAprovacao",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const aprovarPedido = async (token, id) => {
  return api.post("Pedido/AprovarPedido/" + id, null,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const aprovarPedidoLista = async (token, lista) => {
  return api.post("Pedido/AprovarPedidoLista", lista,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const reprovarPedido = async (token, id) => {
  return api.post("Pedido/ReprovarPedido/" + id, null,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedidoExiste = async (token, numeroPedido, tipoRota, cliente, embarcador, destinatario) => {
  let queryParams = new URLSearchParams();

  if (numeroPedido) queryParams.append('NumeroPedido', numeroPedido);
  if (tipoRota) queryParams.append('TipoRota', tipoRota);
  if (cliente) queryParams.append('Cliente', cliente);
  if (embarcador) queryParams.append('Embarcador', embarcador);
  if (destinatario) queryParams.append('Destinatario', destinatario);

  return api.get(`Pedido/PedidoExiste?${queryParams.toString()}`, {
    headers: {
      'Authorization': token
    }
  });
}

export const getNumeroSeguintePedido = async (token) => {
  return api.get("NumeroSeguintePedido",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPedidoPorId = async (id, token) => {
  return api.get("pedido/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getHistoricoPedido = async (pedido, token) => {
  return api.get("pedido/buscaHistoricoEdicao/" + pedido,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deletePedidoPorId = async (id, token) => {
  return api.delete("pedido/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaPedidoPorId = async (data, token) => {
  return api.put("pedido",
    data,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getPrevisaoTempo = async (token, data, idCliente) => {
  return api.get("PrevisaoTempo/" + data + "/" + idCliente,
    {
      headers: {
        'Authorization': token
      }
    })
}

export default api;