import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraFiliais = async (filiais, token) => {
  return api.post("Filiais",
    filiais
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getFiliais = async (token) => {
  return api.get("Filiais",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getFiliaisPorId = async (id, token) => {
  return api.get("Filiais/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteFiliaisPorId = async (id, token) => {
  return api.delete("Filiais/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaFiliaisPorId = async (filiais, token) => {
  return api.put("Filiais",
    filiais,
  {
    headers: {
      'Authorization': token
    }
  })
}