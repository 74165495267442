import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraCarreta = async (carreta, token) => {
  return api.post("carreta",
    carreta
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCarreta = async (token) => {
  return api.get("carreta",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCarretaPorId = async (id, token) => {
  return api.get("carreta/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const transformaMercosulCarreta = async (id, token) => {
  return api.get("carreta/transformaMercosul/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCarretaPorId = async (id, token) => {
  return api.delete("carreta/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaCarretaPorId = async (carreta, token) => {
  return api.put("carreta/",
    carreta,
  {
    headers: {
      'Authorization': token
    }
  })
}