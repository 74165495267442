import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./ValorEmbarque.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import CoresRedux from "../../contexts/Cor";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../contexts/Usuario";
import Tabela from "../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import Text from "../../components/Text/Text";
import SemAutorizacao from "../../components/SemAutorizacao/SemAutorizacao";
import { getRegras, getValorEmbarqueDependencias } from "../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../contexts/RequestController";
import { motion } from "framer-motion";
import { valorMask } from "../../regex/valorMask";
import ModalCadastro from "../../components/ModalCadastro/ModalCadastro.tsx";
import SelectEmbarqueV2 from "../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import TextLink from "../../components/TextLink/TextLink";
import { Box, FormControl, LinearProgress, MenuItem, Select } from "@mui/material";
import ReactSelect from 'react-select';
import { cadastraValorEmbarque, getValorEmbarquePorId, deleteValorEmbarquePorId, editaValorEmbarquePorId, getValorEmbarque } from "../../services/ModuloGerenciador/ValorEmbarque";
import TabelaV2 from "../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../components/CustomButton copy/CustomButtonV2";
import InfoIcon from '@mui/icons-material/Info';
import HeaderCadastro from "../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../components/CloseIcon/CloseIcon.js";
import LoadingBar from "../../components/LoadingBar/LoadingBar.js";
import NewIcon from "../../components/NewIcon/NewIcon.js";

function ValorEmbarque(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [valorCheck, setValorCheck] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [ehCarreta, setEhCarreta] = useState(false);

    const [ehCaminhao, setEhCaminhao] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaClientes, setListaClientes] = useState([]);

    const [listaRotas, setListaRotas] = useState([]);

    const [listaGrupoPrecificacao, setListaGrupoPrecificacao] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [openCadastroTipoCheck, setOpenCadastroTipoCheck] = useState(false);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectRota, setLoadingSelectRota] = useState(false);

    const [openCadastroRota, setOpenCadastroRota] = useState(false);

    const handleCloseCadastroRota = (value) => {
        if (idAtual) {
            setOpenCadastroRota(false);
        } else {
            setLoadingSelectRota(true);
            buscarValorEmbarqueSemLoading();
            setOpenCadastroRota(false);
        }
    };

    const [loadingSelectCliente, setLoadingSelectCliente] = useState(false);

    const [openCadastroCliente, setOpenCadastroCliente] = useState(false);

    const handleCloseCadastroCliente = (value) => {
        if (idAtual) {
            setOpenCadastroCliente(false);
        } else {
            setLoadingSelectCliente(true);
            buscarValorEmbarqueSemLoading();
            setOpenCadastroCliente(false);
        }
    };

    const [loadingSelectGrupoPrecificacao, setLoadingSelectGrupoPrecificacao] = useState(false);

    const [openCadastroGrupoPrecificacao, setOpenCadastroGrupoPrecificacao] = useState(false);

    const handleCloseCadastroGrupoPrecificacao = (value) => {
        if (idAtual) {
            setOpenCadastroGrupoPrecificacao(false);
        } else {
            setLoadingSelectGrupoPrecificacao(true);
            buscarValorEmbarqueSemLoading();
            setOpenCadastroGrupoPrecificacao(false);
        }
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarValorEmbarqueSemLoading = async () => {
        await getValorEmbarqueDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            setListaRotas(result.data.rotas);
            setListaGrupoPrecificacao(result.data.gruposPrecificacao);
            setLoadingSelectRota(false);
            setLoadingSelectCliente(false);
            setLoadingSelectGrupoPrecificacao(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const cadastrar = async () => {
        if ((values.eixos !== "") && (validator.eixos === "true") &&
            (values.km !== "") && (validator.km === "true") &&
            (values.valorEixo !== "") && (validator.valorEixo === "true") &&
            (values.adicionalCargaDescarga !== "") && (validator.adicionalCargaDescarga === "true") &&
            (values.motorista !== "") && (validator.motorista === "true") &&
            (values.empresa !== "") && (validator.empresa === "true") &&
            (values.icms !== "") && (validator.icms === "true") &&
            (values.descarga !== "") && (validator.descarga === "true") &&
            (values.pedagio !== "") && (validator.pedagio === "true") &&
            (values.freteFiscal !== "") && (validator.freteFiscal === "true") &&
            (values.rotaId !== "") && (validator.rotaId === "true") &&
            (values.clienteId !== "") && (validator.clienteId === "true") &&
            (values.grupoPrecificacaoId !== "") && (validator.grupoPrecificacaoId === "true")) {
            setLoading(true);
            await cadastraValorEmbarque(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarValorEmbarque();
                    setDadosRequestControllerRedux("Fechar Modal Embalagem");
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.eixos !== "") && (validator.eixos === "true") &&
            (values.km !== "") && (validator.km === "true") &&
            (values.valorEixo !== "") && (validator.valorEixo === "true") &&
            (values.adicionalCargaDescarga !== "") && (validator.adicionalCargaDescarga === "true") &&
            (values.motorista !== "") && (validator.motorista === "true") &&
            (values.empresa !== "") && (validator.empresa === "true") &&
            (values.icms !== "") && (validator.icms === "true") &&
            (values.descarga !== "") && (validator.descarga === "true") &&
            (values.pedagio !== "") && (validator.pedagio === "true") &&
            (values.freteFiscal !== "") && (validator.freteFiscal === "true") &&
            (values.rotaId !== "") && (validator.rotaId === "true") &&
            (values.clienteId !== "") && (validator.clienteId === "true") &&
            (values.grupoPrecificacaoId !== "") && (validator.grupoPrecificacaoId === "true")) {
            setLoading(true);
            await editaValorEmbarquePorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarValorEmbarque();
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setEditando(false);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }

    }

    const buscarValorEmbarque = async () => {
        setLoading(true);
        buscarValorEmbarqueDependencias();
        await getValorEmbarque(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data)
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarValorEmbarqueDependencias = async () => {
        await getValorEmbarqueDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaClientes(result.data.clientes);
            setListaRotas(result.data.rotas);
            setListaGrupoPrecificacao(result.data.gruposPrecificacao);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarValorEmbarquePorId = async () => {
        setLoading(true);
        await getValorEmbarquePorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result.data);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarValorEmbarquePorIdModal = async (id) => {
        setLoading(true);
        await getValorEmbarquePorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result);
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarValorEmbarquePorId = async () => {
        setLoading(true);
        await deleteValorEmbarquePorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarValorEmbarque();
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);

    useEffect(() => {
        setValidator(current => ({ ...current, eixos: "false" }));
        setValidator(current => ({ ...current, km: "false" }));
        setValidator(current => ({ ...current, valorEixo: "false" }));
        setValidator(current => ({ ...current, adicionalCargaDescarga: "false" }));
        setValidator(current => ({ ...current, motorista: "false" }));
        setValidator(current => ({ ...current, empresa: "false" }));
        setValidator(current => ({ ...current, icms: "false" }));
        setValidator(current => ({ ...current, descarga: "false" }));
        setValidator(current => ({ ...current, pedagio: "false" }));
        setValidator(current => ({ ...current, freteFiscal: "false" }));
        setValidator(current => ({ ...current, rotaId: "false" }));
        setValidator(current => ({ ...current, clienteId: "false" }));
        setValidator(current => ({ ...current, grupoPrecificacaoId: "false" }));
    }, [telaCadastro])

    useEffect(() => {
        if (editando) {
            buscarValorEmbarquePorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarValorEmbarque();
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Precificação') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Precificação') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const separarPorTipo = (dados) => {
        return dados.reduce((acc, item) => {
            if (!acc[item.grupoPrecificacao.nome]) {
                acc[item.grupoPrecificacao.nome] = [];
            }
            acc[item.grupoPrecificacao.nome].push(item);
            return acc;
        }, {});
    }

    const [dadosPorTipo, setDadosPorTipo] = useState({});

    useEffect(() => {
        if (dadosTabela.length > 0) {
            setDadosPorTipo(separarPorTipo(dadosTabela));
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [dadosTabela]);

    const preencherDadosEditando = async (result) => {
        const grupoPrecificacaoSelecionado = listaGrupoPrecificacao.find(option => option.nome === result.grupoPrecificacao.nome);
        if (grupoPrecificacaoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                grupoPrecificacaoId: {
                    value: grupoPrecificacaoSelecionado.nome,
                    label: grupoPrecificacaoSelecionado.nome,
                    id: grupoPrecificacaoSelecionado.idPrecificacao
                }
            }));
            setValues(current => ({ ...current, grupoPrecificacaoId: grupoPrecificacaoSelecionado.idPrecificacao }));
        }
        const rotaSelecionado = listaRotas.find(option => option.nome === result.rota.nome);
        if (rotaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                rotaId: {
                    value: rotaSelecionado.nome,
                    label: rotaSelecionado.nome,
                    id: rotaSelecionado.idRota
                }
            }));
            setValues(current => ({ ...current, rotaId: rotaSelecionado.idRota }));
        }
        const clienteSelecionado = listaClientes.find(option => option.nomeFantasia === result.cliente.nomeFantasia);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                clienteId: {
                    value: clienteSelecionado.nomeFantasia,
                    label: clienteSelecionado.nomeFantasia,
                    id: clienteSelecionado.idCliente
                }
            }));
            setValues(current => ({ ...current, clienteId: clienteSelecionado.idCliente }));
        }
    };


    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal Cliente") {
            handleCloseCadastroCliente();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Rota") {
            handleCloseCadastroRota();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    const clearGrupoPrecificacao = () => {
        setValuesSelect(current => ({ ...current, grupoPrecificacaoId: '' }));
        setValues(current => ({ ...current, grupoPrecificacaoId: null }));
        setValidator(current => ({ ...current, grupoPrecificacaoId: "false" }));
    };

    const clearRota = () => {
        setValuesSelect(current => ({ ...current, rotaId: '' }));
        setValues(current => ({ ...current, rotaId: null }));
        setValidator(current => ({ ...current, rotaId: "false" }));
    };

    const clearCliente = () => {
        setValuesSelect(current => ({ ...current, clienteId: '' }));
        setValues(current => ({ ...current, clienteId: null }));
        setValidator(current => ({ ...current, clienteId: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Precificação" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Precificação" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Precificação</p>
                                                </div>
                                            </div>
                                            {
                                                Object.keys(dadosPorTipo).length === 1
                                                    ? (
                                                        // código comentado
                                                        Object.keys(dadosPorTipo).map((tipo, index) => (
                                                            <div key={index}>
                                                                <h3>{tipo}</h3>
                                                                <TabelaV2
                                                                    setTelaCadastro={setTelaCadastro}
                                                                    setEditando={setEditando}
                                                                    classeSelecionado="idValorEmbarque"
                                                                    nomeClasse1="outer-wrapper-rota"
                                                                    nomeClasse2="table-wrapper-v2"
                                                                    colunas={dadosTabelaRedux.ValorEmbarque}
                                                                    dados={dadosPorTipo[tipo]}
                                                                />
                                                            </div>
                                                        ))
                                                    )
                                                    : (
                                                        // código descomentado
                                                        Object.keys(dadosPorTipo).map((tipo, index, array) => {
                                                            if (index % 2 === 0) {
                                                                return (
                                                                    <div key={index} className="flexContainerValor">
                                                                        <div>
                                                                            <h3>{tipo}</h3>
                                                                            <TabelaV2
                                                                                setTelaCadastro={setTelaCadastro}
                                                                                setEditando={setEditando}
                                                                                classeSelecionado="idValorEmbarque"
                                                                                nomeClasse1="outer-wrapper-rota"
                                                                                nomeClasse2="table-wrapper-v2"
                                                                                colunas={dadosTabelaRedux.ValorEmbarque}
                                                                                dados={dadosPorTipo[tipo]}
                                                                            />
                                                                        </div>

                                                                        {(array[index + 1]) && (
                                                                            <div>
                                                                                <h3>{array[index + 1]}</h3>
                                                                                <TabelaV2
                                                                                    setTelaCadastro={setTelaCadastro}
                                                                                    setEditando={setEditando}
                                                                                    classeSelecionado="idValorEmbarque"
                                                                                    nomeClasse1="outer-wrapper-rota"
                                                                                    nomeClasse2="table-wrapper-v2"
                                                                                    colunas={dadosTabelaRedux.ValorEmbarque}
                                                                                    dados={dadosPorTipo[array[index + 1]]}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    )
                                            }
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Valor Viagem /"}</p>
                                                        <p className="indicador-emb">{"Cadastro de Valor Viagem"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Valor Viagem" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Eixos:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, eixos: e.target.value }));
                                                                        setValidator(current => ({ ...current, eixos: "true" }));
                                                                    }}
                                                                    value={values.eixos}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 1" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, km: e.target.value }));
                                                                        setValidator(current => ({ ...current, km: "true" }));
                                                                    }}
                                                                    value={values.km}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 50" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Valor Eixo:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, valorEixo: e.target.value }));
                                                                        setValidator(current => ({ ...current, valorEixo: "true" }));
                                                                    }}
                                                                    value={values.valorEixo}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 100" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Adicional Descarga:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, adicionalCargaDescarga: e.target.value }));
                                                                        setValidator(current => ({ ...current, adicionalCargaDescarga: "true" }));
                                                                    }}
                                                                    value={values.adicionalCargaDescarga}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 100" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, motorista: e.target.value }));
                                                                        setValidator(current => ({ ...current, motorista: "true" }));
                                                                    }}
                                                                    value={values.motorista}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: João" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Empresa:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, empresa: e.target.value }));
                                                                        setValidator(current => ({ ...current, empresa: "true" }));
                                                                    }}
                                                                    value={values.empresa}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: Cassava" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">ICMS:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, icms: e.target.value }));
                                                                        setValidator(current => ({ ...current, icms: "true" }));
                                                                    }}
                                                                    value={values.icms}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 100" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Descarga:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, descarga: e.target.value }));
                                                                        setValidator(current => ({ ...current, descarga: "true" }));
                                                                    }}
                                                                    value={values.descarga}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 0" />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Pedágio:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, pedagio: e.target.value }));
                                                                        setValidator(current => ({ ...current, pedagio: "true" }));
                                                                    }}
                                                                    value={values.pedagio}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 0" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectGrupoPrecificacao ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Grupo Precificação:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={230}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, grupoPrecificacaoId: e }));
                                                                                setValues(current => ({ ...current, grupoPrecificacaoId: e.id }));
                                                                                setValidator(current => ({ ...current, grupoPrecificacaoId: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroGrupoPrecificacao(true);
                                                                            }}
                                                                            clearSelection={clearGrupoPrecificacao}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idPrecificacao"
                                                                            grande={true}
                                                                            value={valuesSelect.grupoPrecificacaoId}
                                                                            titulo="Grupo Precificação"
                                                                            dadosSelect={listaGrupoPrecificacao}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome" />
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroGrupoPrecificacao(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        <div className="coluna-emb">
                                                            {
                                                                loadingSelectRota ? (
                                                                    <LoadingBar />
                                                                ) : (
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Rota:</p>
                                                                            <SelectEmbarqueV2
                                                                                classeCss={230}
                                                                                onChange={(e) => {
                                                                                    setValuesSelect(current => ({ ...current, rotaId: e }));
                                                                                    setValues(current => ({ ...current, rotaId: e.id }));
                                                                                    setValidator(current => ({ ...current, rotaId: "true" }));
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setDisable(true);
                                                                                    setOpenCadastroRota(true);
                                                                                }}
                                                                                clearSelection={clearRota}
                                                                                onInfoIconClick={setIdAtual}
                                                                                id="idRota"
                                                                                grande={true}
                                                                                value={valuesSelect.rotaId}
                                                                                titulo="Rota"
                                                                                dadosSelect={listaRotas}
                                                                                referenciaId="nome"
                                                                                referenciaItem="nome" />
                                                                        </div>
                                                                        <NewIcon
                                                                            onClick={(e) => {
                                                                                setOpenCadastroRota(true); setIdAtual(null); setDisable(false);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="coluna-emb">
                                                            {
                                                                loadingSelectCliente ? (
                                                                    <LoadingBar />
                                                                ) : (
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                                                            <SelectEmbarqueV2
                                                                                classeCss={230}
                                                                                onChange={(e) => {
                                                                                    setValuesSelect(current => ({ ...current, clienteId: e }));
                                                                                    setValues(current => ({ ...current, clienteId: e.id }));
                                                                                    setValidator(current => ({ ...current, clienteId: "true" }));
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setDisable(true);
                                                                                    setOpenCadastroCliente(true);
                                                                                }}
                                                                                clearSelection={clearCliente}
                                                                                onInfoIconClick={setIdAtual}
                                                                                id="idCliente"
                                                                                grande={true}
                                                                                value={valuesSelect.clienteId}
                                                                                titulo="Grupo Cliente"
                                                                                dadosSelect={listaClientes}
                                                                                referenciaId="nomeFantasia"
                                                                                referenciaItem="nomeFantasia" />
                                                                        </div>
                                                                        <NewIcon
                                                                            onClick={(e) => {
                                                                                setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Frete Fiscal:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, freteFiscal: e.target.value }));
                                                                        setValidator(current => ({ ...current, freteFiscal: "true" }));
                                                                    }}
                                                                    value={values.freteFiscal}
                                                                    disabled={props.desabilitar}
                                                                    placeholder="Ex: 100" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.eixos !== "") && (validator.eixos === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.valorEixo !== "") && (validator.valorEixo === "true") &&
                                                                                    (values.adicionalCargaDescarga !== "") && (validator.adicionalCargaDescarga === "true") &&
                                                                                    (values.motorista !== "") && (validator.motorista === "true") &&
                                                                                    (values.empresa !== "") && (validator.empresa === "true") &&
                                                                                    (values.icms !== "") && (validator.icms === "true") &&
                                                                                    (values.descarga !== "") && (validator.descarga === "true") &&
                                                                                    (values.pedagio !== "") && (validator.pedagio === "true") &&
                                                                                    (values.freteFiscal !== "") && (validator.freteFiscal === "true") &&
                                                                                    (values.rotaId !== "") && (validator.rotaId === "true") &&
                                                                                    (values.clienteId !== "") && (validator.clienteId === "true") &&
                                                                                    (values.grupoPrecificacaoId !== "") && (validator.grupoPrecificacaoId === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.eixos !== "") && (validator.eixos === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.valorEixo !== "") && (validator.valorEixo === "true") &&
                                                                                    (values.adicionalCargaDescarga !== "") && (validator.adicionalCargaDescarga === "true") &&
                                                                                    (values.motorista !== "") && (validator.motorista === "true") &&
                                                                                    (values.empresa !== "") && (validator.empresa === "true") &&
                                                                                    (values.icms !== "") && (validator.icms === "true") &&
                                                                                    (values.descarga !== "") && (validator.descarga === "true") &&
                                                                                    (values.pedagio !== "") && (validator.pedagio === "true") &&
                                                                                    (values.freteFiscal !== "") && (validator.freteFiscal === "true") &&
                                                                                    (values.rotaId !== "") && (validator.rotaId === "true") &&
                                                                                    (values.clienteId !== "") && (validator.clienteId === "true") &&
                                                                                    (values.grupoPrecificacaoId !== "") && (validator.grupoPrecificacaoId === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarValorEmbarquePorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroRota="sim"
                                        open={openCadastroRota}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroRota}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCliente="sim"
                                        open={openCadastroCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCliente}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroGrupoPrecificacao="sim"
                                        open={openCadastroGrupoPrecificacao}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroGrupoPrecificacao}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default ValorEmbarque;