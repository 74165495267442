import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import '../ModalFiltroDiaria/ModalFiltroDiaria.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import { calcularDiarias } from "../../services/ModuloGerenciador/Diaria";
import DadosUsuarioRedux from "../../contexts/Usuario";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2";
import ModalDadosDiaria from "../ModalDadosDiaria/ModalDadosDiaria.tsx";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaStatus: any;
    onClose: () => void;
    onClick: () => void;
    fecha: () => void;
    grupoClienteId: number;
}

function ModalFiltroDiaria(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        setValues({ dataInicial: "", dataFinal: "", cte: "", grupoClienteId: 0, statusId: 0 });
        onClose();
    };

    const fecha = () => {
        props.fecha();
    };

    const [loading, setLoading] = useState(false); // Estado para loading
    const [error, setError] = useState(""); // Estado para a mensagem de erro

    const [values, setValues] = useState({
        dataInicial: "",
        dataFinal: "",
        cte: "",
        grupoClienteId: props.grupoClienteId,
        statusId: 0
    });

    const [valuesSelect, setValuesSelect] = useState({
        status: {}
    });

    const validateDates = () => {
        if (values.dataInicial === '' || values.dataFinal === '') {
            setError("Datas são obrigatórias");
        } else {
            setError(""); // Limpa a mensagem de erro se as datas estiverem preenchidas
        }
    };

    const [modalUsuarioOpen, setModalUsuarioOpen] = useState(false);
    const [usuarioData, setUsuarioData] = useState(null);

    useEffect(() => {
        validateDates(); // Valida as datas sempre que elas forem alteradas
    }, [values.dataInicial, values.dataFinal]);

    const buscarCalcularDiarias = async () => {
        if (values.dataInicial === '' || values.dataFinal === '') {
            setError("Datas obrigatórias");
        } else {
            setLoading(true);
            await calcularDiarias(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    if (result.data.length > 0) {
                        handleClose();
                        setUsuarioData(result.data); // Armazena o result.data
                        setModalUsuarioOpen(true); // Abre o novo modal
                        setLoading(false);
                    } else {
                        setError("Nenhuma diária encontrada");
                        setLoading(false);
                    }
                }
            }).catch((error) => {
                setLoading(false);
            });
        }
    };

    function LoadingComponent(props) {
        return (
            <>
                <b className='carregando-texto' style={{ marginTop: '12px' }}>Carregando...</b>
            </>
        )
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-filtro-diaria'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb">Data Inicial:</p>
                                        <input
                                            type="date"
                                            className="input-emb"
                                            style={{ width: '200px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, dataInicial: e.target.value }));
                                            }}
                                            value={values.dataInicial} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb">Data Final:</p>
                                        <input
                                            type="date"
                                            className="input-emb"
                                            style={{ width: '200px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, dataFinal: e.target.value }));
                                            }}
                                            value={values.dataFinal} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb">CTE:</p>
                                        <input
                                            type="text"
                                            maxLength={5}
                                            className="input-emb"
                                            style={{ width: '200px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, cte: e.target.value }));
                                            }}
                                            value={values.cte} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb">Status:</p>
                                        <SelectEmbarqueV2
                                            classeCss={200}
                                            onChange={(e) => {
                                                setValuesSelect(current => ({ ...current, status: e }));
                                                setValues(current => ({ ...current, statusId: e.id }));
                                            }}
                                            pequeno={true}
                                            id="idStatus"
                                            value={valuesSelect.status}
                                            titulo="Status"
                                            dadosSelect={props.listaStatus}
                                            referenciaId="nome"
                                            referenciaItem="nome"
                                            info={false} />
                                    </div>
                                </div>
                                {error && <p className="error-message">{error}</p>} {/* Exibe a mensagem de erro */}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo={loading ? <LoadingComponent /> : "OK"}
                            cor="#0946B8"
                            onClick={buscarCalcularDiarias}
                            disabled={loading || values.dataInicial === '' || values.dataFinal === ''}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            {/* Novo modal que recebe os dados */}
            {usuarioData && (
                <ModalDadosDiaria
                    open={modalUsuarioOpen}
                    onClose={() => setModalUsuarioOpen(false)}
                    titulo="Diárias Automáticas"
                    dados={usuarioData}
                    fecha={fecha}
                />
            )}
        </div>
    );
}

export default ModalFiltroDiaria;
