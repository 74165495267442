import React, { useState, createContext } from 'react';

const valorInicial = {
    'Embarque': [
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Entrega',
            'field': 'entrega',
            'visivel': 'true'
        },
        {
            'quebraColunaEmbarque': false
        },
    ],
    'Modelo Embarque': [
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Entrega',
            'field': 'entrega',
            'visivel': 'true'
        },
        {
            'quebraColunaEmbarque': false
        },
    ],
    'Pedido': [
        {
            'nomeColuna': 'N Pedido',
            'field': 'pedido',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'tipoRota',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Embarcador Estado',
            'field': 'embarcadorEstado',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Destinatario Estado',
            'field': 'destinatarioEstado',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'visivel': 'true',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino',
            'visivel': 'true'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao',
            'visivel': 'true'
        },
        {
            'quebraColunaPedido': false
        },
    ],
};

const Menus = createContext();

export default Menus;

export const MenusRedux = ({ children }) => {
    const [menusRedux, setDadosMenusRedux] = useState(valorInicial);

    return (
        <Menus.Provider value={{
            menusRedux,
            setDadosMenusRedux,
        }}>
            {children}
        </Menus.Provider>
    );
};