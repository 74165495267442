import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import './AutoEditar.css'
import Box from '@mui/material/Box';
import { InputLabel } from "@mui/material";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CoresRedux from "../../../../contexts/Cor";
import RequestControllerRedux from "../../../../contexts/RequestController";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx"
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx"
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { useNavigate } from "react-router-dom";
import { alterarSenhaDentroSistema } from "../../../../services/ModuloGerenciador/Solicitacao";
import { autoEditaUser, getUserId, verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { cadastraFotoUsuario } from "../../../../services/ModuloGerenciador/Midia";
import { motion } from "framer-motion";
import TabelaRedux from "../../../../contexts/Tabela";

function AutoEditar() {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [values, setValues] = useState([]);

    const [imageSrc, setImageSrc] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();

        reader.onloadend = (event) => {
            // Configura a visualização da imagem
            setImageSrc(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [passou, setPassou] = useState(false);

    const navigate = useNavigate();

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    const buscarUsuarioPorId = async () => {
        setLoading(true);
        await getUserId(dadosUsuarioRedux.usuario.idUsuario, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setValues(current => ({ ...current, usuarioId: result.data.idUsuario }))
                setValues(current => ({ ...current, nome: result.data.nome }))
                setValues(current => ({ ...current, telefone: result.data.telefone }))
                setValues(current => ({ ...current, email: result.data.email }))
                var todayEm = new Date(result.data.nascimento);
                var ddPrEn = String(todayEm.getDate()).padStart(2, '0');
                var mmPrEn = String(todayEm.getMonth() + 1).padStart(2, '0');
                var yyyyPrEn = todayEm.getFullYear();
                todayEm = yyyyPrEn + '-' + mmPrEn + '-' + ddPrEn;
                setValues(current => ({ ...current, nascimento: todayEm }))
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }


    const autoEditaUsuario = async () => {
        setLoading(true);
        await autoEditaUser(dadosUsuarioRedux.usuario.idUsuario, values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setLoading(false);
                navigate('/');
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const salvarFoto = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
        await cadastraFotoUsuario(dadosUsuarioRedux.usuario.idUsuario, formData, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setLoading(false);
                navigate('/');
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        buscarUsuarioPorId();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Mudar Senha" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV3 selecionado="Mudar Senha" />
                    {!loading ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="containerEdita"
                        >
                            <p>Dados Usuário</p>
                            <div className="form">
                                <p className="text">Edite as informações abaixo para atualizar seus dados!</p>
                                <hr></hr>
                                <Box display={'flex'} justifyContent={'center'} className="box">
                                    <div className="principal-edit-user">
                                        <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                        <input
                                            id="email"
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, nome: e.target.value }))}
                                            value={values.nome} />
                                        <p className="input-label-emb" htmlFor="descricao">Data Nascimento:</p>
                                        <input
                                            id="email"
                                            type="date"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, nascimento: e.target.value }))}
                                            value={values.nascimento} />
                                        <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                        <input
                                            id="email"
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, telefone: e.target.value }))}
                                            value={values.telefone} />
                                        <p className="input-label-emb" htmlFor="descricao">Email:</p>
                                        <input
                                            id="email"
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '350px' }}
                                            onChange={(e) => setValues(current => ({ ...current, email: e.target.value }))}
                                            value={values.email} />
                                        <p className="input-label-emb" htmlFor="descricao">Foto:</p>
                                        <input
                                            id="imageInput"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                        {imageSrc && (
                                            <div className="image-preview">
                                                <img src={imageSrc} alt="Uploaded preview" />
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            </div>
                            <Box display={'flex'} className="botoes-edit-user">
                                <CustomButtonV2
                                    classCss="custom-button-cadastrar"
                                    classText="button-texto-cadastrar"
                                    icone="iconeSave"
                                    titulo="Enviar"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => {
                                        autoEditaUsuario();
                                        salvarFoto();
                                    }}
                                />
                                <CustomButtonV2
                                    classCss="custom-button-cancelar"
                                    classText="button-texto-cancelar"
                                    titulo="Cancelar"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                />
                            </Box>
                        </motion.div>
                    ) : (
                        <LoadingComponent />
                    )}
                    <div>
                        <ToastSucesso
                            texto={textoSucesso}
                            open={openToastSucesso}
                            onClose={handleCloseToastSucesso}
                        />
                        <ToastErro
                            texto={textoErro}
                            open={openToastErro}
                            onClose={handleCloseToastErro}
                        />
                    </div>
                </div >
            )
            }
        </motion.div>
    );
}

export default AutoEditar;