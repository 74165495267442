import React, { useState, useContext, useEffect, useRef } from "react";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../NavbarV3/NavbarV3.css';
import { IconContext } from 'react-icons';
import CoresRedux from "../../contexts/Cor.js";
import logoNavbar from '../../images/logoNavbar.png'
import iconNotification from '../../images/IconNotification.png'
import iconConfig from '../../images/IconConfig.png'
import * as MdIcons from 'react-icons/md';
import styled from "styled-components";
import FilialCidade from "../../contexts/CidadesFilial.js";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque.js";
import ModalConfirmacao from "../ModalConfirmacao/ModalConfirmacao.tsx";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getNumeroSolicitacoes } from "../../services/ModuloGerenciador/Solicitacao.js";
import { logoutUser, verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario.js";
import DadosUsuarioRedux from "../../contexts/Usuario.js";
import ToastSucesso from "../Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../Toast/ToastErro/ToastErro.tsx";
import { getMidiaIdUsuario } from "../../services/ModuloGerenciador/Midia.js";
import ModalNotificacao from "../ModalNotificacao/ModalNotificacao.tsx";
import { SidebarDataUsoComum } from "./SidebarDataV3";
import { useNavigate } from "react-router-dom";
import iconeHome from '../../images/iconeHome.png'
import iconeEmbarque from '../../images/iconeEmbarque.png'
import iconePedidos from '../../images/iconePedidos.png'
import iconeOnTime from '../../images/iconeOnTime.png'
import iconeFeedback from '../../images/iconeFeedback.png'
import iconeAjuda from '../../images/iconeAjuda.png'
import iconeMenu from '../../images/iconeMenu.png'
import iconeUsuario from '../../images/iconeUsuario.png'
import iconeAdm from '../../images/iconeAdm.png'
import iconeRastreamento from '../../images/iconeRastreamento.png'
import iconeVeiculos from '../../images/iconeVeiculos.png'
import iconePessoas from '../../images/iconePessoas.png'
import iconeComum from '../../images/iconeComum.png'
import iconeDash from '../../images/iconeDash.png'
import iconePreco from '../../images/iconePreco.png'
import iconeCotacao from '../../images/iconeCotacao.png'
import iconeFaturamento from '../../images/iconeFaturamento.png'
import iconeAlerta from '../../images/iconeAlerta.png'
import iconeRota from '../../images/iconeRota.png'
import iconeCheck from '../../images/iconeCheck.png'
import ModalConfirmacao2 from "../ModalConfirmacao2/ModalConfirmacao2.tsx";
import ModalAlerta from "../ModalAlerta/ModalAlerta.tsx";
import { getNotificacoes } from "../../services/ModuloGerenciador/Geral.js";
import TodayIcon from '@mui/icons-material/Today';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

function NavbarV3(props) {
  const [sidebar, setSidebar] = useState(false);

  const ref = useRef();

  const [loading, setLoading] = useState(false);

  const [numeroSolicitacoes, setNumeroSolicitacoes] = useState();

  const [quantidadePedidosAguardando, setQuantidadePedidosAguardando] = useState();

  const [numeroCte, setNumeroCte] = useState();

  const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

  const showSidebar = () => {
    localStorage.removeItem("filtros");
    setSidebar(!sidebar);
  }

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSidebar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [dropDown, setDropDown] = useState(false);

  const showDropDow = () => setDropDown(!dropDown);

  const [dropDownEmbarques, setDropDownEmbarques] = useState(false);

  const showDropDowEmbarques = () => setDropDownEmbarques(!dropDownEmbarques);

  const [dropDownVeiculos, setDropDownVeiculos] = useState(false);

  const showDropDowVeiculos = () => setDropDownVeiculos(!dropDownVeiculos);

  const [dropDownPessoas, setDropDownPessoas] = useState(false);

  const showDropDowPessoas = () => setDropDownPessoas(!dropDownPessoas);

  const [dropDownDashboard, setDropDownDashboard] = useState(false);

  const showDropDowDashboard = () => setDropDownDashboard(!dropDownDashboard);

  const [dropDownPrecificacao, setDropDownPrecificacao] = useState(false);

  const showDropDowPrecificacao = () => setDropDownPrecificacao(!dropDownPrecificacao);

  const [dropDownCotacao, setDropDownCotacao] = useState(false);

  const showDropDowCotacao = () => setDropDownCotacao(!dropDownCotacao);

  const [dropDownPedido, setDropDownPedido] = useState(false);

  const showDropDowPedido = () => setDropDownPedido(!dropDownPedido);

  const [dropDownOnTime, setDropDownOnTime] = useState(false);

  const showDropDowOnTime = () => setDropDownOnTime(!dropDownOnTime);

  const [dropDownDiaria, setDropDownDiaria] = useState(false);

  const showDropDowDiaria = () => setDropDownDiaria(!dropDownDiaria);

  const [dropDownGerenciamentoFrota, setDropDownGerenciamentoFrota] = useState(false);

  const showDropDowGerenciamentoFrota = () => setDropDownGerenciamentoFrota(!dropDownGerenciamentoFrota);

  const [dropDownRastreamento, setDropDownRastreamento] = useState(false);

  const showDropDowRastreamento = () => setDropDownRastreamento(!dropDownRastreamento);

  const [dropDownDiversos, setDropDownDiversos] = useState(false);

  const showDropDowDiversos = () => {
    setDropDownDiversos(!dropDownDiversos)
  };

  const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

  const [dropDownConfigs, setDropDownConfigs] = useState(false);

  const showDropDowConfigs = () => setDropDownConfigs(!dropDownConfigs);

  const [dropDownUsuario, setDropDownUsuario] = useState(false);

  const showDropDowUsuario = () => setDropDownUsuario(!dropDownUsuario);

  const { dadosCoresRedux } = useContext(CoresRedux);
  const { dadosFilialRedux } = useContext(FilialCidade);

  const [open, setOpen] = useState(false);

  const [textoSucesso, setTextoSucesso] = useState("");

  const [textoErro, setTextoErro] = useState("");

  const handleClose = (value) => {
    setOpen(false);
  };

  const [openToastErro, setOpenToastErro] = useState(false);

  const handleCloseToastErro = (value) => {
    setOpenToastErro(false);
  };

  const [openToastSucesso, setOpenToastSucesso] = useState(false);

  const handleCloseToastSucesso = (value) => {
    setOpenToastSucesso(false);
  };

  const [notificacao, setNotificacao] = useState([]);

  const [openNotificacao, setOpenNotificacao] = useState(false);

  const handleCloseNotificacao = (value) => {
    setOpenNotificacao(false);
  };

  const [openExpiracao, setOpenExpiracao] = useState(false);

  const handleCloseExpiracao = (value) => {
    setOpenExpiracao(false);
  };

  const [configDropdownOpen, setConfigDropdownOpen] = useState(false);

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const logout = async () => {
    await logoutUser(dadosUsuarioRedux.usuario.idUsuario, dadosUsuarioRedux.token).then((result) => {
      setTextoSucesso(result.message);
    }).catch((error) => {
      setTextoErro(error.message);
    });
    localStorage.removeItem("usuario");
    localStorage.removeItem("token");
    localStorage.removeItem("expiracao");
    localStorage.removeItem("expirateTime");
    localStorage.removeItem("usuarioTabela");
    localStorage.removeItem("Cookies");
    localStorage.removeItem("flagPassword");
    window.location.reload();
  };

  const Menu = styled.div`     
    background: ${dadosCoresRedux.fundoMenu};

    &:hover {
    background: ${dadosCoresRedux.botaoSelecionado};
    }
  `

  const Rota = styled(Link)`     
    color: ${dadosCoresRedux.textoBotaoNormal};
    text-decoration: none;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;

    &:hover {
    background: #ececec;
    transition: 0.5s;
    }
  `

  const onClick2 = (item) => {
    setDadosContextoEmbarqueRedux(item.contexto);
  }

  const MenuConfigs = [
    {
      menu: "Avisos",
      titulo: "Avisos",
      rota: "/avisos"
    },
    {
      menu: "Cores",
      titulo: "ConfiguracoesCores",
      rota: "/configuracoes-cores"
    },
    {
      menu: "Colunas",
      titulo: "ConfiguracoesColunas",
      rota: "/configuracoes-coluna"
    },
    {
      menu: "Filiais",
      titulo: "Filiais",
      rota: "/filiais"
    },
    {
      menu: "Notificações",
      titulo: "Notificacoes",
      rota: "/notificacoes"
    },
    {
      menu: "Enviar Email",
      titulo: "EnviarEmail",
      rota: "/envia-email"
    },
  ];

  const MenuUsuario = [
    {
      menu: "Usuários",
      titulo: "Usuários",
      rota: "/cadastrar-usuario"
    },
    {
      menu: "Solicitações",
      titulo: "Solicitações",
      rota: "/solicitacoes",
      notificao: true
    },
  ];

  const buscarNumeroSolicitacoes = async () => {
    if (!window.location.href.endsWith("login")) {
      await getNotificacoes(dadosUsuarioRedux.token).then((result) => {
        if (result.status === 200) {
          setNumeroSolicitacoes(result.data.nSolicitacoes);
          setQuantidadePedidosAguardando(result.data.nPedidos);
          setNumeroCte(result.data.nEmbarques);
        }
      }).catch((error) => {
        setLoading(false);
      });
    }
  }

  const [imageSrc, setImageSrc] = useState(null);

  const buscarMidiaUsuario = async () => {
    if (!window.location.href.endsWith("login")) {
      await getMidiaIdUsuario(dadosUsuarioRedux?.usuario?.idUsuario, dadosUsuarioRedux.token).then((result) => {
        if (result.status === 200) {
          setImageSrc(result.data);
        }
      }).catch((error) => {
        setImageSrc(error.response.data);
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    buscarMidiaUsuario();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      buscarNumeroSolicitacoes();
    }, 30000);

    buscarNumeroSolicitacoes();

    return () => clearInterval(interval);
  }, []);

  const verificaMenuAberto = async () => {
    dadosFilialRedux.map((filial) => {
      if (props.selecionado === filial.contexto) {
        setDropDownEmbarques(true);
      }
    });
    switch (props.selecionado) {
      case 'Aguardando CTE':
      case 'Diárias':
      case 'Configuração Empresa':
        setDropDownDiaria(true);
        break;
      case 'Localizador Veículos':
        setDropDownEmbarques(true);
        break;
      case 'Conjuntos':
      case 'Caminhões':
      case 'Carretas':
      case 'GrupoCarga':
      case 'GrupoCargaVinculo':
        setDropDownVeiculos(true);
        break;
      case 'Frota':
      case 'Veículos Vencidos':
        setDropDownGerenciamentoFrota(true);
        break;
      case 'Adicionar Checklist Veículo':
      case 'Tipo de Checkagem':
      case 'Checkagem':
        setDropDownGerenciamentoFrota(true);
        setDropDownDiversos(true);
        break;
      case 'Proprietário':
      case 'Motoristas':
      case 'Clientes':
      case 'Grupo de Clientes':
      case 'Vincular Clientes':
        setDropDownPessoas(true);
        break;
      case 'Cadastro Rastreio':
      case 'Cidade':
      case 'Embalagem':
      case 'Mercadoria':
      case 'Empresas Subsidiárias':
      case 'Filial':
      case 'Meio Pagamento':
      case 'Status':
      case 'Unidade Embarque':
      case 'Motivo':
      case 'Taxa ICMS':
      case 'Motivo On Time':
      case 'Tipo Veículo':
      case 'Tipo Carroceria':
      case 'Motivo Cotação':
      case 'Segmento Cliente':
      case 'Ocorrência':
      case 'Tipo Ocorrência':
      case 'Sub Tipo Ocorrência':
        setDropDown(true);
        break;
      case 'Dashboard':
      case 'Dashboard Cockpit':
      case 'Painel':
      case 'Responsáveis':
        setDropDownDashboard(true);
        break;
      case 'Precificação':
      case 'Grupo Precificação':
        setDropDownPrecificacao(true);
        break;
      case 'Usuários':
      case 'Solicitações':
        setDropDownUsuario(true);
        break;
      case 'Pedidos':
      case 'Gerenciamento de Pedido':
        setDropDownPedido(true);
        break;
      case 'Cotações':
      case 'Dashboard Cotações':
        setDropDownCotacao(true);
        break;
      case 'Check List Frota':
      case 'Veículos Vencidos':
        setDropDownGerenciamentoFrota(true);
        break;
      case 'Adicionar Checklist Veículo':
      case 'Tipo de Checkagem':
      case 'Checkagem':
        setDropDownGerenciamentoFrota(true);
        setDropDownDiversos(true);
        break;
      case 'Todos':
      case 'Embarque':
      case 'Entrega':
        setDropDownOnTime(true);
        break;
      default:
        return;
    }
  }

  const verificarExpiracao = () => {
    const alertaJaExibido = localStorage.getItem("expirateTime");
    if (alertaJaExibido) return;

    const expiracao = localStorage.getItem("expiracao");
    const dataExpiracao = new Date(expiracao);
    const agora = new Date();
    const diferenca = dataExpiracao - agora;
    const dezMinutos = 10 * 60 * 1000;

    if (diferenca <= dezMinutos) {
      setOpenExpiracao(true);
      localStorage.setItem('expirateTime', true);
    } else {
      setTimeout(verificarExpiracao, diferenca - dezMinutos);
    }
  }

  // useEffect(() => {
  //   verificaMenuAberto();
  //   verificarExpiracao();
  // }, []);

  const fecharDrops1 = () => {
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops2 = () => {
    setDropDownEmbarques(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops3 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops4 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops5 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops6 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setConfigDropdownOpen(false);
    setUserDropdownOpen(false);
  }

  const fecharDrops7 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setConfigDropdownOpen(false);
  }

  const fecharDrops8 = () => {
    setDropDownEmbarques(false);
    setDropDownRastreamento(false);
    setDropDownPessoas(false);
    setDropDownDashboard(false);
    setDropDownPrecificacao(false);
    setDropDownDiaria(false);
    setUserDropdownOpen(false);
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar' style={{ backgroundColor: dadosCoresRedux.headerFooter }}>

          {
            sidebar ? (
              <div className="navbar-icons-2">
                <div className="navbar-icons-2">
                  <div onClick={() => { navigate("/"); }} className="navbar-inicio">
                    <img style={{ cursor: 'default' }} src={logoNavbar} className="iconeMenu" />
                    <p className="title-menu-inicio">Início</p>
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownEmbarques(!dropDownEmbarques);
                    fecharDrops1();
                  }}>
                    <p className="title-menu">Viagens e Pedidos</p>
                    {
                      dropDownEmbarques ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownRastreamento(!dropDownRastreamento)
                    fecharDrops2();
                  }}>
                    <p className="title-menu">Rotas e Rastreio</p>
                    {
                      dropDownRastreamento ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownPessoas(!dropDownPessoas)
                    fecharDrops3();
                  }}>
                    <p className="title-menu">Gestão</p>
                    {
                      dropDownPessoas ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownDashboard(!dropDownDashboard)
                    fecharDrops4();
                  }}>
                    <p className="title-menu">Dashboards</p>
                    {
                      dropDownDashboard ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownPrecificacao(!dropDownPrecificacao)
                    fecharDrops5();
                  }}>
                    <p className="title-menu">Financeiro</p>
                    {
                      dropDownPrecificacao ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                  <div className="navbar-title" onClick={() => {
                    setDropDownDiaria(!dropDownDiaria)
                    fecharDrops6();
                  }}>
                    <p className="title-menu">Diárias</p>
                    {
                      dropDownDiaria ? (
                        <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                      ) : (
                        <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                      )
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div className="navbar-icons">
                <div onClick={() => { navigate("/"); }} className="navbar-inicio">
                  <img style={{ cursor: 'default' }} src={logoNavbar} className="iconeMenu" />
                  <p className="title-menu-inicio">Início</p>
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownEmbarques(!dropDownEmbarques);
                  fecharDrops1();
                }}>
                  <p className="title-menu">Viagens e Pedidos</p>
                  {
                    dropDownEmbarques ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownRastreamento(!dropDownRastreamento)
                  fecharDrops2();
                }}>
                  <p className="title-menu">Rotas e Rastreio</p>
                  {
                    dropDownRastreamento ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownPessoas(!dropDownPessoas)
                  fecharDrops3();
                }}>
                  <p className="title-menu">Gestão</p>
                  {
                    dropDownPessoas ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownDashboard(!dropDownDashboard)
                  fecharDrops4();
                }}>
                  <p className="title-menu">Dashboards</p>
                  {
                    dropDownDashboard ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownPrecificacao(!dropDownPrecificacao)
                  fecharDrops5();
                }}>
                  <p className="title-menu">Financeiro</p>
                  {
                    dropDownPrecificacao ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
                <div className="navbar-title" onClick={() => {
                  setDropDownDiaria(!dropDownDiaria)
                  fecharDrops6();
                }}>
                  <p className="title-menu">Diárias</p>
                  {
                    dropDownDiaria ? (
                      <MdIcons.MdOutlineKeyboardArrowUp color="#FFF" />
                    ) : (
                      <MdIcons.MdOutlineKeyboardArrowDown color="#FFF" />
                    )
                  }
                </div>
              </div>
            )
          }
          <div className="right-icons">
            <img className="image-header" onClick={() => { navigate("/ajuda"); }} src={iconeAjuda} alt="iconeAjuda" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            <img className="image-header" onClick={() => { setOpenNotificacao(true); }} src={iconNotification} alt="iconNotification" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            <img className="image-header" onClick={() => {
              setConfigDropdownOpen(!configDropdownOpen);
              fecharDrops8();
            }} src={iconConfig} alt="iconConfig" style={{ maxWidth: "100%", maxHeight: "60%", marginRight: "1%" }} />
            {dropDownEmbarques && (
              <div style={{ height: '65%' }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeEmbarque} />
                      <span className="titulo-navbar-texto">Viagens</span>
                    </div>
                    {dadosFilialRedux.map((item, index) => (
                      <Rota to="/embarque" onClick={() => { onClick2(item); }}>
                        <span className="botao-menu">{item.nomeFilial}</span>
                      </Rota>
                    ))}
                    <Rota to="/aguardando-cte">
                      <span className="botao-menu">Aguardando CTE</span>
                      {
                        numeroCte ? (
                          <Badge badgeContent={numeroCte} color="primary">
                            <NotificationsIcon className="icone-notificao" color="inherit" />
                          </Badge>
                        ) : (
                          <></>
                        )
                      }
                    </Rota>
                    <Rota to="/localizador-veiculos">
                      <span className="botao-menu">Localizador Veículos</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2-linha">
                    <div className="titulo-navbar-icone">
                      <img src={iconePedidos} />
                      <span className="titulo-navbar-texto">Pedido</span>
                    </div>
                    <Rota to="/pedido">
                      <span className="botao-menu">Pedidos</span>
                    </Rota>
                    <Rota to="/gerenciamento-pedido">
                      <span className="botao-menu">Gerenciamento de Pedido</span>
                      <Badge badgeContent={quantidadePedidosAguardando} color="primary">
                        <NotificationsIcon className="icone-notificao" color="inherit" />
                      </Badge>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeOnTime} />
                      <span className="titulo-navbar-texto">On Time</span>
                    </div>
                    <Rota to="/ontime">
                      <span className="botao-menu">Todos</span>
                    </Rota>
                    <Rota to="/ontime-embarque">
                      <span className="botao-menu">Embarque</span>
                    </Rota>
                    <Rota to="/ontime-entrega">
                      <span className="botao-menu">Entrega</span>
                    </Rota>
                  </div>
                </div>
              </div>
            )}
            {dropDownRastreamento && (
              <div style={{ height: '20%', left: 300, }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div style={{
                    borderRight: '1px dashed rgba(0, 0, 0, 0.10)',
                    background: '#FFF',
                  }} className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeRota} />
                      <span className="titulo-navbar-texto">Rotas</span>
                    </div>
                    <Rota to="/rota">
                      <span className="botao-menu">Visualizar Rotas</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeRastreamento} />
                      <span className="titulo-navbar-texto">Rastreamento</span>
                    </div>
                    <Rota to="/rastreamento">
                      <span className="botao-menu">Cadastro Rastreio</span>
                    </Rota>
                    <Rota to="/tipo-rastreamento">
                      <span className="botao-menu">Tipo Rastreio</span>
                    </Rota>
                  </div>
                </div>
              </div>
            )}
            {dropDownPessoas && (
              <div style={{ height: '80%', left: 480, }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconePessoas} />
                      <span className="titulo-navbar-texto">Gestão de Pessoas</span>
                    </div>
                    <Rota to="/proprietario">
                      <span className="botao-menu">Proprietário</span>
                    </Rota>
                    <Rota to="/motorista">
                      <span className="botao-menu">Motoristas</span>
                    </Rota>
                    <Rota to="/cliente">
                      <span className="botao-menu">Clientes</span>
                    </Rota>
                    <Rota to="/grupo-clientes">
                      <span className="botao-menu">Grupo de Clientes</span>
                    </Rota>
                    <Rota to="/vincular-clientes">
                      <span className="botao-menu">Vincular Clientes</span>
                    </Rota>
                    <div className="titulo-navbar-icone">
                      <img src={iconeUsuario} />
                      <span className="titulo-navbar-texto">Usuários</span>
                    </div>
                    <Rota to="/cadastrar-usuario">
                      <span className="botao-menu">Usuários</span>
                    </Rota>
                    <Rota to="/solicitacoes">
                      <span className="botao-menu">Solicitações</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2-linha">
                    <div className="titulo-navbar-icone">
                      <img src={iconeVeiculos} />
                      <span className="titulo-navbar-texto">Gestão de Veiculos</span>
                    </div>
                    <Rota to="/conjuntos">
                      <span className="botao-menu">Conjuntos</span>
                    </Rota>
                    <Rota to="/diaria-veiculos">
                      <span className="botao-menu">Agenda</span>
                    </Rota>
                    <Rota to="/caminhoes">
                      <span className="botao-menu">Caminhões</span>
                    </Rota>
                    <Rota to="/carretas">
                      <span className="botao-menu">Carretas</span>
                    </Rota>
                    <Rota to="/grupo-cargas">
                      <span className="botao-menu">Grupo Cargas</span>
                    </Rota>
                    <Rota to="/grupo-cargas-vinculo">
                      <span className="botao-menu">Vincular Grupo Cargas</span>
                    </Rota>
                    <div className="titulo-navbar-icone">
                      <img src={iconeCheck} />
                      <span className="titulo-navbar-texto">Checklist</span>
                    </div>
                    <Rota to="/frota">
                      <span className="botao-menu">Checklist Frota</span>
                    </Rota>
                    <Rota to="/veiculos-vencidos">
                      <span className="botao-menu">Veículos Vencidos</span>
                    </Rota>
                    <Rota to="/adicionar-check-veiculo">
                      <span className="botao-menu">Adicionar Checklist Veículo</span>
                    </Rota>
                    <Rota to="/tipo-checkagem">
                      <span className="botao-menu">Tipo de Checkagem</span>
                    </Rota>
                    <Rota to="/checkagem">
                      <span className="botao-menu">Checkagem</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeComum} />
                      <span className="titulo-navbar-texto">Uso Comum</span>
                    </div>
                    {SidebarDataUsoComum.map((item, index) => (
                      <Rota to={item.rota}>
                        <span className="botao-menu">{item.titulo}</span>
                      </Rota>
                    ))}
                  </div>
                </div>
              </div>

            )}
            {dropDownDashboard && (
              <div style={{ left: 600, }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeDash} />
                      <span className="titulo-navbar-texto">Dashboard</span>
                    </div>
                    <Rota to="/dashboard">
                      <span className="botao-menu">Dashboard</span>
                    </Rota>
                    <Rota to="/dashboard-cockpit">
                      <span className="botao-menu">Dashboard Cockpit</span>
                    </Rota>
                    <Rota to="/painel">
                      <span className="botao-menu">Painel</span>
                    </Rota>
                    <Rota to="/responsaveis">
                      <span className="botao-menu">Responsáveis</span>
                    </Rota>
                    <Rota to="/dashboard-ocorrencias">
                      <span className="botao-menu">Ocorrências</span>
                    </Rota>
                    <Rota to="/relatorio-proprietarios">
                      <span className="botao-menu">Estatísticas Terceiros</span>
                    </Rota>
                  </div>
                </div>
              </div>
            )}
            {dropDownPrecificacao && (
              <div style={{ height: '20%', left: 730 }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconePreco} />
                      <span className="titulo-navbar-texto">Precificações</span>
                    </div>
                    <Rota to="/precificacao">
                      <span className="botao-menu">Precificação</span>
                    </Rota>
                    <Rota to="/grupo-precificacao">
                      <span className="botao-menu">Grupo Precificação</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2-linha">
                    <div className="titulo-navbar-icone">
                      <img src={iconeCotacao} />
                      <span className="titulo-navbar-texto">Cotações</span>
                    </div>
                    <Rota to="/cotacao">
                      <span className="botao-menu">Cotação</span>
                    </Rota>
                    <Rota to="/dashboard-cotacao">
                      <span className="botao-menu">Dashboard Cotações</span>
                    </Rota>
                  </div>
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <img src={iconeFaturamento} />
                      <span className="titulo-navbar-texto">Faturamento</span>
                    </div>
                    <Rota to="/faturamento">
                      <span className="botao-menu">Visualizar Faturamento</span>
                    </Rota>
                  </div>
                </div>
              </div>
            )}
            {dropDownDiaria && (
              <div style={{ height: '20%', left: 840 }} className="dropdown-content">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div className="titulo-navbar-icone">
                      <TodayIcon className="diariaBotao" />
                      <span className="titulo-navbar-texto">Diárias</span>
                    </div>
                    <Rota to="/diaria">
                      <span className="botao-menu">Diárias</span>
                    </Rota>
                    <Rota to="/configuracao-empresa">
                      <span className="botao-menu">Configuração Empresa</span>
                    </Rota>
                  </div>
                </div>
              </div>
            )}
            {configDropdownOpen && (
              <div className="dropdown-content-config">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div style={{ margin: 0 }} className="titulo-navbar-icone">
                      <SettingsIcon className="diariaBotao" />
                      <span className="titulo-navbar-texto">Opções</span>
                    </div>
                    <p className="p-p-p" onClick={() => { navigate("/avisos"); }}>Avisos</p>
                    <p className="p-p-p" onClick={() => { navigate("/configuracoes-cores"); }}>Cores</p>
                    <p className="p-p-p" onClick={() => { navigate("/configuracoes-coluna"); }}>Colunas</p>
                    <p className="p-p-p" onClick={() => { navigate("/filiais"); }}>Filiais</p>
                    <p className="p-p-p" onClick={() => { navigate("/notificacoes"); }}>Notificações</p>
                    <p className="p-p-p" onClick={() => { navigate("/envia-email"); }}>Enviar Email</p>
                    <p className="p-p-p" onClick={() => { navigate("/feedback"); }}>Feedback</p>
                    <p className="p-p-p" onClick={() => { navigate("/sorteio"); }}>Sorteio</p>
                    <p className="p-p-p" onClick={() => { navigate("/alertas"); }}>Alertas</p>
                  </div>
                </div>
              </div>
            )}
            {userDropdownOpen && (
              <div className="dropdown-content-config">
                <div className="separador-menu"></div>
                <div className="content-menu-rows">
                  <div className="dropdown-content-2">
                    <div style={{ margin: 0 }} className="titulo-navbar-icone">
                      <AccountCircleIcon className="diariaBotao" />
                      <span className="titulo-navbar-texto">Usuário</span>
                    </div>
                    <p className="p-p-p" onClick={() => { navigate("/dados-usuario"); }}>Editar Usuário</p>
                    <p className="p-p-p" onClick={() => { navigate("/editar-usuario"); }}>Mudar Senha</p>
                    <p className="p-p-p" onClick={() => { setOpen(true); }}>Sair</p>
                  </div>
                </div>
              </div>
            )}
            {imageSrc && (
              <div className="image-perfil">
                <img
                  onClick={() => {
                    setUserDropdownOpen(!userDropdownOpen);
                    fecharDrops7();
                  }}
                  src={imageSrc && imageSrc.tipo ? `data:${imageSrc.tipo};base64,${imageSrc.dado}` : imageSrc === 'Arquivo não encontrado!' ? iconeUsuario : null}
                  alt="Uploaded preview" />
              </div>
            )}
          </div>
        </div>
      </IconContext.Provider >
      <div>
        <ModalConfirmacao2
          titulo="Sair"
          texto="Deseja Sair do Sistema?"
          tituloConfirmar="Sim"
          tituloCancelar="Não"
          textoBotao="Sair"
          open={open}
          onClose={handleClose}
          onClick={logout}
        />
        <ModalNotificacao
          titulo={props.notificoes}
          tituloConfirmar="OK"
          open={openNotificacao}
          onClose={handleCloseNotificacao}
        />
        <ModalAlerta
          open={openExpiracao}
          onClose={handleCloseExpiracao}
          titulo="Expiração"
        />
      </div>
      <div>
        <ToastSucesso
          texto={textoSucesso}
          open={openToastSucesso}
          onClose={handleCloseToastSucesso}
        />
        <ToastErro
          texto={textoErro}
          open={openToastErro}
          onClose={handleCloseToastErro}
        />
      </div>
    </>
  );
}

export default NavbarV3;
