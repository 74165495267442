import React, { useEffect, useState, useContext } from "react";
import TabelaRedux from "../../contexts/Tabela";
import "./TabelaV2.css";
import styled from "styled-components";
import CoresRedux from "../../contexts/Cor";
import MenusRedux from "../../contexts/Menus";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import SettingsIcon from '@mui/icons-material/Settings';
import ModalConfigTabela from "../ModalConfigTabela/ModalConfigTabela.tsx";
import InputLabel from "@mui/material/InputLabel";
import ModalFiltroEmbarque from "../ModalFiltroEmbarque/ModalFiltroEmbarque.tsx";
import ModalEntregas from "../ModalEntregas/ModalEntregas.tsx";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltIcon2 from '@mui/icons-material/Search';
import ModalFiltroCidade from "../ModalFiltroCidade/ModalFiltroCidade.tsx";
import RequestControllerRedux from "../../contexts/RequestController";
import ToastSucesso from "../Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../Toast/ToastErro/ToastErro.tsx";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { verificaDadosUsuarioRedux } from "../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../contexts/Usuario";
import FiltrosRedux from "../../contexts/Filtros";
import { getUsuarioDependencias } from "../../services/ModuloGerenciador/Geral";
import { aceitaSolicitacao, recusaSolicitacao } from "../../services/ModuloGerenciador/Solicitacao";
import Text from "../Text/Text";
import { CSVLink } from "react-csv";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { cnpjMask } from "../../regex/cnpjMask";
import RastrearRedux from "../../contexts/Rastreio";
import { useNavigate } from "react-router-dom";
import { cpfMask } from "../../regex/cpfMask";
import iconeConfig from "../../images/iconeConfig.png";
import iconeDownload from "../../images/iconeDownload.png";
import FilialCidade from "../../contexts/CidadesFilial.js";

function TabelaV2(props) {
    const headers = [
        { label: "ID", key: "idEmbarque" },
        { label: "Empresa", key: "empresa" },
        { label: "Filial", key: "filial" },
        { label: "Unidade Embarque", key: "unidEmbarque" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
        { label: "Pedido", key: "pedido" },
        { label: "Previsão Viagem", key: "previsaoEmbarque" },
        { label: "Data Viagem", key: "dataEmbarque" },
        { label: "Previsão Entrega", key: "previsaoEntrega" },
        { label: "Data Entrega", key: "dataEntrega" },
        { label: "Aceite", key: "aceite" },
        { label: "Status", key: "status" },
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Adicional Empresa", key: "adicionalEmpresa" },
        { label: "Adicional Motorista", key: "adicionalMotorista" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificacao", key: "classificacao" },
        { label: "Adiantamento", key: "adiantamento" },
        { label: "Saldo", key: "saldo" },
        { label: "Meio Pagamento", key: "meioPagamento" },
        { label: "Meio Pagamento 2", key: "meioPagamento2" },
        { label: "KM", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Destinatário", key: "destinatario" },
        { label: "Load", key: "load" },
        { label: "Mercadoria", key: "mercadoria" },
        { label: "Peso", key: "peso" },
        { label: "Embalagem", key: "embalagem" },
        { label: "Observação", key: "observacao" },
        { label: "Frota", key: "frota" },
        { label: "Cancelado", key: "cancelado" },
        { label: "Motivo", key: "motivo" },
    ];

    const headersPedido = [
        { label: "N Pedido", key: "pedido" },
        { label: "Rota", key: "tipoRota" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificação", key: "classificacao" },
        { label: "Km", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Embarcador Estado", key: "embarcadorEstado" },
        { label: "Destinatario", key: "destinatario" },
        { label: "Destinatario Estado", key: "destinatarioEstado" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Observação", key: "observacao" },
    ];

    const headersVeiculo = [
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Motorista 2", key: "motorista2" },
    ];

    const headersRota = [
        { label: "Rota", key: "nome" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
    ];

    let novaArray = [];

    if (!Array.isArray(props.dados)) {
        novaArray.push(props.dados);
    } else {
        novaArray = props.dados;
    }

    const chavesRemover = ["deletado", "embarqueHistoricoId", "contexto", "dataHoraChegadaEmbarque", "dataHoraEmbarcado", "dataHoraChegadaEntrega", "dataHoraEntregue", "data", "usuario"];
    const listaFiltradaObjetos = novaArray.map((obj) => {
        const novoObjeto = Object.keys(obj)
            .filter(key => !chavesRemover.includes(key))
            .reduce((novoObjeto, key) => {
                novoObjeto[key] = obj[key];
                return novoObjeto;
            }, {});
        return novoObjeto;
    });

    const chavesFormatar = ["previsaoEmbarque", "dataEmbarque", "previsaoEntrega", "dataEntrega"];
    const listaFormatada = listaFiltradaObjetos.map((obj) => {
        const novoObj = {};
        Object.keys(obj).forEach(key => {
            if (chavesFormatar.includes(key) && obj[key]) {
                novoObj[key] = obj[key].replace(/T00:00:00/, "");
            } else {
                novoObj[key] = obj[key];
            }
        });
        return novoObj;
    });

    const colunas = {
        'Entregas': [
            {
                'nomeColuna': 'Rota',
                'field': 'nomeRota'
            },
            {
                'nomeColuna': 'Cidade Origem',
                'field': 'cidadeOrigemRota'
            },
            {
                'nomeColuna': 'Cidade Destino',
                'field': 'cidadeDestinoRota'
            },
            {
                'nomeColuna': 'Estado Origem',
                'field': 'estadoOrigemRota'
            },
            {
                'nomeColuna': 'Estado Destino',
                'field': 'estadoDestinoRota'
            },
            {
                'nomeColuna': 'Valor',
                'field': 'valor'
            },
            {
                'nomeColuna': 'Tarifa',
                'field': 'tarifa'
            },
            {
                'nomeColuna': 'Peso',
                'field': 'peso'
            },
            {
                'nomeColuna': 'Destinatario',
                'field': 'destinatario'
            },
            {
                'nomeColuna': 'CTE',
                'field': 'cte'
            },
            {
                'nomeColuna': 'Data Chegada Entrega',
                'field': 'dataHoraChegadaEntrega'
            },
            {
                'nomeColuna': 'Data Entregue',
                'field': 'dataHoraEntregue'
            },
        ],
    }

    const navigate = useNavigate();

    const [valuesFiltro, setValuesFiltro] = useState({});

    const [hover] = useState(true);

    const [regras, setRegras] = useState([]);

    const [fileName, setFileName] = useState("");

    const [regrasEditada, setRegrasEditada] = useState([]);

    const [values, setValues] = useState([]);

    const [values2, setValues2] = useState([]);

    const [valuesFiltroData, setValuesFiltroData] = useState([]);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const [editando, setEditando] = useState(false);

    const [striped] = useState(true);

    const [data, setData] = useState([]);

    const [selecionado, setSelecionado] = useState("");

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosRastrearRedux, setDadosRastrearRedux } = useContext(RastrearRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const [quebraTabelaPedido, setQuebraTabelaPedidoValue] = useState(dadosTabelaRedux.Pedido[17]);

    const [quebraTabelaEmbarque, setQuebraTabelaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[49]);

    const [configDataHora, setConfigDataHora] = useState(menusRedux[props.nomeTable]?.[50]?.configDataHora);

    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const [dadosEntregas, setDadosEntregas] = useState([]);

    const [loading, setLoading] = useState(true);

    const [openFiltro, setOpenFiltro] = useState(false);

    const [openEntregas, setOpenEntregas] = useState(false);

    const [openFiltroCidade, setOpenFiltroCidade] = useState(false);

    const [odenConfig, setOpenConfig] = useState(false);

    const [menuConfig, setMenuConfig] = useState(false);

    const [textoErro, setTextoErro] = useState("");

    const [textoSucesso, setTextoSucesso] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [ehCidade, setEhCidade] = useState(false);

    const [openRegras, setOpenRegras] = useState(false);

    const showDropDowEmbarques = (row) => {
        setDadosEntrega(prevState => ({ ...prevState, [row.idEmbarque]: row.entrega }));
        if (linhaSelecionada.includes(row.idEmbarque)) {
            setLinhaSelecionada(linhaSelecionada.filter(id => id !== row.idEmbarque));
        } else {
            setLinhaSelecionada([...linhaSelecionada, row.idEmbarque]);
        }
    };

    const [dadosEntrega, setDadosEntrega] = useState([]);

    const [linhaSelecionada, setLinhaSelecionada] = useState([]);

    const handleCloseRegras = (value) => {
        setOpenRegras(false);
    };

    const [openMotivo, setOpenMotivo] = useState(false);

    const handleCloseMotivo = (value) => {
        setValues2({});
        setOpenMotivo(false);
    };

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = () => {
        setOpenToastErro(false);
    };

    const handleCloseConfig = (value) => {
        if (dadosRequestControllerRedux === "Sucesso") {
            setTextoSucesso("Alterado Com Sucesso");
            setOpenToastSucesso(true);
            setOpenConfig(false);
        }
        setOpenConfig(false);
    };

    const handleCloseFiltro = (value) => {
        const dataStorage = localStorage.getItem('filtros');
        if (dataStorage) {
            const storageParse = JSON.parse(dataStorage);
            if (storageParse) {
                filtraData(storageParse)
            }
        }

        setOpenFiltro(false);
    };

    const handleCloseEntregas = (value) => {
        setOpenEntregas(false);
    };

    const handleCloseFiltroCidade = (value) => {
        const dataStorage = localStorage.getItem('filtros');
        if (dataStorage) {
            const storageParse = JSON.parse(dataStorage);
            if (storageParse) {
                filtraData(storageParse)
            }
        }
        setOpenFiltroCidade(false);
    };

    const TableTh = styled.th`     
        padding: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        position: sticky; 
        top: 0px;
        background-color: ${dadosCoresRedux.tituloColuna};
        color: ${dadosCoresRedux.textoTituloColuna};
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        border: 1px solid #E7E7E7;
        cursor: pointer;
    `

    const filtra = () => {
        if ((window.location.href.endsWith("embarque") || window.location.search.includes('id=') || window.location.href.endsWith("faturamento") || window.location.href.endsWith("aguardando-cte")) && dadosContextoEmbarqueRedux != 'templateEmbarque') {
            setDadosFiltrados();
            setLoading(false);
        } else {
            setLoading(true);
            setDadosFiltrados("1");
            var dadosfiltra = props.dados;
            dadosfiltra.sort(function (a, b) {
                if (a.placaCavalo < b.placaCavalo || a.pedido < b.pedido || a.cidade < b.cidade) {
                    return -1;
                } else {
                    return true;
                }
            });
            setDadosFiltrados(dadosfiltra);
            setLoading(false);
        }
    }

    const filtraData = (valor) => {
        const dataInicial = converteData(converteDataPadrao(valor.embarque));
        const dataFinal = converteData(converteDataPadrao(valor.embarqueSegundo));
        if (props.classeSelecionado === "idEmbarque") {
            if (valor.embarque && valor.embarqueSegundo) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial && converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal }));
            } else if (valor.embarque && !valor.embarqueSegundo) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) >= dataInicial }));
            } else if (valor.embarqueSegundo && !valor.embarque) {
                setData(props.dados.filter((data) => { return converteData(converteDataPadrao(data.dataEmbarque)) <= dataFinal }));
            } else {
                setData(props.dados);
            }
        } else if (props.classeSelecionado === "idCidadeEstado") {
            if (valor.cidade && valor.estado) {
                setDadosFiltrados(props.dados.filter((data) => { return data.cidade.toLowerCase().includes(valor.cidade.toLowerCase()) && data.estado.toLowerCase().includes(valor.estado.toLowerCase()) }));
            } else if (valor.cidade && !valor.estado) {
                setDadosFiltrados(props.dados.filter((data) => { return data.cidade.toLowerCase().includes(valor.cidade.toLowerCase()) }));
            } else if (valor.estado && !valor.cidade) {
                setDadosFiltrados(props.dados.filter((data) => { return data.estado.toLowerCase().includes(valor.estado.toLowerCase()) }));
            } else {
                setDadosFiltrados(props.dados);
            }
        }
    }

    function resetFiltro() {
        let dados = props.dados;
        setDadosFiltrados(dados);
    }

    function filtrarCidade(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.cidade.toLowerCase().includes(value.filtroOrigem.toLowerCase()) }));
    }

    function filtrarCidade2(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.estado.toLowerCase().includes(value.filtroOrigemEstado.toLowerCase()) }));
    }

    function filtrarEstado(value) {
        let dados = props.dados;
        setDadosFiltrados(dados.filter((data) => { return data.cidade.toLowerCase().includes(value.filtroOrigem.toLowerCase()) && data.estado.toLowerCase().includes(value.filtroOrigemEstado.toLowerCase()) }));
    }

    const dadosOrdenados = dadosFiltrados;

    function converteDataPadrao(data) {
        return new Date(data).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" });
    }

    function converteData(DataDDMMYY) {
        const dataSplit = DataDDMMYY.split("/");
        const novaData = new Date(parseInt(dataSplit[2], 10),
            parseInt(dataSplit[1], 10) - 1,
            parseInt(dataSplit[0], 10));
        return novaData;
    }

    const buscarRegras = async () => {
        setLoading(true);
        await getUsuarioDependencias(dadosUsuarioRedux.token).then((result) => {
            setRegras(result.data.regras);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const aceitarSolicitacao = async () => {
        setLoading(true);
        var arr = values.regras;
        var value = 0
        arr = arr.filter(function (item) {
            return item !== value
        })
        const objetoSolicitacao = {
            usuarioId: tabelaRedux.colunaSelecionada,
            regras: arr,
            colunas: values.colunas
        }
        await aceitaSolicitacao(objetoSolicitacao, dadosUsuarioRedux.token).then((result) => {
            setLoading(false);
            handleCloseRegras();
            setDadosRequestControllerRedux("Solicitacao Aceita");
            setValues(current => ({ ...current, regras: [0] }));
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const recusarSolicitacao = async () => {
        setLoading(true);
        const objetoSolicitacao = {
            usuarioId: tabelaRedux.colunaSelecionada,
            motivo: values2.motivo
        }
        await recusaSolicitacao(objetoSolicitacao, dadosUsuarioRedux.token).then((result) => {
            setLoading(false);
            setDadosRequestControllerRedux("Solicitacao Recusada");
            setValues2({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    const filtrarData = () => {
        localStorage.setItem('filtros', JSON.stringify(values));
        setFiltrosRedux(valuesFiltroData);
    };

    const limparFiltro = () => {
        setValuesFiltroData([]);
        setFiltrosRedux([]);
    };

    useEffect(() => {
        setDadosRastrearRedux({});
        if (window.location.href.endsWith("embarque") || window.location.search.includes('id=')) {
            dadosFilialRedux.map((item) => {
                if (dadosContextoEmbarqueRedux === item.contexto) {
                    setFileName(item.nomeFilial);
                }
            });
        }
        if (window.location.href.endsWith("pedido")) {
            setFileName("Pedidos");
        }
        if (window.location.href.endsWith("rota")) {
            setFileName("Rotas");
        }
        if (window.location.href.endsWith("veiculo")) {
            setFileName("Veiculos");
        }
        const camposFormatados = JSON.stringify(menusRedux).replace(/"/g, `'`)
        setValues(current => ({ ...current, colunas: camposFormatados }));
        setValues(current => ({ ...current, regras: [0] }));
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setData(props.dados);
        if (props.nome) {
            setMenuConfig(true);
        }
        filtra();
        if (window.location.href.endsWith("cidade-estado")) {
            setEhCidade(true);
        }
        if (window.location.href.endsWith("solicitacoes")) {
            buscarRegras();
        }
        setValuesFiltroData(filtrosRedux);
    }, []);

    useEffect(() => {
        if (props.dados && props.dados.length > 0 && props.dados[0] && props.dados[0].idEmbarque) {
            const tableWrapper = document.getElementById("tableWrapper");
            if (tableWrapper && (window.location.href.endsWith("embarque") && window.location.search.includes('id=') && dadosContextoEmbarqueRedux != 'templateEmbarque')) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        }
    }, [loading]);

    const buscarOrdenacao = () => { }

    const navegarEdicaoEmbarque = (row) => {
        setDadosContextoEmbarqueRedux(row.contexto);
        navigate(`/embarque?id=${row.idEmbarque}`);
    };

    return (
        <div>
            {
                !loading ? (
                    <div class={props.nomeClasse1}>
                        {/* {!!ehCidade && (
                            <div>
                                <InputLabel htmlFor="filtro">Filtrar</InputLabel>
                                <input
                                    placeholder="Cidade"
                                    type="text"
                                    className="input-text-filtrar"
                                    onChange={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigem: e.target.value }));
                                    }}
                                    value={valuesFiltro.filtroOrigem} />
                                <input
                                    placeholder="UF"
                                    maxLength={2}
                                    type="text"
                                    onChange={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigemEstado: e.target.value }));
                                    }}
                                    className="input-text-filtrar-estado"
                                    value={valuesFiltro.filtroOrigemEstado} />
                                <FilterAltIcon2 className="icon-filtro" onClick={(e) => {
                                    if (valuesFiltro.filtroOrigem && valuesFiltro.filtroOrigemEstado) {
                                        filtrarEstado(valuesFiltro);
                                    } else if (valuesFiltro.filtroOrigem && !valuesFiltro.filtroOrigemEstado) {
                                        filtrarCidade(valuesFiltro);
                                    } else if (valuesFiltro.filtroOrigemEstado && !valuesFiltro.filtroOrigem) {
                                        filtrarCidade2(valuesFiltro);
                                    } else {
                                        resetFiltro();
                                    }
                                }} />
                                <ClearIcon className="icon-filtro"
                                    onClick={(e) => {
                                        setValuesFiltro(current => ({ ...current, filtroOrigem: '' }));
                                        setValuesFiltro(current => ({ ...current, filtroOrigemEstado: '' }));
                                        resetFiltro();
                                    }}
                                />
                            </div>
                        )} */}
                        {
                            menuConfig ? (
                                <div className="config-tabela-v2">
                                    {/* {props.classeSelecionado === "idEmbarque" && !window.location.href.endsWith("faturamento")
                                        && !window.location.href.endsWith("embarque-template") ? (
                                        <div className="div-data">
                                            <div className="div-datas">
                                                <p htmlFor="embarque">Embarque Início</p>
                                                <input
                                                    type={'date'}
                                                    className='input-filtro-data'
                                                    value={valuesFiltroData.embarqueInicio}
                                                    style={{ width: 120 }}
                                                    onChange={(e) => { setValuesFiltroData(current => ({ ...current, embarqueInicio: e.target.value })) }}
                                                    id='embarque'>
                                                </input>
                                            </div>
                                            <div className="div-datas">
                                                <p htmlFor="embarquef">Embarque Final</p>
                                                <input
                                                    type={'date'}
                                                    className='input-filtro-data'
                                                    value={valuesFiltroData.embarqueFinal}
                                                    style={{ width: 120 }}
                                                    onChange={(e) => { setValuesFiltroData(current => ({ ...current, embarqueFinal: e.target.value })) }}
                                                    id='embarquef'>
                                                </input>
                                            </div>
                                            <div className="div-datas">
                                                <Tooltip title={<p>Filtrar</p>}>
                                                    <FilterAltIcon2 className="icon-filtro"
                                                        onClick={(e) => {
                                                            filtrarData();
                                                        }} />
                                                </Tooltip>

                                            </div>
                                            <div className="div-datas">
                                                <Tooltip title={<p>Limpar Filtro</p>}>
                                                    <ClearIcon className="icon-filtro"
                                                        onClick={(e) => {
                                                            limparFiltro();
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ) : (<></>)} */}
                                    {props.classeSelecionado === "idPedido" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersPedido} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <img src={iconeDownload} />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idVeiculo" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersVeiculo} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <img src={iconeDownload} />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idRota" ? (
                                        <CSVLink className="csv-botao" data={listaFormatada} headers={headersRota} filename={fileName}>
                                            <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                <IconButton className="butao-csv">
                                                    <img src={iconeDownload} />
                                                </IconButton>
                                            </Tooltip>
                                        </CSVLink>
                                    ) : (<></>)}
                                    {props.classeSelecionado != "idFaturamento" && !window.location.href.endsWith("faturamento") && !window.location.href.endsWith("aguardando-cte")
                                        && !window.location.href.endsWith("embarque") && !window.location.search.includes('id=') && !window.location.search.includes('diaria-veiculos') ? (
                                        <div className="filtro">
                                            <Tooltip title={<p>Abrir configurações colunas</p>}>
                                                <IconButton className="butao-csv">
                                                    <img src={iconeConfig} className="click-configurar" onClick={() => setOpenConfig(true)} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (<></>)}
                                    {props.classeSelecionado === "idCidadeEstado" ? (
                                        <div className="filtro">
                                            <Tooltip title={<p>Filtros</p>}>
                                                <IconButton className="butao-csv">
                                                    <FilterAltIcon onClick={() => setOpenFiltroCidade(true)} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ) : (<></>)}
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <div class={props.nomeClasse2} onScroll={props.onScrollHandler} id="tableWrapper">
                            <table className={props.breakTabela ? props.breakTabela : ''}>
                                <thead>
                                    {
                                        props.nomeTable ? (
                                            <tr>
                                                {menusRedux[props.nomeTable].map((head, index) => {
                                                    const nomeColuna = props.colunas[index];
                                                    if (head.visivel === 'true' || head.visivel === true) {
                                                        const headsplit = nomeColuna.nomeColuna?.split(' ');
                                                        if (head.field === "descricao") {
                                                            if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                )
                                                            } else {
                                                                return (
                                                                    <></>
                                                                )
                                                            }
                                                        }
                                                        if (headsplit?.length >= 0 && headsplit?.length <= 2) {
                                                            if (window.location.href.endsWith("embarque") && window.location.search.includes('id=') && dadosContextoEmbarqueRedux != 'templateEmbarque') {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                            {headsplit[0]}
                                                                            <br></br>
                                                                            {headsplit[1]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                        {headsplit[0]}
                                                                        <br></br>
                                                                        {headsplit[1]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else if (headsplit?.length === 3) {
                                                            if (window.location.href.endsWith("embarque") && window.location.search.includes('id=') && dadosContextoEmbarqueRedux != 'templateEmbarque') {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                            {headsplit[0]} {headsplit[1]}
                                                                            <br></br>
                                                                            {headsplit[2]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna?.split(' ');
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                        {headsplit[0]} {headsplit[1]}
                                                                        <br></br>
                                                                        {headsplit[2]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else if (headsplit?.length === 4) {
                                                            if (window.location.href.endsWith("embarque") && window.location.search.includes('id=') && dadosContextoEmbarqueRedux != 'templateEmbarque') {
                                                                if (quebraTabelaEmbarque.quebraColunaEmbarque === true) {
                                                                    const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                            {headsplit[0]} {headsplit[1]}
                                                                            <br></br>
                                                                            {headsplit[2]} {headsplit[3]}
                                                                        </TableTh>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                    );
                                                                }
                                                            }
                                                            if (quebraTabelaPedido.quebraColunaPedido === true) {
                                                                const headsplit = nomeColuna.nomeColuna.split(' ');
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                        {headsplit[0]} {headsplit[1]}
                                                                        <br></br>
                                                                        {headsplit[2]} {headsplit[3]}
                                                                    </TableTh>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                                );
                                                            }
                                                        } else {
                                                            return (
                                                                <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{nomeColuna.nomeColuna}</TableTh>
                                                            );
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        ) : (
                                            <tr>
                                                {props.colunas && props.colunas.map((head) => {
                                                    if (window.location.href.endsWith("faturamento") || window.location.href.endsWith("aguardando-cte")) {
                                                        const headsplit = head.nomeColuna.split(' ');
                                                        return (
                                                            <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>
                                                                {headsplit[0]}
                                                                <br></br>
                                                                {headsplit[1]}
                                                            </TableTh>
                                                        );
                                                    } else {
                                                        return (
                                                            <TableTh onClick={() => props.buscarOrdenacao && props.buscarOrdenacao(head.field)}>{head.nomeColuna}</TableTh>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        )
                                    }
                                </thead>
                                {
                                    !dadosOrdenados ? (
                                        <tbody>
                                            {data &&
                                                data.map((row) => (
                                                    <>
                                                        <tr onClick={() => {
                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                            setSelecionado(row[props.classeSelecionado]);
                                                        }} className={`${hover && "hover"}  ${striped && "striped"}
                                                            ${selecionado === row[props.classeSelecionado] && "selecionado"}
                                                            ${row.cte && row.cte !== null && row.cte !== "" && row.cte !== " " && row.cte !== "  " && "cte"}
                                                            ${row.cancelado === true && "cancelado"}
                                                            ${row.foiCarregado === false && "cancelado"}
                                                            ${row.placaCavalo === null && "negrito-embarque"}`}
                                                            onDoubleClick={() => {
                                                                if (!window.location.href.endsWith("diaria")) {
                                                                    if (props.classeSelecionado !== 'entrega-embarque' && props.classeSelecionado !== 'data' && !window.location.href.endsWith("aguardando-cte") && !window.location.href.endsWith("faturamento")) {
                                                                        if (!window.location.href.endsWith("veiculos-vencidos")) {
                                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                            setSelecionado(row[props.classeSelecionado]);
                                                                            props.setTelaCadastro(true);
                                                                            props.setEditando(true);
                                                                        } else {
                                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                            setSelecionado(row[props.classeSelecionado]);
                                                                            props.setTelaCheck();
                                                                        }
                                                                    }
                                                                } else {
                                                                    navigate("/diarias", { state: { myParam: row.idGrupoCliente } });
                                                                }
                                                            }}>
                                                            {
                                                                props.nomeTable ? (
                                                                    <>
                                                                        {menusRedux[props.nomeTable].map((col) => {
                                                                            if (col.visivel === 'true' || col.visivel === true) {
                                                                                if (col.field === "motorista") {
                                                                                    if (row.coordenadasRastreioMotorista != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.coordenadasRastreioMotorista != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            endereco: row.coordenadasRastreioMotorista,
                                                                                                            idEmbarque: row.idEmbarque
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.motorista}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.motorista}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "nEntregas") {
                                                                                    if (row.nEntregas) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.nEntregasFeitas}/{row.nEntregas}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "usuarioResponsavel") {
                                                                                    if (row.usuarioResponsavel) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.usuarioResponsavel.usuario}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "cte") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cte}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "load") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.load}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "km") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-right">{Math.trunc(parseFloat(row.km))}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "embarcador" || col.field === "cliente" || col.field === "destinatario" || col.field === "pedido") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "placaCavalo") {
                                                                                    if (row.caminhao != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.caminhao != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.caminhao.idCaminhao,
                                                                                                            tipoVeiculo: 1,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCavalo}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        if (row.temApiSul === true) {
                                                                                            return (
                                                                                                <td className="td-left-veiculo" style={{ color: row.corCliente }}>{row.placaCavalo}</td>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{row.placaCavalo}</td>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "placaCarreta") {
                                                                                    if (row.carreta != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.carreta != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.carreta.idCarreta,
                                                                                                            tipoVeiculo: 2,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCarreta}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.placaCarreta}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "placaCarreta2") {
                                                                                    if (row.carreta2 != null) {
                                                                                        return (
                                                                                            <td className="rastreio"
                                                                                                onClick={(e) => {
                                                                                                    if (row.carreta2 != null) {
                                                                                                        setDadosRastrearRedux((current) => ({
                                                                                                            ...current,
                                                                                                            idVeiculo: row.carreta2.idCarreta,
                                                                                                            tipoVeiculo: 2,
                                                                                                        }));
                                                                                                        navigate("/rastrear");
                                                                                                    }
                                                                                                }}>
                                                                                                {row.placaCarreta2}
                                                                                            </td>
                                                                                        );
                                                                                    } else {
                                                                                        <td style={{ color: row.corCliente }}>{row.placaCarreta2}</td>
                                                                                    }
                                                                                }
                                                                                if (col.field === "descricao") {
                                                                                    if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === 'margem' || col.field === "pedido") {
                                                                                    return (
                                                                                        <td className="td-right" style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === 'adiantamento') {
                                                                                    if (row.adiantamento != null) {
                                                                                        const adtSplit = row.adiantamento.split(',');
                                                                                        return (
                                                                                            <td className="td-right">{adtSplit[0]}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === 'saldo') {
                                                                                    if (row.saldo != null) {
                                                                                        const adtSplit = row.saldo.split(',');
                                                                                        return (
                                                                                            <td className="td-right">{adtSplit[0]}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === 'freteFiscal' || col.field === 'freteMotorista') {
                                                                                    if (row.freteFiscal != null) {
                                                                                        let value;
                                                                                        if (typeof row[col.field] === 'string') {
                                                                                            value = row[col.field].replace(',', '.');
                                                                                        }
                                                                                        if ((value.match(/\./g) || []).length > 1) {
                                                                                            value = value.replace('.', '');
                                                                                        }
                                                                                        if (row.valorVariavelAlto) {
                                                                                            if (isNaN(parseFloat(value))) {
                                                                                                return <td style={{ color: row.corCliente }} className="td-center-frete-fiscal">{row[col.field]}</td>;
                                                                                            } else {
                                                                                                let floatValue = parseFloat(value);
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }} className="td-center-frete-fiscal">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            if (isNaN(parseFloat(value))) {
                                                                                                return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                            } else {
                                                                                                let floatValue = parseFloat(value);
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.objetoUnidadeEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "unidEmbarque") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="v-entrega">
                                                                                            {
                                                                                                row.entrega && row.entrega.length > 0 ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            linhaSelecionada.includes(row.idEmbarque) ? (
                                                                                                                <KeyboardArrowUpIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <KeyboardArrowDownIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            )
                                                                                                        }

                                                                                                        <span>{row[col.field]}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span>{row[col.field]}</span>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "embarcadorEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.embarcadorEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "unidEmbarque") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.unidEmbarque}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "estadoOrigem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.estadoOrigem}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "estadoDestino") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.estadoDestino}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "previsaoEmbarque") {
                                                                                    if (row.previsaoEmbarque === "2000-01-01T00:00:00" || row.previsaoEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (row.tempoFaltanteEmbarque !== null) {
                                                                                            if (row.tempoFaltanteEmbarque === 1) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEmbarque === 2) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-2" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-2" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEmbarque === 3) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-3" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-3" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEmbarque === 4) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-4" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-4" style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEmbarque") {
                                                                                    if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (configDataHora) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "aceite") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.aceite}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "destinatarioEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.destinatarioEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEmbarque") {
                                                                                    if (row.dataHoraChegadaEmbarque === "2000-01-01T00:00:00" || row.dataHoraChegadaEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (row.onTimeEmbarque) {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        } else {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEntrega") {
                                                                                    if (row.dataHoraChegadaEntrega === "2000-01-01T00:00:00" || row.dataHoraChegadaEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (row.onTimeEntrega) {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        } else {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEntregue") {
                                                                                    if (row.dataHoraEntregue === "2000-01-01T00:00:00" || row.dataHoraEntregue === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (configDataHora) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEmbarcado") {
                                                                                    if (row.dataHoraEmbarcado === "2000-01-01T00:00:00" || row.dataHoraEmbarcado === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (configDataHora) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "frota") {
                                                                                    if (row.frota === "Sim") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarACadaData") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "tipoCheck") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "dataCheckagem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "proximaRevisaoData") {
                                                                                    if (row.proximaRevisaoData === null) {
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarAutomatico" || col.field === "cancelado"
                                                                                    || col.field === "comprovante" || col.field === "checkInFeito") {
                                                                                    if (row[col.field] === true) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                // if (col.field === "cancelado") {
                                                                                //     if (row.cancelado === true) {
                                                                                //         return (
                                                                                //             <td style={{ color: row.corCliente }}>Sim</td>
                                                                                //         )
                                                                                //     } else {
                                                                                //         return (
                                                                                //             <td style={{ color: row.corCliente }}>Não</td>
                                                                                //         )
                                                                                //     }
                                                                                // }
                                                                                if (col.field === "previsaoEntrega") {
                                                                                    if (row.previsaoEntrega === "2000-01-01T00:00:00" || row.previsaoEntrega === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (row.tempoFaltanteEntrega !== null) {
                                                                                            if (row.tempoFaltanteEntrega === 1) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-1" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEntrega === 2) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-2" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-2" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEntrega === 3) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-3" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-3" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            } else if (row.tempoFaltanteEntrega === 4) {
                                                                                                if (configDataHora) {
                                                                                                    return (
                                                                                                        <td className="data-piscando-4" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <td className="data-piscando-4" style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                    )
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            if (configDataHora) {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                                )
                                                                                            }
                                                                                        }

                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEntrega") {
                                                                                    if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (configDataHora) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEmbarque") {
                                                                                    if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        if (configDataHora) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    if (col.tipo === "number") {
                                                                                        let value;
                                                                                        if (typeof row[col.field] === 'string') {
                                                                                            value = row[col.field].replace(',', '.');
                                                                                        }
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        let value;
                                                                                        if (typeof row[col.field] === 'string') {
                                                                                            value = row[col.field].replace(',', '.');
                                                                                        }
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {props.colunas.map((col) => {
                                                                            if (col.field === 'freteFiscal') {
                                                                                if (row.freteFiscal != null) {
                                                                                    let value;
                                                                                    if (typeof row[col.field] === 'string') {
                                                                                        value = row[col.field].replace(',', '.');
                                                                                    }
                                                                                    if ((value.match(/\./g) || []).length > 1) {
                                                                                        value = value.replace('.', '');
                                                                                    }
                                                                                    if (row.valorVariavelAlto) {
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }} className="td-center-frete-fiscal">{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }} className="td-center-frete-fiscal">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    } else {
                                                                                        if (isNaN(parseFloat(value))) {
                                                                                            return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                        } else {
                                                                                            let floatValue = parseFloat(value);
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.field === "pedido" || col.field === "nEntregas") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "usuarioResponsavel") {
                                                                                if (row.usuarioResponsavel) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.usuarioResponsavel.usuario}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraAcontecido" || col.field === "dataHoraChegada" || col.field === "dataHoraPrevisao" || col.field === "data") {
                                                                                if (row[col.field] === "2000-01-01T00:00:00" || row[col.field] === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row[col.field]).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "onTime") {
                                                                                if (row.onTime === true) {
                                                                                    return (
                                                                                        <td style={{ color: '#48B43B' }}>On Time</td>
                                                                                    )
                                                                                } else if (row.onTime === null) {
                                                                                    return (
                                                                                        <td>Aguardando</td>
                                                                                    )
                                                                                } else {
                                                                                    if (row.motivoOnTime != null) {
                                                                                        return (
                                                                                            <td style={{ color: '#FF0000' }}>
                                                                                                Ñ On Time
                                                                                            </td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: '#FF0000' }}>
                                                                                                Ñ On Time
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.field === "motivoOnTime") {
                                                                                if (row.motivoOnTime != undefined || row.motivoOnTime != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}
                                                                                        // onClick={() => {
                                                                                        //     const lista = []
                                                                                        //     lista.push(row.embarque);
                                                                                        //     setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: lista });
                                                                                        //     props.setOpen(true);
                                                                                        //     if (props.isEmbarque === true) {
                                                                                        //         props.setIsEmbarque(true);
                                                                                        //         props.setIsEntrega(false);
                                                                                        //     } else if (props.setIsEntrega) {
                                                                                        //         props.setIsEmbarque(false);
                                                                                        //         props.setIsEntrega(true);
                                                                                        //     }
                                                                                        // }}
                                                                                        >
                                                                                            <span className="">{row.motivoOnTime.motivo}</span>
                                                                                        </td>
                                                                                    );
                                                                                } else {
                                                                                    if (row.onTime === false) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>
                                                                                                <span className="endereco" style={{ color: '#5353ef' }}
                                                                                                    onClick={() => {
                                                                                                        const lista = []
                                                                                                        lista.push(row.embarque);
                                                                                                        setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: lista });
                                                                                                        props.setOpen(true);
                                                                                                        if (props.isEmbarque === true) {
                                                                                                            props.setIsEmbarque(true);
                                                                                                            props.setIsEntrega(false);
                                                                                                        } else if (props.setIsEntrega) {
                                                                                                            props.setIsEmbarque(false);
                                                                                                            props.setIsEntrega(true);
                                                                                                        }
                                                                                                    }}> Adicionar</span>
                                                                                            </td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.field === "nome") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.nome}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "cte") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cte}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "descricao") {
                                                                                if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.tipo === "number") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "previsaoEmbarque") {
                                                                                if (row.previsaoEmbarque === "2000-01-01T00:00:00" || row.previsaoEmbarque === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "frota") {
                                                                                if (row.frota === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarACadaData") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "tipoCheck") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "dataCheckagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "proximaRevisaoData") {
                                                                                if (row.proximaRevisaoData === null) {
                                                                                    <td style={{ color: row.corCliente }}></td>
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarAutomatico") {
                                                                                if (row.notificarAutomatico === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "cancelado") {
                                                                                if (row.cancelado === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEntrega") {
                                                                                if (row.previsaoEntrega === "2000-01-01T00:00:00" || row.previsaoEntrega === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraChegadaEmbarque") {
                                                                                if (row.dataHoraChegadaEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraChegadaEntrega") {
                                                                                if (row.dataHoraChegadaEntrega === "2000-01-01T00:00:00" || row.dataHoraChegadaEntrega === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraEntregue") {
                                                                                if (row.dataHoraEntregue === "2000-01-01T00:00:00" || row.dataHoraEntregue === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraEmbarcado") {
                                                                                if (row.dataHoraEmbarcado === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEntrega") {
                                                                                if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "dataEmbarque") {
                                                                                if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                if (isNaN(parseFloat(row[col.field]))) {
                                                                                    return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{parseFloat(row[col.field]).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                        {linhaSelecionada.includes(row.idEmbarque) && (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <TabelaV2 classeSelecionado="entrega-embarque" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper-entregas"
                                                                        colunas={colunas.Entregas} dados={dadosEntrega[row.idEmbarque]} buscarOrdenacao={buscarOrdenacao} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {dadosOrdenados &&
                                                dadosOrdenados.map((row) => (
                                                    <>
                                                        <tr onClick={() => {
                                                            setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                            setSelecionado(row[props.classeSelecionado]);
                                                        }}
                                                            onDoubleClick={() => {
                                                                if (window.location.href.endsWith("diaria-veiculos")) {
                                                                    navegarEdicaoEmbarque(row);
                                                                    return;
                                                                }
                                                                if (!window.location.href.endsWith("diaria")) {
                                                                    if (!window.location.href.endsWith("ontime") && !window.location.href.endsWith("adicionar-check-veiculo") && !window.location.href.endsWith("veiculos-vencidos")) {
                                                                        setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                        setSelecionado(row[props.classeSelecionado]);
                                                                        props.setTelaCadastro(true);
                                                                        props.setEditando(true);
                                                                    } else if (window.location.href.endsWith("ontime")) {
                                                                        const lista = []
                                                                        lista.push(row.embarque);
                                                                        setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: lista });
                                                                        setSelecionado(row[props.classeSelecionado]);
                                                                        props.setOpenHistoricoEdicao(true);
                                                                    } else {
                                                                        setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: row });
                                                                        setSelecionado(row[props.classeSelecionado]);
                                                                        props.setTelaCheck();
                                                                    }
                                                                } else {
                                                                    navigate("/diarias", { state: { myParam: row.idGrupoCliente } });
                                                                }
                                                            }}
                                                            className={`${hover && "hover"} ${striped && "striped"} 
                                                            ${selecionado === row[props.classeSelecionado] && "selecionado"}
                                                            ${row.cte && row.cte !== null && row.cte !== "" && row.cte !== " " && row.cte !== "  " && "cte"}
                                                            ${row.foiCarregado === false && "cancelado"}
                                                            ${row.foiCarregado === true && "cte"}
                                                            ${row.foiCarregado === null && "null"}
                                                            ${row.placaCavalo === null && "negrito-embarque"}`}
                                                        >
                                                            {
                                                                props.nomeTable ? (
                                                                    <>
                                                                        {menusRedux[props.nomeTable].map((col) => {
                                                                            if (col.visivel === 'true' || col.visivel === true) {
                                                                                if (col.field === 'pedido' && window.location.href.endsWith("gerenciamento-pedido")) {
                                                                                    if (row.pedido) {
                                                                                        return (
                                                                                            <td style={{
                                                                                                color: row.corCliente,
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                gap: '5px',
                                                                                                justifyContent: 'flex-start'
                                                                                            }}>
                                                                                                <input type="checkbox" onChange={(e) => {
                                                                                                    props.setLista(e.target.checked, row.idPedido);
                                                                                                }} />
                                                                                                {row.pedido}
                                                                                            </td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "usuarioResponsavel") {
                                                                                    if (row.usuarioResponsavel) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.usuarioResponsavel.usuario}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEmbarque") {
                                                                                    if (row.dataHoraChegadaEmbarque === "2000-01-01T00:00:00" || row.dataHoraChegadaEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraChegadaEntrega") {
                                                                                    if (row.dataHoraChegadaEntrega === "2000-01-01T00:00:00" || row.dataHoraChegadaEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraChegadaEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEntregue") {
                                                                                    if (row.dataHoraEntregue === "2000-01-01T00:00:00" || row.dataHoraEntregue === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEntregue).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataHoraEmbarcado") {
                                                                                    if (row.dataHoraEmbarcado === "2000-01-01T00:00:00" || row.dataHoraEmbarcado === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.dataHoraEmbarcado).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "unidEmbarque") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="v-entrega">
                                                                                            {
                                                                                                row.entrega && row.entrega.length > 0 ? (
                                                                                                    <>
                                                                                                        {
                                                                                                            linhaSelecionada.includes(row.idEmbarque) ? (
                                                                                                                <KeyboardArrowUpIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            ) : (
                                                                                                                <KeyboardArrowDownIcon
                                                                                                                    className="icon-entrega"
                                                                                                                    onClick={() => {
                                                                                                                        showDropDowEmbarques(row);
                                                                                                                    }}
                                                                                                                />
                                                                                                            )
                                                                                                        }

                                                                                                        <span>{row[col.field]}</span>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span>{row[col.field]}</span>
                                                                                                )
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "freteFiscal" || col.field === "icms" || col.field === "pedagio" ||
                                                                                    col.field === "classificao" || col.field === "km" || col.field === "freteMotorista") {
                                                                                    let value;
                                                                                    if (typeof row[col.field] === 'string') {
                                                                                        value = row[col.field].replace(',', '.');
                                                                                    }
                                                                                    if ((value.match(/\./g) || []).length > 1) {
                                                                                        value = value.replace('.', '');
                                                                                    }
                                                                                    if (isNaN(parseFloat(value))) {
                                                                                        return <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>;
                                                                                    } else {
                                                                                        let floatValue = parseFloat(value);
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-right">{floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "descricao") {
                                                                                    if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "previsaoEmbarque") {
                                                                                    if (row.previsaoEmbarque === "2000-01-01T00:00:00" || row.previsaoEmbarque === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEmbarque") {
                                                                                    if (row.dataEmbarque === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "dataEntrega") {
                                                                                    if (row.dataEntrega === "2000-01-01T00:00:00") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "previsaoEntrega") {
                                                                                    if (row.previsaoEntrega === "2000-01-01T00:00:00" || row.previsaoEntrega === null) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}></td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{new Date(row.previsaoEntrega).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.objetoUnidadeEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "embarcadorEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.embarcadorEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "destinatarioEstado") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{row.destinatarioEstado}</td>
                                                                                    );
                                                                                }
                                                                                if (col.field === "permissoes") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{`${row.regras},`}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "nascimento") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.nascimento).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "ultimoLogin" && row.ultimoLogin !== null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogin).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "ultimoLogout" && row.ultimoLogout !== null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogout).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "data") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "mensagem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className={props.breakTd ? props.breakTd : ''}>{row.mensagem}</td>
                                                                                    )
                                                                                }
                                                                                if (col.objetoUnidadeEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                    );
                                                                                }
                                                                                if (col.objetoValorEmbarque === "true") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.valorEmbarque?.rota?.nome}</td>
                                                                                    );
                                                                                }
                                                                                if (col.visivel === 'true' || col.visivel === true) {
                                                                                    if (col.field === "valorEmbarque" || col.field === "permiteAlterarFreteFiscal"
                                                                                        || col.field === "icmsEmbutido" || col.field === "icmsEmbarcador" || col.field === "ativoDiaria") {
                                                                                        if (row[col.field] === true) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>Sim</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>Não</td>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                    if (col.objetoUnidadeEmbarque === "true") {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarACadaData") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "tipoCheck") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "dataCheckagem") {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                                if (col.field === "proximaRevisaoData") {
                                                                                    if (row.proximaRevisaoData === null) {
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "notificarAutomatico") {
                                                                                    if (row.notificarAutomatico === true) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                if (col.field === "frota") {
                                                                                    if (row.frota === true) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Sim</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>Não</td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {props.colunas.map((col) => {
                                                                            if (col.field === "cotacaoCarroceria") {
                                                                                if (row.cotacaoCarroceria && row.cotacaoCarroceria.length > 0) {
                                                                                    const carroceriasNomes = row.cotacaoCarroceria.map(item => item.carroceria.nome).join(', ');
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>
                                                                                            {carroceriasNomes}
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "usuarioResponsavel") {
                                                                                if (row.usuarioResponsavel?.usuario) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.usuarioResponsavel.usuario}</td>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.usuarioResponsavel}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "tipoVeiculo") {
                                                                                if (col.objetoTipoVeiculo === "true") {
                                                                                    if (row.tipoVeiculo != undefined) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.tipoVeiculo.nome}</td>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (row.tipoVeiculo) {
                                                                                        const tipoVeiculoSelecionado = props.listaTipos.find(opcao => opcao.valor === row.tipoVeiculo);
                                                                                        if (tipoVeiculoSelecionado) {
                                                                                            return (
                                                                                                <td style={{ color: row.corCliente }}>{tipoVeiculoSelecionado.opcao}</td>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.objetoTipoCarroceria === "true") {
                                                                                if (row.tipoCarroceria != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoCarroceria.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoCidadeEstado === "true") {    
                                                                                if (row.cidadeEstado !== undefined && row.cidadeEstado !== null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cidadeEstado.abreviacao ? row.cidadeEstado.abreviacao : row.cidadeEstado.cidade} - {row.cidadeEstado.estado}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoTipoVeiculo === "true") {
                                                                                if (row.tipoVeiculoConjuntos != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoVeiculoConjuntos.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoSegmentoCliente === "true") {
                                                                                if (row.segmentoCliente != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.segmentoCliente.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoMotivo === "true") {
                                                                                if (row.motivoCotacao != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.motivoCotacao.motivo}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarqueNome === "true") {
                                                                                if (row.unidadeEmbarque != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.unidadeEmbarque.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "fabricanteRastreamento") {
                                                                                if (row.fabricanteRastreamento) {
                                                                                    const fabricanteRastreamentoSelecionado = props.listaTipos2.find(opcao => opcao.valor === row.fabricanteRastreamento);
                                                                                    if (fabricanteRastreamentoSelecionado) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{fabricanteRastreamentoSelecionado.opcao}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.field === "dataHoraAcontecido" || col.field === "dataHoraChegada" || col.field === "dataHoraPrevisao" || col.field === "dataAtualizacaoLocalizacao") {
                                                                                if (row[col.field] === "2000-01-01T00:00:00" || row[col.field] === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row[col.field]).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "onTime") {
                                                                                if (row.onTime === true) {
                                                                                    return (
                                                                                        <td style={{ color: '#48B43B' }}>On Time</td>
                                                                                    )
                                                                                } else if (row.onTime === null) {
                                                                                    return (
                                                                                        <td>Aguardando</td>
                                                                                    )
                                                                                } else {
                                                                                    if (row.motivoOnTime != null) {
                                                                                        return (
                                                                                            <td style={{ color: '#FF0000' }}>
                                                                                                Ñ On Time
                                                                                            </td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td style={{ color: '#FF0000' }}>
                                                                                                Ñ On Time
                                                                                            </td>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.field === "motivoOnTime") {
                                                                                if (row.motivoOnTime != undefined || row.motivoOnTime != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}
                                                                                        // onClick={() => {
                                                                                        //     const lista = []
                                                                                        //     lista.push(row.embarque);
                                                                                        //     setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: lista });
                                                                                        //     props.setOpen(true);
                                                                                        //     if (props.isEmbarque === true) {
                                                                                        //         props.setIsEmbarque(true);
                                                                                        //         props.setIsEntrega(false);
                                                                                        //     } else if (props.setIsEntrega) {
                                                                                        //         props.setIsEmbarque(false);
                                                                                        //         props.setIsEntrega(true);
                                                                                        //     }
                                                                                        // }}
                                                                                        >
                                                                                            <span className="">{row.motivoOnTime.motivo}</span>
                                                                                        </td>
                                                                                    );
                                                                                } else {
                                                                                    if (row.onTime === false) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>
                                                                                                <span className="endereco" style={{ color: '#5353ef' }}
                                                                                                    onClick={() => {
                                                                                                        const lista = []
                                                                                                        lista.push(row.embarque);
                                                                                                        setTabelaRedux({ colunaSelecionada: row[props.classeSelecionado], usuarioAlteracao: row.usuario, dataAlteracao: row.data, row: lista });
                                                                                                        props.setOpen(true);
                                                                                                        if (props.isEmbarque === true) {
                                                                                                            props.setIsEmbarque(true);
                                                                                                            props.setIsEntrega(false);
                                                                                                        } else if (props.setIsEntrega) {
                                                                                                            props.setIsEmbarque(false);
                                                                                                            props.setIsEntrega(true);
                                                                                                        }
                                                                                                    }}> Adicionar</span>
                                                                                            </td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.objetoCliente === "true") {
                                                                                if (row.cliente != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.cliente.nomeFantasia}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoGrupoCarga === "true") {
                                                                                if (row.grupoCarga != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.grupoCarga.nomeGrupo}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoMotorista === "true") {
                                                                                if (row.motorista != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.motorista.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoProprietario === "true") {
                                                                                if (row.proprietario != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.proprietario.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoRota === "true") {
                                                                                if (row.rota != undefined) {
                                                                                    if (row.rota.nome) {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.rota.nome}</td>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <td style={{ color: row.corCliente }}>{row.nomeRota}</td>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                            if (col.objetoGrupoPrecificacao === "true") {
                                                                                if (row.grupoPrecificacao != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.grupoPrecificacao.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoUsuarios === "true") {
                                                                                if (row.usuariosClientes != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.usuariosClientes.usuario}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "telefone") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.telefone}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "nomeFantasia" || col.field === "razaoSocial" || col.field === "projetado"
                                                                                || col.field === "cte" || col.field === "numeroRastreador" || col.field === "pedido") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "km") {
                                                                                return (
                                                                                    <td className="td-right" style={{ color: row.corCliente }}>{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "tempoEsperando") {
                                                                                return (
                                                                                    <td className="td-right" style={{ color: row.corCliente }}>{row[col.field].replace(/:00$/, '')}</td>
                                                                                );
                                                                            }
                                                                            if (col.objetoGrupoCliente === "true") {
                                                                                if (row.grupoCliente != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.grupoCliente.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoTipoOcorrencia === "true") {
                                                                                if (row.tipoOcorrencia != undefined) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.tipoOcorrencia.nome}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.objetoEndereco === "true") {
                                                                                if (row.endereco != undefined) {
                                                                                    return (
                                                                                        <td className="endereco"
                                                                                            onClick={(e) => {
                                                                                                setDadosRastrearRedux((current) => ({
                                                                                                    ...current,
                                                                                                    endereco: row.endereco,
                                                                                                }));
                                                                                                navigate("/rastrear");
                                                                                            }}>
                                                                                            {
                                                                                                row.endereco.rua + ', ' + row.endereco.numero
                                                                                                + ', ' + row.endereco.bairro + " - " + row.endereco.cidade
                                                                                            }
                                                                                        </td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "regimeTributario") {
                                                                                if (row.regimeTributario === 1) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Simples nacional</td>
                                                                                    );
                                                                                }
                                                                                if (row.regimeTributario === 2) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Lucro presumido</td>
                                                                                    );
                                                                                }
                                                                                if (row.regimeTributario === 3) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Lucro real</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "ano" || col.field === "renavam" || col.field === "modelo"
                                                                                || col.field === "numeroDocumento" || col.field === "numeroEixos" || col.field === "pedido"
                                                                                || col.field === "cuponsPorViagem" || col.field === "numerosKmPorCupom" || col.field === "nome"
                                                                                || col.field === "tempoDiariaMinutos") {
                                                                                return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                            }
                                                                            if (col.objetoRastreamento === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoRastreamento.nome}</td>
                                                                                );
                                                                            }
                                                                            if (col.objetoVeiculo === "true") {
                                                                                if (row.caminhao != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.caminhao.placa}</td>
                                                                                    );
                                                                                }
                                                                                if (row.carreta != null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.carreta.placa}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                            if (col.field === "ip") {
                                                                                return (
                                                                                    <td>{row.ip}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "cnpj") {
                                                                                if (row.cnpj.length === 11) {
                                                                                    return (
                                                                                        <td>{cpfMask(row.cnpj)}</td>
                                                                                    )
                                                                                } else if (row.cnpj.length > 11) {
                                                                                    return (
                                                                                        <td>{cnpjMask(row.cnpj)}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "descricao") {
                                                                                if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{row.descricao}</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <></>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.tipo === "number") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-right">{row[col.field]}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "notificarAutomatico" || col.field === "diFreteFiscal" ||
                                                                                col.field === "diPedagio" || col.field === "diClassificacao" || col.field === "bloqueado"
                                                                                || col.field === "apenasCpf" || col.field === "motoristas" || col.field === "proprietarios"
                                                                                || col.field === "sorteados" || col.field === "encerrado" || col.field === "diariaEmbarque"
                                                                                || col.field === "diariaEntrega" || col.field === "somaDiarias") {
                                                                                if (row[col.field] === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "sabadoDiaUtil") {
                                                                                if (row[col.field] === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não Conta</td>
                                                                                    )
                                                                                } else if (row[col.field] === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Dia Inteiro</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Meio Dia</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "foiCarregado") {
                                                                                if (row[col.field] === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else if (row[col.field] === false) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                } else if (row[col.field] === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Aguardando</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "notificarACadaData") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.notificarACadaData}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "tipoCheck") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.tipoCheck.nome}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "dataCheckagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCheckagem).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "fimBusca" || col.field === "inicioBusca" || col.field === "dataSorteio") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row[col.field]).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }

                                                                            if (col.field === "dataCotacao") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center">{new Date(row.dataCotacao).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "proximaRevisaoData") {
                                                                                if (row.proximaRevisaoData === null) {
                                                                                    <td style={{ color: row.corCliente }}></td>
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }} className="td-center">{new Date(row.proximaRevisaoData).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "frota") {
                                                                                if (row.frota === true) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Sim</td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>Não</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "previsaoEmbarque") {
                                                                                if (row.previsaoEmbarque === "2000-01-01T00:00:00" || row.previsaoEmbarque === null) {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}></td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{new Date(row.previsaoEmbarque).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                            if (col.field === "permissoes") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{`${row.regras},`}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "nascimento") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.nascimento).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogin" && row.ultimoLogin !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogin).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "ultimoLogout" && row.ultimoLogout !== null) {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.ultimoLogout).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "data") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{new Date(row.data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" })}</td>
                                                                                )
                                                                            }
                                                                            if (col.field === "mensagem") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className={props.breakTd ? props.breakTd : ''}>{row.mensagem}</td>
                                                                                )
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.objetoUnidadeEmbarque === "true") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }}>{row.cidadeEstado.cidade}</td>
                                                                                );
                                                                            }
                                                                            if (col.field === "aceitar") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center"><CheckIcon
                                                                                        className="icon-filtro"
                                                                                        onClick={(e) => {
                                                                                            setOpenRegras(true);
                                                                                        }}
                                                                                    /></td>
                                                                                );
                                                                            }
                                                                            if (col.field === "recusar") {
                                                                                return (
                                                                                    <td style={{ color: row.corCliente }} className="td-center"><ClearIcon
                                                                                        className="icon-filtro"
                                                                                        onClick={(e) => {
                                                                                            setOpenMotivo(true);
                                                                                        }}
                                                                                    /></td>
                                                                                );
                                                                            } else {
                                                                                if (isNaN(parseFloat(row[col.field]))) {
                                                                                    return <td style={{ color: row.corCliente }}>{row[col.field]}</td>;
                                                                                } else {
                                                                                    return (
                                                                                        <td style={{ color: row.corCliente }}>{parseFloat(row[col.field]).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                                    );
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                        {linhaSelecionada.includes(row.idEmbarque) && (
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <TabelaV2 classeSelecionado="cte" nomeClasse1="outer-wrapper-rota" nomeClasse2="table-wrapper-entregas"
                                                                        colunas={colunas.Entregas} dados={dadosEntrega[row.idEmbarque]} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                        <div>
                            <ModalConfigTabela
                                titulo="Colunas Visíveis"
                                tituloConfirmar="OK"
                                open={odenConfig}
                                onClose={handleCloseConfig}
                                checks={props.colunas}
                                nome={props.nome}
                            />
                        </div>
                        <div>
                            <ModalFiltroEmbarque
                                open={openFiltro}
                                onClose={handleCloseFiltro}>
                            </ModalFiltroEmbarque>
                            <ModalEntregas
                                open={openEntregas}
                                onClose={handleCloseEntregas}
                                dadosEntregas={dadosEntregas}>
                            </ModalEntregas>
                            <ModalFiltroCidade
                                open={openFiltroCidade}
                                onClose={handleCloseFiltroCidade}>
                            </ModalFiltroCidade>
                            <ToastSucesso
                                texto={textoSucesso}
                                open={openToastSucesso}
                                onClose={handleCloseToastSucesso}
                            />
                            <ToastErro
                                texto={textoErro}
                                open={openToastErro}
                                onClose={handleCloseToastErro}
                            />
                        </div>
                        <div className="modal-usuario">
                            <Dialog className="corpoModalCookies" open={openRegras} onClose={handleCloseRegras}>
                                {
                                    !loading ? (
                                        <>
                                            <DialogTitle>Defina as Permissões do Usuário</DialogTitle>
                                            <DialogContent>
                                                <div className="containerPermissao">
                                                    <h3>Permissões:</h3>
                                                    {regras.map((dado, index) => (
                                                        <Box display={'flex'} key={dado.regrasId}>
                                                            <input
                                                                type={'checkbox'}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setValues(current => ({ ...current, regras: [...values.regras, dado.regrasId] }));
                                                                    } else {
                                                                        var arr = values.regras;
                                                                        var value = dado.regrasId
                                                                        arr = arr.filter(function (item) {
                                                                            return item !== value
                                                                        })
                                                                        setValues(current => ({ ...current, regras: arr }));
                                                                    }
                                                                }}
                                                            >
                                                            </input>{dado.regra} {
                                                                dado.check ? (<p> - Já possui</p>) : (<p></p>)
                                                            }
                                                        </Box>
                                                    ))}
                                                </div>
                                            </DialogContent></>
                                    ) : (
                                        <LoadingComponent />
                                    )
                                }
                                <div className="botaoConcluir">
                                    <Button
                                        sx={{ width: 180 }}
                                        variant="contained"
                                        onClick={() => { aceitarSolicitacao(); }}>
                                        Aceitar
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                        <div className="modal-usuario">
                            <Dialog className="corpoModalCookies" open={openMotivo} onClose={handleCloseMotivo}>
                                {
                                    !loading ? (
                                        <>
                                            <DialogTitle>Digite porque está recusando está solicitação</DialogTitle>
                                            <DialogContent>
                                                <div className="principal">
                                                    <InputLabel htmlFor="status"><Text texto="Motivo" /></InputLabel>
                                                    <textarea
                                                        type="text"
                                                        className="input-text-motivo"
                                                        rows="3"
                                                        onChange={(e) => setValues2(current => ({ ...current, motivo: e.target.value }))}
                                                        value={values2.motivo}
                                                    ></textarea>
                                                </div>
                                            </DialogContent></>
                                    ) : (
                                        <LoadingComponent />
                                    )
                                }
                                <div className="botaoConcluir">
                                    <Button
                                        sx={{ width: 180 }}
                                        variant="contained"
                                        onClick={() => { recusarSolicitacao(); }}>
                                        Recusar
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                ) : (
                    <LoadingComponent />
                )
            }

        </div >
    );
}

export default TabelaV2;