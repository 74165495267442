import React, { useState, useEffect } from 'react';
import '../ModalPrevisaoTempo/ModalPrevisaoTempo.css';
import icone01d from '../../images/previsaoTempo/01d.png'
import icone01n from '../../images/previsaoTempo/01n.png'
import icone02d from '../../images/previsaoTempo/02d.png'
import icone02n from '../../images/previsaoTempo/02n.png'
import icone03d from '../../images/previsaoTempo/03d.png'
import icone03n from '../../images/previsaoTempo/03n.png'
import icone04d from '../../images/previsaoTempo/04d.png'
import icone04n from '../../images/previsaoTempo/04n.png'
import icone09d from '../../images/previsaoTempo/09d.png'
import icone09n from '../../images/previsaoTempo/09n.png'
import icone10d from '../../images/previsaoTempo/10d.png'
import icone10n from '../../images/previsaoTempo/10n.png'
import icone11d from '../../images/previsaoTempo/11d.png'
import icone11n from '../../images/previsaoTempo/11n.png'
import icone13d from '../../images/previsaoTempo/13d.png'
import icone13n from '../../images/previsaoTempo/13n.png'
import icone50d from '../../images/previsaoTempo/50d.png'
import icone50n from '../../images/previsaoTempo/50n.png'

export interface SimpleDialogProps {
    open: boolean;
    listaPrevisaoTempo: any;
    handleClosePrevisao: any;
}

function ModalPrevisaoTempo(props: SimpleDialogProps) {
    const [open, setOpen] = useState(props.open);

    const iconMap = {
        '01d': icone01d,
        '01n': icone01n,
        '02d': icone02d,
        '02n': icone02n,
        '03d': icone03d,
        '03n': icone03n,
        '04d': icone04d,
        '04n': icone04n,
        '09d': icone09d,
        '09n': icone09n,
        '10d': icone10d,
        '10n': icone10n,
        '11d': icone11d,
        '11n': icone11n,
        '13d': icone13d,
        '13n': icone13n,
        '50d': icone50d,
        '50n': icone50n,
    };

    const descricaoMap = {
        'clear sky': 'Céu limpo',
        'few clouds': 'Poucas nuvens',
        'scattered clouds': 'Nuvens dispersas',
        'broken clouds': 'Nuvens quebradas',
        'shower rain': 'Chuva de banho',
        'rain': 'Chuva',
        'thunderstorm': 'Trovão',
        'snow': 'Neve',
        'mist': 'Névoa',
    };

    function WeatherDescription({ descricao }) {
        const traducao = descricaoMap[descricao] || 'Condição desconhecida';
        return <p>{traducao}</p>;
    }

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true);

            const timer = setTimeout(() => {
                setOpen(false);
                props.handleClosePrevisao();
            }, 10000);

            return () => clearTimeout(timer);
        } else {
            setOpen(false);
        }
    }, [props.open]);

    function WeatherIcon({ iconCode }) {
        const iconUrl = iconMap[iconCode];
        return <img style={{ width: '70px' }} src={iconUrl} alt="Weather Icon" />;
    }

    return (
        <>
            {props.listaPrevisaoTempo && props.listaPrevisaoTempo.length > 0 && (
                <div className={`modal ${open ? 'open' : 'closed'}`} aria-hidden={!open}>
                    <div className="modal-backdrop" style={{ backgroundColor: 'transparent' }}>
                        <div className='div-modal-previsao'>
                            <div className="modal-title" id="alert-dialog-title">
                                {"Previsão do Tempo"}
                            </div>
                            <div className="modal-content">
                                {props.listaPrevisaoTempo.map((previsao, index) => (
                                    <div className='card-previsao' key={index}>
                                        <p>{formatarData(previsao.data)}</p>
                                        <div className='img-previsao'>
                                            <WeatherIcon iconCode={previsao.condicoes_climaticas[0].icone} />
                                            <div>
                                                <p>{previsao.temperatura_maxima.toFixed(0)}°C Max.</p>
                                                <p>{previsao.temperatura_minima.toFixed(0)}°C Min.</p>
                                            </div>
                                        </div>
                                        <p>Chuva: {previsao.chuva.toFixed(0)}%</p>
                                        <WeatherDescription descricao={previsao.condicoes_climaticas[0].descricao} />
                                    </div>
                                ))}
                            </div>
                            <div className="modal-actions">
                                {/* Botões ou ações podem ser adicionados aqui se necessário */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalPrevisaoTempo;
