import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './ModalEmbarque.css';
import FilialCidade from "../../contexts/CidadesFilial";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../CloseIcon/CloseIcon";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onClick: (value: string) => void;
}

function ModalEmbarque(props: SimpleDialogProps){
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { dadosFilialRedux, setDadosFilialRedux } = useContext(FilialCidade);

  const { setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

  const navigate = useNavigate();

  const onClick2 = (item) => {
    setDadosContextoEmbarqueRedux(item.contexto);
    navigate("/embarque");
  }

  const { onClose, open, onClick } = props;

  const handleClose = () => {
    onClose();
  };


  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
      >
        <div className='modal-embarque'>

          <div className='div-modal-embarque'>
            <div className='titulo-modal-embarque'>
              <DialogTitle id="alert-dialog-title">
                <h3 className='title-modal-embarque'>Filiais</h3>
              </DialogTitle>
              <CloseIcon onClick={(e) => {
                handleClose();
              }} />
            </div>
            <DialogContent >
              <p>Ops, parece que você não tem permissão ou o sistema não se lembra onde você estava, por favor, escolha uma filial.</p>
              <DialogContentText>
                {dadosFilialRedux.map((item) => {
                  if (item.nomeFilial != 'Modelo Viagens') {
                    return (
                      <p className='options' onClick={() => { onClick2(item); }}>{item.nomeFilial}</p>
                    );
                  }
                })}
              </DialogContentText>
            </DialogContent>
          </div>
        </div >
      </Dialog>
    </div>
  );
}

export default ModalEmbarque;