import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import { ChromePicker } from 'react-color';
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import "./ConfiguracoesCores.css";
import { getEmpresaPorId, editaEmpresa } from "../../../../services/ModuloGerenciador/Administrativo";
import ModalAtualizar from "../../../../components/ModalAtualizar/ModalAtualizar.tsx";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { motion } from "framer-motion";

function ConfiguracoesCores() {
    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [colorHeaderFooter, setColorHeaderFooter] = useState(dadosCoresRedux.headerFooter);

    const [showColorHeaderFooter, setShowColorHeaderFooter] = useState(false);

    const [colorFundoPadrao, setColorFundoPadrao] = useState(dadosCoresRedux.fundoPadrao);

    const [showColorFundoPadrao, setShowColorFundoPadrao] = useState(false);

    const [colorBotaoSelecionado, setColorBotaoSelecionado] = useState(dadosCoresRedux.botaoSelecionado);

    const [showColorBotaoSelecionado, setShowColorBotaoSelecionado] = useState(false);

    const [colorBotaoPadrao, setColorBotaoPadrao] = useState(dadosCoresRedux.botaoPadrao);

    const [showColorBotaoPadrao, setShowColorBotaoPadrao] = useState(false);

    const [colorTextoBotaoSelecionado, setColorTextoBotaoSelecionado] = useState(dadosCoresRedux.textoBotaoSelecionado);

    const [showColorTextoBotaoSelecionado, setShowColorTextoBotaoSelecionado] = useState(false);

    const [colorTextoBotaoPadrao, setColorTextoBotaoPadrao] = useState(dadosCoresRedux.textoBotaoPadrao);
    const [showColorTextoBotaoPadrao, setShowColorTextoBotaoPadrao] = useState(false);

    const [colorTextosInputs, setColorTextosInputs] = useState(dadosCoresRedux.textosInputs);
    const [showColorTextosInputs, setShowColorTextosInputs] = useState(false);

    const [colorBotaoSecundario, setColorBotaoSecundario] = useState(dadosCoresRedux.botaoSecundario);
    const [showColorBotaoSecundario, setShowColorBotaoSecundario] = useState(false);

    const [colorTextoBotaoSecundario, setColorTextoBotaoSecundario] = useState(dadosCoresRedux.textoBotaoSecundario);
    const [showColorTextoBotaoSecundario, setShowColorTextoBotaoSecundario] = useState(false);

    const [colorTituloColuna, setColorTituloColuna] = useState(dadosCoresRedux.tituloColuna);
    const [showColorTituloColuna, setShowColorTituloColuna] = useState(false);

    const [colorTextoTituloColuna, setColorTextoTituloColuna] = useState(dadosCoresRedux.textoTituloColuna);
    const [showColorTextoTituloColuna, setShowColorTextoTituloColuna] = useState(false);

    const [colorBordaTituloColuna, setColorBordaTituloColuna] = useState(dadosCoresRedux.bordaTituloColuna);
    const [showColorBordaTituloColuna, setShowColorBordaTituloColuna] = useState(false);

    const [colorColuna, setColorColuna] = useState(dadosCoresRedux.coluna);
    const [showColorColuna, setShowColorColuna] = useState(false);

    const [colorTextoColuna, setColorTextoColuna] = useState(dadosCoresRedux.textoColuna);
    const [showColorTextoColuna, setShowColorTextoColuna] = useState(false);

    const [colorBotaoMenu, setColorBotaoMenu] = useState(dadosCoresRedux.botaoMenu);
    const [showColorBotaoMenu, setShowColorBotaoMenu] = useState(false);

    const [colorFundoMenu, setColorFundoMenu] = useState(dadosCoresRedux.fundoMenu);
    const [showColorFundoMenu, setShowColorFundoMenu] = useState(false);

    const [colorTextoBotaoNormal, setColorTextoBotaoNormal] = useState(dadosCoresRedux.textoBotaoNormal);
    const [showColorTextoBotaoNormal, setShowColorTextoBotaoNormal] = useState(false);

    const [open, setOpen] = useState(false);

    const [jaPassou, setJaPassou] = useState(false);

    const [loading, setLoading] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const cadastrar = async () => {
        setLoading(true);
        try {
            await getEmpresaPorId(localStorage.getItem('tenant')).then((result) => {
                if (result.status === 200) {
                    const nomeColuna = result.data.colunas.replace(/'/g, '"');
                    const colunas = JSON.parse(nomeColuna);
                    setDadosTabelaRedux(colunas);
                    const objeto = {
                        tenant: localStorage.getItem('tenant'),
                        cores: JSON.stringify(dadosCoresRedux).replace(/"/g, `'`),
                        colunas: JSON.stringify(dadosTabelaRedux).replace(/"/g, `'`),
                    }
                    editaEmpresa(dadosUsuarioRedux.token, objeto).then((result) => {
                        if (result.status === 200) {
                            if (!jaPassou) {
                                setOpen(true);
                            }
                            setJaPassou(true);
                            setTextoSucesso("Configurações Atualizadas com Sucesso!");
                            setOpenToastSucesso(true);
                        }
                    }).catch((error) => {
                        console.error(error);
                        setTextoErro(error.response.data);
                        setOpenToastErro(true);
                    });
                }
            });
        } catch (error) {
            console.error(error);
            setTextoErro(error.response.data);
            setOpenToastErro(true);
        }
    }

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Configuracoes') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Configuracoes') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="ConfiguracoesCores" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    <NavbarV3 selecionado="ConfiguracoesCores" />
                    {!loading ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="corpo-cores"
                        >
                            <div className="header-configuracoes-v2">
                                <div className="flex">
                                    <p className="indicador-principal-embarque-v2">Painel Configurações Cores</p>
                                </div>
                            </div>
                            <div className="botoes-cores">
                                <p className="titulo-configs">Cores Fundo</p>
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorHeaderFooter ? 'FECHAR' : 'Fundo Superior'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorHeaderFooter(showColorHeaderFooter => !showColorHeaderFooter);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorHeaderFooter && (
                                        <div>
                                            <p>Fundo Superior</p>
                                            <ChromePicker
                                                color={colorHeaderFooter}
                                                onChange={updatedColor => setColorHeaderFooter(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorFundoPadrao ? 'FECHAR' : 'Fundo Padrão'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorFundoPadrao(showColorFundoPadrao => !showColorFundoPadrao);
                                        setShowColorHeaderFooter(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorFundoPadrao && (
                                        <div>
                                            <p>Fundo Padrão</p>
                                            <ChromePicker
                                                color={colorFundoPadrao}
                                                onChange={updatedColor => setColorFundoPadrao(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorFundoMenu ? 'FECHAR' : 'Fundo Menu'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorFundoMenu(showColorFundoMenu => !showColorFundoMenu);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorFundoMenu && (
                                        <div>
                                            <p>Fundo Menu</p>
                                            <ChromePicker
                                                color={colorFundoMenu}
                                                onChange={updatedColor => setColorFundoMenu(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="botoes-cores">
                                <p className="titulo-configs">Cores Botões</p>
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorBotaoPadrao ? 'FECHAR' : 'Botão Padrão'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorBotaoPadrao(showColorBotaoPadrao => !showColorBotaoPadrao);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorBotaoPadrao && (
                                        <div>
                                            <p>Botão Padrão</p>
                                            <ChromePicker
                                                color={colorBotaoPadrao}
                                                onChange={updatedColor => setColorBotaoPadrao(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorBotaoSecundario ? 'FECHAR' : 'Botão Secundário'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorBotaoSecundario(showColorBotaoSecundario => !showColorBotaoSecundario);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorBotaoSecundario && (
                                        <div>
                                            <p>Botão Secundário</p>
                                            <ChromePicker
                                                color={colorBotaoSecundario}
                                                onChange={updatedColor => setColorBotaoSecundario(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorBotaoMenu ? 'FECHAR' : 'Botão Menu'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorBotaoMenu(showColorBotaoMenu => !showColorBotaoMenu);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorBotaoMenu && (
                                        <div>
                                            <p>Botão Menu</p>
                                            <ChromePicker
                                                color={colorBotaoMenu}
                                                onChange={updatedColor => setColorBotaoMenu(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorBotaoSelecionado ? 'FECHAR' : 'Botão Selecionado'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorBotaoSelecionado(showColorBotaoSelecionado => !showColorBotaoSelecionado);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorBotaoSelecionado && (
                                        <div>
                                            <p>Botão Selecionado</p>
                                            <ChromePicker
                                                color={colorBotaoSelecionado}
                                                onChange={updatedColor => setColorBotaoSelecionado(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="botoes-cores">
                                <p className="titulo-configs">Cores Textos</p>
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoBotaoPadrao ? 'FECHAR' : 'Texto Botão Padrão'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoBotaoPadrao(showColorTextoBotaoPadrao => !showColorTextoBotaoPadrao);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextoBotaoPadrao && (
                                        <div>
                                            <p>Texto Botão Padrão</p>
                                            <ChromePicker
                                                color={colorTextoBotaoPadrao}
                                                onChange={updatedColor => setColorTextoBotaoPadrao(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoBotaoNormal ? 'FECHAR' : 'Texto Botão Normal'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoBotaoNormal(showColorTextoBotaoNormal => !showColorTextoBotaoNormal);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                    }}
                                />
                                {
                                    showColorTextoBotaoNormal && (
                                        <div>
                                            <p>Texto Botão Normal</p>
                                            <ChromePicker
                                                color={colorTextoBotaoNormal}
                                                onChange={updatedColor => setColorTextoBotaoNormal(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoBotaoSelecionado ? 'FECHAR' : 'Texto Botão Selecionado'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoBotaoSelecionado(showColorTextoBotaoSelecionado => !showColorTextoBotaoSelecionado);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextoBotaoSelecionado && (
                                        <div>
                                            <p>Texto Botão Selecionado</p>
                                            <ChromePicker
                                                color={colorTextoBotaoSelecionado}
                                                onChange={updatedColor => setColorTextoBotaoSelecionado(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoBotaoSecundario ? 'FECHAR' : 'Texto Botão Secundário'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoBotaoSecundario(showColorTextoBotaoSecundario => !showColorTextoBotaoSecundario);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextoBotaoSecundario && (
                                        <div>
                                            <p>Texto Botão Secundário</p>
                                            <ChromePicker
                                                color={colorTextoBotaoSecundario}
                                                onChange={updatedColor => setColorTextoBotaoSecundario(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextosInputs ? 'FECHAR' : 'Título Caixa de Texto'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextosInputs(showColorTextosInputs => !showColorTextosInputs);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextosInputs && (
                                        <div>
                                            <p>Título Caixa de Texto</p>
                                            <ChromePicker
                                                color={colorTextosInputs}
                                                onChange={updatedColor => setColorTextosInputs(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="botoes-cores">
                                <p className="titulo-configs">Cores Tabela</p>
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTituloColuna ? 'FECHAR' : 'Titulo Coluna'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTituloColuna(showColorTituloColuna => !showColorTituloColuna);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTituloColuna && (
                                        <div>
                                            <p>Titulo Coluna</p>
                                            <ChromePicker
                                                color={colorTituloColuna}
                                                onChange={updatedColor => setColorTituloColuna(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoTituloColuna ? 'FECHAR' : 'Texto Titulo Coluna'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoTituloColuna(showColorTextoTituloColuna => !showColorTextoTituloColuna);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextoTituloColuna && (
                                        <div>
                                            <p>Texto Titulo Coluna</p>
                                            <ChromePicker
                                                color={colorTextoTituloColuna}
                                                onChange={updatedColor => setColorTextoTituloColuna(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorBordaTituloColuna ? 'FECHAR' : 'Borda Titulo Coluna'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorBordaTituloColuna(showColorBordaTituloColuna => !showColorBordaTituloColuna);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorBordaTituloColuna && (
                                        <div>
                                            <p>Borda Titulo Coluna</p>
                                            <ChromePicker
                                                color={colorBordaTituloColuna}
                                                onChange={updatedColor => setColorBordaTituloColuna(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorColuna ? 'FECHAR' : 'Coluna'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorColuna(showColorColuna => !showColorColuna);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorTextoColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorColuna && (
                                        <div>
                                            <p>Coluna</p>
                                            <ChromePicker
                                                color={colorColuna}
                                                onChange={updatedColor => setColorColuna(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo={showColorTextoColuna ? 'FECHAR' : 'Texto Coluna'}
                                    cor={dadosCoresRedux.botaoPadrao}
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setShowColorTextoColuna(showColorTextoColuna => !showColorTextoColuna);
                                        setShowColorHeaderFooter(false);
                                        setShowColorFundoPadrao(false);
                                        setShowColorBotaoSelecionado(false);
                                        setShowColorBotaoPadrao(false);
                                        setShowColorTextoBotaoSelecionado(false);
                                        setShowColorTextoBotaoPadrao(false);
                                        setShowColorTextosInputs(false);
                                        setShowColorBotaoSecundario(false);
                                        setShowColorTextoBotaoSecundario(false);
                                        setShowColorTituloColuna(false);
                                        setShowColorTextoTituloColuna(false);
                                        setShowColorBordaTituloColuna(false);
                                        setShowColorColuna(false);
                                        setShowColorBotaoMenu(false);
                                        setShowColorFundoMenu(false);
                                        setShowColorTextoBotaoNormal(false);
                                    }}
                                />
                                {
                                    showColorTextoColuna && (
                                        <div>
                                            <p>Texto Coluna</p>
                                            <ChromePicker
                                                color={colorTextoColuna}
                                                onChange={updatedColor => setColorTextoColuna(updatedColor.hex)}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="botoes-cores">
                                <CustomButton
                                    classCss="custom-button-margin-giant"
                                    titulo="Salvar"
                                    cor="#008000"
                                    corTexto={dadosCoresRedux.textoBotaoPadrao}
                                    onClick={() => {
                                        setDadosCoresRedux({
                                            headerFooter: colorHeaderFooter,
                                            fundoPadrao: colorFundoPadrao,
                                            botaoSelecionado: colorBotaoSelecionado,
                                            botaoPadrao: colorBotaoPadrao,
                                            textoBotaoSelecionado: colorTextoBotaoSelecionado,
                                            textoBotaoPadrao: colorTextoBotaoPadrao,
                                            textosInputs: colorTextosInputs,
                                            botaoSecundario: colorBotaoSecundario,
                                            textoBotaoSecundario: colorTextoBotaoSecundario,
                                            tituloColuna: colorTituloColuna,
                                            textoTituloColuna: colorTextoTituloColuna,
                                            bordaTituloColuna: colorBordaTituloColuna,
                                            coluna: colorColuna,
                                            textoColuna: colorTextoColuna,
                                            botaoMenu: colorBotaoMenu,
                                            fundoMenu: colorFundoMenu,
                                            textoBotaoNormal: colorTextoBotaoNormal
                                        });
                                        cadastrar();
                                    }}
                                />
                            </div>
                        </motion.div>
                    ) : (
                        <LoadingComponent />
                    )}
                    <div>
                        <ModalAtualizar
                            titulo="Cores"
                            texto="Clique no botão para Atulizar a Página!"
                            open={open}
                            onClose={handleClose}
                            onClick={cadastrar}
                        />
                        <ToastSucesso
                            texto={textoSucesso}
                            open={openToastSucesso}
                            onClose={handleCloseToastSucesso}
                        />
                        <ToastErro
                            texto={textoErro}
                            open={openToastErro}
                            onClose={handleCloseToastErro}
                        />
                    </div>
                </div>
            )
            }
        </motion.div>
    );
}

export default ConfiguracoesCores;