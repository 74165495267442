import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import "./Veiculo.css";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import { cadastraVeiculo, deleteVeiculoPorId, editaVeiculoPorId, getVeiculo, getVeiculoPorId } from "../../../../services/ModuloGerenciador/Veiculo";
import { VerificaExistenciaVeiculo } from "../../../../services/ModuloGerenciador/VerificaExistencia";
import { VeiculoModel } from "../../../../models/VeiculoModel"
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras, getVeiculoDependencias } from "../../../../services/ModuloGerenciador/Geral";
import ModalCadastro from "../../../../components/ModalCadastro/ModalCadastro.tsx";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { motion } from "framer-motion";
import { getVeiculoSearch } from "../../../../services/ModuloGerenciador/Search";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import SelectEmbarqueV2 from "../../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import Select from 'react-select';
import LoadingBar from "../../../../components/LoadingBar/LoadingBar";
import NewIcon from "../../../../components/NewIcon/NewIcon";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import FiltrosRedux from "../../../../contexts/Filtros.js";
import ReactSelect from 'react-select';
import { getCidadeEstado } from "../../../../services/ModuloGerenciador/CidadeEstado.js";
import ClearIcon from '@mui/icons-material/Clear';

function Veiculo(props) {

    const opcoes = [
        {
            'opcao': 'Cavalo',
            'valor': 1
        },
        {
            'opcao': 'Carreta',
            'valor': 2
        },
        {
            'opcao': 'Bitrem',
            'valor': 3
        },
        {
            'opcao': 'Tritrem',
            'valor': 4
        },
        {
            'opcao': 'Automovel',
            'valor': 5
        },
    ];

    const labelVeiculo = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesVeiculo = opcoes.map((data) => ({
        value: data.valor,
        label: data.opcao,
        ...data
    }));

    const estiloVeiculo = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'Motorista2',
            'field': 'motorista2'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const [placaValida, setPlacaValida] = useState(true);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getVeiculoSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const verificadorExistenciaVeiculo = async (placaCavalo) => {
        if (placaCavalo !== null && placaCavalo !== '') {
            await VerificaExistenciaVeiculo(dadosUsuarioRedux.token, placaCavalo).then((result) => {
                if (result.data === true) {
                    setTextoSucesso("Placa do caminhão não possui nenhum vínculo.");
                    setOpenToastSucesso(true);
                    setPlacaValida(true);
                } else {
                    setTextoErro("Placa do caminhão já possui um vínculo ativo, não será possível cadastrar novamente com ela.");
                    setOpenToastErro(true);
                    setPlacaValida(false);
                }
            }).catch(() => {
                setTextoErro("Placa do caminhão já possui um vínculo ativo, não será possível cadastrar novamente com ela.");
                setOpenToastErro(true);
                setPlacaValida(false);
            });
        }
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [openCadastro, setOpenCadastro] = useState(false);

    const handleCloseCadastro = (value) => {
        if (idAtual) {
            setOpenCadastro(false);
        } else {
            setLoadingSelectMotorista(true);
            buscarDependenciasSemLoading();
            setOpenCadastro(false);
        }
    };

    const [openCadastroProprietario, setOpenCadastroProprietario] = useState(false);

    const handleCloseCadastroProprietario = (value) => {
        if (idAtual) {
            setOpenCadastroProprietario(false);
        } else {
            setLoadingSelectProprietario(true);
            buscarDependenciasSemLoading();
            setOpenCadastroProprietario(false);
        }
    };

    const [openCadastroCaminhao, setOpenCadastroCaminhao] = useState(false);

    const handleCloseCadastroCaminhao = (value) => {
        if (idAtual) {
            setOpenCadastroCaminhao(false);
        } else {
            setLoadingSelectCaminhao(true);
            buscarDependenciasSemLoading();
            setOpenCadastroCaminhao(false);
        }
    };

    const [openCadastroCarreta, setOpenCadastroCarreta] = useState(false);

    const handleCloseCadastroCarreta = (value) => {
        if (idAtual) {
            setOpenCadastroCarreta(false);
        } else {
            setLoadingSelectCarreta(true);
            buscarDependenciasSemLoading();
            setOpenCadastroCarreta(false);
        }
    };

    const [openCadastroCarroceria, setOpenCadastroCarroceria] = useState(false);

    const handleCloseCadastroCarroceria = (value) => {
        if (idAtual) {
            setOpenCadastroCarroceria(false);
        } else {
            setLoadingSelectCarroceria(true);
            buscarDependenciasSemLoading();
            setOpenCadastroCarroceria(false);
        }
    };

    const [openCadastroTipoVeiculo, setOpenCadastroTipoVeiculo] = useState(false);

    const handleCloseCadastroTipoVeiculo = (value) => {
        if (idAtual) {
            setOpenCadastroTipoVeiculo(false);
        } else {
            setLoadingSelectTipoVeiculo(true);
            buscarDependenciasSemLoading();
            setOpenCadastroTipoVeiculo(false);
        }
    };

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [loadingSelectProprietario, setLoadingSelectProprietario] = useState(false);

    const [loadingSelectMotorista, setLoadingSelectMotorista] = useState(false);

    const [loadingSelectCaminhao, setLoadingSelectCaminhao] = useState(false);

    const [loadingSelectCarreta, setLoadingSelectCarreta] = useState(false);

    const [loadingSelectCarroceria, setLoadingSelectCarroceria] = useState(false);

    const [loadingSelectTipoVeiculo, setLoadingSelectTipoVeiculo] = useState(false);

    const [values, setValues] = useState(new VeiculoModel({}));

    const [valuesSelect, setValuesSelect] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaProprietario, setListaProprietarios] = useState([]);

    const [listaMotoristas, setListaMotoristas] = useState([]);

    const [listaCaminhoes, setListaCaminhoes] = useState([]);

    const [listaCarretas, setListaCarretas] = useState([]);

    const [listaCarrocerias, setListaCarrocerias] = useState([]);

    const [listaTipoVeiculos, setListaTipoVeiculos] = useState([]);

    const [tokenApi1, setTokenApi1] = useState("");

    const [idAtual, setIdAtual] = useState();

    const [disable, setDisable] = useState(false);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarDependencias = async () => {
        setLoading(true);
        await getVeiculoDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaProprietarios(result.data.proprietarios);
            setListaMotoristas(result.data.motoristas);
            setListaCaminhoes(result.data.caminhaos);
            setListaCarretas(result.data.carretas);
            setListaCarrocerias(result.data.tipoCarrocerias);
            setListaTipoVeiculos(result.data.tipoVeiculos);
            setListaCidade(result.data.cidadeEstados);
            setDadosTabela(result.data.veiculos);
            setDadosTabelaCopia(result.data.veiculos);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarDependenciasSemLoading = async () => {
        await getVeiculoDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaProprietarios(result.data.proprietarios);
            setListaMotoristas(result.data.motoristas);
            setListaCaminhoes(result.data.caminhaos);
            setListaCarretas(result.data.carretas);
            setListaCarrocerias(result.data.tipoCarrocerias);
            setListaTipoVeiculos(result.data.tipoVeiculos);
            setDadosTabela(result.data.veiculos);
            setTabelaRedux({});
            setLoadingSelectProprietario(false);
            setLoadingSelectMotorista(false);
            setLoadingSelectCaminhao(false);
            setLoadingSelectCarreta(false);
            setLoadingSelectCarroceria(false);
            setLoadingSelectTipoVeiculo(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoadingSelectProprietario(false);
            setLoadingSelectMotorista(false);
            setLoadingSelectCaminhao(false);
            setLoadingSelectCarreta(false);
            setLoadingSelectTipoVeiculo(false);
            setLoadingSelectCarroceria(false);
        });
    }

    const buscarVeiculo = async () => {
        setLoading(true);
        await getVeiculo(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        })
    }

    const [listaCidade, setListaCidade] = useState([]);

    // const buscarCidadeEstado = async () => {
    //     await getCidadeEstado(dadosUsuarioRedux.token).then((result) => {
    //         setListaCidade(result.data);
    //     });
    // }

    const cadastrar = async () => {
        if ((values.placaCavalo !== "" || values.proprietario !== "" || values.motorista !== "" || values.tipoCarroceria !== "" || values.tipoVeiculoConjuntos !== "") &&
            (validator.placaCavalo === "true" && validator.proprietario === "true" && validator.motorista === "true" && validator.tipoCarroceria === "true" && validator.tipoVeiculoConjuntos === "true")) {
            await cadastraVeiculo(values, dadosUsuarioRedux.token).then((result) => {
                setLoading(true);
                if (result.status === 200) {
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarDependencias();
                    } else {
                        buscarSearch();
                    }
                    setDadosRequestControllerRedux("Fechar Modal Veiculo");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.caminhao !== "" && values.proprietario !== "" && values.motorista !== "" && values.tipoCarroceria !== null && values.tipoVeiculoConjuntos !== null)) {
            setLoading(true);
            await editaVeiculoPorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarDependencias();
                    } else {
                        buscarSearch();
                    }
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setEditando(false);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
        }
    }

    const preencherDadosEditando = async (result) => {
        const tipoVeiculoSelecionado = opcoes.find(opcao => opcao.valor === result.data.tipoVeiculo);
        if (tipoVeiculoSelecionado) {
            setValuesSelect(current => ({ ...current, tipoVeiculo: tipoVeiculoSelecionado }));
        }
        const placaCavaloSelecionado = listaCaminhoes.find(option => option.placa === result.data.caminhao.placa);
        if (placaCavaloSelecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCavalo: {
                    value: placaCavaloSelecionado.placa,
                    label: placaCavaloSelecionado.placa,
                    id: placaCavaloSelecionado.idCaminhao
                }
            }));
            setValues(current => ({ ...current, CaminhaoId: placaCavaloSelecionado.idCaminhao }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCavalo: {
                    value: result.data.caminhao.placa,
                    label: result.data.caminhao.placa,
                    id: null
                }
            }));
        }
        const placaCarretaSelecionado = listaCarretas.find(option => option.placa === result.data.carreta?.placa);
        if (placaCarretaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCarreta: {
                    value: placaCarretaSelecionado.placa,
                    label: placaCarretaSelecionado.placa,
                    id: placaCarretaSelecionado.idCarreta
                }
            }));
            setValues(current => ({ ...current, placaCarretaId: placaCarretaSelecionado.idCarreta }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCarreta: {
                    value: result.data.carreta?.placa,
                    label: result.data.carreta?.placa,
                    id: null
                }
            }));
        }
        const placaCarreta2Selecionado = listaCarretas.find(option => option.placa === result.data.carreta2?.placa);
        if (placaCarreta2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCarreta2: {
                    value: placaCarreta2Selecionado.placa,
                    label: placaCarreta2Selecionado.placa,
                    id: placaCarreta2Selecionado.idCarreta
                }
            }));
            setValues(current => ({ ...current, carreta2Id: placaCarreta2Selecionado.idCarreta }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCarreta2: {
                    value: result.data.carreta2?.placa,
                    label: result.data.carreta2?.placa,
                    id: null
                }
            }));
        }
        const proprietarioSelecionado = listaProprietario.find(option => option.nome === result.data.proprietarioVeiculo?.nome);
        if (proprietarioSelecionado) {
            setValuesSelect(current => ({
                ...current,
                proprietario: {
                    value: proprietarioSelecionado.nome,
                    label: proprietarioSelecionado.nome,
                    id: proprietarioSelecionado.idProprietario
                }
            }));
            setValues(current => ({ ...current, proprietarioId: proprietarioSelecionado.idProprietario }));
        }
        const tipoCarroceriaSelecionado = listaCarrocerias.find(option => option.nome === result.data.tipoCarroceria?.nome);
        if (tipoCarroceriaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoCarroceria: {
                    value: tipoCarroceriaSelecionado.nome,
                    label: tipoCarroceriaSelecionado.nome,
                    id: tipoCarroceriaSelecionado.id
                }
            }));
            setValues(current => ({ ...current, tipoCarroceriaId: tipoCarroceriaSelecionado.id }));
        }
        const tipoVeiculoConjuntosSelecionado = listaTipoVeiculos.find(option => option.nome === result.data.tipoVeiculoConjuntos?.nome);
        if (tipoVeiculoConjuntosSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoVeiculoConjuntos: {
                    value: tipoVeiculoConjuntosSelecionado.nome,
                    label: tipoVeiculoConjuntosSelecionado.nome,
                    id: tipoVeiculoConjuntosSelecionado.id
                }
            }));
            setValues(current => ({ ...current, tipoVeiculoId: tipoVeiculoConjuntosSelecionado.id }));
        }
        const motoristaSelecionado = listaMotoristas.find(option => option.idMotorista === result.data?.motoristaId);
        if (motoristaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motorista: {
                    value: motoristaSelecionado.nome,
                    label: motoristaSelecionado.nome,
                    id: motoristaSelecionado.idMotorista
                }
            }));
            setValues(current => ({ ...current, motoristaId: motoristaSelecionado.idMotorista }));
        }
        const motorista2Selecionado = listaMotoristas.find(option => option.idMotorista === result.data?.motorista2Id);
        if (motorista2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                motorista2: {
                    value: motorista2Selecionado.nome,
                    label: motorista2Selecionado.nome,
                    id: motorista2Selecionado.idMotorista
                }
            }));
            setValues(current => ({ ...current, motorista2Id: motorista2Selecionado.idMotorista }));
        }
        const cidadeSelecionado = listaCidade.find(option =>
            option.idCidadeEstado === result.data.cidadeEstadoId
        );
        if (cidadeSelecionado) {
            setValuesSelect(current => ({ ...current, cidadeEstadoId: cidadeSelecionado }));
        }
        setValues(current => ({ ...current, dataAtualizacaoLocalizacao: new Date(result.data.dataAtualizacaoLocalizacao).toISOString().split('T')[0] }));
    };

    const buscarVeiculoPorId = async () => {
        setLoading(true);
        await getVeiculoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    await preencherDadosEditando(result);
                    setLoading(false);
                    setTimeout(() => {
                        setValidator(current => ({ ...current, placaCavalo: "true" }));
                        setValidator(current => ({ ...current, proprietario: "true" }));
                        setValidator(current => ({ ...current, motorista: "true" }));
                        setValidator(current => ({ ...current, tipoCarroceria: "true" }));
                        setValidator(current => ({ ...current, tipoVeiculoConjuntos: "true" }));
                    }, 50);
                }
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarVeiculoPorIdModal = async (id) => {
        setLoading(true);
        await getVeiculoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result);
                setValues(result.data);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const deletarVeiculoPorId = async () => {
        setLoading(true);
        await deleteVeiculoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDependencias();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValues(current => ({ ...current, motorista3: 'N/D' }));
        setValues(current => ({ ...current, motorista4: 'N/D' }));
        setValues(current => ({ ...current, motorista5: 'N/D' }));
        setValues(current => ({ ...current, motorista6: 'N/D' }));
        setValues(current => ({ ...current, motorista7: 'N/D' }));
        setValues(current => ({ ...current, motorista8: 'N/D' }));
        setValues(current => ({ ...current, motorista9: 'N/D' }));
        setValues(current => ({ ...current, motorista10: 'N/D' }));
        setValues(current => ({ ...current, motorista11: 'N/D' }));
        setValues(current => ({ ...current, motorista12: 'N/D' }));
        setValidator(current => ({ ...current, placaCavalo: "false" }));
        setValidator(current => ({ ...current, proprietario: "false" }));
        setValidator(current => ({ ...current, motorista: "false" }));
        setValidator(current => ({ ...current, tipoCarroceria: "false" }));
        setValidator(current => ({ ...current, tipoVeiculoConjuntos: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarVeiculoPorId();
        }
    }, [editando]);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal Proprietario") {
            handleCloseCadastroProprietario();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Motorista") {
            handleCloseCadastro();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Caminhao") {
            handleCloseCadastroCaminhao();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Carreta") {
            handleCloseCadastroCarreta();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    useEffect(() => {
        buscarDependencias();
    }, []);

    useEffect(() => {
        if (props.idAtual) {
            buscarVeiculoPorIdModal(props.idAtual);
        }
    }, [listaProprietario, listaCaminhoes, listaCarretas, listaCarrocerias, listaMotoristas]);

    useEffect(() => {
        if (telaCadastro === true && editando === false) {
            setValues(current => ({ ...current, ativoDiaria: true }));
        }
    }, [telaCadastro, editando]);

    const labelCidadeEstado = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.abreviacao ? data.abreviacao : data.cidade} - {data.estado}
            {valuesSelect.cidadeEstadoId === data &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearCidade();
                    }} />
            }
        </div>
    );

    const clearCidade = () => {
        setValues((current) => ({
            ...current,
            cidadeEstadoId: null,
        }));
        setValuesSelect(current => ({ ...current, cidadeEstadoId: '' }));
        setValidator((current) => ({
            ...current,
            cidadeEstadoId: "false",
        }));
    };

    const estiloCidadeEstado = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const opcoesCidadeEstado = listaCidade.map((data) => ({
        value: data.idCidadeEstado,
        label: `${data.abreviacao ? data.abreviacao : data.cidade} - ${data.estado}`,
        ...data
    }));

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Veiculo') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Veiculo') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const clearPlacaCavalo = () => {
        setValuesSelect(current => ({ ...current, placaCavalo: '' }));
        setValues(current => ({ ...current, CaminhaoId: '' }));
        setValues(current => ({ ...current, placaCavalo: '' }));
        setValidator(current => ({ ...current, placaCavalo: "true" }));
    };

    const clearPlacaCarreta = () => {
        setValuesSelect(current => ({ ...current, placaCarreta: '' }));
        setValues(current => ({ ...current, placaCarreta: '' }));
        setValues(current => ({ ...current, carretaId: null }));
        setValidator(current => ({ ...current, placaCarreta: "false" }));
    };

    const clearPlacaCarreta2 = () => {
        setValuesSelect(current => ({ ...current, placaCarreta2: '' }));
        setValues(current => ({ ...current, placaCarreta2: '' }));
        setValues(current => ({ ...current, carreta2Id: null }));
        setValidator(current => ({ ...current, placaCarreta2: "false" }));
    };

    const clearTipoCarroceria = () => {
        setValuesSelect(current => ({ ...current, tipoCarroceria: '' }));
        setValues(current => ({ ...current, tipoCarroceria: '' }));
        setValues(current => ({ ...current, tipoCarroceriaId: null }));
        setValidator(current => ({ ...current, tipoCarroceria: "false" }));
    };

    const clearTipoVeiculo = () => {
        setValuesSelect(current => ({ ...current, tipoVeiculoConjuntos: '' }));
        setValues(current => ({ ...current, tipoVeiculoConjuntos: '' }));
        setValues(current => ({ ...current, tipoVeiculoId: null }));
        setValidator(current => ({ ...current, tipoVeiculoConjuntos: "false" }));
    };

    const clearProprietario = () => {
        setValuesSelect(current => ({ ...current, proprietario: '' }));
        setValues(current => ({ ...current, proprietario: '' }));
        setValues(current => ({ ...current, proprietarioId: null }));
        setValidator(current => ({ ...current, proprietario: "false" }));
    };

    const clearMotorista = () => {
        setValuesSelect(current => ({ ...current, motorista: '' }));
        setValues(current => ({ ...current, motorista: '' }));
        setValues(current => ({ ...current, motoristaId: null }));
        setValidator(current => ({ ...current, motorista: "false" }));
    };

    const clearMotorista2 = () => {
        setValuesSelect(current => ({ ...current, motorista2: '' }));
        setValues(current => ({ ...current, motorista2Id: null }));
        setValues(current => ({ ...current, motorista2: '' }));
        setValidator(current => ({ ...current, motorista: "false" }));
    };

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Conjuntos" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Conjuntos" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Conjuntos</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idVeiculo"
                                                nomeClasse1="outer-wrapper-veiculo"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Veiculo}
                                                nome="veiculo"
                                                dados={dadosTabela}
                                                listaTipos={opcoes} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Veiculo / "}</p>
                                                        <p className="indicador-emb">{"Cadastro de Veiculo"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Conjunto" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({});
                                                                setValuesSelect({});
                                                                setTelaCadastro(false);
                                                                setEditando(false);
                                                                setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectCaminhao ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={values.placaCavalo === "" && validator.placaCavalo === "false" ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Placa Cavalo:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, placaCavalo: e }));
                                                                                setValues(current => ({ ...current, CaminhaoId: e.id }));
                                                                                setValues(current => ({ ...current, placaCavalo: e.value }));
                                                                                setValidator(current => ({ ...current, placaCavalo: "true" }));
                                                                                verificadorExistenciaVeiculo(e.value);
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroCaminhao(true);
                                                                            }}
                                                                            clearSelection={clearPlacaCavalo}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idCaminhao"
                                                                            value={valuesSelect.placaCavalo}
                                                                            titulo="Placa Cavalo"
                                                                            erro={!placaValida}
                                                                            dadosSelect={listaCaminhoes}
                                                                            referenciaId="placa"
                                                                            referenciaItem="placa"
                                                                            disabled={props.desabilitar || editando} />
                                                                        {!placaValida && <div className="error-text">Placa já cadastrada</div>}

                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCaminhao(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectCarreta ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Placa Carreta:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, placaCarreta: e }));
                                                                                setValues(current => ({ ...current, placaCarreta: e.value }));
                                                                                setValues(current => ({ ...current, carretaId: e.id }));
                                                                                setValidator(current => ({ ...current, placaCarreta: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroCarreta(true);
                                                                            }}
                                                                            clearSelection={clearPlacaCarreta}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idCarreta"
                                                                            value={valuesSelect.placaCarreta}
                                                                            titulo="Placa Carreta"
                                                                            dadosSelect={listaCarretas}
                                                                            referenciaId="placa"
                                                                            referenciaItem="placa"
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCarreta(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectCarreta ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Placa Carreta 2:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, placaCarreta2: e }));
                                                                                setValues(current => ({ ...current, placaCarreta2: e.value }));
                                                                                setValues(current => ({ ...current, carreta2Id: e.id }));
                                                                                setValidator(current => ({ ...current, placaCarreta2: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroCarreta(true);
                                                                            }}
                                                                            clearSelection={clearPlacaCarreta2}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idCarreta"
                                                                            value={valuesSelect.placaCarreta2}
                                                                            titulo="Placa Carreta 2"
                                                                            dadosSelect={listaCarretas}
                                                                            referenciaId="placa"
                                                                            referenciaItem="placa"
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCarreta(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectProprietario ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={validator.proprietario === "false" ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, proprietario: e }));
                                                                                setValues(current => ({ ...current, proprietario: e.value }));
                                                                                setValues(current => ({ ...current, proprietarioId: e.id }));
                                                                                setValidator(current => ({ ...current, proprietario: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroProprietario(true);
                                                                            }}
                                                                            clearSelection={clearProprietario}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idProprietario"
                                                                            value={valuesSelect.proprietario}
                                                                            titulo="Proprietário"
                                                                            dadosSelect={listaProprietario}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome"
                                                                            disabled={props.desabilitar}
                                                                            erro={validator.proprietario === "false"} />
                                                                        {validator.proprietario === "false" && <div className="error-text">Campo Obrigatório</div>}
                                                                    </div>

                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroProprietario(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectMotorista ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={validator.motorista === "false" ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, motorista: e }));
                                                                                setValues(current => ({ ...current, motorista: e.value }));
                                                                                setValues(current => ({ ...current, motoristaId: e.id }));
                                                                                setValidator(current => ({ ...current, motorista: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastro(true);
                                                                            }}
                                                                            clearSelection={clearMotorista}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idMotorista"
                                                                            value={valuesSelect.motorista}
                                                                            titulo="Motorista"
                                                                            dadosSelect={listaMotoristas}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome"
                                                                            disabled={props.desabilitar}
                                                                            erro={validator.motorista === "false"} />
                                                                        {validator.motorista === "false" && <div className="error-text">Campo Obrigatório</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastro(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            loadingSelectMotorista ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Motorista 2:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, motorista2: e }));
                                                                                setValues(current => ({ ...current, motorista2Id: e.id }));
                                                                                setValues(current => ({ ...current, motorista2: e.value }));
                                                                                setValidator(current => ({ ...current, motorista: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastro(true);
                                                                            }}
                                                                            clearSelection={clearMotorista2}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="idMotorista"
                                                                            value={valuesSelect.motorista2}
                                                                            titulo="Motorista 2"
                                                                            dadosSelect={listaMotoristas}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome"
                                                                            disabled={props.desabilitar} /></div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastro(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Veículo APISUL:</p>
                                                                <FormControl sx={{ width: 265 }}>
                                                                    <Select
                                                                        value={valuesSelect.tipoVeiculo}
                                                                        options={opcoesVeiculo}
                                                                        formatOptionLabel={labelVeiculo}
                                                                        styles={estiloVeiculo}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    tipoVeiculo: selectedOption.valor,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, tipoVeiculo: selectedOption }));
                                                                                setValidator(current => ({ ...current, tipoVeiculo: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        {
                                                            loadingSelectCarroceria ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={validator.tipoCarroceria === "false" ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo Carroceria:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, tipoCarroceria: e }));
                                                                                setValues(current => ({ ...current, tipoCarroceria: e.value }));
                                                                                setValues(current => ({ ...current, tipoCarroceriaId: e.id }));
                                                                                setValidator(current => ({ ...current, tipoCarroceria: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroCarroceria(true);
                                                                            }}
                                                                            clearSelection={clearTipoCarroceria}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="id"
                                                                            value={valuesSelect.tipoCarroceria}
                                                                            titulo="Placa Carreta"
                                                                            dadosSelect={listaCarrocerias}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome"
                                                                            disabled={props.desabilitar}
                                                                            erro={validator.tipoCarroceria === "false"} />
                                                                        {validator.tipoCarroceria === "false" && <div className="error-text">Campo Obrigatório</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroCarroceria(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="rota-div-2">
                                                        {
                                                            loadingSelectTipoVeiculo ? (
                                                                <LoadingBar />
                                                            ) : (
                                                                <div className={validator.tipoVeiculoConjuntos === "false" ? "coluna-emb" : "coluna-emb-erro"}>
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo Veículo:</p>
                                                                        <SelectEmbarqueV2
                                                                            classeCss={250}
                                                                            onChange={(e) => {
                                                                                setValuesSelect(current => ({ ...current, tipoVeiculoConjuntos: e }));
                                                                                setValues(current => ({ ...current, tipoVeiculoConjuntos: e.value }));
                                                                                setValues(current => ({ ...current, tipoVeiculoId: e.id }));
                                                                                setValidator(current => ({ ...current, tipoVeiculoConjuntos: "true" }));
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setDisable(true);
                                                                                setOpenCadastroTipoVeiculo(true);
                                                                            }}
                                                                            clearSelection={clearTipoVeiculo}
                                                                            onInfoIconClick={setIdAtual}
                                                                            id="id"
                                                                            value={valuesSelect.tipoVeiculoConjuntos}
                                                                            titulo="Placa Carreta"
                                                                            dadosSelect={listaTipoVeiculos}
                                                                            referenciaId="nome"
                                                                            referenciaItem="nome"
                                                                            disabled={props.desabilitar}
                                                                            erro={validator.tipoVeiculoConjuntos === "false"} />
                                                                        {validator.tipoVeiculoConjuntos === "false" && <div className="error-text">Campo Obrigatório</div>}
                                                                    </div>
                                                                    <NewIcon
                                                                        onClick={(e) => {
                                                                            setOpenCadastroTipoVeiculo(true); setIdAtual(null); setDisable(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        <div className="coluna-emb">
                                                            <FormControlLabel control={
                                                                <Checkbox
                                                                    checked={values.ativoDiaria}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked === true) {
                                                                            setValues(current => ({ ...current, ativoDiaria: true }));
                                                                        }
                                                                        else {
                                                                            setValues(current => ({ ...current, ativoDiaria: false }));
                                                                        }
                                                                    }}
                                                                />} label={'Ativo Diária'} />
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade/UF:</p>
                                                                <FormControl sx={{ width: 220 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.cidadeEstadoId}
                                                                        isDisabled={props.desabilitar}
                                                                        options={opcoesCidadeEstado}
                                                                        formatOptionLabel={labelCidadeEstado}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: selectedOption.idCidadeEstado,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, cidadeEstadoId: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Data Última Localização:</p>
                                                                <input
                                                                    type="date"
                                                                    className="input-emb"
                                                                    style={{ width: '190px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, dataAtualizacaoLocalizacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, dataAtualizacaoLocalizacao: "true" }));
                                                                    }}
                                                                    value={values.dataAtualizacaoLocalizacao}
                                                                    disabled={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-veiculo">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.placaCavalo !== "" && values.proprietario !== ""
                                                                                    && values.motorista !== "" && values.tipoCarroceria !== "" && values.tipoVeiculoConjuntos !== "")
                                                                                    && (validator.placaCavalo === "true" && validator.proprietario === "true"
                                                                                        && validator.motorista === "true" && validator.tipoCarroceria === "true" && validator.tipoVeiculoConjuntos === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.placaCavalo !== "" && values.proprietario !== ""
                                                                                    && values.motorista !== "" && values.tipoCarroceria !== "" && values.tipoVeiculoConjuntos !== "")
                                                                                    && (validator.placaCavalo === "true" && validator.proprietario === "true"
                                                                                        && validator.motorista === "true" && validator.tipoCarroceria === "true" && validator.tipoVeiculoConjuntos === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarVeiculoPorId}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroMotorista="sim"
                                        open={openCadastro}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastro}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroProprietario="sim"
                                        open={openCadastroProprietario}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroProprietario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCaminhao="sim"
                                        open={openCadastroCaminhao}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCaminhao}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCarreta="sim"
                                        open={openCadastroCarreta}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCarreta}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroCarroceria="sim"
                                        open={openCadastroCarroceria}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroCarroceria}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoVeiculo="sim"
                                        open={openCadastroTipoVeiculo}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoVeiculo}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Veiculo;