import React, { useContext } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalFilial/ModalFilial.css';
import { PropaneSharp } from '@mui/icons-material';
import CustomButton from '../CustomButton/CustomButton';
import CoresRedux from "../../contexts/Cor";
import FilialCidade from "../../contexts/CidadesFilial";
import { useNavigate } from "react-router-dom";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    texto: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

function ModalFilial(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const onClick2 = (item) => {
        if (item.cidade === "T S - Too") {
            setDadosContextoEmbarqueRedux('toledo');
            navigate(item.rota);
        } else if (item.cidade === "T S - Pvai") {
            setDadosContextoEmbarqueRedux('paranavai');
            navigate(item.rota);
        } else if (item.cidade === "T S - Go") {
            setDadosContextoEmbarqueRedux('rioVerde');
            navigate(item.rota);
        } else if (item.cidade === "T S - Ms") {
            setDadosContextoEmbarqueRedux('gloriaDeDourados');
            navigate(item.rota);
        } else if (item.cidade === "T S - Frota") {
            setDadosContextoEmbarqueRedux('frota');
            navigate(item.rota);
        } else if (item.cidade === "Todos") {
            setDadosContextoEmbarqueRedux('todos');
            navigate(item.rota);
        } else if (item.cidade === "Modelo Viagens") {
            setDadosContextoEmbarqueRedux('templateEmbarque');
            navigate(item.rota);
        }
    }

    const navigate = useNavigate();

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-confirmacao'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.texto}
                            {dadosFilialRedux.map((item) => {
                                if (item.cidade != 'Modelo Viagens') {
                                    return (
                                        <p onClick={() => { onClick2(item); }}>{item.cidade}</p>
                                    );
                                }
                            })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalFilial;