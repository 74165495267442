import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import "./OnTimeEntrega.css";
import CoresRedux from "../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import { useLocation, useNavigate } from 'react-router-dom';
import ModalOnTime from "../../../components/ModalOnTime/ModalOnTime.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import RequestControllerRedux from "../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../components/CloseIcon/CloseIcon.js";
import { getOnTimeEntregaSearch } from "../../../services/ModuloGerenciador/Search.js";
import FiltrosRedux from "../../../contexts/Filtros.js";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2.js";
import { Card } from 'primereact/card';
import Charts from "../../../components/Charts/Charts.tsx";
import ModalHistoricoEdicao from "../../../components/ModalHistoricoEdicao/ModalHistoricoEdicao.tsx";
import { getMotivoOnTime, vincularMotivoPorId } from "../../../services/ModuloGerenciador/MotivoOnTime.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";

function OnTimeEntrega(props) {

    const colunasEntrega = [
        {
            'nomeColuna': 'Previsão',
            'field': 'dataHoraPrevisao'
        },
        {
            'nomeColuna': 'Chegada',
            'field': 'dataHoraChegada'
        },
        {
            'nomeColuna': 'Entregue',
            'field': 'dataHoraAcontecido'
        },
        {
            'nomeColuna': 'Esperando',
            'field': 'tempoEsperando'
        },
        {
            'nomeColuna': 'Placa',
            'field': 'placaCaminhao',
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte',
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivoOnTime'
        },
        {
            'nomeColuna': 'On Time',
            'field': 'onTime'
        },
        {
            'nomeColuna': 'Rota',
            'field': 'rota'
        },
    ];


    const headersEntrega = [
        {
            'nomeColuna': 'Percentual ON Time Entrega',
            'field': 'percentualOnTimeEntrega',
            'className': 'card-ontime',
            'unidade': '%'
        },
        {
            'nomeColuna': 'Percentual Ñ ON Time Entrega',
            'field': 'percentualNaoOnTimeEntrega',
            'className': 'card-nao-ontime',
            'unidade': '%'
        },
        {
            'nomeColuna': 'Tempo Esperando Entrega',
            'field': 'tempoEsperandoEntrega',
            'className': 'card-ontime-azul',
            'unidade': 'h'
        },
        {
            'nomeColuna': 'Média Tempo Esperando entrega',
            'field': 'tempoMedioEsperandoEntrega',
            'className': 'card-ontime-azul',
            'unidade': 'h'
        }
    ];

    const [numeroCargas, setNumeroCargas] = useState();

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [listaMotivos, setListaMotivos] = useState([]);

    const [dadosTabelaEntrega, setDadosTabelaEntrega] = useState([]);

    const [dadosTabelaEmbarque, setDadosTabelaEmbarque] = useState([]);

    const [motivoId, setMotivoId] = useState();

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [dadosClientesPioresIndicesEntrega, setDadosClientesPioresIndicesEntrega] = useState([]);

    const [dadosFilial, setDadosFilial] = useState([]);

    const [dadosMotivosEntrega, setDadosMotivosEntrega] = useState([]);

    const [dadosOnTime, setDadosOnTime] = useState([]);

    const [nomeMotivo, setNomeMotivo] = useState();

    const handleClose = () => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = () => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = () => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = () => {
        setOpenUsuario(false);
    };


    const [isEmbarque, setIsEmbarque] = useState(false);

    const [isEntrega, setIsEntrega] = useState(false);

    const vincularMotivoOnTime = async () => {
        let bool = false;
        if (isEmbarque === true) {
            bool = true
        }
        if (isEntrega === true) {
            bool = false;
        }
        await vincularMotivoPorId(dadosUsuarioRedux.token, tabelaRedux.colunaSelecionada, motivoId, bool).then((result) => {
            if (result.status === 200) {
                handleClose();
                buscarDadosDashboard4();
                buscarMotivo();
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const converterData = (data) => {
        const parts = data.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const buscarDadosDashboard4 = async () => {
        setLoading(true);
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        const data1 = converterData(primeiroDia);
        const data2 = converterData(ultimoDia);
        const inicioFormatado = filtrosRedux.embarqueInicio ? converterData(filtrosRedux.embarqueInicio) : undefined;
        const finalFormatado = filtrosRedux.embarqueFinal ? converterData(filtrosRedux.embarqueFinal) : undefined;
        if ((Array.isArray(filtrosRedux) && filtrosRedux.length !== 0) ||
            (filtrosRedux && Object.keys(filtrosRedux).length !== 0 && filtrosRedux.constructor === Object)) {
            const pesquisaFormatada = `${filtrosRedux.item}=${filtrosRedux.pesquisa}`
            await getOnTimeEntregaSearch(dadosUsuarioRedux.token,
                pesquisaFormatada,
                filtrosRedux.contexto ? filtrosRedux.contexto : 'todos',
                filtrosRedux.embarqueInicio ? inicioFormatado : data1,
                filtrosRedux.embarqueFinal ? finalFormatado : data2).then((result) => {
                    setDadosClientesPioresIndicesEntrega(result.data.clientesPioresIndicesEntrega);
                    setDadosTabelaEntrega(result.data.entrega);
                    setDadosFilial(result.data.filial);
                    setDadosMotivosEntrega(result.data.motivosEntrega);
                    setNumeroCargas(result.data.numeroDeCargasTotais);
                    const arrayOnTime = [];
                    arrayOnTime.push(result.data.percentualOnTimeEntrega);
                    arrayOnTime.push(result.data.percentualNaoOnTimeEntrega);
                    arrayOnTime.push(result.data.tempoEsperandoEntrega);
                    arrayOnTime.push(result.data.tempoMedioEsperandoEntrega);
                    setDadosOnTime(arrayOnTime);
                    setLoading(false);
                }).catch((error) => {
                    if (error.response?.status !== 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
        } else {
            await getOnTimeEntregaSearch(dadosUsuarioRedux.token, 'undefined=undefined', 'todos', data1, data2).then((result) => {
                setDadosClientesPioresIndicesEntrega(result.data.clientesPioresIndicesEntrega);
                setDadosTabelaEntrega(result.data.entrega);
                setDadosFilial(result.data.filial);
                setDadosMotivosEntrega(result.data.motivosEntrega);
                setNumeroCargas(result.data.numeroDeCargasTotais);
                const arrayOnTime = [];
                arrayOnTime.push(result.data.percentualOnTimeEntrega);
                arrayOnTime.push(result.data.percentualNaoOnTimeEntrega);
                arrayOnTime.push(result.data.tempoEsperandoEntrega);
                arrayOnTime.push(result.data.tempoMedioEsperandoEntrega);
                setDadosOnTime(arrayOnTime);
                setLoading(false);
            }).catch((error) => {
                if (typeof error?.response?.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        buscarDadosDashboard4();
        buscarMotivo();
    }, []);

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getOnTimeEntregaSearch(dadosUsuarioRedux.token, pesquisaFormatada, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal).then((result) => {
            setDadosClientesPioresIndicesEntrega(result.data.clientesPioresIndicesEntrega);
            setDadosTabelaEntrega(result.data.entrega);
            setDadosFilial(result.data.filial);
            setDadosMotivosEntrega(result.data.motivosEntrega);
            setNumeroCargas(result.data.numeroDeCargasTotais);
            const arrayOnTime = [];
            arrayOnTime.push(result.data.percentualOnTimeEntrega);
            arrayOnTime.push(result.data.percentualNaoOnTimeEntrega);
            arrayOnTime.push(result.data.tempoEsperandoEntrega);
            arrayOnTime.push(result.data.tempoMedioEsperandoEntrega);
            setDadosOnTime(arrayOnTime);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarSearchReset = async () => {
        setLoading(true);
        const pesquisaFormatada = 'undefined=undefined'
        await getOnTimeEntregaSearch(dadosUsuarioRedux.token, pesquisaFormatada, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal).then((result) => {
            setDadosClientesPioresIndicesEntrega(result.data.clientesPioresIndicesEntrega);
            setDadosTabelaEntrega(result.data.entrega);
            setDadosFilial(result.data.filial);
            setDadosMotivosEntrega(result.data.motivosEntrega);
            setNumeroCargas(result.data.numeroDeCargasTotais);
            const arrayOnTime = [];
            arrayOnTime.push(result.data.percentualOnTimeEntrega);
            arrayOnTime.push(result.data.percentualNaoOnTimeEntrega);
            arrayOnTime.push(result.data.tempoEsperandoEntrega);
            arrayOnTime.push(result.data.tempoMedioEsperandoEntrega);
            setDadosOnTime(arrayOnTime);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const buscarMotivo = async () => {
        await getMotivoOnTime(dadosUsuarioRedux.token).then((result) => {
            setListaMotivos(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    useEffect(() => {
        if (valuesPesquisa.contexto != undefined) {
            buscarDadosDashboard4();
            buscarMotivo();
        }
    }, [valuesPesquisa.contexto]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        buscarSearchReset();
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria'
        },
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
    ];

    const [showTables, setShowTables] = useState(true);

    const handleClickShowTables = () => {
        setShowTables(!showTables);
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'On Time') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'On Time') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const [openHistoricoEdicao, setOpenHistoricoEdicao] = useState(false);

    const handleCloseHistoricoEdicao = () => {
        setOpenHistoricoEdicao(false);
    };

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Entrega" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Entrega" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">On Time</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                                <div className="pointer" onClick={handleClickShowTables}>
                                                    {showTables ? <Visibility /> : <VisibilityOff />}
                                                </div>
                                            </div>
                                            <div className="numero-embarques">
                                                <h3 style={{ textAlign: 'center' }}>Número de cargas: {numeroCargas}</h3>
                                            </div>
                                            <div className="container-faturamento-ontime-entrega">
                                                <div className="div-card-ontime-entrega">
                                                    {headersEntrega.map((header, i) => (
                                                        <div className={header.className} key={header.field}>
                                                            <div className="card-header-ontime">
                                                                {header.nomeColuna}
                                                            </div>
                                                            <div className="card-body">
                                                                {dadosOnTime[i]} {header.unidade}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {showTables ? <div className="principal-ontime">
                                                <div className="container-ontime">
                                                    <h2 className="text-ontime">Entregas</h2>
                                                    <TabelaV2
                                                        setOpenHistoricoEdicao={setOpenHistoricoEdicao}
                                                        setOpen={setOpen}
                                                        isEmbarque={false}
                                                        setIsEntrega={setIsEntrega}
                                                        setIsEmbarque={setIsEmbarque}
                                                        classeSelecionado="embarqueId"
                                                        nomeClasse1="outer-wrapper-rota"
                                                        nomeClasse2="table-wrapper-v2"
                                                        colunas={colunasEntrega}
                                                        dados={dadosTabelaEntrega} />
                                                </div>
                                            </div> : <></>}
                                            <div className="charts">
                                                {
                                                    !loading ? (
                                                        <div>
                                                            <div className="graficosCima">
                                                                <div class="item">
                                                                    <Card>
                                                                        <Charts data={dadosClientesPioresIndicesEntrega} type={"pie-negative"} height={300} width={570} title={"Clientes Piortes Indices Entrega"}></Charts>
                                                                    </Card>
                                                                </div>
                                                                <div class="item">
                                                                    <Card>
                                                                        <Charts data={dadosFilial} type={"pie"} height={300} width={570} title={"Filial"}></Charts>
                                                                    </Card>
                                                                </div>
                                                                <div class="item">
                                                                    <Card>
                                                                        <Charts data={dadosMotivosEntrega} type={"pie"} height={300} width={570} title={"Motivos"}></Charts>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    ) : (
                                                        <LoadingComponent />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de OnTime Entrega / "}</p>
                                                        <p className="indicador-emb">{"Cadastro de OnTime Entrega"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de OnTime Entrega" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Nome OnTime:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Pallet"
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalOnTime
                                        titulo="Vincular Motivo"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={vincularMotivoOnTime}
                                        listaMotivos={listaMotivos}
                                        setMotivoId={setMotivoId}
                                        setNomeMotivo={setNomeMotivo}
                                    />
                                    <ModalHistoricoEdicao
                                        valueTabela={tabelaRedux}
                                        valueDados={tabelaRedux.row}
                                        valueUnico={values}
                                        open={openHistoricoEdicao}
                                        onClose={handleCloseHistoricoEdicao}
                                        titulo="Embarque"
                                        classeSelecionado="idEmbarque"
                                        colunas="Embarque"
                                        nome="embarque"
                                        nomeTable="Embarque"
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default OnTimeEntrega;