import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalStatusManual/ModalStatusManual.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2";
import { cadastraStatusManual } from "../../services/ModuloGerenciador/Search";
import DadosUsuarioRedux from "../../contexts/Usuario";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaVeiculos: any[];
    listaCidade: any[];
    listaStatus: any[];
    onClose: () => void;
    onClick: () => void;
}

function ModalStatusManual(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        enviarStatusManual();
    };

    const enviarStatusManual = async () => {
        if (values.cidadeId && values.veiculoId && values.data && values.status) {
            await cadastraStatusManual(values, dadosUsuarioRedux.token).then(() => {
                handleClose();
            });
        }
    }

    const data = tabelaRedux.dataAlteracao;
    const formatDate = (data) => {
        return new Date(data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-status-manual'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', marginBottom: '10px' }}>
                        <div>
                            <p className="input-label-emb">Veículo:</p>
                            <SelectEmbarqueV2
                                classeCss={180}
                                onChange={(e) => {
                                    setValuesSelect(current => ({ ...current, veiculoId: e }));
                                    setValues(current => ({ ...current, veiculoId: e.id }));
                                }}
                                pequeno={true}
                                id="idVeiculo"
                                value={valuesSelect.veiculoId}
                                titulo="Veículo"
                                dadosSelect={props.listaVeiculos}
                                referenciaId="caminhao"
                                referenciaItem="caminhao"
                                referenciaItem2="placa"
                                info={false} />
                        </div>
                        <div>
                            <p className="input-label-emb">Status:</p>
                            <input
                                type="text"
                                className="input-emb"
                                style={{ width: '180px' }}
                                onChange={(e) => {
                                    setValues(current => ({ ...current, status: e.target.value }));
                                }}
                                value={values.status} />
                        </div>
                        <div>
                            <p className="input-label-emb">Cidade:</p>
                            <SelectEmbarqueV2
                                classeCss={180}
                                onChange={(e) => {
                                    setValuesSelect(current => ({ ...current, cidadeId: e }));
                                    setValues(current => ({ ...current, cidadeId: e.id }));
                                }}
                                pequeno={true}
                                id="idCidadeEstado"
                                value={valuesSelect.cidadeId}
                                titulo="Veículo"
                                dadosSelect={props.listaCidade}
                                referenciaId="cidade"
                                referenciaItem="cidade"
                                info={false} />
                        </div>
                        <div>
                            <p className="input-label-emb">Data:</p>
                            <input
                                type="datetime-local"
                                className="input-emb"
                                style={{ width: '180px' }}
                                onChange={(e) => {
                                    setValues(current => ({ ...current, data: e.target.value }));
                                }}
                                value={values.data} />
                        </div>
                    </div>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="CANCELAR"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalStatusManual;