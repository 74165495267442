import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./Diarias.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getDiariaGrupoCliente, cadastraDiaria, editaDiariaPorId, getDiariaPorId, deleteDiariaPorId, getDiariaTodos } from "../../../services/ModuloGerenciador/Diaria";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import { getConfiguracaoGrupoCliente } from "../../../services/ModuloGerenciador/ConfiguracaoEmpresa.js";
import { valorMask } from "../../../regex/valorMask.js";
import styled from "styled-components";
import { FormControl } from "@mui/material";
import Select from 'react-select';
import ModalFiltroDiaria from "../../../components/ModalFiltroDiaria/ModalFiltroDiaria.tsx";
import { getStatus } from "../../../services/ModuloGerenciador/Status.js";

function Diarias(props) {
    const opcoes = [
        {
            'opcao': 'Diária',
            'valor': 1
        },
        {
            'opcao': 'Devolução',
            'valor': 2
        }
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '180px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const [values, setValues] = useState({
        cte: 0,
        observacao: '',
        valorPagoMotorista: 0,
        tipoDiaria: 1,
        grupoClienteId: 0,
        valores: []
    });

    const [valuesSelect, setValuesSelect] = useState({});


    const handleInputChange = (e, configuracao) => {
        const newValue = e.target.value;

        if (configuracao) {
            // Campos dinâmicos: Atualiza o array de valores dinâmicos
            setValues(currentValues => {
                const existingValueIndex = currentValues.valores.findIndex(item => item.colunasId === configuracao.id);
                let updatedValores;

                if (existingValueIndex !== -1) {
                    // Atualiza o valor existente
                    updatedValores = [...currentValues.valores];
                    updatedValores[existingValueIndex].valor = newValue;
                } else {
                    // Adiciona um novo valor
                    updatedValores = [...currentValues.valores, { colunasId: configuracao.id, valor: newValue }];
                }

                return { ...currentValues, valores: updatedValores };
            });
        } else {
            // Campos estáticos: Atualiza diretamente no objeto principal
            setValues(currentValues => ({
                ...currentValues,
                [e.target.name]: newValue
            }));
        }

        setValidator(current => ({ ...current, [configuracao?.nomeColuna || e.target.name]: "true" }));
    };

    const handleInputChange2 = (e, configuracao) => {
        const maskedValue = valorMask(e.target.value);

        setValues(currentValues => {
            const existingValueIndex = currentValues.valores.findIndex(item => item.colunasId === configuracao.id);
            let updatedValores;

            if (existingValueIndex !== -1) {
                updatedValores = [...currentValues.valores];
                updatedValores[existingValueIndex].valor = maskedValue;
            } else {
                updatedValores = [...currentValues.valores, { colunasId: configuracao.id, valor: maskedValue }];
            }

            return { ...currentValues, valores: updatedValores };
        });

        setValidator(current => ({ ...current, [configuracao.nomeColuna]: "true" }));
    };

    const renderInputField = (configuracao) => {
        const dynamicValue = values.valores.find(item => item.colunasId === configuracao.id)?.valor || '';

        switch (configuracao.tipoColuna) {
            case 1: // Texto
                return (
                    <input
                        type="text"
                        className="input-emb"
                        style={{ width: '270px' }}
                        onChange={(e) => handleInputChange(e, configuracao)}
                        placeholder={`Ex: ${configuracao.nomeColuna}`}
                        value={dynamicValue}
                    />
                );
            case 2: // Numérico
                return (
                    <input
                        type="number"
                        className="input-emb"
                        style={{ width: '270px' }}
                        onChange={(e) => handleInputChange2(e, configuracao)}
                        placeholder={`Digite um número para ${configuracao.nomeColuna}`}
                        value={valorMask(dynamicValue)}
                    />
                );
            case 3: // Verdadeiro ou Falso
                return (
                    <select
                        className="input-emb"
                        style={{ width: '270px' }}
                        onChange={(e) => handleInputChange(e, configuracao)}
                        value={dynamicValue}
                    >
                        <option value="">Selecione</option>
                        <option value={true}>Verdadeiro</option>
                        <option value={false}>Falso</option>
                    </select>
                );
            default:
                return null;
        }
    };

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosConfiguracoes, setDadosConfiguracoes] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const location = useLocation();

    const buscarDiarias = async () => {
        if (location.state?.myParam === null) {
            setLoading(true);
            await getDiariaTodos(dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
                setTabelaRedux({});
                setLoading(false);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setLoading(true);
            await getDiariaGrupoCliente(location.state?.myParam, dadosUsuarioRedux.token).then((result) => {
                setDadosTabela(result.data);
                setTabelaRedux({});
                setLoading(false);
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        }

    }

    const [listaStatus, setListaStatus] = useState([]);

    const buscarStatus = async () => {
        await getStatus(dadosUsuarioRedux.token).then((result) => {
            setListaStatus(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const buscarConfiguracaoEmpresa = async () => {
        setLoading(true);
        await getConfiguracaoGrupoCliente(location.state?.myParam, dadosUsuarioRedux.token).then((result) => {
            setDadosConfiguracoes(result.data.colunas);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.cte !== "") && (validator.cte === "true")) {
            setLoading(true);
            await cadastraDiaria(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarDiarias();
                    buscarConfiguracaoEmpresa();
                    setDadosRequestControllerRedux("Fechar Modal Diarias");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setValues({
                        cte: 0,
                        observacao: '',
                        valorPagoMotorista: 0,
                        tipoDiaria: 1,
                        grupoClienteId: 0,
                        valores: []
                    });
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaDiariaPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDiarias();
                buscarConfiguracaoEmpresa();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setLoading(false);
                setTelaCadastro(false); setEditando(false); setValues({
                    cte: 0,
                    observacao: '',
                    valorPagoMotorista: 0,
                    tipoDiaria: 1,
                    grupoClienteId: 0,
                    valores: []
                });
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherDadosEditando = async (result) => {
        let tipoDiaria;
        if (result.tipoDiaria === 1) {
            tipoDiaria = "Diária";
        } else if (tipoDiaria === 2) {
            tipoDiaria = "Devolução"
        }
        const tipoDiariaSelecionado = opcoes.find(option =>
            option.opcao === tipoDiaria
        );
        if (tipoDiariaSelecionado) {
            setValuesSelect(current => ({ ...current, tipoDiaria: tipoDiariaSelecionado }));
        }
        setValues({
            id: result.id,
            cte: result.embarque.cte,
            observacao: result.observacao,
            valorPagoMotorista: result.valorPagoMotorista,
            tipoDiaria: result.tipoDiaria,
            grupoClienteId: result.grupoClienteId,
            valores: result.valores
        });
    }

    const buscarDiariasPorId = async () => {
        setLoading(true);
        await getDiariaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    preencherDadosEditando(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarDiariasPorIdModal = async (id) => {
        setLoading(true);
        await getDiariaPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarDiariasPorId = async () => {
        setLoading(true);
        await deleteDiariaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDiarias();
                buscarConfiguracaoEmpresa();
                setTabelaRedux({});
                setOpenToastSucesso(true);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, grupoClienteId: location.state?.myParam }));
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, cte: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarDiariasPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarDiarias();
        buscarStatus();
        if (location.state?.myParam !== null) {
            buscarConfiguracaoEmpresa();
        }
        if (props.idAtual) {
            buscarDiariasPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Diárias') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Diárias') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });
    
    const colunaComValores = dadosTabela.find(dado => dado.valores && dado.valores.length > 0);

    const colunasDinamicas = colunaComValores 
        ? colunaComValores.valores.map(valor => `${valor.nome}`)
        : [];    

    const TableTh = styled.th`     
        padding: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        position: sticky; 
        top: 0px;
        background-color: ${dadosCoresRedux.tituloColuna};
        color: ${dadosCoresRedux.textoTituloColuna};
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        border: 1px solid #E7E7E7;
        cursor: pointer;
    `

    const formatBoolean = (value) => {
        if (value === "true") return "Sim";
        if (value === "false") return "Não";
        return value;
    };

    // Função para transformar tipoDiaria em "Diária" ou "Devolução"
    const formatTipoDiaria = (tipoDiaria) => {
        switch (tipoDiaria) {
            case 1:
                return "Diária";
            case 2:
                return "Devolução";
            default:
                return tipoDiaria;
        }
    };

    const [selecionado, setSelecionado] = useState("");

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };


    const [openFiltroDiaria, setOpenFiltroDiaria] = useState(false);

    const handleCloseFiltroDiaria = (value) => {
        setOpenFiltroDiaria(false);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Diarias" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Diarias" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Diárias</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', overflow: 'auto', marginTop: '20px' }}>
                                                    <table style={{ width: '90%', overflow: 'auto' }}>
                                                        <thead>
                                                            <tr>
                                                                <TableTh>Tipo Diária</TableTh>
                                                                <TableTh>CTE</TableTh>
                                                                <TableTh>Grupo Cliente</TableTh>
                                                                <TableTh>Placa</TableTh>
                                                                <TableTh>Motorista</TableTh>
                                                                <TableTh>Origem</TableTh>
                                                                <TableTh>Destino</TableTh>
                                                                <TableTh>Data / Hora Chegada</TableTh>
                                                                <TableTh>Data / Hora Saída</TableTh>
                                                                <TableTh>Observação</TableTh>
                                                                <TableTh>Valor Pago Motorista</TableTh>
                                                                {colunasDinamicas.map((coluna, index) => (
                                                                    <TableTh key={index}>{coluna}</TableTh>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dadosTabela.map((data, rowIndex) => (
                                                                <tr className={`${selecionado === data.id && "selecionado"} ${true && "tr-diaria"}`}
                                                                    onClick={() => {
                                                                        setTabelaRedux({ colunaSelecionada: data.id, usuarioAlteracao: data.usuario, dataAlteracao: data.data, row: data });
                                                                        setSelecionado(data.id);
                                                                    }}
                                                                    key={rowIndex}
                                                                    onDoubleClick={() => {
                                                                        setTabelaRedux({ colunaSelecionada: data.id, usuarioAlteracao: data.usuario, dataAlteracao: data.data, row: data });
                                                                        setSelecionado(data.id);
                                                                        setTelaCadastro(true);
                                                                        setEditando(true);
                                                                    }}>
                                                                    <td className="td-diaria">{formatTipoDiaria(data?.tipoDiaria)}</td>
                                                                    <td className="td-diaria">{data?.embarque?.cte}</td>
                                                                    <td className="td-diaria">{data?.grupoCliente?.nome}</td>
                                                                    <td className="td-diaria">
                                                                        {data?.embarque?.veiculoEmbarque?.caminhao?.placa}
                                                                        {data?.embarque?.veiculoEmbarque?.carreta?.placa ? `/${data?.embarque?.veiculoEmbarque?.carreta?.placa}` : ''}
                                                                        {data?.embarque?.veiculoEmbarque?.carreta?.placa && data?.embarque?.veiculoEmbarque?.carreta2?.placa ? `/${data?.embarque?.veiculoEmbarque?.carreta2?.placa}` : ''}
                                                                    </td>
                                                                    <td className="td-diaria">{data?.embarque?.motorista}</td>
                                                                    <td className="td-diaria">{data?.embarque?.pedidoEmbarque?.rota?.cidadeOrigemRota?.cidade} - {data?.embarque?.pedidoEmbarque?.rota?.cidadeOrigemRota?.estado}</td>
                                                                    <td className="td-diaria">{data?.embarque?.pedidoEmbarque?.rota?.cidadeDestinoRota?.cidade} - {data?.embarque?.pedidoEmbarque?.rota?.cidadeDestinoRota?.estado}</td>
                                                                    <td className="td-diaria">{formatarData(data?.embarque?.dataHoraEntregue)}</td>
                                                                    <td className="td-diaria">{formatarData(data?.embarque?.dataHoraEmbarcado)}</td>
                                                                    <td className="td-diaria">{data?.observacao}</td>
                                                                    <td className="td-diaria">{data?.valorPagoMotorista}</td>
                                                                    {data?.valores?.map((valor, colIndex) => (
                                                                        <td className="td-diaria" key={colIndex}>{formatBoolean(valor?.valor)}</td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {
                                                location.state?.myParam !== null && (
                                                    <div className="container-botoes-cliente">
                                                        <div style={{ display: 'flex' }}>
                                                            <CustomButtonV2
                                                                classCss="custom-button-editar"
                                                                classText="button-texto-editar"
                                                                width="200px"
                                                                titulo="Buscar Diárias"
                                                                cor="#CDCDCD"
                                                                valor={values}
                                                                onClick={() => {
                                                                    setOpenFiltroDiaria(true);
                                                                }}
                                                            />
                                                            <CustomButtonV2
                                                                classCss="custom-button-cadastrar"
                                                                classText="button-texto-cadastrar"
                                                                icone="iconeSave"
                                                                titulo="Cadastrar"
                                                                cor="#0946B8"
                                                                valor={values}
                                                                onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                            />
                                                            <CustomButtonV2
                                                                classCss="custom-button-editar"
                                                                classText="button-texto-editar"
                                                                titulo="Editar"
                                                                icone="iconeEdit"
                                                                cor="#CDCDCD"
                                                                valor={values}
                                                                onClick={() => {
                                                                    if (tabelaRedux.colunaSelecionada) {
                                                                        setTelaCadastro(true);
                                                                        setEditando(true);
                                                                    } else {
                                                                        setTextoErro("Selecione Uma Coluna");
                                                                        setOpenToastErro(true);
                                                                    }
                                                                }
                                                                }
                                                            />
                                                            <CustomButtonV2
                                                                classCss="custom-button-deletar"
                                                                classText="button-texto-cadastrar"
                                                                titulo="Deletar"
                                                                icone="iconeDelete"
                                                                cor="#DC2D2D"
                                                                valor={values}
                                                                onClick={() => {
                                                                    if (tabelaRedux.colunaSelecionada) {
                                                                        setOpen(true);
                                                                        setTextoSucesso("Deletado Com Sucesso");
                                                                    } else {
                                                                        setTextoErro("Selecione Uma Coluna");
                                                                        setOpenToastErro(true);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Diarias / "}</p>
                                                        <p className="indicador-emb">{"Cadastro de Diarias"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Diarias" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({
                                                                    cte: 0,
                                                                    observacao: '',
                                                                    valorPagoMotorista: 0,
                                                                    tipoDiaria: 1,
                                                                    grupoClienteId: 0,
                                                                    valores: []
                                                                }); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="configuracoes-grid">
                                                {dadosConfiguracoes.map(configuracao => (
                                                    <div key={configuracao.id} className="grid-item">
                                                        <p className="input-label-emb" htmlFor={configuracao.nomeColuna}>{configuracao.nomeColuna}:</p>
                                                        {renderInputField(configuracao)}
                                                    </div>
                                                ))}
                                                <div className="grid-item">
                                                    <p className="input-label-emb" htmlFor="descricao">CTE:</p>
                                                    <input
                                                        type="text"
                                                        maxLength={5}
                                                        className="input-emb"
                                                        style={{ width: '270px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, cte: e.target.value }));
                                                            setValidator(current => ({ ...current, cte: "true" }));
                                                        }}
                                                        value={values.cte}
                                                        disabled={props.desabilitar} />
                                                </div>
                                                <div className="grid-item">
                                                    <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                    <input
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '270px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, observacao: e.target.value }));
                                                            setValidator(current => ({ ...current, observacao: "true" }));
                                                        }}
                                                        value={values.observacao}
                                                        disabled={props.desabilitar} />
                                                </div>
                                                <div className="grid-item">
                                                    <p className="input-label-emb" htmlFor="descricao">Valor Pago Motorista:</p>
                                                    <input
                                                        type="number"
                                                        className="input-emb"
                                                        style={{ width: '270px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, valorPagoMotorista: e.target.value }));
                                                            setValidator(current => ({ ...current, valorPagoMotorista: "true" }));
                                                        }}
                                                        value={values.valorPagoMotorista}
                                                        disabled={props.desabilitar} />
                                                </div>
                                                <div className="grid-item-2">
                                                    <p className="input-label-emb" htmlFor="descricao">Tipo Diária:</p>
                                                    <FormControl sx={{ width: '270px' }}>
                                                        <Select
                                                            value={valuesSelect.tipoDiaria}
                                                            options={opcoesFrota}
                                                            formatOptionLabel={labelFrota}
                                                            styles={estiloFrota}
                                                            placeholder=""
                                                            noOptionsMessage={() => "Não há mais opções"}
                                                            onChange={(selectedOption) => {
                                                                if (selectedOption) {
                                                                    setValues(current => ({ ...current, tipoDiaria: selectedOption.valor }));
                                                                    setValuesSelect(current => ({ ...current, tipoDiaria: selectedOption }));
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="botoes-Diarias">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                editar();
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.cte !== "") && (validator.cte === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.cte !== "") && (validator.cte === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                setValues({
                                                                    cte: 0,
                                                                    observacao: '',
                                                                    valorPagoMotorista: 0,
                                                                    tipoDiaria: 1,
                                                                    grupoClienteId: 0,
                                                                    valores: []
                                                                }); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarDiariasPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalFiltroDiaria
                                        titulo="Diária"
                                        tituloConfirmar="OK"
                                        open={openFiltroDiaria}
                                        onClose={handleCloseFiltroDiaria}
                                        listaStatus={listaStatus}
                                        fecha={buscarDiarias}
                                        grupoClienteId={location.state?.myParam}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Diarias;