import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import "./AguardaCTE.css";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import { motion } from "framer-motion";
import TabelaRedux from "../../../contexts/Tabela.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import { getAguardaCTE, lancarCteStatus } from "../../../services/ModuloGerenciador/Embarque.js";
import ModalLancarCTEStatus from "../../../components/ModalLancarCTEStatus/ModalLancarCTEStatus.tsx";
import { getStatus } from "../../../services/ModuloGerenciador/Status.js";

function AguardaCTE() {

    const [values, setValues] = useState([]);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [openTelaCheia, setOpenTelaCheia] = useState(true);

    const [loading, setLoading] = useState(true);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosRelatorio, setDadosRelatorio] = useState([]);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const buscarAguardandoCte = async () => {
        try {
            const result = await getAguardaCTE(dadosUsuarioRedux.token);
            setDadosTabela(prevDadosTabela => {
                if (!prevDadosTabela || result.data.length > prevDadosTabela.length || result.data.length < prevDadosTabela.length) {
                    setLoading(true);
                    return result.data;
                }
                return prevDadosTabela;
            });
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            setTabelaRedux({});
        } catch (error) {
            if (error.response.data.length > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
        }
    };

    const buscarAguardandoCte2 = async () => {
        let dados = [];
        setLoading(true);
        await getAguardaCTE(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        buscarAguardandoCte();
        buscarStatus();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            buscarAguardandoCte();
            buscarStatus();
        }, 30000);

        buscarAguardandoCte();
        buscarStatus();

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Aguardando CTE') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Aguardando CTE') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (error.response.data.lenght > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    });

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const chavesFormatar = ["previsaoEmbarque", "dataEmbarque", "previsaoEntrega", "dataEntrega"];
    const listaFormatada = dadosTabela.map((obj) => {
        const novoObj = {};
        Object.keys(obj).forEach(key => {
            if (chavesFormatar.includes(key) && obj[key]) {
                novoObj[key] = obj[key].replace(/T00:00:00/, "");
            } else {
                novoObj[key] = obj[key];
            }
        });
        return novoObj;
    });

    const dadosRelatorioCSV = [];
    const formatarNumero = (numero) => {
        return numero.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    const dadosFormatados = {};

    for (const key in dadosRelatorio) {
        if (dadosRelatorio.hasOwnProperty(key)) {
            dadosFormatados[key] = formatarNumero(dadosRelatorio[key]);
        }
    }
    dadosRelatorioCSV.push(dadosFormatados);

    const [selecioneOpen, setSelecioneOpen] = useState(false);

    useEffect(() => {
        if (selecioneOpen === true) {
            setTimeout(() => {
                setSelecioneOpen(false);
            }, 1000);
        }
    }, [selecioneOpen]);

    const [openStatus, setOpenStatus] = useState(false);

    const handleCloseStatus = (value) => {
        setStatus(null);
        setOpenStatus(false);
    };

    const [embarqueId, setEmbarqueId] = useState();

    const [cte, setCTE] = useState();

    const definirStatus = async () => {
        const embarque = {
            "cte": cte,
            "embarqueId": embarqueId,
            "statusId": status,
        };
        await lancarCteStatus(embarque, dadosUsuarioRedux.token).then((result) => {
            handleCloseStatus();
            setTextoSucesso("CTE e Status Definido com Sucesso!");
            setOpenToastSucesso(true);
            buscarAguardandoCte();
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const [listaStatus, setListaStatus] = useState([]);

    const [status, setStatus] = useState();

    const buscarStatus = async () => {
        try {
            const result = await getStatus(dadosUsuarioRedux.token);
            setLoading(true);
            setListaStatus(prevListaStatus => {
                if (!prevListaStatus || result.data.length > prevListaStatus.length) {
                    return result.data;
                }
                return prevListaStatus;
            });
            setLoading(false);
            setTabelaRedux({});
        } catch (error) {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Aguardando CTE" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    {
                        openTelaCheia ? (
                            <NavbarV3 selecionado="Aguardando CTE" />
                        ) : (
                            <></>
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="aguardaCTEs">
                                    <div className="header-aguardaCTEs">
                                        <div className="flex2">
                                            <div className="flex3">
                                                <p className="indicador-principal-aguardaCTE-v2">Aguardando CTE</p>
                                                {
                                                    !openTelaCheia ? (
                                                        <p className="texto-tela-cheia">Modo Tela Cheia Ativo</p>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        openTelaCheia ? (
                                            <div className="filtro-embarque-v2">
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <TabelaV2 classeSelecionado="idEmbarque" nomeClasse1="outer-wrapper" nomeClasse2="table-wrapper-v2"
                                        colunas={dadosTabelaRedux.Embarque} dados={dadosTabela} nome="embarque" nomeTable="Embarque" />
                                    <div className="container-botoes-emb" style={{ justifyContent: 'center' }}>
                                        <div className="flex-row">
                                            <div className="hover-emb">
                                                {selecioneOpen &&
                                                    <div className="config-dropdown-none">
                                                        <p>Selecione Uma Coluna</p>
                                                    </div>
                                                }
                                                <CustomButtonV2
                                                    classCss="custom-button-user"
                                                    classText="button-texto-user"
                                                    titulo="Lançar CTE"
                                                    cor="#FFFFFF"
                                                    valor={values}
                                                    onClick={() => {
                                                        if (tabelaRedux.colunaSelecionada) {
                                                            setEmbarqueId(tabelaRedux.colunaSelecionada);
                                                            setOpenStatus(true);
                                                        } else {
                                                            setSelecioneOpen(!selecioneOpen);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                    <ModalLancarCTEStatus
                                        titulo="Lançar CTE e Status"
                                        tituloConfirmar="OK"
                                        open={openStatus}
                                        onClick={definirStatus}
                                        onClose={handleCloseStatus}
                                        setStatus={setStatus}
                                        setCTE={setCTE}
                                        listaStatus={listaStatus}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div >
    );
}

export default AguardaCTE;