import React, { useContext, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalEmbarques/ModalEmbarques.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import { cadastraStatusManual } from "../../services/ModuloGerenciador/Search";
import DadosUsuarioRedux from "../../contexts/Usuario";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import CloseIcon from "../CloseIcon/CloseIcon";
import { useNavigate } from "react-router-dom";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import SearchIcon from '@mui/icons-material/Search';

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaEmbarques: any[];
    onClose: () => void;
    onClick: () => void;
    buscarEmbarques: () => void;
    setPesquisa: (e) => void;
    pesquisa: string;
}

function ModalEmbarques(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        enviarStatusManual();
    };

    const enviarStatusManual = async () => {
        if (values.cidadeId && values.veiculoId && values.data && values.status) {
            await cadastraStatusManual(values, dadosUsuarioRedux.token).then(() => {
                handleClose();
            });
        }
    }

    const navigate = useNavigate();

    const data = tabelaRedux.dataAlteracao;
    const formatDate = (data) => {
        return new Date(data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
    };

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const navegarEdicaoEmbarque = (row) => {
        setDadosContextoEmbarqueRedux(row.contexto);
        navigate(`/embarque?id=${row.idEmbarque}`);
    };

    const formatarData = (data) => {
        if (data === '2000-01-01T00:00:00' || !data) return '';  // Não exibe se for a data inválida ou nula
        const dataObj = new Date(data);
        return new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(dataObj);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-embarques-disponiveis'>
                    <DialogTitle id="alert-dialog-title">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            marginTop: '20px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {props.titulo}
                            <CloseIcon onClick={(e) => {
                                handleClose();
                            }} />
                        </div>
                        <div style={{
                            position: 'relative', width: '100%', alignItems: 'center',
                            justifyContent: 'center', display: 'flex'
                        }}>
                            <input
                                type={'number'}
                                className='input-filtro-data-v2'
                                value={props.pesquisa}
                                placeholder='Informar Raio em KM'
                                style={{ width: 200 }}
                                onChange={(e) => {
                                    props.setPesquisa(e.target.value);
                                }}
                                id='embarque'>
                            </input>
                            {
                                <SearchIcon style={{
                                    position: 'absolute',
                                    left: 275,
                                    top: 5,
                                    cursor: 'pointer'
                                }}
                                    onClick={(e) => {
                                        props.buscarEmbarques();
                                    }}
                                />
                            }
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            marginTop: '5px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                           <p>Raio utilizado na busca de embarques próximos a distância informada</p>
                        </div>
                    </DialogTitle>
                    <div className="outer-wrapper-diaria-veiculo">
                        <div className="table-wrapper-diaria-veiculo" id="tableWrapper">
                            <table className="tabela-diaria-veiculo">
                                <thead>
                                    <tr>
                                        {dadosTabelaRedux.Embarque.filter(coluna => coluna.field !== 'descricao').map((coluna, index) => (
                                            <th key={index} className="th-diaria-veiculo">
                                                {coluna.nomeColuna}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.listaEmbarques.map((linha, index) => (
                                        <tr
                                            key={index}
                                            className="tr-hover"
                                            onDoubleClick={() => {
                                                if (window.location.href.endsWith("diaria-veiculos")) {
                                                    navegarEdicaoEmbarque(linha);
                                                }
                                            }}
                                        >
                                            {dadosTabelaRedux.Embarque.filter(coluna => coluna.field !== 'descricao').map((coluna, colIndex) => (
                                                <td key={colIndex} className="td-diaria-veiculo">
                                                    {coluna.field === 'empresa' && linha?.empresaEmbarque?.nome}
                                                    {coluna.field === 'filial' && linha?.filialEmbarque?.nome}
                                                    {coluna.field === 'rota' && (
                                                        <>
                                                            {linha.pedidoEmbarque.rota.cidadeOrigemRota.abreviacao} - {linha.pedidoEmbarque.rota.cidadeOrigemRota.estado}
                                                            <br />
                                                            {linha.pedidoEmbarque.rota.cidadeDestinoRota.abreviacao} - {linha.pedidoEmbarque.rota.cidadeDestinoRota.estado}
                                                        </>
                                                    )}
                                                    {coluna.field === 'pedido' && linha.pedidoEmbarque.pedido}
                                                    {['previsaoEmbarque', 'previsaoEntrega', 'dataHoraChegadaEmbarque', 'dataHoraEmbarcado', 'dataHoraChegadaEntrega', 'dataHoraEntregue'].includes(coluna.field) &&
                                                        formatarData(linha[coluna.field])
                                                    }
                                                    {coluna.field !== 'empresa' && coluna.field !== 'filial' && coluna.field !== 'rota' && coluna.field !== 'pedido' && !['previsaoEmbarque', 'previsaoEntrega', 'dataHoraChegadaEmbarque', 'dataHoraEmbarcado', 'dataHoraChegadaEntrega', 'dataHoraEntregue'].includes(coluna.field) &&
                                                        linha[coluna.field]
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalEmbarques;