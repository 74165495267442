import { api } from "./UrlPadrao"

export const cadastraConfiguracaoEmpresa = async (configuracaoDiaria, token) => {
  return api.post("ConfiguracaoDiaria",
    configuracaoDiaria
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getConfiguracaoGrupoCliente = async (id, token) => {
  return api.get("ConfiguracaoDiaria/GrupoCliente/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getConfiguracaoEmpresa = async (token) => {
  return api.get("ConfiguracaoDiaria",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getConfiguracaoEmpresaPorId = async (id, token) => {
  return api.get("ConfiguracaoDiaria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteConfiguracaoEmpresaPorId = async (id, token) => {
  return api.delete("ConfiguracaoDiaria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaConfiguracaoEmpresaPorId = async (configuracaoDiaria, token) => {
  return api.put("ConfiguracaoDiaria/" + configuracaoDiaria.id,
    configuracaoDiaria,
  {
    headers: {
      'Authorization': token
    }
  })
}