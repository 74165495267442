import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraSorteio = async (sorteio, token) => {
  return api.post("sorteio",
    sorteio
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const cadastraCupom = async (sorteio, token) => {
  return api.post("sorteio/cupom",
    sorteio
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getSorteio = async (token) => {
  return api.get("sorteio",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getSorteioPorId = async (id, token) => {
  return api.get("sorteio/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteSorteioPorId = async (id, token) => {
  return api.delete("sorteio/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaSorteioPorId = async (sorteio, token) => {
  return api.put("sorteio/" + sorteio.id,
    sorteio,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCuponsId = async (sorteio, token) => {
  return api.get("sorteio/" + sorteio + "/cupons",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getVencedores = async (sorteio, token) => {
  return api.get("sorteio/" + sorteio + "/premios/cupons",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const sortear = async (sorteioId, token) => {
  return api.post("sorteio/" + sorteioId + "/sortear",
    null
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const gerarCupons = async (sorteioId, token) => {
  return api.post("sorteio/" + sorteioId + "/gerar-cupons",
    null
    , {
      headers: {
        'Authorization': token
      }
    })
}