import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraEmbarque = async (unidadeEmbarque, token) => {
  return api.post("embarque",
    unidadeEmbarque
    , {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarque = async (token) => {
  return api.get("embarque",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarqueContexto = async (token, contexto) => {
  return api.get("embarque/contexto/" + contexto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getEmbarquePorId = async (id, token) => {
  return api.get("embarque/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const deleteEmbarquePorId = async (id, token) => {
  return api.delete("embarque/" + id,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const editaEmbarquePorId = async (unidadeEmbarque, token) => {
  return api.put("embarque/",
    unidadeEmbarque,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const cancelarEmbarque = async (objeto, token) => {
  return api.put("embarque/cancelar",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarCte = async (objeto, token) => {
  return api.put("embarque/lancarCte",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarVeiculo = async (objeto, token) => {
  return api.put("embarque/lancarVeiculo",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarStatus = async (objeto, token) => {
  return api.put("embarque/lancarStatus",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarCheckin = async (id, token) => {
  return api.put("embarque/checkInFeito/" + id,
    {},
    {
      headers: {
        'Authorization': token
      }
    })
}

export const comprovanteEntregue = async (id, token) => {
  return api.put("embarque/comprovanteEntregue/" + id,
    {},
    {
      headers: {
        'Authorization': token
      }
    })
}

export const definirMeiosPagamentos = async (objeto, token) => {
  return api.put("embarque/definirMeiosPagamentos",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarMercadoria = async (objeto, token) => {
  return api.put("embarque/definirMercadoria",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarEmbalagem = async (objeto, token) => {
  return api.put("embarque/definirEmbalagem",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getHistoricoEmbarque = async (embarque, token) => {
  return api.get("embarque/historicoEmbarque/" + embarque,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getHistoricoStatus = async (embarque, token) => {
  return api.get("embarque/historicoStatus/" + embarque,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getHistoricoPedido = async (embarque, token) => {
  return api.get("embarque/historicoPedido/" + embarque,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getHistoricoFreteFiscal = async (embarque, token) => {
  return api.get("embarque/historicoFreteFiscal/" + embarque,
    {
      headers: {
        'Authorization': token
      }
    })
}

export const getAguardaCTE = async (token) => {
  return api.get("embarque/aguardandoCte",
    {
      headers: {
        'Authorization': token
      }
    })
}

export const lancarCteStatus = async (objeto, token) => {
  return api.put("embarque/lancarCteStatus",
    objeto,
    {
      headers: {
        'Authorization': token
      }
    })
}