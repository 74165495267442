import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3.js";
import "./DiariaVeiculo.css";
import CoresRedux from "../../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../../contexts/Usuario.js";
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela.js";
import { loginUser, verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent.js";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao.js";
import ModalCadastro from "../../../../components/ModalCadastro/ModalCadastro.tsx";
import RequestControllerRedux from "../../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import { deleteStatusManual, getDiariaVeiculoSearch, getEmbarquesDisponiveis, ocultarVeiculo } from "../../../../services/ModuloGerenciador/Search.js";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2.js";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2.js";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon.js";
import SelectEmbarqueV2 from "../../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import Select from 'react-select';
import LoadingBar from "../../../../components/LoadingBar/LoadingBar.js";
import NewIcon from "../../../../components/NewIcon/NewIcon.js";
import { FormControl } from "@mui/material";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import FiltrosRedux from "../../../../contexts/Filtros.js";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral.js";
import ModalStatusManual from "../../../../components/ModalStatusManual/ModalStatusManual.tsx";
import { getVeiculo } from "../../../../services/ModuloGerenciador/Veiculo.js";
import { getCidadeEstado } from "../../../../services/ModuloGerenciador/CidadeEstado.js";
import { getStatus } from "../../../../services/ModuloGerenciador/Status.js";
import ModalEmbarques from "../../../../components/ModalEmbarques/ModalEmbarques.tsx";

function DiariaVeiculo(props) {

    const opcoes = [
        {
            'opcao': 'Cavalo',
            'valor': 1
        },
        {
            'opcao': 'Carreta',
            'valor': 2
        },
        {
            'opcao': 'Bitrem',
            'valor': 3
        },
        {
            'opcao': 'Tritrem',
            'valor': 4
        },
        {
            'opcao': 'Automovel',
            'valor': 5
        },
    ];

    const labelDiariaVeiculo = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesDiariaVeiculo = opcoes.map((data) => ({
        value: data.valor,
        label: data.opcao,
        ...data
    }));

    const formatarData = (data) => {
        const [ano, mes, dia] = data.split('-');
        return `${dia}/${mes}/${ano}`;
    };

    const estiloDiariaVeiculo = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria'
        },
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'motorista'
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
    ];


    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const [placaValida, setPlacaValida] = useState(true);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const converterData = (data) => {
        const parts = data.split('-');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };

    const buscarSearchInicial = async () => {
        setLoading(true);
        let pesquisaFormatada;
        if (valuesPesquisa.lenght > 0) {
            pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        } else {
            pesquisaFormatada = `undefined=`;
        }
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }

        const date = new Date();
        var primeiroDiaDisp = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        let data1Disp = converterData(primeiroDiaDisp);
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 8).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        let data1 = converterData(primeiroDia);
        var ultimoDia = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 8).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
        let data2 = converterData(ultimoDia);

        await getDiariaVeiculoSearch(dadosUsuarioRedux.token, 'todos', data1, data2, data1Disp, data2, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.length > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const ocultarVeiculoManual = async () => {
        if (idVeiculo !== null) {
            setLoading(true);
            await ocultarVeiculo(idVeiculo, dadosUsuarioRedux.token).then((result) => {
                setLoading(false);
                buscarSearch();
            }).catch((error) => {
                if (error.response.data.length > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    const deletarVeiculo = async () => {
        if (idManual !== null) {
            setLoading(true);
            await deleteStatusManual(idManual, dadosUsuarioRedux.token).then((result) => {
                setLoading(false);
                buscarSearch();
            }).catch((error) => {
                if (error.response.data.length > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    }

    const [listaVeiculos, setListaVeiculos] = useState([]);

    const [listaCidade, setListaCidade] = useState([]);

    const [listaStatus, setListaStatus] = useState([]);

    const [listaEmbarques, setListaEmbarques] = useState([]);

    const buscarEmbarques = async () => {
        await getEmbarquesDisponiveis(dadosUsuarioRedux.token, valuesPesquisa.contexto, dadosTabela.colunas[colIndex], cidadeId, pesquisa).then((result) => {
            setListaEmbarques(result.data);
            setOpenEmbarques(true);
        })
    }

    const buscarVeiculo = async () => {
        await getVeiculo(dadosUsuarioRedux.token).then((result) => {
            setListaVeiculos(result.data);
        })
    }

    const buscarStatus = async () => {
        await getStatus(dadosUsuarioRedux.token).then((result) => {
            setListaStatus(result.data);
        })
    }

    const buscarCidadeEstado = async () => {
        await getCidadeEstado(dadosUsuarioRedux.token).then((result) => {
            setListaCidade(result.data);
        });
    }

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getDiariaVeiculoSearch(dadosUsuarioRedux.token, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal,
            valuesPesquisa.embarqueInicioDisp, valuesPesquisa.embarqueFinalDisp, pesquisaFormatada).then((result) => {
                setDadosTabela(result.data);
                setLoading(false);
            }).catch((error) => {
                if (error.response.data.lenght > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [valuesSelect, setValuesSelect] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaProprietario, setListaProprietarios] = useState([]);

    const [listaMotoristas, setListaMotoristas] = useState([]);

    const [listaCaminhoes, setListaCaminhoes] = useState([]);

    const [listaCarretas, setListaCarretas] = useState([]);

    const [listaCarrocerias, setListaCarrocerias] = useState([]);

    const [idAtual, setIdAtual] = useState();

    const [disable, setDisable] = useState(false);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openStatusManual, setOpenStatusManual] = useState(false);

    const handleCloseStatusManual = (value) => {
        setOpenStatusManual(false);
        buscarSearch();
    };

    const [openEmbarques, setOpenEmbarques] = useState(false);

    const handleCloseEmbarques = (value) => {
        setOpenEmbarques(false);
    };

    const navigate = useNavigate();

    const preencherDadosEditando = async (result) => {
        const tipoDiariaVeiculoSelecionado = opcoes.find(opcao => opcao.valor === result.data.tipoDiariaVeiculo);
        if (tipoDiariaVeiculoSelecionado) {
            setValuesSelect(current => ({ ...current, tipoDiariaVeiculo: tipoDiariaVeiculoSelecionado }));
        }
        const placaCavaloSelecionado = listaCaminhoes.find(option => option.placa === result.data.caminhao.placa);
        if (placaCavaloSelecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCavalo: {
                    value: placaCavaloSelecionado.placa,
                    label: placaCavaloSelecionado.placa,
                    id: placaCavaloSelecionado.idCaminhao
                }
            }));
            setValues(current => ({ ...current, CaminhaoId: placaCavaloSelecionado.idCaminhao }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCavalo: {
                    value: result.data.caminhao.placa,
                    label: result.data.caminhao.placa,
                    id: null
                }
            }));
        }
        const placaCarretaSelecionado = listaCarretas.find(option => option.placa === result.data.carreta?.placa);
        if (placaCarretaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCarreta: {
                    value: placaCarretaSelecionado.placa,
                    label: placaCarretaSelecionado.placa,
                    id: placaCarretaSelecionado.idCarreta
                }
            }));
            setValues(current => ({ ...current, placaCarretaId: placaCarretaSelecionado.idCarreta }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCarreta: {
                    value: result.data.carreta?.placa,
                    label: result.data.carreta?.placa,
                    id: null
                }
            }));
        }
        const placaCarreta2Selecionado = listaCarretas.find(option => option.placa === result.data.carreta2?.placa);
        if (placaCarreta2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                placaCarreta2: {
                    value: placaCarreta2Selecionado.placa,
                    label: placaCarreta2Selecionado.placa,
                    id: placaCarreta2Selecionado.idCarreta
                }
            }));
            setValues(current => ({ ...current, carreta2Id: placaCarreta2Selecionado.idCarreta }));
        } else {
            setValuesSelect(current => ({
                ...current,
                placaCarreta2: {
                    value: result.data.carreta2?.placa,
                    label: result.data.carreta2?.placa,
                    id: null
                }
            }));
        }
        const proprietarioSelecionado = listaProprietario.find(option => option.nome === result.data.proprietarioDiariaVeiculo?.nome);
        if (proprietarioSelecionado) {
            setValuesSelect(current => ({
                ...current,
                proprietario: {
                    value: proprietarioSelecionado.nome,
                    label: proprietarioSelecionado.nome,
                    id: proprietarioSelecionado.idProprietario
                }
            }));
            setValues(current => ({ ...current, proprietarioId: proprietarioSelecionado.idProprietario }));
        }
        const tipoCarroceriaSelecionado = listaCarrocerias.find(option => option.nome === result.data.tipoCarroceria?.nome);
        if (tipoCarroceriaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoCarroceria: {
                    value: tipoCarroceriaSelecionado.nome,
                    label: tipoCarroceriaSelecionado.nome,
                    id: tipoCarroceriaSelecionado.id
                }
            }));
            setValues(current => ({ ...current, tipoCarroceriaId: tipoCarroceriaSelecionado.id }));
        }
        const motoristaSelecionado = listaMotoristas.find(option => option.idMotorista === result.data?.motoristaId);
        if (motoristaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motorista: {
                    value: motoristaSelecionado.nome,
                    label: motoristaSelecionado.nome,
                    id: motoristaSelecionado.idMotorista
                }
            }));
            setValues(current => ({ ...current, motoristaId: motoristaSelecionado.idMotorista }));
        }
        const motorista2Selecionado = listaMotoristas.find(option => option.idMotorista === result.data?.motorista2Id);
        if (motorista2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                motorista2: {
                    value: motorista2Selecionado.nome,
                    label: motorista2Selecionado.nome,
                    id: motorista2Selecionado.idMotorista
                }
            }));
            setValues(current => ({ ...current, motorista2Id: motorista2Selecionado.idMotorista }));
        }
    };

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValues(current => ({ ...current, motorista3: 'N/D' }));
        setValues(current => ({ ...current, motorista4: 'N/D' }));
        setValues(current => ({ ...current, motorista5: 'N/D' }));
        setValues(current => ({ ...current, motorista6: 'N/D' }));
        setValues(current => ({ ...current, motorista7: 'N/D' }));
        setValues(current => ({ ...current, motorista8: 'N/D' }));
        setValues(current => ({ ...current, motorista9: 'N/D' }));
        setValues(current => ({ ...current, motorista10: 'N/D' }));
        setValues(current => ({ ...current, motorista11: 'N/D' }));
        setValues(current => ({ ...current, motorista12: 'N/D' }));
        setValidator(current => ({ ...current, placaCavalo: "false" }));
        setValidator(current => ({ ...current, proprietario: "false" }));
        setValidator(current => ({ ...current, motorista: "false" }));
        setValidator(current => ({ ...current, tipoCarroceria: "false" }));
        setValidator(current => ({ ...current, tipoDiariaVeiculo: "false" }));
    }, [loading]);

    useEffect(() => {
        const fetchData = async () => {
            await buscarSearchInicial();
            await buscarVeiculo();
            await buscarStatus();
            await buscarCidadeEstado();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (valuesPesquisa.contexto != undefined && valuesPesquisa.pesquisa !== "") {
            buscarSearch();
        }
    }, [valuesPesquisa.contexto]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Diária Veículo') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Diária Veículo') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const clearPlacaCavalo = () => {
        setValuesSelect(current => ({ ...current, placaCavalo: '' }));
        setValues(current => ({ ...current, CaminhaoId: '' }));
        setValues(current => ({ ...current, placaCavalo: '' }));
        setValidator(current => ({ ...current, placaCavalo: "true" }));
    };

    const clearPlacaCarreta = () => {
        setValuesSelect(current => ({ ...current, placaCarreta: '' }));
        setValues(current => ({ ...current, placaCarreta: '' }));
        setValues(current => ({ ...current, carretaId: null }));
        setValidator(current => ({ ...current, placaCarreta: "false" }));
    };

    const clearPlacaCarreta2 = () => {
        setValuesSelect(current => ({ ...current, placaCarreta2: '' }));
        setValues(current => ({ ...current, placaCarreta2: '' }));
        setValues(current => ({ ...current, carreta2Id: null }));
        setValidator(current => ({ ...current, placaCarreta2: "false" }));
    };

    const clearTipoCarroceria = () => {
        setValuesSelect(current => ({ ...current, tipoCarroceria: '' }));
        setValues(current => ({ ...current, tipoCarroceria: '' }));
        setValues(current => ({ ...current, tipoCarroceriaId: null }));
        setValidator(current => ({ ...current, tipoCarroceria: "false" }));
    };

    const clearProprietario = () => {
        setValuesSelect(current => ({ ...current, proprietario: '' }));
        setValues(current => ({ ...current, proprietario: '' }));
        setValues(current => ({ ...current, proprietarioId: null }));
        setValidator(current => ({ ...current, proprietario: "false" }));
    };

    const clearMotorista = () => {
        setValuesSelect(current => ({ ...current, motorista: '' }));
        setValues(current => ({ ...current, motorista: '' }));
        setValues(current => ({ ...current, motoristaId: null }));
        setValidator(current => ({ ...current, motorista: "false" }));
    };

    const clearMotorista2 = () => {
        setValuesSelect(current => ({ ...current, motorista2: '' }));
        setValues(current => ({ ...current, motorista2Id: null }));
        setValues(current => ({ ...current, motorista2: '' }));
        setValidator(current => ({ ...current, motorista: "false" }));
    };

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    const [linhaSelecionada, setLinhaSelecionada] = useState(null);
    const [colunaSelecionada, setColunaSelecionada] = useState(null);
    const [idManual, setIdManual] = useState(null);
    const [idVeiculo, setIdVeiculo] = useState(null);

    const [cidadeId, setCidadeId] = useState(null);
    const [colIndex, setColIndex] = useState(null);
    const [pesquisa, setPesquisa] = useState('150');

    useEffect(() => {
        if (cidadeId !== null && colIndex !== null) {
            buscarEmbarques();
        }
    }, [cidadeId, colIndex])

    const handleTdClick = (linhaIndex, colunaIndex, coluna) => {
        setIdManual(coluna.veiculoStatusManualIds[colunaIndex]);
        setIdVeiculo(coluna.veiculo.idVeiculo);
        setLinhaSelecionada(linhaIndex);
        setColunaSelecionada(colunaIndex);
    };

    const buscarEmbarqueValido = (cidadeIds, currentIndex) => {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if (cidadeIds[i] !== 0) {
                return cidadeIds[i];
            }
        }
        for (let i = currentIndex + 1; i < cidadeIds.length; i++) {
            if (cidadeIds[i] !== 0) {
                return cidadeIds[i];
            }
        }
        return 0;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Agenda" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Agenda" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Agenda</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <div class="outer-wrapper-diaria-veiculo">
                                                <div class="table-wrapper-diaria-veiculo" id="tableWrapper">
                                                    <table className="tabela-diaria-veiculo">
                                                        <thead>
                                                            <tr>
                                                                <th className="th-diaria-veiculo">Placa Veículo</th>
                                                                {dadosTabela.colunas.map((coluna, index) => (
                                                                    <th key={index} className="th-diaria-veiculo">
                                                                        {formatarData(coluna)}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dadosTabela.veiculos.map((linha, index) => (
                                                                <tr key={index} className="tr-hover">
                                                                    <td className="td-diaria-veiculo">
                                                                        {linha.veiculo.caminhao.placa}
                                                                        <br />
                                                                        {linha.motoristaNome}
                                                                    </td>
                                                                    {dadosTabela.colunas.map((coluna, colIndex) => (
                                                                        <td
                                                                            key={colIndex}
                                                                            className={`td-diaria-veiculo ${linhaSelecionada === index && colunaSelecionada === colIndex ? 'linha-selecionada' : ''
                                                                                }`}
                                                                            onClick={() => handleTdClick(index, colIndex, linha)}
                                                                        >
                                                                            {linha.statusPorDia[colIndex] === 'disp' || linha.statusPorDia[colIndex].includes('disp') ? (
                                                                                <span
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        let cidadeId = linha.cidadeId[colIndex];
                                                                                        if (cidadeId === 0) {
                                                                                            cidadeId = buscarEmbarqueValido(linha.cidadeId, colIndex);
                                                                                        }
                                                                                        if (cidadeId !== 0) {
                                                                                            setCidadeId(cidadeId);
                                                                                            setColIndex(colIndex);
                                                                                        }
                                                                                    }}
                                                                                    style={{ textDecoration: 'underline', color: '#5353ef' }}
                                                                                >
                                                                                    {linha.statusPorDia[colIndex].split('-')[0].trim()}
                                                                                    <br />
                                                                                    {linha.statusPorDia[colIndex].split('-')[1]}
                                                                                </span>
                                                                            ) : (
                                                                                linha.statusPorDia[colIndex].split('-')[0].trim()
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        width="200px"
                                                        titulo="Adicionar Veículo"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => {
                                                            setOpenStatusManual(true);
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Remover Veículo"
                                                        width="190px"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (idManual) {
                                                                deletarVeiculo();
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Ocultar Veículo"
                                                        cor="#FFFFFF"
                                                        width="190px"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (idVeiculo) {
                                                                ocultarVeiculoManual();
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de DiariaVeiculo / "}</p>
                                                        <p className="indicador-emb">{"Cadastro de DiariaVeiculo"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Conjunto" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({});
                                                                setValuesSelect({});
                                                                setTelaCadastro(false);
                                                                setEditando(false);
                                                                setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="botoes-diariaVeiculo">
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalStatusManual
                                        titulo="Adicionar Veículo"
                                        tituloConfirmar="OK"
                                        open={openStatusManual}
                                        listaVeiculos={listaVeiculos}
                                        listaCidade={listaCidade}
                                        listaStatus={listaStatus}
                                        onClose={handleCloseStatusManual}
                                    />
                                    <ModalEmbarques
                                        titulo="Viagens Disponiveis"
                                        tituloConfirmar="OK"
                                        open={openEmbarques}
                                        listaEmbarques={listaEmbarques}
                                        onClose={handleCloseEmbarques}
                                        buscarEmbarques={buscarEmbarques}
                                        setPesquisa={setPesquisa}
                                        pesquisa={pesquisa}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default DiariaVeiculo;