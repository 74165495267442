import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from "react-slick";
import "./CarroselPainel.css";
import Tooltip from '@mui/material/Tooltip';

const CarroselPainel = ({ avisos }) => {
  let slidesToShow = avisos.length < 3 ? avisos.length : 3;
  const settings = {
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
  };

  return (
    <div className="carrossel-vertical-painel">
      {avisos.map((aviso, index) => (
        <div key={index} className="painel-card">
          <p>{aviso.texto}</p>
          <div className="bolinhas">
            <Tooltip title={<p>{aviso.isPrevisaoEmbarque ? "Viagem já possui previsão" : "Viagem não possui previsão"}</p>}>
              <div className={aviso.isPrevisaoEmbarque ? "bolinha-verde" : "bolinha-vermelha"}></div>
            </Tooltip>
            <Tooltip title={<p>{aviso.isPrevisaoEmbarque ? "Viagem já possui placa cadastrada" : "Viagem não possui placa cadastrada"}</p>}>
              <div className={aviso.isPlaca ? "bolinha-verde" : "bolinha-vermelha"}></div>
            </Tooltip>
            <Tooltip title={<p>{aviso.isPrevisaoEmbarque ? "Viagem já possui CTE" : "Viagem não possui CTE"}</p>}>
              <div className={aviso.isCTE ? "bolinha-verde" : "bolinha-vermelha"}></div>
            </Tooltip>
            <Tooltip title={<p>{aviso.isPrevisaoEmbarque ? "Viagem em Viagem" : "Viagem aguardando viagem"}</p>}>
              <div className={aviso.isEmViagem ? "bolinha-verde" : "bolinha-vermelha"}></div>
            </Tooltip>
            <Tooltip title={<p>{aviso.isPrevisaoEmbarque ? "Entrega Concluída" : "Aguardando Entrega"}</p>}>
              <div className={aviso.isEntregue ? "bolinha-verde" : "bolinha-vermelha"}></div>
            </Tooltip>
            <b>{new Date(aviso.data).toLocaleDateString('pt-BR', { month: "numeric", day: "numeric", hour: 'numeric', minute: 'numeric' })}</b>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CarroselPainel;
