import React, { useContext, useEffect, useState } from "react";
import "./TabelaOcorrencias.css";
import iconDeleteLow from "../../images/iconDeleteLow.png";
import iconOpcoesLow from "../../images/iconOpcoesLow.png";
import iconeCalendario from "../../images/iconeCalendario.png";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

function TabelaOcorrencias(props) {
    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const [selecioneOpen, setSelecioneOpen] = useState(false);
    const [opcoesOpenIndex, setOpcoesOpenIndex] = useState(null);

    const handleMouseLeave = (index) => {
        setOpcoesOpenIndex(null);
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    const colunasFiltradas = props.colunas.filter(coluna =>
        ["dataOcorrencia", "observacao", "ocorrencia", "subTipoOcorrencia", "tipoOcorrencia"].includes(coluna.field)
    );

    useEffect(() => {
        if (props.dados && props.dados.length > 0 && props.dados[0] && props.dados[0].idEmbarque) {
            const tableWrapper = document.getElementById("tableWrapper");
            if (tableWrapper && (window.location.href.endsWith("embarque") && dadosContextoEmbarqueRedux != 'templateEmbarque')) {
                tableWrapper.scrollTop = tableWrapper.scrollHeight;
            }
        }
    }, []);

    return (
        <div class="outer-wrapper-ocorrencias">
            <div class="table-wrapper-ocorrencias" id="tableWrapper">
                <p style={{ textAlign: 'center', marginBottom: 10 }}>Ocorrências</p>
                <table className="tabela-ocorrencias">
                    <thead>
                        <tr>
                            {colunasFiltradas.map((coluna, index) => (
                                <th className="th-ocorrencias" key={index}>{coluna.nomeColuna}</th>
                            ))}
                            <th className="th-ocorrencias">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.dados ? props.dados.map((linha, index) => (
                            <>
                                <tr onDoubleClick={() => {
                                    // setTabelaRedux({ colunaSelecionada: linha.idEmbarque, usuarioAlteracao: linha.usuario, dataAlteracao: linha.data, row: linha });
                                    // props.setTelaCadastro(true);
                                    // props.setEditando(true);
                                }}
                                    className="tr-hover"
                                    key={index}> {/* Linha de dados com hover */}
                                    {colunasFiltradas.map((coluna) => (
                                        <td className="td-ocorrencias" key={coluna.field}>
                                            {coluna.field === "dataOcorrencia"
                                                ? formatarData(linha[coluna.field])
                                                : typeof linha[coluna.field] === "object" && linha[coluna.field] !== null
                                                    ? (linha[coluna.field].nome || linha[coluna.field].tipo || '')
                                                    : linha[coluna.field]
                                            }
                                        </td>
                                    ))}

                                    <td className="td-ocorrencias">
                                        <div style={{ gap: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Tooltip title="Deletar">
                                                <img className="pointer" src={iconDeleteLow} alt="Delete"
                                                    onClick={() => {
                                                        props.setDeletarOcorrencia(linha);
                                                    }} />
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <EditIcon className="pointer" style={{ width: '30px', height: '30px', backgroundColor: '#dfdfdf', borderRadius: 20, padding: 6 }}
                                                    onClick={(e) => {
                                                        props.setEditarOcorrencia(linha);
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr >
                            </>
                        )) : null}
                    </tbody>

                </table>
            </div>
        </div >
    );
}

export default TabelaOcorrencias;