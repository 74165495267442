import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalHistoricoStatus/ModalHistoricoStatus.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import TabelaV2 from "../TabelaV2/TabelaV2";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    onClose: () => void;
    onClick: () => void;
    valoresHistorico: any;
    colunas: any;
}

function ModalHistoricoStatus(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClose();
    };

    const data = tabelaRedux.dataAlteracao;
    const formatDate = (data) => {
        return new Date(data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TabelaV2
                                classeSelecionado="data"
                                nomeClasse1="outer-wrapper"
                                nomeClasse2="table-wrapper-v2"
                                colunas={props.colunas}
                                dados={props.valoresHistorico}
                                nome="status" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalHistoricoStatus;