import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import { useLocation, useNavigate } from 'react-router-dom';
import TabelaRedux from "../../../../contexts/Tabela";
import { cadastraProprietario, getProprietario, getProprietarioPorId, deleteProprietarioPorId, editaProprietarioPorId } from "../../../../services/ModuloGerenciador/Proprietario";
import { getUserClientes, verificaDadosUsuarioRedux, vincularUsuario } from "../../../../services/ModuloGerenciador/Usuario";
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import InputLabel from "@mui/material/InputLabel";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { getCidadeEstadoComSigla, getCidadeEstadoPorId } from "../../../../services/ModuloGerenciador/CidadeEstado";
import "./Proprietario.css";
import { cnpjMask } from '../../../../regex/cnpjMask'
import Text from "../../../../components/Text/Text";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { ProprietarioModel } from "../../../../models/ProprietarioModel";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { motion } from "framer-motion";
import { cpfMask } from "../../../../regex/cpfMask";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2";
import { getProprietarioSearch } from "../../../../services/ModuloGerenciador/Search";
import { gerarUsuarioMotorista } from "../../../../services/ModuloGerenciador/Motorista";
import ModalUsuarioMotorista from "../../../../components/ModalUsuarioMotorista/ModalUsuarioMotorista.tsx";
import ModalConfirmacao2 from "../../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import ModalVincular from "../../../../components/ModalVincular/ModalVincular.tsx";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import FiltrosRedux from "../../../../contexts/Filtros.js";

function Proprietario(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState(new ProprietarioModel({}));

    const [valuesUsuario, setValuesUsuario] = useState([]);

    const [dadosUsuarioMotorista, setDadosUsuarioMotorista] = useState([]);

    const [validator, setValidator] = useState([]);

    const [listaCidades, setListaCidades] = useState([]);

    const [cidadeCompleta, setCidadeCompleta] = useState([]);

    const [valuesFiltro, setValuesFiltro] = useState({});

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const navigate = useNavigate();

    const [openUsuario, setOpenUsuario] = useState(false);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [cnpjMascara, setCnpjMascara] = useState("");

    const [cidadeObjeto, setCidadeObjeto] = useState([]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openUsuarioMotorista, setOpenUsuarioMotorista] = useState(false);

    const handleCloseUsuarioMotorista = (value) => {
        setOpenUsuarioMotorista(false);
        setOpenConfirmar(false);
        buscarEmpresa();
    };

    const abrirConfirmar = (value) => {
        setOpenConfirmar(true);
    };

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmar = (value) => {
        setOpenConfirmar(false);
    };

    const clickGerar = () => {
        gerarUsuario();
    };

    const [listaUsuarios, setListaUsuario] = useState([]);

    const [usuarioId, setUsuarioId] = useState();

    const [nomeUsuario, setNomeUsuario] = useState();

    const [openGerar, setOpenGerar] = useState(false);

    const handleCloseGerar = (value) => {
        setOpenGerar(false);
    };

    const buscarCidadeEstado = async () => {
        await getCidadeEstadoComSigla(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEmpresa = async () => {
        setLoading(true);
        await getProprietario(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEmpresaPorID = async () => {
        await getProprietarioPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    setCidadeObjeto(result.data.cidadeEstado);
                    setCnpjMascara(cnpjMask(result.data.cnpj))
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const buscarEmpresaPorIDModal = async (id) => {
        await getProprietarioPorId(id, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setValues(result.data);
                setCidadeObjeto(result.data.cidadeEstado);
                setCnpjMascara(cnpjMask(result.data.cnpj))
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const buscarUsuariosClientes = async () => {
        await getUserClientes(dadosUsuarioRedux.token).then((result) => {
            setListaUsuario(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const gerarUsuario = async () => {
        const usuarioVinculo = {
            "proprietarioId": tabelaRedux.colunaSelecionada,
            "usuarioClientesId": usuarioId
        }
        await vincularUsuario(usuarioVinculo, dadosUsuarioRedux.token).then((result) => {
            setOpenGerar(false);
            buscarEmpresa();
            setTextoSucesso("Vinculado Com Sucesso");
            setOpenToastSucesso(true);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const cadastrar = async () => {
        if ((values.nome !== "" || values.cnpj !== "") && (validator.cnpj === "true" && validator.nome === "true")) {
            await cadastraProprietario(values, dadosUsuarioRedux.token).then((result) => {
                setLoading(true);
                if (result.status === 200) {
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarEmpresa();
                    } else {
                        buscarSearch();
                    }
                    setTelaCadastro(false);
                    setValues({});
                    setCnpjMascara('');
                    setDadosRequestControllerRedux("Fechar Modal Proprietario");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error?.response?.data === 'string') {
                    setTextoErro(error.response.data);
                } else if (typeof error?.response?.data?.data === 'string') {
                    setTextoErro(error.response.data.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaProprietarioPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (valuesPesquisa.pesquisa === undefined) {
                    buscarEmpresa();
                } else {
                    buscarSearch();
                }
                setTabelaRedux({});
                setTextoSucesso("Editado Com Sucesso");
                setOpenToastSucesso(true);
                setEditando(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const deletarEmpresa = async () => {
        setLoading(true)
        await deleteProprietarioPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarEmpresa();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCidadeEstadoPorId = async () => {
        await getCidadeEstadoPorId(values.cidadeEstadoId).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(current => ({ ...current, cidadeOBJ: result.data }))
                }
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, cnpj: "false" }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    const handleChangeCnpj = (event) => {
        setValues(current => ({ ...current, cnpj: event.target.value }));
        setCnpjMascara(cnpjMask(event.target.value));
    }

    useEffect(() => {
        if (editando) {
            buscarEmpresaPorID();
        }
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [editando]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [telaCadastro]);

    useEffect(() => {
        if (props.idAtual) {
            buscarEmpresaPorIDModal(props.idAtual);
        }
        buscarEmpresa();
        buscarUsuariosClientes();
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoGerarLogin, setSemAutorizacaoGerarLogin] = useState(true);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === 'Uso Comum') {
                    setSemAutorizacao(false);
                }
                if (dado.regras.regra === 'Gerar Login') {
                    setSemAutorizacaoGerarLogin(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }, []);

    const itensFiltro = [
        {
            'nomeColuna': 'Nome',
            'field': 'nome'
        },
        {
            'nomeColuna': 'CNPJ',
            'field': 'cnpj'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getProprietarioSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Proprietário" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Proprietário" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Proprietários</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idProprietario"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Proprietario}
                                                dados={dadosTabela}
                                                nome="veiculo" />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {semAutorizacaoGerarLogin ? (
                                                    <></>
                                                ) : (
                                                    <div>
                                                        <CustomButtonV2
                                                            classCss="custom-button-vincular"
                                                            classText="button-texto-user"
                                                            titulo="Vincular Usuário"
                                                            cor="#FFFFFF"
                                                            valor={values}
                                                            onClick={() => {
                                                                if (tabelaRedux.colunaSelecionada) {
                                                                    setOpenGerar(true);
                                                                } else {
                                                                    setTextoErro("Selecione Uma Coluna");
                                                                    setOpenToastErro(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Cadastro de Proprietário / "}</p>
                                                        <p className="indicador-emb">{"Dados Proprietário"}</p>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Cadastro de Proprietário" />
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setCnpjMascara('');
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    id="nome"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Ex: João"
                                                                    maxLength={24}
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">CPF/CNPJ:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        let valorMascara = e.target.value;
                                                                        if (e.target.value.length <= 14) {
                                                                            valorMascara = cpfMask(e.target.value);
                                                                        } else if (e.target.value.length > 13) {
                                                                            valorMascara = cnpjMask(e.target.value);
                                                                        }
                                                                        setValues(current => ({ ...current, cnpj: valorMascara }));
                                                                        setValidator(current => ({ ...current, cnpj: "true" }));
                                                                    }}
                                                                    placeholder="Ex: 00.000.000/0000-00"
                                                                    value={values.cnpj}
                                                                    disabled={props.desabilitar || editando}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setValues({}); setCnpjMascara(''); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.cnpj !== "")
                                                                                    && (validator.nome === "true" && validator.cnpj === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.cnpj !== "")
                                                                                    && (validator.nome === "true" && validator.cnpj === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setTelaCadastro(false); setEditando(false); setCnpjMascara(''); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarEmpresa}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalUsuarioMotorista
                                        titulo="Login Motorista"
                                        tituloConfirmar="OK"
                                        open={openUsuarioMotorista}
                                        onClose={abrirConfirmar}
                                        dados={dadosUsuarioMotorista}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Fechar? "
                                        texto="Ao fechar esse modal não será mais possivel visalizar a senha."
                                        textoBotao="Sim"
                                        open={openConfirmar}
                                        onClose={handleCloseConfirmar}
                                        onClick={handleCloseUsuarioMotorista}
                                    />
                                    <ModalVincular
                                        titulo="Vincular Usuário"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={openGerar}
                                        onClose={handleCloseGerar}
                                        onClick={clickGerar}
                                        listaUsuarios={listaUsuarios}
                                        setUsuarioId={setUsuarioId}
                                        setNomeUsuario={setNomeUsuario}
                                        referenciaId="nome"
                                        referenciaItem="nome"
                                        id="id"
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Proprietario;