import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import "./RelatorioProprietarios.css";
import CoresRedux from "../../../../contexts/Cor";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import { editaMeta, getMetaContexto, getRelatorio } from "../../../../services/ModuloGerenciador/Relatorio";
import TabelaRedux from "../../../../contexts/Tabela";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import Tabela from "../../../../components/Tabela/Tabela";
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon2 from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilialCidade from "../../../../contexts/CidadesFilial";
import Filtro from "../../../../components/Filtro/Filtro";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import iconeTelaCheia from "../../../../images/iconeTelaCheia.png";
import Charts from "../../../../components/Charts/Charts.tsx";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import { Button, Dialog, DialogTitle } from "@mui/material";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import { useLocation } from "react-router-dom";
import FiltrosRedux from "../../../../contexts/Filtros.js";
import { getRelatorioProprietarios } from "../../../../services/ModuloGerenciador/Proprietario.js";
import TabelaEstatisticas from "../../../../components/TabelaEstatisticas/TabelaEstatisticas.js";

function RelatorioProprietarios() {

    const [values, setValues] = useState([]);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const [openRelatorioProprietarioss, setOpenRelatorioProprietarioss] = useState(true);

    const [openProjecao, setOpenProjecao] = useState(false);

    const [openTelaCheia, setOpenTelaCheia] = useState(true);

    const [loading, setLoading] = useState(true);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosRelatorio, setDadosRelatorio] = useState([]);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const [dadosGrafico, setDadosGrafico] = useState([]);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const buscarDependenciasData = async (contexto, dataInicio, dataFim) => {
        setLoading(true);
        let dados = [];
        await getRelatorio(dadosUsuarioRedux.token, contexto, dataInicio, dataFim).then((result) => {
            setDadosTabela(result.data.embarques);
            setDadosGrafico(result.data.projecaoRelatorioProprietarios);
            dados.push(result.data.paginaRelatorioValores);
            setDadosRelatorio(dados[0]);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    useEffect(() => {
        setValorInicial();
    }, []);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    const setValorInicial = () => {
        const date = new Date();
        const contexto = 'todos';
        const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var diaPrimeiro = String(primeiroDia?.getDate()).padStart(2, '0');
        var diaUltimo = String(ultimoDia?.getDate()).padStart(2, '0');
        var mesPriemiro = String(date?.getMonth() + 1).padStart(2, '0');
        var anoPrimeiro = date?.getFullYear();
        var dateInicio = anoPrimeiro + '-' + mesPriemiro + '-' + diaPrimeiro;
        var dateFinal = anoPrimeiro + '-' + mesPriemiro + '-' + diaUltimo;
        setValuesPesquisa(current => ({ ...current, contexto: contexto }));
        setValuesPesquisa(current => ({ ...current, embarqueInicio: dateInicio }));
        setValuesPesquisa(current => ({ ...current, embarqueFinal: dateFinal }));
        // buscarDependenciasDataInicial(contexto, dateInicio, dateFinal);
    };

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Estatísticas Terceiros') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Estatísticas Terceiros') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (error.response.data.lenght > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    });

    const itensFiltro = [
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Proprietário',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Média',
            'field': 'media'
        },
        {
            'nomeColuna': 'Total',
            'field': 'total'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setValorInicial();
        buscarSearch();
        setTimeout(() => {
            setLoading(false);
        }, 0);
    }

    const [mesSelecionado, setMesSelecionado] = useState('');
    
    useEffect(() => {
        buscarSearch();
    }, [mesSelecionado])

    const buscarSearch = async (ordenarPor) => {
        setLoading(true);
        let dados = [];
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getRelatorioProprietarios(dadosUsuarioRedux.token, valuesPesquisa.contexto, ordenarPor, pesquisaFormatada).then((result) => {
            const sortedDados = result.data.sort((a, b) => {
                const valorA = a.embarquesMes[mesSelecionado] || 0;
                const valorB = b.embarquesMes[mesSelecionado] || 0;
                return valorB - valorA;
            });
            setDadosTabela(sortedDados);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (valuesPesquisa.contexto != undefined) {
            buscarSearch();
        }
    }, [valuesPesquisa.contexto])

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoMetas, setSemAutorizacaoMetas] = useState(true);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="RelatorioProprietarios" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    {
                        openTelaCheia ? (
                            <NavbarV3 selecionado="RelatorioProprietarios" />
                        ) : (
                            <></>
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="faturamentos">
                                    <div className="header-faturamentos">
                                        <div className="flex2">
                                            <div className="flex3">
                                                <p className="indicador-principal-faturamento-v2">Estatísticas Terceiros</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filtro-embarque-v2">
                                        <FiltroV2
                                            classe='div-filtrar-faturamento-v2'
                                            classOption='select-options'
                                            itensFiltro={itensFiltro}
                                            valuesPesquisa={valuesPesquisa}
                                            setValuesPesquisa={setValuesPesquisa}
                                            buscarSearch={buscarSearch}
                                            limpar={limpar}
                                        />
                                    </div>
                                    <TabelaEstatisticas
                                        colunas={dadosTabelaRedux.RelatorioProprietarios}
                                        dados={dadosTabela}
                                        setMesSelecionado={setMesSelecionado}
                                        buscarSearch={buscarSearch} />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default RelatorioProprietarios;