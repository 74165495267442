import React, { useEffect, useContext, useState } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import CoresRedux from "../../../contexts/Cor";
import RequestControllerRedux from "../../../contexts/RequestController";
import { useNavigate } from "react-router-dom";
import './Home.css'
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import pdf from '../../../images/Politica_de_Privacidade_-_Gerenciado_de_Transportes.pdf';
import Button from '@mui/material/Button';
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ModalNotificacao from "../../../components/ModalNotificacao/ModalNotificacao.tsx";
import { getNotificacaoPorUsuario } from "../../../services/ModuloGerenciador/Notificacoes";
import { motion } from "framer-motion";
import VerticalCarousel from "../../../components/Carrosel/Carrosel";
import { getAvisosTelaInicial } from "../../../services/ModuloGerenciador/Avisos";

function Home() {

    const [openCookies, setOpenCookies] = useState(false);

    const handleCloseCookies = (value) => {
        setOpenCookies(false);
    };

    const { dadosCoresRedux, setDadosCoresRedux } = useContext(CoresRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const navigate = useNavigate();

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [textoErro, setTextoErro] = useState("");

    const [textoSucesso, setTextoSucesso] = useState("");

    const [notificacao, setNotificacao] = useState([]);

    const [avisos, setAvisos] = useState([]);

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openNotificacao, setOpenNotificacao] = useState(false);

    const handleCloseNotificacao = (value) => {
        setOpenNotificacao(false);
    };

    const buscarNotificacoes = async () => {
        await getNotificacaoPorUsuario(dadosUsuarioRedux.usuario.idUsuario, dadosUsuarioRedux.token).then((result) => {
            if (result.data !== "Nenhuma notificação encontrada.") {
                setNotificacao(result.data);
                localStorage.setItem("notificacaoId", result.data[0]?.notificacaoId);
                setTimeout(() => {
                    setOpenNotificacao(true);
                }, 2000)
            }
        });
    }

    const buscarAvisos = async () => {
        if (!window.location.href.endsWith("login")) {
            await getAvisosTelaInicial(dadosUsuarioRedux.token).then((result) => {
                setAvisos(result.data);
            });
        }
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        buscarAvisos();
        buscarNotificacoes();
    }, []);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra);
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            });
        }
    })

    useEffect(() => {
        if (localStorage.getItem("flagPassword")) {
            navigate("/editar-usuario");
        }
        // verifica se o usuário tem token!
        const user = localStorage.getItem("token");
        if (user === null) {
            navigate("/login");
        }

        // retorna mensagem de erro de sem acesso!
        const semAcesso = localStorage.getItem("semAcesso");
        if (semAcesso) {
            let semAcessoParsed = JSON.parse(semAcesso)
            if (semAcessoParsed) {
                setOpenToastErro(true);
                setTextoErro("Sem Acesso!")
            }
            localStorage.removeItem("semAcesso");
        }
    })

    useEffect(() => {
        if (!localStorage.getItem("Cookies")) {
            setTimeout(() => {
                setOpenCookies(false);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (dadosRequestControllerRedux === "Email Senha Codigo") {
            setDadosRequestControllerRedux("");
            setTextoSucesso("Senha alterada com sucesso!");
            setOpenToastSucesso(true);
        }
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fundo"
            style={{ backgroundColor: dadosCoresRedux.fundoPadrao }}
        >
            <NavbarV3 selecionado="Home" />
            <div className="divImagem">
                <img className="imagemHome" src={require('../../../assets/images/transscalcon.jpeg')} alt="logo"></img>
                <div className="card-carrosel">
                    <VerticalCarousel avisos={avisos} />
                </div>
            </div>
            <ToastErro
                texto={textoErro}
                open={openToastErro}
                onClose={handleCloseToastErro}
            />
            <ToastSucesso
                texto={textoSucesso}
                open={openToastSucesso}
                onClose={handleCloseToastSucesso}
            />
            <Dialog className="corpoModalCookies" open={openCookies}>
                <DialogTitle>Esse site usa cookies</DialogTitle>
                <p className='texto-cookies'>Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.</p>
                <div className="botaoConcluir">
                    <a className="texto-link" href={pdf} target="_blank">Política de Privacidade</a>
                    <Button
                        sx={{ width: 180 }}
                        variant="contained"
                        onClick={() => { handleCloseCookies(); localStorage.setItem("Cookies", "Aceito") }}>
                        Aceitar
                    </Button>
                </div>
            </Dialog>
            <ModalNotificacao
                titulo={notificacao}
                tituloConfirmar="OK"
                open={openNotificacao}
                onClose={handleCloseNotificacao}
            />
        </motion.div>
    );
}

export default Home;