import React, { useContext, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import '../ModalDadosDiaria/ModalDadosDiaria.css';
import { criarDiarias } from "../../services/ModuloGerenciador/Diaria";
import DadosUsuarioRedux from "../../contexts/Usuario";
import ToastSucesso from "../Toast/ToastSucesso/ToastSucesso.tsx";

export interface SimpleDialogProps {
    titulo: string;
    open: boolean;
    dados: any[]; // Assuming dados is an array of objects
    onClose: () => void;
    onSubmit?: (selectedIds: number[]) => void; // Callback to handle submission
    fecha: () => void;
}

function ModalDadosDiaria(props: SimpleDialogProps) {
    const [textoSucesso, setTextoSucesso] = useState("");
    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = () => {
        setOpenToastSucesso(false);
    };

    const { dadosCoresRedux } = useContext(CoresRedux);
    const { dadosUsuarioRedux } = useContext(DadosUsuarioRedux);
    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, dados, titulo, onSubmit } = props;

    const [selectedIds, setSelectedIds] = useState<number[]>([]); // State to track selected IDs
    const [searchTerm, setSearchTerm] = useState(''); // State para pesquisa por CTE
    const [selectAll, setSelectAll] = useState(false); // State para selecionar todos

    const handleClose = () => {
        props.fecha();
        onClose();
    };

    const handleCheckboxChange = (id: number) => {
        setSelectedIds(prevSelectedIds =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allIds = dados.map(data => data.embarqueId);
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(selectedIds);
        }
        onClose();
    };

    const formatTipoDiaria = (tipoDiaria) => {
        switch (tipoDiaria) {
            case 1:
                return "Diária";
            case 2:
                return "Devolução";
            default:
                return tipoDiaria;
        }
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };

    const formatBoolean = (value) => {
        if (value === "true") return "Sim";
        if (value === "false") return "Não";
        return value;
    };

    const buscarCriarDiarias = async () => {
        await criarDiarias(selectedIds, dadosUsuarioRedux.token).then((result) => {
            handleClose();
            setTextoSucesso(result.data.mensagem);
            setOpenToastSucesso(true);
        }).catch((error) => {

        });
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {titulo}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', overflow: 'auto', marginTop: '20px', height: '450px' }}>
                                    <table style={{ width: '90%', overflow: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th className="thzada">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                </th>
                                                <th className="thzada">Tipo Diária</th>
                                                <th className="thzada">CTE</th>
                                                <th className="thzada">Veículo</th>
                                                <th className="thzada">Motorista</th>
                                                <th className="thzada">Origem</th>
                                                <th className="thzada">Destino</th>
                                                <th className="thzada">Data Entregue</th>
                                                <th className="thzada">Data Embarcado</th>
                                                <th className="thzada">Observação</th>
                                                <th className="thzada">Valor Pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dados.map((data, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedIds.includes(data.embarqueId)}
                                                            onChange={() => handleCheckboxChange(data.embarqueId)}
                                                        />
                                                    </td>
                                                    <td>{formatTipoDiaria(data.tipoDiaria)}</td>
                                                    <td>{data.embarque?.cte}</td>
                                                    <td>
                                                        {data.embarque?.veiculoEmbarque?.caminhao?.placa}
                                                        {data.embarque?.veiculoEmbarque?.carreta?.placa ? `/${data.embarque?.veiculoEmbarque?.carreta?.placa}` : ''}
                                                        {data.embarque?.veiculoEmbarque?.carreta?.placa && data.embarque?.veiculoEmbarque?.carreta2?.placa ? `/${data.embarque?.veiculoEmbarque?.carreta2?.placa}` : ''}
                                                    </td>
                                                    <td>{data.embarque?.motorista}</td>
                                                    <td>{data.embarque?.pedidoEmbarque?.rota?.cidadeOrigemRota?.cidade} - {data.embarque?.pedidoEmbarque?.rota?.cidadeOrigemRota?.estado}</td>
                                                    <td>{data.embarque?.pedidoEmbarque?.rota?.cidadeDestinoRota?.cidade} - {data.embarque?.pedidoEmbarque?.rota?.cidadeDestinoRota?.estado}</td>
                                                    <td>{formatarData(data.embarque?.dataHoraEntregue)}</td>
                                                    <td>{formatarData(data.embarque?.dataHoraEmbarcado)}</td>
                                                    <td>{data.observacao}</td>
                                                    <td>{data.valorPagoMotorista}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={buscarCriarDiarias} // Handle submit with selected IDs
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <ToastSucesso
                texto={textoSucesso}
                open={openToastSucesso}
                onClose={handleCloseToastSucesso}
            />
        </div>
    );
}

export default ModalDadosDiaria;
