import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalLancarCTEStatus/ModalLancarCTEStatus.css';
import CoresRedux from "../../contexts/Cor.js";
import TabelaRedux from "../../contexts/Tabela.js";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";
import { valorMask } from "../../regex/valorMask.js";
import NewIcon from "../NewIcon/NewIcon.js";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2.js";
import RequestControllerRedux from "../../contexts/RequestController.js";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import LoadingBar from "../LoadingBar/LoadingBar.js";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaStatus: any[];
    onClose: () => void;
    onClick: () => void;
    setStatus: (e) => void;
    setCTE: (e) => void;
}

function ModalLancarCTEStatus(props: SimpleDialogProps) {

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClick();
    };

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
    };

    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    const [openCadastroStatus, setOpenCadastroStatus] = useState(false);

    const [loadingSelectStatus, setLoadingSelectStatus] = useState(false);

    useEffect(() => {
        const statusSelecionado = props.listaStatus.find(option => option.nome === tabelaRedux?.row?.status);
        if (statusSelecionado) {
            setValuesSelect(current => ({
                ...current,
                status: {
                    value: statusSelecionado.nome,
                    label: statusSelecionado.nome,
                    id: statusSelecionado.idStatus
                }
            }));
            setValues(current => ({ ...current, statusId: statusSelecionado.idStatus }));
        }

    }, [tabelaRedux]);

    useEffect(() => {
        setLoadingSelectStatus(true);
        setTimeout(() => {
            setLoadingSelectStatus(false);
        }, 1000);
    }, [props.listaStatus]);

    const handleCloseCadastroStatus = () => {
        if (idAtual) {
            setOpenCadastroStatus(false);
            setDadosRequestControllerRedux("Close Status");
        } else {
            setDadosRequestControllerRedux("Close Status");
            setOpenCadastroStatus(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-veiculo">
                        <DialogContentText id="alert-dialog-description">
                            <div className="usuario-data-row">
                                {
                                    loadingSelectStatus ? (
                                        <div className="coluna-modalmotivo">
                                            <LoadingBar />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="coluna-modalmotivo">
                                                <div>
                                                    <p className="input-label-emb">Status:</p>
                                                    <SelectEmbarqueV2
                                                        classeCss={180}
                                                        onChange={(e) => {
                                                            setValuesSelect(current => ({ ...current, status: e }));
                                                            setValues(current => ({ ...current, status: e.value }));
                                                            setValues(current => ({ ...current, statusId: e.id }));
                                                            props.setStatus(e.id);
                                                        }}
                                                        clearSelection={clearStatus}
                                                        pequeno={true}
                                                        id="idStatus"
                                                        value={valuesSelect.status}
                                                        titulo="Status"
                                                        dadosSelect={props.listaStatus}
                                                        referenciaId="nome"
                                                        referenciaItem="nome"
                                                        info={false} />
                                                </div>
                                                <NewIcon
                                                    onClick={(e) => {
                                                        setOpenCadastroStatus(true); setIdAtual(0); setDisable(false);
                                                    }}
                                                />
                                            </div>
                                            <div className="coluna-modalmotivo">
                                                <div>
                                                    <p className="input-label-emb">CTE:</p>
                                                    <input
                                                        maxLength={5}
                                                        placeholder='00000'
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '172px' }}
                                                        onChange={(e) => {
                                                            let valor = e.target.value;
                                                            valor = valor.replace(/\D/g, '');
                                                            setValues(current => ({ ...current, cte: valor }));
                                                            props.setCTE(valor);
                                                        }}
                                                        value={valorMask(values.cte)} />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroStatus="sim"
                    open={openCadastroStatus}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroStatus}
                />
            </div>
        </div>
    );
}

export default ModalLancarCTEStatus;