import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import InputLabel from "@mui/material/InputLabel";
import "./Sorteio.css";
import CustomButton from "../../../components/CustomButton/CustomButton.js";
import CoresRedux from "../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import Tabela from "../../../components/Tabela/Tabela.js";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import { getSorteio, cadastraSorteio, deleteSorteioPorId, getSorteioPorId, editaSorteioPorId, sortear, gerarCupons, cadastraCupom, getCuponsId, getVencedores } from "../../../services/ModuloGerenciador/Sorteio.js";
import Text from "../../../components/Text/Text.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getGrupoCargaDependencias, getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import RequestControllerRedux from "../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../components/CloseIcon/CloseIcon.js";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import LoadingBar from "../../../components/LoadingBar/LoadingBar.js";
import NewIcon from "../../../components/NewIcon/NewIcon.js";
import { getMotorista } from "../../../services/ModuloGerenciador/Motorista.js";
import { getProprietario } from "../../../services/ModuloGerenciador/Proprietario.js";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import { telefoneMask } from "../../../regex/telefoneMask.js";
import { cpfCnpj } from "../../../regex/cpfCnpj.js";

function Sorteio(props) {

    const acoesData = [
        {
            titulo: 'Ver Prêmios',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setEditando(true);
                setVerPremios(true);
            },
        },
        {
            titulo: 'Gerar Cupons',
            onClick: () => {
                setOpcoesOpen(false);
                setOpenCupons(true);
            },
        },
        {
            titulo: 'Sortear',
            onClick: () => {
                setOpcoesOpen(false);
                setOpenSorteio(true);
            },
        },
        {
            titulo: 'Ver Cupons',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setVerCupons(true);
            },
        },
        {
            titulo: 'Cadastrar Cupom',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastroCupom(true);
                setTelaCadastro(true);
                setValues(current => ({ ...current, sorteioId: tabelaRedux.colunaSelecionada }));
            },
        },
        {
            titulo: 'Ver Vencedores',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setVerVencedores(true);
            },
        },
    ];

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [telaCadastroCupom, setTelaCadastroCupom] = useState(false);

    const [editando, setEditando] = useState(false);

    const [verPremios, setVerPremios] = useState(false);

    const [verCupons, setVerCupons] = useState(false);

    const [verVencedores, setVerVencedores] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [cupons, setCupons] = useState([]);

    const [vencedores, setVencedores] = useState([]);

    const [premios, setPremios] = useState([]);

    const adicionarPremios = () => {
        setPremios([
            ...premios,
            {
                descricao: "",
                ordemPremio: 0,
            },
        ]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...premios];
        list[index][name] = value;
        setPremios(list);
    };

    useEffect(() => {
        if (!telaCadastroCupom) {
            setValues(prevValues => ({
                ...prevValues,
                premios,
            }));
        } else {
            setValues(current => ({ ...current, embarqueId: 0 }));
        }
    }, [premios]);

    const removePremio = (indexToRemove) => {
        const newList = premios.filter((_, index) => index !== indexToRemove);
        setPremios(newList);
    };

    const [openSorteio, setOpenSorteio] = useState(false);

    const handleCloseSorteio = (value) => {
        setOpenSorteio(false);
    };

    const [openCupons, setOpenCupons] = useState(false);

    const handleCloseCupons = (value) => {
        setOpenCupons(false);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectMotorista, setLoadingSelectMotorista] = useState(false);

    const [openCadastroMotorista, setOpenCadastroMotorista] = useState(false);

    const handleCloseCadastroMotorista = (value) => {
        if (idAtual) {
            setOpenCadastroMotorista(false);
        } else {
            setLoadingSelectMotorista(true);
            buscarMotoristas();
            setOpenCadastroMotorista(false);
        }
    };

    const [loadingSelectProprietario, setLoadingSelectProprietario] = useState(false);

    const [openCadastroProprietario, setOpenCadastroProprietario] = useState(false);

    const handleCloseCadastroProprietario = (value) => {
        if (idAtual) {
            setOpenCadastroProprietario(false);
        } else {
            setLoadingSelectProprietario(true);
            buscarProprietarios();
            setOpenCadastroProprietario(false);
        }
    };

    const navigate = useNavigate();

    const [listaMotorista, setListaMotorista] = useState([]);

    const [listaProprietario, setListaProprietario] = useState([]);

    const buscarSorteio = async () => {
        setLoading(true);
        await getSorteio(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarMotoristas = async () => {
        await getMotorista(dadosUsuarioRedux.token).then((result) => {
            setListaMotorista(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarProprietarios = async () => {
        await getProprietario(dadosUsuarioRedux.token).then((result) => {
            setListaProprietario(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const geraSorteio = async () => {
        setLoading(true);
        await sortear(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setLoading(false);
                setTextoSucesso("Sorteio efetuado com sucesso!");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const geraCupons = async () => {
        setLoading(true);
        await gerarCupons(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setLoading(false);
                setOpenToastSucesso(true);
                setTextoSucesso(result.data)
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.nome !== "") && (validator.nome === "true")) {
            setLoading(true);
            await cadastraSorteio(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarSorteio();
                    setDadosRequestControllerRedux("Fechar Modal Sorteio");
                    setTabelaRedux({});
                    setValues({});
                    setTelaCadastro(false);
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setPremios([]);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const cadastrarCupom = async () => {
        if ((values.nome !== "") && (validator.nome === "true")) {
            setLoading(true);
            await cadastraCupom(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarSorteio();
                    setDadosRequestControllerRedux("Fechar Modal Sorteio");
                    setTabelaRedux({});
                    setValues({});
                    setValuesSelect({});
                    setValuesSelect({});
                    setTelaCadastro(false);
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setPremios([]);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaSorteioPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarSorteio();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setPremios([]);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarSorteioPorId = async () => {
        setLoading(true);
        await getSorteioPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    setPremios(result.data.premios);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarCuponsPorId = async () => {
        await getCuponsId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setCupons(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarVencedores = async () => {
        await getVencedores(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setVencedores(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarSorteioPorIdModal = async (id) => {
        setLoading(true);
        await getSorteioPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarSorteioPorId = async () => {
        setLoading(true);
        await deleteSorteioPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarSorteio();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);


    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (editando) {
            buscarSorteioPorId();
        }
    }, [editando]);

    useEffect(() => {
        if (verCupons) {
            buscarCuponsPorId();
        }
    }, [verCupons]);

    useEffect(() => {
        if (verVencedores) {
            buscarVencedores();
        }
    }, [verVencedores]);

    useEffect(() => {
        buscarSorteio();
        buscarMotoristas();
        buscarProprietarios();
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Uso Comum') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Uso Comum') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const [valuesSelect, setValuesSelect] = useState([]);
    const [disable, setDisable] = useState(false);
    const [opcoesOpen, setOpcoesOpen] = useState(false);
    const [selecioneOpen, setSelecioneOpen] = useState(false);
    useEffect(() => {
        if (selecioneOpen === true) {
            setTimeout(() => {
                setSelecioneOpen(false);
            }, 1000);
        }
    }, [selecioneOpen]);


    const clearMotorista = () => {
        setValuesSelect(current => ({ ...current, motoristaId: '' }));
        setValues(current => ({ ...current, motoristaId: null }));
        setValidator(current => ({ ...current, motoristaId: "false" }));
    };

    const clearProprietario = () => {
        setValuesSelect(current => ({ ...current, proprietarioId: '' }));
        setValues(current => ({ ...current, proprietarioId: null }));
        setValidator(current => ({ ...current, proprietarioId: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Sorteio" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Sorteio" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Sorteio</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Sorteio}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div style={{ display: 'flex' }}>
                                                    {opcoesOpen && (
                                                        <div className="config-dropdown-sorteio2">
                                                            {acoesData.map((acao, index) => {
                                                                return (
                                                                    <p key={index} onClick={acao.onClick}>
                                                                        {acao.titulo}
                                                                    </p>
                                                                );

                                                            })}
                                                        </div>
                                                    )}
                                                    {selecioneOpen &&
                                                        <div className="config-dropdown-sorteio">
                                                            <p>Selecione Uma Coluna</p>
                                                        </div>
                                                    }
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Ações"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setSelecioneOpen(false);
                                                                setVerVencedores(false);
                                                                setVerCupons(false);
                                                                setOpcoesOpen(!opcoesOpen);                                                           
                                                            } else {
                                                                setSelecioneOpen(!selecioneOpen);
                                                            }
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    {/* <CustomButtonV2
                                                        classCss="custom-button-premios"
                                                        classText="button-texto-editar"
                                                        titulo="Ver Prêmios"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                                setVerPremios(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Gerar Cupons"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenCupons(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Sortear"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenSorteio(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    /> */}

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        !telaCadastroCupom ? (
                                            <div>
                                                {
                                                    !props.desabilitar ? (
                                                        <div className="indicadores">
                                                            <p className="indicador-emb">{"Dados de Sorteio "}</p>
                                                            {!verPremios && !verCupons && !verVencedores && (
                                                                <p className="indicador-emb">{"/ Cadastro de Sorteio"}</p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className="flex">
                                                    {
                                                        !props.botaoVoltar ? (
                                                            verPremios ? (
                                                                <div className="flex-emb">
                                                                    <HeaderCadastro texto="Prêmios" />
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerPremios(false);
                                                                        setTabelaRedux({});
                                                                        setPremios([]);
                                                                    }} />
                                                                </div>
                                                            ) : (
                                                                verCupons ? (
                                                                    <>
                                                                        <div className="flex-emb">
                                                                            <HeaderCadastro texto="Cupons" />
                                                                            <CloseIcon onClick={() => {
                                                                                setValues({});
                                                                                setValuesSelect({});
                                                                                setTelaCadastro(false);
                                                                                setTelaCadastroCupom(false);
                                                                                setEditando(false);
                                                                                setVerCupons(false);
                                                                                setTabelaRedux({});
                                                                                setCupons([]);
                                                                            }} />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    verVencedores ? (
                                                                        <>
                                                                            <div className="flex-emb">
                                                                                <HeaderCadastro texto="Vencedores" />
                                                                                <CloseIcon onClick={() => {
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                    setTelaCadastroCupom(false);
                                                                                    setEditando(false);
                                                                                    setVencedores(false);
                                                                                    setTabelaRedux({});
                                                                                    setCupons([]);
                                                                                }} />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="flex-emb">
                                                                                <HeaderCadastro texto="Cadastro de Sorteio" />
                                                                                <CloseIcon onClick={() => {
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                    setTelaCadastroCupom(false);
                                                                                    setEditando(false);
                                                                                    setVerPremios(false);
                                                                                    setTabelaRedux({});
                                                                                    setPremios([]);
                                                                                }} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-column">
                                                    {!verPremios && !verCupons && !verVencedores && (
                                                        <div className="container-select-emb">
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Nome Sorteio:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, nome: e.target.value }));
                                                                                setValidator(current => ({ ...current, nome: "true" }));
                                                                            }}
                                                                            value={values.nome}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Data Sorteio:</p>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, dataSorteio: e.target.value }));
                                                                                setValidator(current => ({ ...current, dataSorteio: "true" }));
                                                                            }}
                                                                            value={values.dataSorteio}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Início Busca:</p>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, inicioBusca: e.target.value }));
                                                                                setValidator(current => ({ ...current, inicioBusca: "true" }));
                                                                            }}
                                                                            value={values.inicioBusca}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Fim Busca:</p>
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, fimBusca: e.target.value }));
                                                                                setValidator(current => ({ ...current, fimBusca: "true" }));
                                                                            }}
                                                                            value={values.fimBusca}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Números KM por cupom:</p>
                                                                        <input
                                                                            type="number"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                const value = parseInt(e.target.value, 10);
                                                                                setValues(current => ({ ...current, numerosKmPorCupom: value }));
                                                                                setValidator(current => ({ ...current, numerosKmPorCupom: value >= 50 ? "true" : "false" }));
                                                                            }}
                                                                            onBlur={() => {
                                                                                if (values.numerosKmPorCupom < 50) {
                                                                                    setValues(current => ({ ...current, numerosKmPorCupom: 50 }));
                                                                                    setValidator(current => ({ ...current, numerosKmPorCupom: "true" }));
                                                                                }
                                                                            }}
                                                                            value={values.numerosKmPorCupom}
                                                                            disabled={props.desabilitar}
                                                                        />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Cupons por viagem:</p>
                                                                        <input
                                                                            type="number"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, cuponsPorViagem: e.target.value }));
                                                                                setValidator(current => ({ ...current, cuponsPorViagem: "true" }));
                                                                            }}
                                                                            value={values.cuponsPorViagem}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.motoristas}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, motoristas: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, motoristas: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Motoristas'} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.icmsEmbarcador}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, proprietarios: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, proprietarios: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Proprietários'} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.apenasCpf}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, apenasCpf: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, apenasCpf: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Apenas CPF'} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="container-cupom">
                                                        {cupons && cupons.map((cupom, index) => (
                                                            <div className="cupom" key={index}>
                                                                <h5>Cupom {index + 1}</h5>
                                                                <p>CPF/CNPJ: {cupom.cpfcnpj}</p>
                                                                <p>Nome: {cupom.nome}</p>
                                                                <p>Telefone: {cupom.telefone}</p>
                                                            </div>
                                                        ))}
                                                        {vencedores && vencedores.map((vencedor, index) => (
                                                            <div className="cupom" key={index}>
                                                                <h5>Prêmio {vencedor.descricao}</h5>
                                                                <p>CPF/CNPJ: {vencedor.cupom.cpfcnpj}</p>
                                                                <p>Nome: {vencedor.cupom.nome}</p>
                                                                <p>Telefone: {vencedor.cupom.telefone}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {premios && premios.map((premio, index) => {
                                                        return (
                                                            <div style={{ marginTop: '20px' }} key={index}>
                                                                <h5>Prêmio {index + 1}</h5>
                                                                <div className="coluna-1-emb" style={{ justifyContent: 'center', flexDirection: 'row' }}>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Descrição:</p>
                                                                            <input
                                                                                name="descricao"
                                                                                type="text"
                                                                                className="input-emb"
                                                                                style={{ width: '222px' }}
                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                value={premio.descricao}
                                                                                disabled={verPremios} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Ordem Prêmio:</p>
                                                                            <input
                                                                                name="ordemPremio"
                                                                                type="text"
                                                                                className="input-emb"
                                                                                style={{ width: '222px' }}
                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                value={premio.ordemPremio}
                                                                                disabled={verPremios} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {!verPremios && (<div className="grid-item-premio" style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                                                    <Tooltip title="Remover Prêmio">
                                                                        <DeleteIcon className="trash-v2" onClick={() => removePremio(index)}></DeleteIcon>
                                                                    </Tooltip>
                                                                </div>)}
                                                            </div>
                                                        );
                                                    })}
                                                    {!verPremios && !verCupons && !verVencedores && (
                                                        <div className="container-select-emb">
                                                            <div className="coluna-3-emb" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                                <CustomButtonV2
                                                                    classCss="custom-button-entrega"
                                                                    classText="button-texto-entrega"
                                                                    titulo="Adicionar Prêmio"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { adicionarPremios(); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {!verPremios && !verCupons && !verVencedores && (
                                                    <div className="botoes-sorteio">
                                                        {
                                                            editando ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cadastrar"
                                                                    classText="button-texto-cadastrar"
                                                                    icone="iconeSave"
                                                                    titulo="Cadastrar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { editar(); setValuesSelect({}); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerPremios(false); setValues({}); }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !props.desabilitar ? (
                                                                            props.botaoCadastrar ? (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Cadastrar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                            if (premios.length === 0) {
                                                                                                setTextoErro('Pelo menos um prêmios é obrigatório!');
                                                                                                setOpenToastErro(true);
                                                                                            } else {
                                                                                                cadastrar();
                                                                                            }
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Cadastrar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                            if (premios.length === 0) {
                                                                                                setTextoErro('Pelo menos um prêmios é obrigatório!');
                                                                                                setOpenToastErro(true);
                                                                                            } else {
                                                                                                cadastrar();
                                                                                            }
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            props.botaoVoltar ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cancelar"
                                                                    classText="button-texto-cancelar"
                                                                    titulo="Cancelar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { setValues({}); setValuesSelect({}); setPremios([]); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerPremios(false); setTabelaRedux({}); }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {
                                                    !props.desabilitar ? (
                                                        <div className="indicadores">
                                                            <p className="indicador-emb">{"Dados de Sorteio "}</p>
                                                            {!verPremios && (
                                                                <p className="indicador-emb">{"/ Cadastro de Cupom"}</p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className="flex">
                                                    {
                                                        !props.botaoVoltar ? (
                                                            verPremios ? (
                                                                <div className="flex-emb">
                                                                    <HeaderCadastro texto="Prêmios" />
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerPremios(false);
                                                                        setTabelaRedux({});
                                                                        setPremios([]);
                                                                    }} />
                                                                </div>
                                                            ) : (
                                                                <div className="flex-emb">
                                                                    <HeaderCadastro texto="Cadastro de Cupom" />
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerPremios(false);
                                                                        setTabelaRedux({});
                                                                        setPremios([]);
                                                                    }} />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-column">
                                                    {!verPremios && (
                                                        <div className="container-select-emb">
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, nome: e.target.value }));
                                                                                setValidator(current => ({ ...current, nome: "true" }));
                                                                            }}
                                                                            value={values.nome}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, telefone: e.target.value }));
                                                                                setValidator(current => ({ ...current, telefone: "true" }));
                                                                            }}
                                                                            value={telefoneMask(values.telefone)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">CPF/CNPJ:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, cpfcnpj: e.target.value }));
                                                                                setValidator(current => ({ ...current, cpfcnpj: "true" }));
                                                                            }}
                                                                            value={cpfCnpj(values.cpfcnpj)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {
                                                                                loadingSelectMotorista ? (
                                                                                    <LoadingBar />
                                                                                ) : (
                                                                                    <div className="coluna-emb">
                                                                                        <div>
                                                                                            <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                                                            <SelectEmbarqueV2
                                                                                                classeCss={230}
                                                                                                onChange={(e) => {
                                                                                                    setValuesSelect(current => ({ ...current, motoristaId: e }));
                                                                                                    setValues(current => ({ ...current, motoristaId: e.id }));
                                                                                                    setValidator(current => ({ ...current, motoristaId: "true" }));
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setDisable(true);
                                                                                                    setOpenCadastroMotorista(true);
                                                                                                }}
                                                                                                clearSelection={clearMotorista}
                                                                                                onInfoIconClick={setIdAtual}
                                                                                                id="idMotorista"
                                                                                                grande={true}
                                                                                                value={valuesSelect.motoristaId}
                                                                                                titulo="Grupo Cliente"
                                                                                                dadosSelect={listaMotorista}
                                                                                                referenciaId="nome"
                                                                                                referenciaItem="nome" />
                                                                                        </div>
                                                                                        <NewIcon
                                                                                            onClick={(e) => {
                                                                                                setOpenCadastroMotorista(true); setIdAtual(null); setDisable(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-sorteio">
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {
                                                                                loadingSelectProprietario ? (
                                                                                    <LoadingBar />
                                                                                ) : (
                                                                                    <div className="coluna-emb">
                                                                                        <div>
                                                                                            <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                                                            <SelectEmbarqueV2
                                                                                                classeCss={230}
                                                                                                onChange={(e) => {
                                                                                                    setValuesSelect(current => ({ ...current, proprietarioId: e }));
                                                                                                    setValues(current => ({ ...current, proprietarioId: e.id }));
                                                                                                    setValidator(current => ({ ...current, proprietarioId: "true" }));
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setDisable(true);
                                                                                                    setOpenCadastroProprietario(true);
                                                                                                }}
                                                                                                clearSelection={clearProprietario}
                                                                                                onInfoIconClick={setIdAtual}
                                                                                                id="idProprietario"
                                                                                                grande={true}
                                                                                                value={valuesSelect.proprietarioId}
                                                                                                titulo="Grupo Cliente"
                                                                                                dadosSelect={listaProprietario}
                                                                                                referenciaId="nome"
                                                                                                referenciaItem="nome" />
                                                                                        </div>
                                                                                        <NewIcon
                                                                                            onClick={(e) => {
                                                                                                setOpenCadastroProprietario(true); setIdAtual(null); setDisable(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {!verPremios && (
                                                    <div className="botoes-sorteio">
                                                        {
                                                            editando ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cadastrar"
                                                                    classText="button-texto-cadastrar"
                                                                    icone="iconeSave"
                                                                    titulo="Cadastrar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { editar(); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerPremios(false); setValues({}); setValuesSelect({}); }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !props.desabilitar ? (
                                                                            props.botaoCadastrar ? (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Cadastrar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                            cadastrarCupom();
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Cadastrar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                            cadastrarCupom();
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            props.botaoVoltar ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cancelar"
                                                                    classText="button-texto-cancelar"
                                                                    titulo="Cancelar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { setValues({}); setValuesSelect({}); setPremios([]); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerPremios(false); setTabelaRedux({}); }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarSorteioPorId}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroMotorista="sim"
                                        open={openCadastroMotorista}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroMotorista}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroProprietario="sim"
                                        open={openCadastroProprietario}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroProprietario}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Sorteio"
                                        texto="Deseja Sortear?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        textoBotao="Confirmar"
                                        open={openSorteio}
                                        onClose={handleCloseSorteio}
                                        onClick={geraSorteio}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Gerar Cupons"
                                        texto="Deseja Gerar os Cupons?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        textoBotao="Confirmar"
                                        open={openCupons}
                                        onClose={handleCloseCupons}
                                        onClick={geraCupons}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Sorteio;