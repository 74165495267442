import React, { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import "./EmbarqueStepperV2.css";
import { motion } from "framer-motion";
import CustomButton from '../../../../components/CustomButton/CustomButton';
import CoresRedux from '../../../../contexts/Cor';
import TextLink from "../../../../components/TextLink/TextLink";
import { FormControl, InputLabel, LinearProgress, MenuItem, StepButton } from "@mui/material";
import Select from 'react-select';
import { valorMask } from "../../../../regex/valorMask";
import DadosContextoEmbarqueRedux from "../../../../contexts/ContextoEmbarque";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import RequestControllerRedux from "../../../../contexts/RequestController";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import TabelaRedux from "../../../../contexts/Tabela";
import { getEmbarqueDependenciasCadastroData, getRegras, getRotaDependencias } from "../../../../services/ModuloGerenciador/Geral";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { cadastraEmbarque, editaEmbarquePorId, getEmbarque } from "../../../../services/ModuloGerenciador/Embarque";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import ModalCadastro from "../../../../components/ModalCadastro/ModalCadastro.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import InfoIcon from '@mui/icons-material/Info';
import ModalInformacao from "../../../../components/ModalInformacao/ModalInformacao.tsx";
import SelectEmbarque from "../../../../components/SelectEmbarque/SelectEmbarque";
import { cadastraTemplateEmbarque, editaTemplateEmbarquePorId } from "../../../../services/ModuloGerenciador/TemplateEmbarque";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { getTaxaIcmsPorUf } from "../../../../services/ModuloGerenciador/TaxaIcms";
import SelectEmbarqueV2 from "../../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import iconeNovo from "../../../../images/iconeNovo.png";
import LoadingBar from "../../../../components/LoadingBar/LoadingBar";
import NewIcon from "../../../../components/NewIcon/NewIcon";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import FilialCidade from "../../../../contexts/CidadesFilial.js";
import ClearIcon from '@mui/icons-material/Clear';
import ToastAviso from "../../../../components/Toast/ToastAviso/ToastAviso.tsx";
import { cadastraOcorrencia } from "../../../../services/ModuloGerenciador/Ocorrencia.js";
import TabelaOcorrencias from "../../../../components/TabelaOcorrencias/TabelaOcorrencias.js";
import { cadastraOcorrenciaEmbarque, deleteOcorrenciaEmbarquePorId, editaOcorrenciaEmbarquePorId, getOcorrenciaEmbarquePorEmbarqueId, getOcorrenciaEmbarquePorId } from "../../../../services/ModuloGerenciador/OcorrenciaEmbarque.js";
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import { getPrevisaoTempo } from "../../../../services/ModuloGerenciador/Pedido.js";
import ModalPrevisaoTempo from "../../../../components/ModalPrevisaoTempo/ModalPrevisaoTempo.tsx";

const steps = ['Embarque/Carga', 'Entrega/Veículo/Motorista', 'Origem/Destino/Meio Pagamento'];

const stepsTemplate = ['Embarque/Carga', 'Veículo/Motorista', 'Origem/Destino/Meio Pagamento'];

function EmbarqueStepperV2(props) {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (activeStep < 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep != 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const [idAtual, setIdAtual] = useState();

    const [disable, setDisable] = useState(false);

    const [simplesNacional, setSimplesNacional] = useState(false);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [textoErro, setTextoErro] = useState("");

    const [editando, setEditando] = useState(false);

    const [todos, setTodos] = useState(false);

    const [openInformacao, setOpenInformacao] = useState(false);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [listaPrevisaoTempo, setListaPrevisaoTempo] = useState([]);

    const [openPrevisaoTempo, setOpenPrevisaoTempo] = useState(false);

    const handleClosePrevisaoTempo = () => {
        setOpenPrevisaoTempo(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openToastAviso, setOpenToastAviso] = useState(false);

    const [textoAviso, setTextoAviso] = useState("");

    const handleCloseToastAviso = (value) => {
        setOpenToastAviso(false);
    };


    const tokenApi = localStorage.getItem("token");

    const [loadingCadastro, setLoadingCadastro] = useState(false);

    const [values, setValues] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [icmsBackup, setIcmsBackup] = useState();

    const [pedido, setPedido] = useState([]);

    const [validator, setValidator] = useState([]);

    const [openHistoricoEdicao, setOpenHistoricoEdicao] = useState(false);

    const [disabledFreteFiscal, setDisabledFreteFiscal] = useState(true);

    const [icmsEmbutido, setIcmsEmbutido] = useState(false);

    const [listaMeioPagamento, setListaMeioPagamento] = useState([]);

    const [listaUnidades, setListaUnidades] = useState([]);

    const [listaEmpresas, setListaEmpresas] = useState([]);

    const [listaFilial, setListaFilial] = useState([]);

    const [listaVeiculos, setListaVeiculos] = useState([]);

    const [listaEmbalagens, setListaEmbalagens] = useState([]);

    const [listaStatus, setListaStatus] = useState([]);

    const [listaMotivo, setListaMotivo] = useState([]);

    const [listaMercadoria, setListaMercadoria] = useState([]);

    const [listaUsuarios, setListaUsuarios] = useState([]);

    const [listaMotoristas, setListaMotoristas] = useState([]);

    const [listaMotoristas2, setListaMotoristas2] = useState([]);

    const [listaRotas, setListaRotas] = useState([]);

    const [listaClientes, setListaClientes] = useState([]);

    const [listaTemplates, setListaTemplates] = useState([]);

    const [listaOcorrencias, setListaOcorrencias] = useState([]);

    const [listaTipoOcorrencias, setListaTipoOcorrencias] = useState([]);

    const [listaSubTipoOcorrencias, setListaSubTipoOcorrencias] = useState([]);

    const [listaSubTipoOcorrencias2, setListaSubTipoOcorrencias2] = useState([]);

    const [entrega, setEntregas] = useState([]);

    const [empresaValido, setEmpresaValido] = useState(true);

    const [unidEmbarqueValido, setUnidEmbarqueValido] = useState(true);

    const [pedidoValido, setPedidoValido] = useState(true);

    const [previsaoEmbarqueValido, setPrevisaoEmbarqueValido] = useState(true);

    const [destinatarioValido, setDestinatarioValido] = useState(true);

    const [ocorrenciaValido, setOcorrenciaValido] = useState(true);

    const [tipoOcorrenciaValido, setTipoOcorrenciaValido] = useState(true);

    const [subTipoOcorrenciaValido, setSubTipoOcorrenciaValido] = useState(true);

    const [dataOcorrenciaValido, setDataOcorrenciaValido] = useState(true);

    const [observacaoOcorrenciaValido, setObservacaoOcorrenciaValido] = useState(true);

    const [cadastroOcorrencia, setCadastroOcorrencia] = useState(false);

    const [clienteEmbarqueId, setClienteEmbarqueId] = useState(null);

    const [clienteEntregaId, setClienteEntregaId] = useState(null);

    const [previsaoEmbarque, setPrevisaoEmbarque] = useState(null);

    const [previsaoEntrega, setPrevisaoEntrega] = useState(null);

    const [previsaoEditandoCheck, setPrevisaoEditandoCheck] = useState(false);

    const adicionarEntrega = () => {
        setEntregas([
            ...entrega,
            {
                idEntrega: 0,
                nomeRota: "",
                cidadeOrigemRota: "",
                estadoOrigemRota: "",
                cidadeDestinoRota: "",
                estadoDestinoRota: "",
                valor: "",
                tarifa: "",
                peso: "",
                destinatario: "",
                cte: "",
                dataHoraChegadaEntrega: null,
                dataHoraEntregue: null,
                embarqueId: 0,
                // embarque: "",
            },
        ]);
    };

    const [ocorrenciaEmbarques, setOcorrencia] = useState([]);

    const [dadosOcorrenciaEmbarques, setDadosOcorrencia] = useState([]);

    const [editandoOcorrencia, setEditandoOcorrencia] = useState(false);

    const adicionarOcorrencia = () => {
        setOcorrencia([
            ...ocorrenciaEmbarques,
            {
                embarqueId: props.dados.idEmbarque,
                ocorrenciaId: null,
                tipoOcorrenciaId: null,
                subTipoOcorrenciaId: null,
                dataOcorrencia: null,
                observacao: "",
            },
        ]);
    };

    const buscarOcorrenciasPorEmbarque = async () => {
        await getOcorrenciaEmbarquePorEmbarqueId(props.dados.idEmbarque, tokenApi).then((result) => {
            if (result.status === 200) {
                setDadosOcorrencia(result.data);
            }
        }).catch((error) => {
            if (error.response.status != 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const cadastrarOcorrencias = async () => {
        if (ocorrenciaValido && tipoOcorrenciaValido && observacaoOcorrenciaValido && dataOcorrenciaValido) {
            ocorrenciaEmbarques.forEach(async (ocorrencia) => {
                await cadastraOcorrenciaEmbarque(ocorrencia, tokenApi).then((result) => {
                    if (result.status === 200) {
                        setCadastroOcorrencia(false);
                        buscarOcorrenciasPorEmbarque();
                        setOcorrencia([]);
                        setValuesSelect(current => ({ ...current, ocorrencia: null }));
                        setValues(current => ({ ...current, ocorrencia: null }));
                        setValuesSelect(current => ({ ...current, tipoOcorrencia: null }));
                        setValues(current => ({ ...current, tipoOcorrencia: null }));
                    }
                }).catch((error) => {
                    if (error.response.status != 200) {
                        setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    } else {
                        setTextoErro(error.response.data);
                    }
                    setOpenToastErro(true);
                    setLoading(false);
                });
            });
        } else {
            setTextoErro("Há campos sem preencher!");
            setOpenToastErro(true);
        }
    };

    const preencherDadosEditandoOcorrencia = (result) => {
        const tipoOcorrenciaSelecionado = listaTipoOcorrencias.find(option => option.idTipoOcorrencia === result.tipoOcorrenciaId);
        if (tipoOcorrenciaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoOcorrencia: {
                    value: tipoOcorrenciaSelecionado.nome,
                    label: tipoOcorrenciaSelecionado.nome,
                    id: tipoOcorrenciaSelecionado.idTipoOcorrencia
                }
            }));
            setValues(current => ({ ...current, tipoOcorrencia: tipoOcorrenciaSelecionado.nome }));
        }
        const ocorrenciaSelecionado = listaOcorrencias.find(option => option.idOcorrencia === result.ocorrenciaId);
        if (ocorrenciaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                ocorrencia: {
                    value: ocorrenciaSelecionado.tipo,
                    label: ocorrenciaSelecionado.tipo,
                    id: ocorrenciaSelecionado.idOcorrencia
                }
            }));
            setValues(current => ({ ...current, ocorrencia: ocorrenciaSelecionado.tipo }));
        }
        const subTipoOcorrenciaSelecionado = listaSubTipoOcorrencias.find(option => option.idSubTipoOcorrencia === result.subTipoOcorrenciaId);
        if (subTipoOcorrenciaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                subTipoOcorrencia: {
                    value: subTipoOcorrenciaSelecionado.nome,
                    label: subTipoOcorrenciaSelecionado.nome,
                    id: subTipoOcorrenciaSelecionado.idSubTipoOcorrencia
                }
            }));
            setValues(current => ({ ...current, subTipoOcorrencia: subTipoOcorrenciaSelecionado.nome }));
        }
    }

    const editarOcorrencia = async () => {
        ocorrenciaEmbarques.forEach(async (ocorrencia) => {
            await editaOcorrenciaEmbarquePorId(ocorrencia, tokenApi).then((result) => {
                if (result.status === 200) {
                    buscarOcorrenciasPorEmbarque();
                    setOcorrencia([]);
                }
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        });
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${ano}-${mes}-${dia}`;
    };

    const buscarPrevisaoTempoEmbarque = async () => {
        if (previsaoEmbarque !== null && clienteEmbarqueId !== null) {
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(previsaoEmbarque), clienteEmbarqueId).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        } else if (previsaoEmbarque === null && clienteEmbarqueId !== null && props.dados !== undefined) {
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(props.dados.previsaoEmbarque), clienteEmbarqueId).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        } else if (clienteEmbarqueId === null && previsaoEmbarque !== null && props.dados !== undefined) {
            const pedidoSelecionado = opcoesPedido.find(option =>
                option.pedido === props.dados.pedido
            );
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(previsaoEmbarque), pedidoSelecionado.embarcadorPedido.idCliente).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        }
    }

    const buscarPrevisaoTempoEntrega = async () => {
        if (previsaoEntrega !== null && clienteEntregaId !== null) {
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(previsaoEntrega), clienteEntregaId).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        } else if (previsaoEntrega === null && clienteEntregaId !== null && props.dados !== undefined) {
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(props.dados.previsaoEntrega), clienteEmbarqueId).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        } else if (clienteEntregaId === null && previsaoEntrega !== null && props.dados !== undefined) {
            const pedidoSelecionado = opcoesPedido.find(option =>
                option.pedido === props.dados.pedido
            );
            await getPrevisaoTempo(dadosUsuarioRedux.token, formatarData(previsaoEmbarque), pedidoSelecionado.destinatarioPedido.idCliente).then((result) => {
                if (result.data.message === "Endereço de cliente não cadastrado.") {
                    setTextoErro(result.data.message)
                    setOpenToastErro(true);
                } else {
                    setOpenPrevisaoTempo(true);
                    setListaPrevisaoTempo(result.data);
                }
            }).catch((error) => {
                setOpenToastErro(true);
            });
        }
    }

    useEffect(() => {
        buscarPrevisaoTempoEmbarque();
    }, [clienteEmbarqueId, previsaoEmbarque]);

    useEffect(() => {
        buscarPrevisaoTempoEntrega();
    }, [clienteEmbarqueId, previsaoEntrega]);

    const [open, setOpen] = useState(false);

    const [ocorrenciaDeletar, setOcorrenciaDeletar] = useState();

    const handleClose = (value) => {
        setOpen(false);
    };

    const setDeletarOcorrencia = async (linha) => {
        setOpen(true);
        setOcorrenciaDeletar(linha);
    };

    const deletaOcorrencia = async () => {
        await deleteOcorrenciaEmbarquePorId(ocorrenciaDeletar.idOcorrenciaEmbarque, tokenApi).then((result) => {
            if (result.status === 200) {
                setDadosOcorrencia([]);
                buscarOcorrenciasPorEmbarque();
            }
        }).catch((error) => {
            if (error.response.status != 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const setEditarOcorrencia = async (linha) => {
        await getOcorrenciaEmbarquePorId(linha.idOcorrenciaEmbarque, tokenApi).then((result) => {
            if (result.status === 200) {
                const array = [];
                array.push(result.data);
                setEditandoOcorrencia(true);
                preencherDadosEditandoOcorrencia(result.data);
                setOcorrencia(array);
            }
        }).catch((error) => {
            if (error.response.status != 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...entrega];
        list[index][name] = value;
        setEntregas(list);
    };

    const handleOcorrenciaInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...ocorrenciaEmbarques];
        list[index][name] = value;
        setOcorrencia(list);
    };

    const handleRotaChange = (e, index) => {
        const selectedRota = listaRotas.find(rota => rota.nome === e.value);
        const list = [...entrega];
        list[index].nomeRota = selectedRota.nome;
        list[index].cidadeOrigemRota = selectedRota.cidadeOrigem;
        list[index].estadoOrigemRota = selectedRota.estadoOrigem;
        list[index].cidadeDestinoRota = selectedRota.cidadeDestino;
        list[index].estadoDestinoRota = selectedRota.estadoDestino;
        setEntregas(list);
    };

    const handlePedidoChange = (e) => {
        const selectedOption = dadosOrdenados.find(pedido => pedido.pedido === e.value);
        setPedidoValido(true);
        const label = `${selectedOption.pedido} - ${selectedOption.tipoRota}`
        if (selectedOption) {
            setClienteEmbarqueId(selectedOption.embarcadorPedido.idCliente);
            setClienteEntregaId(selectedOption.destinatarioPedido.idCliente);
            if (selectedOption.clientePedido?.diFreteFiscal === true) {
                setDiFreteFiscal(true);
            } else {
                setDiFreteFiscal(false);
            }
            if (selectedOption.clientePedido?.diClassificacao === true) {
                setDiClassificacao(true);
            } else {
                setDiClassificacao(false);
            }
            if (selectedOption.clientePedido?.diPedagio === true) {
                setDiPedagio(true);
            } else {
                setDiPedagio(false);
            }
            setValues((current) => ({
                ...current,
                pedidoId: selectedOption.idPedido,
                pedido: selectedOption.pedido,
                cliente: selectedOption.cliente,
                destinatario: selectedOption.destinatario,
                embarcador: selectedOption.embarcador,
                km: selectedOption.km,
                freteFiscal: selectedOption.freteFiscal,
                pedagio: selectedOption.pedagio,
                classificacao: selectedOption.classificacao,
                freteMotorista: selectedOption.freteMotorista,
                valorEmbarqueId: selectedOption.valorEmbarqueId,
            }));
            setIcmsBackup(current => ({ ...current, icms: selectedOption.icms }));
            if (dadosContextoEmbarqueRedux === "frota") {
                calcularIcms10(selectedOption.freteFiscal, selectedOption.classificacao, selectedOption.pedagio);
            } else {
                if (simplesNacional === false) {
                    setValues(current => ({ ...current, icms: selectedOption.icms }));
                } else if (simplesNacional === true) {
                    setValues(current => ({ ...current, icms: '0,00' }));
                }
            }
            if (selectedOption.permiteAlterarFreteFiscal === true) {
                setIcmsEmbutido(selectedOption.icmsEmbutido);
                buscarTaxaIcms(selectedOption.embarcadorEstado, selectedOption.destinatarioEstado);
                setDisabledFreteFiscal(false);
            } else {
                setDisabledFreteFiscal(true);
            }
            const pedidoSelecionado = opcoesPedido.find(option =>
                option.pedido === selectedOption.pedido
            );
            setValuesSelect(current => ({ ...current, pedido: pedidoSelecionado }));
            setValuesSelect(current => ({ ...current, pedidoLabel: label }));
            setValidator((current) => ({
                ...current,
                validatorPedido: "true",
                validatorDestinatario: "true",
                validatorIcms: "true",
                validatorEmbarcador: "true",
                validatorFreteFiscal: "true",
            }));
            setPedidoValido(true);
            preencherDestinatario(selectedOption);
        }
    };

    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            entrega,
        }));
    }, [entrega]);

    useEffect(() => {
        setValues(prevValues => ({
            ...prevValues,
            ocorrenciaEmbarques,
        }));
    }, [ocorrenciaEmbarques]);

    const removeEntrega = (indexToRemove) => {
        const newList = entrega.filter((_, index) => index !== indexToRemove);
        setEntregas(newList);
    };

    const removeOcorrencia = (indexToRemove) => {
        const newList = ocorrenciaEmbarques.filter((_, index) => index !== indexToRemove);
        setOcorrencia(newList);
    };

    const [verificaMotivo, setVerificaMotivo] = useState(true);

    const [verificaMotorista2, setVerificaMorista2] = useState(true);

    const [openCadastroPedido, setOpenCadastroPedido] = useState(false);

    const [loadingSelectCliente, setLoadingSelectCliente] = useState(false);

    const [loadingSelectPedido, setLoadingSelectPedido] = useState(false);

    const [loading, setLoading] = useState(true);

    const handleCloseCadastroPedido = (value) => {
        if (idAtual) {
            setOpenCadastroPedido(false);
        } else {
            setLoadingSelectPedido(true);
            buscarDependenciasSemLoading();
            setOpenCadastroPedido(false);
        }
    };

    const [openCadastroUnidadeEmbarque, setOpenCadastroUnidadeEmbarque] = useState(false);

    const [loadingSelectUnidadeEmbarque, setLoadingSelectUnidadeEmbarque] = useState(false);

    const handleCloseCadastroUnidadeEmbarque = (value) => {
        if (idAtual) {
            setOpenCadastroUnidadeEmbarque(false);
        } else {
            setLoadingSelectUnidadeEmbarque(true);
            buscarDependenciasSemLoading();
            setOpenCadastroUnidadeEmbarque(false);
        }
    };

    const [openCadastroFilial, setOpenCadastroFilial] = useState(false);

    const [loadingSelectFilial, setLoadingSelectFilial] = useState(false);

    const handleCloseCadastroFilial = (value) => {
        if (idAtual) {
            setOpenCadastroFilial(false);
        } else {
            setLoadingSelectFilial(true);
            buscarDependenciasSemLoading();
            setOpenCadastroFilial(false);
        }
    };

    const [openCadastroEmpresa, setOpenCadastroEmpresa] = useState(false);

    const [loadingSelectEmpresa, setLoadingSelectEmpresa] = useState(false);

    const handleCloseCadastroEmpresa = (value) => {
        if (idAtual) {
            setOpenCadastroEmpresa(false);
        } else {
            setLoadingSelectEmpresa(true);
            buscarDependenciasSemLoading();
            setOpenCadastroEmpresa(false);
        }
    };

    const [openCadastroVeiculo, setOpenCadastroVeiculo] = useState(false);

    const [loadingSelectVeiculo, setLoadingSelectVeiculo] = useState(false);

    const handleCloseCadastroVeiculo = (value) => {
        if (idAtual) {
            setOpenCadastroVeiculo(false);
        } else {
            setLoadingSelectVeiculo(true);
            buscarDependenciasSemLoading();
            setOpenCadastroVeiculo(false);
        }
    };

    const [openCadastroCliente, setOpenCadastroCliente] = useState(false);

    const handleCloseCadastroCliente = (value) => {
        if (idAtual) {
            setOpenCadastroCliente(false);
        } else {
            setLoadingSelectCliente(true);
            buscarDependenciasSemLoading();
            setOpenCadastroCliente(false);
        }
    };

    const [openCadastroMercadoria, setOpenCadastroMercadoria] = useState(false);

    const [loadingSelectMercadoria, setLoadingSelectMercadoria] = useState(false);

    const handleCloseCadastroMercadoria = (value) => {
        if (idAtual) {
            setOpenCadastroMercadoria(false);
        } else {
            setLoadingSelectMercadoria(true);
            buscarDependenciasSemLoading();
            setOpenCadastroMercadoria(false);
        }
    };

    const [openCadastroEmbalagem, setOpenCadastroEmbalagem] = useState(false);

    const [loadingSelectEmbalagem, setLoadingSelectEmbalagem] = useState(false);

    const handleCloseCadastroEmbalagem = (value) => {
        if (idAtual) {
            setOpenCadastroEmbalagem(false);
        } else {
            setLoadingSelectEmbalagem(true);
            buscarDependenciasSemLoading();
            setOpenCadastroEmbalagem(false);
        }
    };

    const [openCadastroStatus, setOpenCadastroStatus] = useState(false);

    const [loadingSelectStatus, setLoadingSelectStatus] = useState(false);

    const handleCloseCadastroStatus = (value) => {
        if (idAtual) {
            setOpenCadastroStatus(false);
        } else {
            setLoadingSelectStatus(true);
            buscarDependenciasSemLoading();
            setOpenCadastroStatus(false);
        }
    };

    const [openCadastroMotivo, setOpenCadastroMotivo] = useState(false);

    const [loadingSelectMotivo, setLoadingSelectMotivo] = useState(false);

    const handleCloseCadastroMotivo = (value) => {
        if (idAtual) {
            setOpenCadastroMotivo(false);
        } else {
            setLoadingSelectMotivo(true);
            buscarDependenciasSemLoading();
            setOpenCadastroMotivo(false);
        }
    };

    const [openCadastroMeioPagamento, setOpenCadastroMeioPagamento] = useState(false);

    const [loadingSelectMeioPagamento, setLoadingSelectMeioPagamento] = useState(false);

    const handleCloseCadastroMeioPagamento = (value) => {
        if (idAtual) {
            setOpenCadastroMeioPagamento(false);
        } else {
            setLoadingSelectMeioPagamento(true);
            buscarDependenciasSemLoading();
            setOpenCadastroMeioPagamento(false);
        }
    };

    const [openCadastroOcorrencia, setOpenCadastroOcorrencia] = useState(false);

    const [loadingSelectOcorrencia, setLoadingSelectOcorrencia] = useState(false);

    const handleCloseCadastroOcorrencia = (value) => {
        if (idAtual) {
            setOpenCadastroOcorrencia(false);
        } else {
            setLoadingSelectOcorrencia(true);
            buscarDependenciasSemLoading();
            setOpenCadastroOcorrencia(false);
        }
    };

    const [openCadastroTipoOcorrencia, setOpenCadastroTipoOcorrencia] = useState(false);

    const [loadingSelectTipoOcorrencia, setLoadingSelectTipoOcorrencia] = useState(false);

    const handleCloseCadastroTipoOcorrencia = (value) => {
        if (idAtual) {
            setOpenCadastroTipoOcorrencia(false);
        } else {
            setLoadingSelectTipoOcorrencia(true);
            buscarDependenciasSemLoading();
            setOpenCadastroTipoOcorrencia(false);
        }
    };

    const [openCadastroSubTipoOcorrencia, setOpenCadastroSubTipoOcorrencia] = useState(false);

    const [loadingSelectSubTipoOcorrencia, setLoadingSelectSubTipoOcorrencia] = useState(false);

    const handleCloseCadastroSubTipoOcorrencia = (value) => {
        if (idAtual) {
            setOpenCadastroSubTipoOcorrencia(false);
        } else {
            setLoadingSelectSubTipoOcorrencia(true);
            buscarDependenciasSemLoading();
            setOpenCadastroSubTipoOcorrencia(false);
        }
    };

    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    const filtraPedido = () => {
        var dadosfiltra = pedido;
        dadosfiltra.sort(function (a, b) {
            if (a.pedido < b.pedido) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados(dadosfiltra);
    }

    const dadosOrdenados = dadosFiltrados;

    const [dadosFiltrados2, setDadosFiltrados2] = useState([]);

    const filtraVeiculo = () => {
        var dadosfiltra = listaVeiculos;
        dadosfiltra.sort(function (a, b) {
            if (a.placaCavalo < b.placaCavalo) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltrados2(dadosfiltra);
    }

    const dadosOrdenados2 = dadosFiltrados2;

    const calcular = (taxa) => {
        const taxaCalculada = ((parseFloat(values.freteFiscal) - parseFloat(taxa)) / parseFloat(values.freteFiscal)) * 100;
        const taxaFormatada = taxaCalculada.toFixed(2).toString();
        setValues(current => ({ ...current, margem: taxaFormatada }));
    }

    const labelPedido = (data) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row' }}>
            {data.label.split("@")[0].trim()}
            {valuesSelect.pedidoLabel !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idPedido);
                        setDisable(true);
                        setOpenCadastroPedido(true);
                    }}
                />
            }
            {valuesSelect.pedidoLabel === data.label &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearPedido();
                    }} />
            }
        </div>
    );

    const labelRotaEntrega = (data) => (
        <div>
            {data.label.split("@")[0].trim()}
        </div>
    );

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const labelTemplate = (data) => (
        <div>
            {data.descricao}
        </div>
    );

    const labelPlaca = (data) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row' }}>
            {data.placaCavalo}
            {values.placaCavalo !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idVeiculo);
                        setDisable(true);
                        setOpenCadastroVeiculo(true);
                    }}
                />
            }
            {values.placaCavalo === data.label &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearPlacaCavalo();
                    }} />
            }
        </div>
    );

    const estiloPedido = {
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '450px',
        }),
        control: (provided) => ({
            ...provided,
            marginTop: '8px',
            minHeight: '48px',
            border: 'groove',
        }),
    }

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    const opcoesPedido = dadosOrdenados.map((data) => ({
        value: data.pedido,
        label: `${data.pedido} - ${data.tipoRota}`,
        ...data
    }));

    const opcoesFrota = dadosTabelaRedux.Opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const opcoesTemplate = listaTemplates.map((data) => ({
        value: data.descricao,
        label: data.descricao,
        ...data
    }));

    const opcoesCavalo = dadosOrdenados2.map((data) => ({
        value: data.placaCavalo,
        label: data.placaCavalo,
        ...data
    }));

    const buscarEmbarque = async () => {
        await getEmbarque(tokenApi).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const buscarDependencias = async () => {
        if (dadosContextoEmbarqueRedux) {
            setLoading(true);
            setLoadingCadastro(true);
            setLoadingSelectUnidadeEmbarque(true);
            setLoadingSelectEmpresa(true);
            setLoadingSelectFilial(true);
            setLoadingSelectPedido(true);
            setLoadingSelectVeiculo(true);
            setLoadingSelectEmbalagem(true);
            setLoadingSelectStatus(true);
            setLoadingSelectMeioPagamento(true);
            setLoadingSelectMotivo(true);
            setLoadingSelectMercadoria(true);
            setLoadingSelectCliente(true);
            setLoadingSelectOcorrencia(true);
            setLoadingSelectTipoOcorrencia(true);
            setLoadingSelectSubTipoOcorrencia(true);
            await getEmbarqueDependenciasCadastroData(tokenApi, dadosContextoEmbarqueRedux).then((result) => {
                setListaUsuarios(result.data.usuarioGerens);
                setListaUnidades(result.data.unidadeEmbarque);
                setListaEmpresas(result.data.empresaSub);
                setListaFilial(result.data.filial);
                setPedido(result.data.pedido);
                setListaVeiculos(result.data.veiculo);
                setListaEmbalagens(result.data.embalagem);
                setListaStatus(result.data.status);
                setListaMeioPagamento(result.data.meioPagamento);
                setListaMotivo(result.data.motivo);
                setListaMercadoria(result.data.mercadoria);
                setListaRotas(result.data.rotaEDependencias.rotas);
                setListaClientes(result.data.clientes);
                setListaTemplates(result.data.embarqueTemplete);
                setListaOcorrencias(result.data.ocorrencias);
                setListaTipoOcorrencias(result.data.tipoOcorrencias);
                setListaSubTipoOcorrencias(result.data.subTipoOcorrencias);
                setListaSubTipoOcorrencias2(result.data.subTipoOcorrencias);
                setDadosTabela(result.data.embarque);
                setTabelaRedux({});
                setLoadingSelectUnidadeEmbarque(false);
                setLoadingCadastro(false);
                setLoadingSelectEmpresa(false);
                setLoadingSelectFilial(false);
                setLoadingSelectPedido(false);
                setLoadingSelectVeiculo(false);
                setLoadingSelectEmbalagem(false);
                setLoadingSelectStatus(false);
                setLoadingSelectMeioPagamento(false);
                setLoadingSelectMotivo(false);
                setLoadingSelectMercadoria(false);
                setLoadingSelectCliente(false);
                setLoadingSelectOcorrencia(false);
                setLoadingSelectTipoOcorrencia(false);
                setLoadingSelectSubTipoOcorrencia(false);
                setLoading(false);
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoadingSelectUnidadeEmbarque(false);
                setLoadingSelectEmpresa(false);
                setLoadingSelectFilial(false);
                setLoadingSelectPedido(false);
                setLoadingSelectVeiculo(false);
                setLoadingSelectEmbalagem(false);
                setLoadingSelectStatus(false);
                setLoadingSelectMeioPagamento(false);
                setLoadingSelectMotivo(false);
                setLoadingSelectMercadoria(false);
                setLoadingSelectCliente(false);
                setLoadingSelectOcorrencia(false);
                setLoadingSelectTipoOcorrencia(false);
                setLoadingSelectSubTipoOcorrencia(false);
            });
        }
    }

    const buscarDependenciasSemLoading = async () => {
        await getEmbarqueDependenciasCadastroData(tokenApi, dadosContextoEmbarqueRedux).then((result) => {
            setListaUnidades(result.data.unidadeEmbarque);
            setListaEmpresas(result.data.empresaSub);
            setListaFilial(result.data.filial);
            setPedido(result.data.pedido);
            setListaVeiculos(result.data.veiculo);
            setListaEmbalagens(result.data.embalagem);
            setListaStatus(result.data.status);
            setListaMeioPagamento(result.data.meioPagamento);
            setListaMotivo(result.data.motivo);
            setListaMercadoria(result.data.mercadoria);
            setListaUsuarios(result.data.usuarioGerens);
            setListaClientes(result.data.clientes);
            setListaOcorrencias(result.data.ocorrencias);
            setListaTipoOcorrencias(result.data.tipoOcorrencias);
            setListaSubTipoOcorrencias(result.data.subTipoOcorrencias);
            setListaSubTipoOcorrencias2(result.data.subTipoOcorrencias);
            setDadosTabela(result.data.embarque);
            setTabelaRedux({});
            setLoadingSelectPedido(false);
            setLoadingSelectUnidadeEmbarque(false);
            setLoadingSelectFilial(false);
            setLoadingSelectEmpresa(false);
            setLoadingSelectEmbalagem(false);
            setLoadingSelectStatus(false);
            setLoadingSelectMercadoria(false);
            setLoadingSelectMotivo(false);
            setLoadingSelectMeioPagamento(false);
            setLoadingSelectCliente(false);
            setLoadingSelectOcorrencia(false);
            setLoadingSelectTipoOcorrencia(false);
            setLoadingSelectSubTipoOcorrencia(false);
        }).catch((error) => {
            if (error.response.status != 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoadingSelectPedido(false);
            setLoadingSelectFilial(false);
            setLoadingSelectEmpresa(false);
            setLoadingSelectEmbalagem(false);
            setLoadingSelectStatus(false);
            setLoadingSelectUnidadeEmbarque(false);
            setLoadingSelectMercadoria(false);
            setLoadingSelectMotivo(false);
            setLoadingSelectMeioPagamento(false);
            setLoadingSelectCliente(false);
            setLoadingSelectOcorrencia(false);
            setLoadingSelectTipoOcorrencia(false);
            setLoadingSelectSubTipoOcorrencia(false);
            setLoading(false);
        });
    }

    function validateAndUpdateDate(dateStr) {
        if (!dateStr || dateStr === "" || Date.parse(dateStr) < Date.parse("2000-01-01")) {
            return "2000-01-01";
        }
        return dateStr;
    }

    function validateAndUpdateDateTime(dateTimeStr) {
        if (!dateTimeStr || dateTimeStr === "" || Date.parse(dateTimeStr) < Date.parse("2000-01-01T00:00")) {
            return "2000-01-01T00:00";
        }
        return dateTimeStr;
    }

    const cadastrar = async () => {
        setLoading(true);
        if (!values.dataEmbarque) {
            values.dataEmbarque = "2000-01-01";
        }
        if (!values.dataEntrega) {
            values.dataEntrega = "2000-01-01";
        }
        if (!values.previsaoEmbarque) {
            values.previsaoEmbarque = "2000-01-01T00:00";
        }
        if (!values.previsaoEntrega) {
            values.previsaoEntrega = "2000-01-01T00:00";
        }
        if (!values.dataHoraChegadaEmbarque) {
            values.dataHoraChegadaEmbarque = "2000-01-01T00:00";
        }
        if (!values.dataHoraChegadaEntrega) {
            values.dataHoraChegadaEntrega = "2000-01-01T00:00";
        }
        if (!values.dataHoraEntregue) {
            values.dataHoraEntregue = "2000-01-01T00:00";
        }
        if (!values.dataHoraEmbarcado) {
            values.dataHoraEmbarcado = "2000-01-01T00:00";
        }
        if (values.adicionalEmpresa?.split(',').length === 1) {
            values.adicionalEmpresa = values.adicionalEmpresa + ",00"
        }
        if (values.adicionalMotorista?.split(',').length === 1) {
            values.adicionalMotorista = values.adicionalMotorista + ",00"
        }
        if (values.adiantamento?.split(',').length === 1) {
            values.adiantamento = values.adiantamento + ",00"
        }
        if (values.saldo?.split(',').length === 1) {
            values.saldo = values.saldo + ",00"
        }
        if (values.classificacao?.split(',').length === 1) {
            values.classificacao = values.classificacao + ",00"
        }
        if (validator.validatorEmpresa === "true" && validator.validatorPedido === "true" && validator.validatorPrevisaoEmbarque === "true" &&
            validator.validatorIcms === "true" && validator.validatorFreteFiscal === "true" && validator.validatorEmbarcador === "true" &&
            validator.validatorDestinatario === "true") {
            await cadastraEmbarque(values, tokenApi).then((result) => {
                if (result.status === 200) {
                    // if (dadosContextoEmbarqueRedux === "toledo") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'toledo' }));
                    // } else if (dadosContextoEmbarqueRedux === "paranavai") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'paranavai' }));
                    // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
                    // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
                    // } else if (dadosContextoEmbarqueRedux === 'frota') {
                    //     setTodos(true);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'frota' }));
                    // } else if (dadosContextoEmbarqueRedux === 'todos') {
                    //     setTodos(true);
                    //     buscarEmbarque();
                    //     buscarDependencias();
                    // }
                    if (dadosContextoEmbarqueRedux === 'todos') {
                        setTodos(true);
                        buscarEmbarque();
                        buscarDependencias();
                    } else {
                        setTodos(false);
                        buscarDependencias();
                        setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
                    }
                    setTabelaRedux({});
                    setDadosRequestControllerRedux("Cadastro False Sucesso");
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            let texto = "O(s) campos a seguir são obrigatorios: ";
            if (validator.validatorEmpresa === "false") {
                setEmpresaValido(false);
                texto += "Empresa -";
            }
            if (validator.validatorPedido === "false") {
                setPedidoValido(false);
                texto += "Pedido -"
            }
            if (validator.validatorIcms === "false") {
                texto += "Icms -"
            }
            if (validator.validatorFreteFiscal === "false") {
                texto += "Frete Fiscal -"
            }
            if (validator.validatorEmbarcador === "false") {
                texto += "Embarcador -"
            }
            if (validator.validatorDestinatario === "false") {
                setDestinatarioValido(false);
                texto += "Destinatario -"
            }
            if (validator.validatorPrevisaoEmbarque === "false") {
                texto += "Previsão Viagem -"
                setPrevisaoEmbarqueValido(false);
            }
            if (texto.length > 200) {
                texto = texto.substring(0, 200) + "...";
            }
            setLoading(false);
            setOpenToastErro(true);
            setTextoErro(texto);
        }
    }

    useEffect(() => {
        if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
            setValues(current => ({ ...current, descricao: '' }));
        }
    }, []);

    const cadastrarTemplate = async () => {
        values.dataEmbarque = validateAndUpdateDate(values.dataEmbarque);
        values.dataEntrega = validateAndUpdateDate(values.dataEntrega);
        values.previsaoEmbarque = validateAndUpdateDateTime(values.previsaoEmbarque);
        values.previsaoEntrega = validateAndUpdateDateTime(values.previsaoEntrega);
        values.dataHoraChegadaEmbarque = validateAndUpdateDateTime(values.dataHoraChegadaEmbarque);
        values.dataHoraChegadaEntrega = validateAndUpdateDateTime(values.dataHoraChegadaEntrega);
        values.dataHoraEntregue = validateAndUpdateDateTime(values.dataHoraEntregue);
        values.dataHoraEmbarcado = validateAndUpdateDateTime(values.dataHoraEmbarcado);
        setLoading(true);
        await cadastraTemplateEmbarque(values, tokenApi).then((result) => {
            if (result.status === 200) {
                setValues(current => ({ ...current, contexto: 'templateEmbarque' }));
                setTabelaRedux({});
                setDadosRequestControllerRedux("Cadastro False Sucesso");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const editarTemplate = async () => {
        values.dataEmbarque = validateAndUpdateDate(values.dataEmbarque);
        values.dataEntrega = validateAndUpdateDate(values.dataEntrega);
        values.previsaoEmbarque = validateAndUpdateDateTime(values.previsaoEmbarque);
        values.previsaoEntrega = validateAndUpdateDateTime(values.previsaoEntrega);
        values.dataHoraChegadaEmbarque = validateAndUpdateDateTime(values.dataHoraChegadaEmbarque);
        values.dataHoraChegadaEntrega = validateAndUpdateDateTime(values.dataHoraChegadaEntrega);
        values.dataHoraEntregue = validateAndUpdateDateTime(values.dataHoraEntregue);
        values.dataHoraEmbarcado = validateAndUpdateDateTime(values.dataHoraEmbarcado);
        setLoading(true);
        await editaTemplateEmbarquePorId(values, tokenApi).then((result) => {
            if (result.status === 200) {
                setTabelaRedux({});
                setDadosRequestControllerRedux("Cadastro False Sucesso");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setLoading(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const editar = async () => {
        setLoading(true);
        if (!values.dataEmbarque) {
            values.dataEmbarque = "2000-01-01";
        }
        if (!values.dataEntrega) {
            values.dataEntrega = "2000-01-01";
        }
        if (!values.previsaoEmbarque) {
            values.previsaoEmbarque = "2000-01-01T00:00";
        }
        if (!values.previsaoEntrega) {
            values.previsaoEntrega = "2000-01-01T00:00";
        }
        if (!values.dataHoraChegadaEmbarque) {
            values.dataHoraChegadaEmbarque = "2000-01-01T00:00";
        }
        if (!values.dataHoraChegadaEntrega) {
            values.dataHoraChegadaEntrega = "2000-01-01T00:00";
        }
        if (!values.dataHoraEntregue) {
            values.dataHoraEntregue = "2000-01-01T00:00";
        }
        if (!values.dataHoraEmbarcado) {
            values.dataHoraEmbarcado = "2000-01-01T00:00";
        }
        if (values.adicionalEmpresa?.split(',').length === 1) {
            values.adicionalEmpresa = values.adicionalEmpresa + ",00"
        }
        if (values.adicionalMotorista?.split(',').length === 1) {
            values.adicionalMotorista = values.adicionalMotorista + ",00"
        }
        if (values.adiantamento?.split(',').length === 1) {
            values.adiantamento = values.adiantamento + ",00"
        }
        if (values.saldo?.split(',').length === 1) {
            values.saldo = values.saldo + ",00"
        }
        if (values.classificacao?.split(',').length === 1) {
            values.classificacao = values.classificacao + ",00"
        }
        if (validator.validatorEmpresa === "true" && validator.validatorPedido === "true" && validator.validatorPrevisaoEmbarque === "true") {
            await editaEmbarquePorId(values, tokenApi).then((result) => {
                if (result.status === 200) {
                    // if (dadosContextoEmbarqueRedux === "toledo") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'toledo' }));
                    // } else if (dadosContextoEmbarqueRedux === "paranavai") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'paranavai' }));
                    // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
                    // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
                    //     setTodos(false);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
                    // } else if (dadosContextoEmbarqueRedux === 'frota') {
                    //     setTodos(true);
                    //     buscarDependencias();
                    //     setValues(current => ({ ...current, contexto: 'frota' }));
                    // } else if (dadosContextoEmbarqueRedux === 'todos') {
                    //     setTodos(true);
                    //     buscarDependencias();
                    //     buscarEmbarque();
                    // }
                    if (dadosContextoEmbarqueRedux === 'todos') {
                        setTodos(true);
                        buscarEmbarque();
                        buscarDependencias();
                    } else {
                        setTodos(false);
                        buscarDependencias();
                        setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
                    }
                    setTabelaRedux({});
                    setDadosRequestControllerRedux("Cadastro False Sucesso");
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            let texto = "O(s) campos a seguir são obrigatorios: ";
            if (validator.validatorEmpresa === "false") {
                setEmpresaValido(false);
                texto += "Empresa -";
            }
            if (validator.validatorPedido === "false") {
                setPedidoValido(false);
                texto += "Pedido -"
            }
            if (validator.validatorIcms === "false") {
                texto += "Icms -"
            }
            if (validator.validatorFreteFiscal === "false") {
                texto += "Frete Fiscal -"
            }
            if (validator.validatorEmbarcador === "false") {
                texto += "Embarcador -"
            }
            if (validator.validatorDestinatario === "false") {
                setDestinatarioValido(false);
                texto += "Destinatario -"
            }
            if (validator.validatorPrevisaoEmbarque === "false") {
                texto += "Previsão Viagem -"
                setPrevisaoEmbarqueValido(false);
            }
            if (texto.length > 200) {
                texto = texto.substring(0, 200) + "...";
            }
            setLoading(false);
            setOpenToastErro(true);
            setTextoErro(texto);
        }
    }

    useEffect(() => {
        // if (dadosContextoEmbarqueRedux === "toledo") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'toledo' }));
        // } else if (dadosContextoEmbarqueRedux === "paranavai") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'paranavai' }));
        // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
        // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
        // } else if (dadosContextoEmbarqueRedux === 'frota') {
        //     setTodos(true);
        //     setValues(current => ({ ...current, contexto: 'frota' }));
        // } else if (dadosContextoEmbarqueRedux === 'todos') {
        //     setTodos(true);
        //     buscarEmbarque();
        // }
        if (dadosContextoEmbarqueRedux === 'todos') {
            setTodos(true);
            buscarEmbarque();
        } else {
            setTodos(false);
            setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
        }
        setValidator(current => ({ ...current, validatorEmpresa: "false" }));
        setValidator(current => ({ ...current, validatorFilial: "false" }));
        // setValidator(current => ({ ...current, validatorUnidEmbarque: "false" }));
        setValidator(current => ({ ...current, validatorPedido: "false" }));
        setValidator(current => ({ ...current, validatorPrevisaoEmbarque: "false" }));
        setValidator(current => ({ ...current, validatorStatus: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCavalo: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCarreta: "false" }));
        setValidator(current => ({ ...current, validatorPlacaCarreta2: "false" }));
        setValidator(current => ({ ...current, validatorProprietario: "false" }));
        setValidator(current => ({ ...current, validatorMotorista: "false" }));
        setValidator(current => ({ ...current, validatorIcms: "false" }));
        setValidator(current => ({ ...current, validatorFreteFiscal: "false" }));
        setValidator(current => ({ ...current, validatorMeioPagamento: "false" }));
        setValidator(current => ({ ...current, validatorEmbarcador: "false" }));
        setValidator(current => ({ ...current, validatorDestinatario: "false" }));
        setValidator(current => ({ ...current, validatorMercadoria: "false" }));
        buscarDependencias();
    }, [dadosContextoEmbarqueRedux]);

    useEffect(() => {
        setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
    }, []);

    const preencherDestinatario = async (result) => {
        const clienteSelecionado = listaClientes.find(option => option.nomeFantasia === result.destinatario || option.apelido === result.destinatario);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                destinatario: {
                    value: clienteSelecionado.nomeFantasia,
                    label: `${clienteSelecionado.nomeFantasia} | ${clienteSelecionado.apelido ? clienteSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}`,
                    id: clienteSelecionado.idCliente
                }
            }));
            setValuesSelect(current => ({ ...current, labelDestinatario: `${clienteSelecionado.nomeFantasia} | ${clienteSelecionado.apelido ? clienteSelecionado.apelido : 'Favor, cadastrar apelido de Cliente'}` }));
        }
    };

    const preencherDadosEditando = async (result) => {
        const pedidoSelecionado = opcoesPedido.find(option =>
            option.pedido === result.pedido
        );
        if (pedidoSelecionado) {
            if (pedidoSelecionado.permiteAlterarFreteFiscal === true) {
                buscarTaxaIcms(pedidoSelecionado.embarcadorEstado, pedidoSelecionado.destinatarioEstado);
                setDisabledFreteFiscal(false);
                setIcmsEmbutido(pedidoSelecionado.icmsEmbutido);
            }
            setIcmsBackup(current => ({ ...current, icms: pedidoSelecionado.icms }));
            setValuesSelect(current => ({ ...current, pedido: pedidoSelecionado }));
            setValues(current => ({ ...current, pedidoId: pedidoSelecionado.idPedido }));
            setValues(current => ({ ...current, valorEmbarqueId: pedidoSelecionado.valorEmbarqueId }));
            // setClienteEmbarqueId(pedidoSelecionado.embarcadorPedido.idCliente);
            // setClienteEntregaId(pedidoSelecionado.destinatarioPedido.idCliente);
            // setPrevisaoEmbarque(result.previsaoEmbarque);
            // setPrevisaoEntrega(result.previsaoEmbarque);
        }
        const frotaSelecionado = opcoesFrota.find(option =>
            option.value === result.frota
        );
        if (frotaSelecionado) {
            setValuesSelect(current => ({ ...current, frota: frotaSelecionado }));
        }
        const aceiteSelecionado = opcoesFrota.find(option =>
            option.value === result.aceite
        );
        if (aceiteSelecionado) {
            setValuesSelect(current => ({ ...current, aceite: aceiteSelecionado }));
        }
        const placaSelecionado = opcoesCavalo.find(option =>
            option.placaCavalo === result.placaCavalo
        );
        if (placaSelecionado) {
            setValuesSelect(current => ({ ...current, placaCavalo: placaSelecionado }));
            setValues(current => ({ ...current, veiculoId: placaSelecionado.idVeiculo }));
        }
        const empresaSelecionado = listaEmpresas.find(option => option.nome === result.empresa);
        if (empresaSelecionado?.regimeTributario === 1) {
            setSimplesNacional(true);
            setTimeout(() => {
                setValues(current => ({ ...current, icms: '0,00' }));
            }, 100);
        }
        if (empresaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                empresa: {
                    value: empresaSelecionado.nome,
                    label: empresaSelecionado.nome,
                    id: empresaSelecionado.idEmpresaSub
                }
            }));
            setValues(current => ({ ...current, empresaId: empresaSelecionado.idEmpresaSub }));
        }
        const filialSelecionado = listaFilial.find(option => option.nome === result.filial);
        if (filialSelecionado) {
            setValuesSelect(current => ({
                ...current,
                filial: {
                    value: filialSelecionado.nome,
                    label: filialSelecionado.nome,
                    id: filialSelecionado.idFilial
                }
            }));
            setValues(current => ({ ...current, filialId: filialSelecionado.idFilial }));
        }
        const unidadeSelecionado = listaUnidades.find(option => option.nome === result.unidEmbarque);
        if (unidadeSelecionado) {
            setValuesSelect(current => ({
                ...current,
                unidEmbarque: {
                    value: unidadeSelecionado.nome,
                    label: unidadeSelecionado.nome,
                    id: unidadeSelecionado.idUnidadeEmbarque
                }
            }));
            setValues(current => ({ ...current, unidEmbarqueId: unidadeSelecionado.idUnidadeEmbarque }));
        }
        const statusSelecionado = listaStatus.find(option => option.nome === result.status);
        if (statusSelecionado) {
            setValuesSelect(current => ({
                ...current,
                status: {
                    value: statusSelecionado.nome,
                    label: statusSelecionado.nome,
                    id: statusSelecionado.idStatus
                }
            }));
            setValues(current => ({ ...current, statusId: statusSelecionado.idStatus }));
        }
        const embalagemSelecionado = listaEmbalagens.find(option => option.nome === result.embalagem);
        if (embalagemSelecionado) {
            setValuesSelect(current => ({
                ...current,
                embalagem: {
                    value: embalagemSelecionado.nome,
                    label: embalagemSelecionado.nome,
                    id: embalagemSelecionado.idEmbalagem
                }
            }));
            setValues(current => ({ ...current, embalagemId: embalagemSelecionado.idEmbalagem }));
        }
        const mercadoriaSelecionado = listaMercadoria.find(option => option.nome === result.mercadoria);
        if (mercadoriaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                mercadoria: {
                    value: mercadoriaSelecionado.nome,
                    label: mercadoriaSelecionado.nome,
                    id: mercadoriaSelecionado.idMercadoria
                }
            }));
            setValues(current => ({ ...current, mercadoriaId: mercadoriaSelecionado.idMercadoria }));
        }
        const usuarioResponsavelSelecionado = listaUsuarios.find(option => option.idUsuario === result.usuarioResponsavelId);
        if (usuarioResponsavelSelecionado) {
            setValuesSelect(current => ({
                ...current,
                usuarioResponsavel: {
                    value: usuarioResponsavelSelecionado.usuario,
                    label: usuarioResponsavelSelecionado.usuario,
                    id: usuarioResponsavelSelecionado.idUsuario
                }
            }));
            setValues(current => ({ ...current, usuarioResponsavelId: usuarioResponsavelSelecionado.idUsuario }));
        }
        const meioPagamentoSelecionado = listaMeioPagamento.find(option => option.nome === result.meioPagamento);
        if (meioPagamentoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                meioPagamento: {
                    value: meioPagamentoSelecionado.nome,
                    label: meioPagamentoSelecionado.nome,
                    id: meioPagamentoSelecionado.idMeioPagamento
                }
            }));
            setValues(current => ({ ...current, meioPagamentoId: meioPagamentoSelecionado.idMeioPagamento }));
        }
        const meioPagamento2Selecionado = listaMeioPagamento.find(option => option.nome === result.meioPagamento2);
        if (meioPagamento2Selecionado) {
            setValuesSelect(current => ({
                ...current,
                meioPagamento2: {
                    value: meioPagamento2Selecionado.nome,
                    label: meioPagamento2Selecionado.nome,
                    id: meioPagamento2Selecionado.idMeioPagamento2
                }
            }));
            setValues(current => ({ ...current, meioPagamento2Id: meioPagamento2Selecionado.idMeioPagamento2 }));
        }
        const motivoSelecionado = listaMotivo.find(option => option.motivo === result.motivo);
        if (motivoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motivo: {
                    value: motivoSelecionado.motivo,
                    label: motivoSelecionado.motivo,
                    id: motivoSelecionado.idMotivo
                }
            }));
            setValues(current => ({ ...current, motivoId: motivoSelecionado.idMotivo }));
        }
        const clienteSelecionado = listaClientes.find(option => option.nomeFantasia === result.destinatario || option.apelido === result.destinatario);
        if (clienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                destinatario: {
                    value: clienteSelecionado.nomeFantasia,
                    label: clienteSelecionado.nomeFantasia,
                    id: clienteSelecionado.idCliente
                }
            }));
            setValues(current => ({ ...current, clienteId: clienteSelecionado.idCliente }));
        }
    };

    useEffect(() => {
        if (props.dados != undefined) {
            preencherDadosEditando(props.dados);
            setValidator(current => ({ ...current, validatorPedido: "true" }));
            setValidator(current => ({ ...current, validatorEmpresa: "true" }));
            setValidator(current => ({ ...current, validatorPrevisaoEmbarque: "true" }));
        }
    }, [dadosOrdenados, dadosOrdenados2, listaEmbalagens, listaEmpresas, listaFilial, listaMeioPagamento, listaMercadoria, listaUsuarios, listaMotivo, listaRotas, listaStatus, listaUnidades, listaVeiculos, listaClientes]);

    useEffect(() => {
        setValues(props.dados);
        if (props.dados != undefined) {
            setEntregas(props.dados.entrega);
            setDadosOcorrencia(props.dados.ocorrenciaEmbarques);
        }
        if (props.dados != undefined) {
            if (props.dados.cancelado === true) {
                setVerificaMotivo(false);
            }
        }
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        dadosFilialRedux.forEach((item) => {
            if (dadosContextoEmbarqueRedux === item.contexto) {
                setValues(current => ({ ...current, unidEmbarque: item.nomeFilial }));
                setValues(current => ({ ...current, filialEmpresaId: item.id }));
            }
        });
        if (props.dados === undefined) {
            setValuesSelect(current => ({
                ...current,
                usuarioResponsavel: {
                    value: dadosUsuarioRedux.usuario.usuario,
                    label: dadosUsuarioRedux.usuario.usuario,
                    id: dadosUsuarioRedux.usuario.idUsuario
                }
            }));
            setValues(current => ({ ...current, usuarioResponsavelId: dadosUsuarioRedux.usuario.idUsuario }));
            setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        }
        if (props.dados === undefined) {
            const aceiteSelecionado = opcoesFrota.find(option =>
                option.value === 'Não'
            );
            if (aceiteSelecionado) {
                setValues((current) => ({ ...current, aceite: aceiteSelecionado.value }));
                setValuesSelect(current => ({ ...current, aceite: aceiteSelecionado }));
            }
        }
        // if (dadosContextoEmbarqueRedux === "toledo") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'toledo' }));
        // } else if (dadosContextoEmbarqueRedux === "paranavai") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'paranavai' }));
        // } else if (dadosContextoEmbarqueRedux === "rioVerde") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'rioVerde' }));
        // } else if (dadosContextoEmbarqueRedux === "gloriaDeDourados") {
        //     setTodos(false);
        //     setValues(current => ({ ...current, contexto: 'gloriaDeDourados' }));
        // } else if (dadosContextoEmbarqueRedux === 'frota') {
        //     setTodos(true);
        //     setValues(current => ({ ...current, contexto: 'frota' }));
        // } else if (dadosContextoEmbarqueRedux === 'todos') {
        //     setTodos(true);
        // }
        if (dadosContextoEmbarqueRedux === 'todos') {
            setTodos(true);
        } else {
            setTodos(false);
            setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
        }
    }, [loading]);

    useEffect(() => {
        filtraPedido();
    }, [loadingSelectPedido]);

    useEffect(() => {
        if (listaVeiculos.length > 0) {
            filtraVeiculo();
        }
    }, [listaVeiculos]);

    useEffect(() => {
        if (dadosOrdenados2.length > 0) {
            setLoadingSelectVeiculo(false);
        }
    }, [dadosOrdenados2]);

    useEffect(() => {
        if (!loading) {
            filtraPedido();
            filtraVeiculo();
        }
    }, [loading])

    useEffect(() => {
        if (dadosRequestControllerRedux === "Fechar Modal Empresa") {
            handleCloseCadastroEmpresa();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Filial") {
            handleCloseCadastroFilial();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Unidade") {
            handleCloseCadastroUnidadeEmbarque();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Pedido") {
            handleCloseCadastroPedido();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Veiculo") {
            handleCloseCadastroVeiculo();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Embalagem") {
            handleCloseCadastroEmbalagem();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Status") {
            handleCloseCadastroStatus();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Motivo") {
            handleCloseCadastroMotivo();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal MeioPagamento") {
            handleCloseCadastroMeioPagamento();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
        if (dadosRequestControllerRedux === "Fechar Modal Mercadoria") {
            handleCloseCadastroMercadoria();
            setDadosRequestControllerRedux("");
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
        }
    }, [dadosRequestControllerRedux]);

    const customStylesErro = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid red',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (values.dataEmbarque === "2000-01-01") {
            values.dataEmbarque = "";
        }
        if (values.dataEntrega === "2000-01-01") {
            values.dataEntrega = "";
        }
        if (values.previsaoEmbarque === "2000-01-01T00:00:00" || values.previsaoEmbarque === "2000-01-01T00:00") {
            values.previsaoEmbarque = "";
        }
        if (values.previsaoEntrega === "2000-01-01T00:00:00" || values.previsaoEntrega === "2000-01-01T00:00") {
            values.previsaoEntrega = "";
        }
        if (values.dataHoraChegadaEmbarque === "2000-01-01T00:00:00" || values.dataHoraChegadaEmbarque === "2000-01-01T00:00") {
            values.dataHoraChegadaEmbarque = "";
        }
        if (values.dataHoraChegadaEntrega === "2000-01-01T00:00:00" || values.dataHoraChegadaEntrega === "2000-01-01T00:00") {
            values.dataHoraChegadaEntrega = "";
        }
        if (values.dataHoraEntregue === "2000-01-01T00:00:00" || values.dataHoraEntregue === "2000-01-01T00:00") {
            values.dataHoraEntregue = "";
        }
        if (values.dataHoraEmbarcado === "2000-01-01T00:00:00" || values.dataHoraEmbarcado === "2000-01-01T00:00") {
            values.dataHoraEmbarcado = "";
        }
        let regraPagina;
        // if (dadosContextoEmbarqueRedux === 'toledo') {
        //     regraPagina = 'Toledo';
        // }

        // if (dadosContextoEmbarqueRedux === 'paranavai') {
        //     regraPagina = 'Paranavai';
        // }

        // if (dadosContextoEmbarqueRedux === 'gloriaDeDourados') {
        //     regraPagina = 'Gloria de Dourados';
        // }

        // if (dadosContextoEmbarqueRedux === 'rioVerde') {
        //     regraPagina = 'Rio Verde';
        // }

        // if (dadosContextoEmbarqueRedux === 'frota') {
        //     regraPagina = 'Frota';
        // }

        // if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
        //     regraPagina = 'Template Embarque';
        // }

        // if (dadosContextoEmbarqueRedux === 'todos') {
        //     regraPagina = 'Todos';
        // }
        dadosFilialRedux.map((item) => {
            if (dadosContextoEmbarqueRedux === item.contexto) {
                regraPagina = item.nomeRegra;
            }
        });
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === regraPagina) {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === regraPagina) {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    })

    const [dadosFiltradosClientes, setDadosFiltradosClientes] = useState([]);

    const filtraClientes = () => {
        var dadosfiltraclientes = listaClientes;
        dadosfiltraclientes.sort(function (a, b) {
            if (a.nomeFantasia < b.nomeFantasia) {
                return -1;
            } else {
                return true;
            }
        });
        setDadosFiltradosClientes(dadosfiltraclientes);
    }

    const dadosOrdenadosClientes = dadosFiltradosClientes;

    useEffect(() => {
        if (!loading) {
            filtraClientes();
        }
    }, [loading, loadingSelectCliente]);

    const estiloRota = {
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '410px',
        }),
        control: (provided) => ({
            ...provided,
            marginTop: '8px',
            minHeight: '48px',
            border: 'groove',
        }),
    }

    const opcoesDestinatario = dadosOrdenadosClientes.map((data) => ({
        value: data.nomeFantasia,
        label: `${data.nomeFantasia} | ${data.apelido ? data.apelido : 'Favor, cadastrar apelido de Cliente'}`,
        ...data
    }));

    const labelDestinatario = (data) => (
        <div style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row' }}>
            {data.label}
            {valuesSelect.labelDestinatario !== data.label &&
                <InfoIcon
                    className="info-icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdAtual(data.idCliente);
                        setDisable(true);
                        setOpenCadastroCliente(true);
                    }}
                />
            }
            {valuesSelect.labelDestinatario === data.label &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearDestinatario();
                    }} />
            }
        </div>
    );

    const [taxaIcms, setTaxaIcms] = useState();

    const buscarTaxaIcms = async (ufOrigem, ufDestino) => {
        await getTaxaIcmsPorUf(dadosUsuarioRedux.token, ufOrigem, ufDestino).then((result) => {
            const taxaIcmsAqui = result.data[0].taxa;
            if (taxaIcmsAqui === 0) {
                setTaxaIcms(taxaIcmsAqui);
                setValues(current => ({ ...current, icms: '0' }));
            } else {
                const taxaDividida = taxaIcmsAqui / 100;
                setTaxaIcms(taxaDividida);
            }
        }).catch((error) => {
            setTextoErro('Rota não Possui Taxa de Icms.');
            setTaxaIcms();
            setValues(current => ({ ...current, icms: '0.00' }));
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const [diFreteFiscal, setDiFreteFiscal] = useState(false);

    const [diPedagio, setDiPedagio] = useState(false);

    const [diClassificacao, setDiClassificacao] = useState(false);

    const calcularIcms = () => {
        if (values.freteFiscal == undefined) {
            values.freteFiscal = '0';
        }
        if (values.classificacao == undefined) {
            values.classificacao = '0';
        }
        if (values.pedagio == undefined) {
            values.pedagio = '0';
        }
        let taxaFreteFicasl = 0;
        let taxaPedagio = 0;
        let taxaClassificacao = 0;
        let taxaFreteFiscalReplace = 0;
        if (values.freteFiscal != '0,00' && values.freteFiscal != undefined) {
            taxaFreteFicasl = values.freteFiscal.replace('.', '');
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        } else {
            taxaFreteFicasl = values.freteFiscal;
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        }
        let taxaPedagioReplace = 0;
        if (diPedagio === true) {
            if (values.pedagio == undefined) {
                values.pedagio = '0';
            }
            if (values.pedagio != '0,00' && values.pedagio != undefined) {
                taxaPedagio = values.pedagio.replace('.', '');
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            } else {
                taxaPedagio = values.pedagio;
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            }
        }
        let taxaClassificacaoReplace = 0;
        if (diClassificacao === true) {
            if (values.classificacao == undefined) {
                values.classificacao = '0';
            }
            if (values.classificacao != '0,00' && values.classificacao != undefined) {
                taxaClassificacao = values.classificacao.replace('.', '');
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            } else {
                taxaClassificacao = values.classificacao;
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            }
        }
        let somatoriaGeral = parseFloat(taxaFreteFiscalReplace) + parseFloat(taxaPedagioReplace) + parseFloat(taxaClassificacaoReplace);
        const somatoriaGeralString = somatoriaGeral.toString();
        const taxaCalculada = ((somatoriaGeralString.replace(',', '.') / (1 - taxaIcms)) * taxaIcms);
        const taxaFormatada = taxaCalculada.toFixed(2).toString();
        const taxaFReplace = taxaFormatada.replace('.', ',');
        if (icmsEmbutido) {
            setValues(current => ({ ...current, icms: '0,00' }));
        } else {
            if (!isNaN(taxaFReplace) || typeof taxaFReplace === 'string') {
                setValues(current => ({ ...current, icms: taxaFReplace }));
            }
        }
    }

    const calcularIcms10 = (freteFiscal, classificacao, pedagio) => {
        if (freteFiscal == undefined) {
            freteFiscal = '0';
        }
        if (classificacao == undefined) {
            classificacao = '0';
        }
        if (pedagio == undefined) {
            pedagio = '0';
        }
        let taxaFreteFicasl = 0;
        let taxaPedagio = 0;
        let taxaClassificacao = 0;
        let taxaFreteFiscalReplace = 0;
        if (freteFiscal != '0,00' && freteFiscal != undefined) {
            taxaFreteFicasl = freteFiscal.replace('.', '');
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        } else {
            taxaFreteFicasl = freteFiscal;
            taxaFreteFiscalReplace = taxaFreteFicasl.replace(',', '.');
        }
        let taxaPedagioReplace = 0;
        if (diPedagio === true) {
            if (pedagio == undefined) {
                pedagio = '0';
            }
            if (pedagio != '0,00' && pedagio != undefined) {
                taxaPedagio = pedagio.replace('.', '');
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            } else {
                taxaPedagio = pedagio;
                taxaPedagioReplace = taxaPedagio.replace(',', '.');
            }
        }
        let taxaClassificacaoReplace = 0;
        if (diClassificacao === true) {
            if (classificacao == undefined) {
                classificacao = '0';
            }
            if (classificacao != '0,00' && classificacao != undefined) {
                taxaClassificacao = classificacao.replace('.', '');
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            } else {
                taxaClassificacao = classificacao;
                taxaClassificacaoReplace = taxaClassificacao.replace(',', '.');
            }
        }
        let somatoriaGeral = parseFloat(taxaFreteFiscalReplace) + parseFloat(taxaPedagioReplace) + parseFloat(taxaClassificacaoReplace);
        const somatoriaGeralString = somatoriaGeral.toString();
        const taxaCalculada = ((somatoriaGeralString.replace(',', '.') / (1 - 0.10)) * 0.10);
        const taxaFormatada = taxaCalculada.toFixed(2).toString();
        const taxaFReplace = taxaFormatada.replace('.', ',');
        if (!isNaN(taxaFReplace)) {
            setValues(current => ({ ...current, icms: taxaFReplace }));
        }
    }

    useEffect(() => {
        if (disabledFreteFiscal === false) {
            calcularIcms();
        }
    }, [values.freteFiscal, values.pedido, values.pedagio, values.classificacao, diClassificacao, diFreteFiscal, diPedagio, disabledFreteFiscal, taxaIcms]);

    const clearEmpresa = () => {
        setValuesSelect(current => ({ ...current, empresa: '' }));
        setValues(current => ({ ...current, empresa: '' }));
        setValues(current => ({ ...current, empresaId: null }));
        setValidator(current => ({ ...current, validatorEmpresa: "false" }));
        setEmpresaValido(false);
    };

    const clearEmbalagem = () => {
        setValuesSelect(current => ({ ...current, embalagem: '' }));
        setValues(current => ({ ...current, embalagem: '' }));
        setValues(current => ({ ...current, embalagemId: null }));
    };

    const clearMercadoria = () => {
        setValuesSelect(current => ({ ...current, mercadoria: '' }));
        setValues(current => ({ ...current, mercadoria: '' }));
        setValues(current => ({ ...current, mercadoriaId: null }));
        setValidator(current => ({ ...current, validatorMercadoria: "false" }));
    };

    const clearMeioPagamento = () => {
        setValuesSelect(current => ({ ...current, meioPagamento: '' }));
        setValues(current => ({ ...current, meioPagamento: '' }));
        setValues(current => ({ ...current, meioPagamentoId: null }));
        setValidator(current => ({ ...current, validatorMeioPagamento: "false" }));
    };

    const clearMeioPagamento2 = () => {
        setValuesSelect(current => ({ ...current, meioPagamento2: '' }));
        setValues(current => ({ ...current, meioPagamento2: '' }));
        setValues(current => ({ ...current, meioPagamento2Id: null }));
        setValidator(current => ({ ...current, validatorMeioPagamento2: "false" }));
    };

    const clearFilial = () => {
        setValuesSelect(current => ({ ...current, filial: '' }));
        setValues(current => ({ ...current, filial: '' }));
        setValues(current => ({ ...current, filialId: null }));
        setValidator(current => ({ ...current, validatorFilial: "false" }));
    };

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
        setValidator(current => ({ ...current, validatorStatus: "false" }));
    };

    const clearPedido = () => {
        setValues((current) => ({
            ...current,
            pedidoId: null,
            pedido: '',
            cliente: '',
            destinatario: '',
            embarcador: '',
            km: '',
            freteFiscal: '',
            valorEmbarqueId: null,
            icms: '',
            classificacao: '',
            freteMotorista: '',
            pedagio: ''
        }));
        setValuesSelect(current => ({ ...current, pedido: '' }));
        setValuesSelect(current => ({ ...current, pedidoLabel: '' }));
        setValidator((current) => ({
            ...current,
            validatorPedido: "false",
            validatorDestinatario: "false",
            validatorIcms: "false",
            validatorEmbarcador: "false",
            validatorFreteFiscal: "false",
        }));
        setValuesSelect(current => ({ ...current, destinatario: '' }));
        setValuesSelect(current => ({ ...current, labelDestinatario: '' }));
        setPedidoValido(false);
    };

    const clearDestinatario = () => {
        setValuesSelect(current => ({ ...current, destinatario: '' }));
        setValues(current => ({ ...current, destinatario: '' }));
        setValues(current => ({ ...current, destinatarioId: null }));
        setValidator(current => ({ ...current, validatorDestinatario: "false" }));
        setValuesSelect(current => ({ ...current, destinatarioLabel: '' }));
    };

    const clearPlacaCavalo = () => {
        setValues((current) => ({
            ...current,
            veiculoId: null,
            placaCavalo: '',
            placaCarreta: '',
            placaCarreta2: '',
            proprietario: '',
            motorista: '',
        }));
        setValuesSelect(current => ({ ...current, placaCavalo: '' }));
        setValidator((current) => ({
            ...current,
            validatorPlacaCavalo: "false",
            validatorPlacaCarreta: "false",
            validatorPlacaCarreta2: "false",
            validatorProprietario: "false",
            validatorMotorista: "false",
        }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {
                !loading ? (
                    <div className="flex-column">
                        {
                            dadosContextoEmbarqueRedux === 'templateEmbarque' ? (
                                <div className="div-template">
                                    <p className="input-label-emb" htmlFor="embarque">Descrição Template</p>
                                    <input
                                        type="text"
                                        className="input-emb"
                                        style={{ width: '300px' }}
                                        onChange={(e) => {
                                            setValues(current => ({ ...current, descricao: e.target.value }));
                                        }}
                                        value={values.descricao} />
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        {
                            dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                <div className="div-template">
                                    <p htmlFor="embarque">Usar Modelo</p>
                                    <FormControl sx={{ width: 250 }}>
                                        <Select
                                            value={valuesSelect.template}
                                            options={opcoesTemplate}
                                            formatOptionLabel={labelTemplate}
                                            styles={estiloFrota}
                                            placeholder=""
                                            noOptionsMessage={() => "Não há mais opções"}
                                            onChange={(selectedOption) => {
                                                setValues({});
                                                setValuesSelect({});
                                                setTimeout(() => {
                                                    if (selectedOption) {
                                                        setValuesSelect(current => ({ ...current, template: selectedOption }));
                                                        preencherDadosEditando(selectedOption);
                                                        for (let chave in selectedOption) {
                                                            if (selectedOption[chave] != null && (chave != 'previsaoEmbarque' && chave != 'dataEmbarque'
                                                                && chave != 'previsaoEntrega' && chave != 'dataEntrega' && chave != 'dataHoraChegadaEmbarque' &&
                                                                chave != 'dataHoraEmbarcado' && chave != 'dataHoraChegadaEntrega' && chave != 'dataHoraEntregue')) {
                                                                setValues(current => ({ ...current, [chave]: selectedOption[chave] }));
                                                            }
                                                        }
                                                        if (selectedOption.empresa != null) {
                                                            setValidator(current => ({ ...current, validatorEmpresa: "true" }));
                                                            setEmpresaValido(true);
                                                        }
                                                        if (selectedOption.filial != null) {
                                                            setValidator(current => ({ ...current, validatorFilial: "true" }));
                                                        }
                                                        // if (selectedOption.unidEmbarque != null) {
                                                        //     setValidator(current => ({ ...current, validatorUnidEmbarque: "true" }));
                                                        //     setUnidEmbarqueValido(true);
                                                        // }
                                                        if (selectedOption.pedido != null) {
                                                            setValidator(current => ({ ...current, validatorPedido: "true" }));
                                                            setPedidoValido(true);
                                                        }
                                                        if (selectedOption.status != null) {
                                                            setValidator(current => ({ ...current, validatorStatus: "true" }));
                                                        }
                                                        if (selectedOption.placaCavalo != null) {
                                                            setValidator(current => ({ ...current, validatorPlacaCavalo: "true" }));
                                                        }
                                                        if (selectedOption.placaCarreta != null) {
                                                            setValidator(current => ({ ...current, validatorPlacaCarreta: "true" }));
                                                        }
                                                        if (selectedOption.placaCarreta2 != null) {
                                                            setValidator(current => ({ ...current, validatorPlacaCarreta2: "true" }));
                                                        }
                                                        if (selectedOption.proprietario != null) {
                                                            setValidator(current => ({ ...current, validatorProprietario: "true" }));
                                                        }
                                                        if (selectedOption.motorista != null) {
                                                            setValidator(current => ({ ...current, validatorMotorista: "true" }));
                                                        }
                                                        if (selectedOption.icms != null) {
                                                            setValidator(current => ({ ...current, validatorIcms: "true" }));
                                                        }
                                                        if (selectedOption.freteFiscal != null) {
                                                            setValidator(current => ({ ...current, validatorFreteFiscal: "true" }));
                                                        }
                                                        if (selectedOption.meioPagamento != null) {
                                                            setValidator(current => ({ ...current, validatorMeioPagamento: "true" }));
                                                        }
                                                        if (selectedOption.mercadoria != null) {
                                                            setValidator(current => ({ ...current, validatorMercadoria: "true" }));
                                                        }
                                                        if (selectedOption.destinatario != null) {
                                                            setValidator(current => ({ ...current, validatorDestinatario: "true" }));
                                                            setDestinatarioValido(true);
                                                        }
                                                        if (selectedOption.embarcador != null) {
                                                            setValidator(current => ({ ...current, validatorEmbarcador: "true" }));
                                                        }
                                                        // preencherDatas(selectedOption);
                                                        setIcmsBackup(current => ({ ...current, icms: selectedOption.icms }));
                                                        // setValues(current => ({ ...current, dataHoraChegadaEmbarque: "2000-01-01" }));
                                                        // setValues(current => ({ ...current, dataHoraChegadaEntrega: "2000-01-01" }));
                                                        // setValues(current => ({ ...current, dataHoraEntregue: "2000-01-01" }));
                                                        // setValues(current => ({ ...current, dataHoraEmbarcado: "2000-01-01" }));
                                                        dadosFilialRedux.forEach((item) => {
                                                            if (dadosContextoEmbarqueRedux === item.contexto) {
                                                                setValues(current => ({ ...current, unidEmbarque: item.nomeFilial }));
                                                                setValues(current => ({ ...current, filialEmpresaId: item.id }));
                                                            }
                                                        });
                                                        setValues(current => ({ ...current, contexto: dadosContextoEmbarqueRedux }));
                                                    }
                                                }, 200);
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <div className="cadastro-principal">
                            <p className="dados-embarque-text">Dados de Viagem:</p>
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-1-emb">
                                {
                                    loadingSelectEmpresa ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className={empresaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Empresa:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={200}
                                                    onChange={(e) => {
                                                        setEmpresaValido(true);
                                                        const empresaSelecionado = listaEmpresas.find(option => option.nome === e.value);
                                                        if (empresaSelecionado.regimeTributario === 1) {
                                                            setSimplesNacional(true);
                                                            setValues(current => ({ ...current, icms: '0,00' }));
                                                        } else {
                                                            setSimplesNacional(false);
                                                            setValues(current => ({ ...current, icms: "" }));
                                                            if (icmsBackup != undefined && icmsBackup.icms) {
                                                                setValues(current => ({ ...current, icms: icmsBackup.icms }));
                                                            }
                                                        }
                                                        setValuesSelect(current => ({ ...current, empresa: e }));
                                                        setValues(current => ({ ...current, empresa: e.value }));
                                                        setValues(current => ({ ...current, empresaId: e.id }));
                                                        setValidator(current => ({ ...current, validatorEmpresa: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroEmpresa(true);
                                                    }}
                                                    clearSelection={clearEmpresa}
                                                    onInfoIconClick={setIdAtual}
                                                    id="idEmpresaSub"
                                                    pequeno={true}
                                                    value={valuesSelect.empresa}
                                                    titulo="Empresa"
                                                    dadosSelect={listaEmpresas}
                                                    referenciaId="nome"
                                                    referenciaItem="nome"
                                                    erro={!empresaValido}
                                                    open={openInformacao}
                                                    onClose={() => { setOpenInformacao(false) }}
                                                />
                                                {!empresaValido && <div className="error-text">Obrigatório!</div>}
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroEmpresa(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    loadingSelectFilial ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Filial:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={200}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, filial: e }));
                                                        setValues(current => ({ ...current, filial: e.value }));
                                                        setValues(current => ({ ...current, filialId: e.id }));
                                                        setValidator(current => ({ ...current, validatorFilial: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroFilial(true);
                                                    }}
                                                    clearSelection={clearFilial}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idFilial"
                                                    value={valuesSelect.filial}
                                                    titulo="Filial"
                                                    dadosSelect={listaFilial}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroFilial(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                {/* {
                                    loadingSelectUnidadeEmbarque ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className={unidEmbarqueValido ? "coluna-emb" : "coluna-emb-erro"}>
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Unidade de Embarque:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={133}
                                                    onChange={(e) => {
                                                        setUnidEmbarqueValido(true);
                                                        setValuesSelect(current => ({ ...current, unidEmbarque: e }));
                                                        setValues(current => ({ ...current, unidEmbarque: e.value }));
                                                        setValues(current => ({ ...current, unidEmbarqueId: e.id }));
                                                        setValidator(current => ({ ...current, validatorUnidEmbarque: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroUnidadeEmbarque(true);
                                                    }}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    erro={!unidEmbarqueValido}
                                                    id="idUnidadeEmbarque"
                                                    value={valuesSelect.unidEmbarque}
                                                    titulo="Unidade de Embarque"
                                                    dadosSelect={listaUnidades}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                                {!unidEmbarqueValido && <div className="error-text">Obrigatório!</div>}
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroUnidadeEmbarque(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                } */}
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Peso:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '172px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, peso: e.target.value }));
                                            }}
                                            placeholder='0,00'
                                            value={valorMask(values.peso)} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Cte:</p>
                                        <input
                                            maxLength="5"
                                            placeholder='00000'
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '172px' }}
                                            onChange={(e) => {
                                                let valor = e.target.value;
                                                valor = valor.replace(/\D/g, '');
                                                setValues(current => ({ ...current, cte: valor }));
                                            }}
                                            value={valorMask(values.cte)} />
                                    </div>
                                </div>
                                {
                                    loadingSelectStatus ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Status:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={180}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, status: e }));
                                                        setValues(current => ({ ...current, status: e.value }));
                                                        setValues(current => ({ ...current, statusId: e.id }));
                                                        setValidator(current => ({ ...current, validatorStatus: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroStatus(true);
                                                    }}
                                                    clearSelection={clearStatus}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idStatus"
                                                    value={valuesSelect.status}
                                                    titulo="Status"
                                                    dadosSelect={listaStatus}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroStatus(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="coluna-2-emb">
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '400px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, observacao: e.target.value }));
                                            }}
                                            value={values.observacao} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cadastro-principal">
                            <p className="dados-embarque-text">Dados Pedido:</p>
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-1-emb">
                                {
                                    loadingSelectPedido ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className={pedidoValido ? "coluna-emb" : "coluna-emb-erro"}>
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Pedido:</p>
                                                <FormControl sx={{ width: 231 }}>
                                                    <Select
                                                        value={valuesSelect.pedido}
                                                        options={dadosOrdenados.map((data) => ({
                                                            value: data.pedido,
                                                            label: `${data.pedido} - ${data.tipoRota}
                                                                        @ ${data.rota.cidadeDestinoRota.cidade}
                                                                        - ${data.rota.cidadeDestinoRota.abreviacao}
                                                                        - ${data.rota.cidadeOrigemRota.cidade}
                                                                        - ${data.rota.cidadeOrigemRota.abreviacao}`,
                                                        }))}
                                                        erro={!pedidoValido}
                                                        formatOptionLabel={labelPedido}
                                                        styles={pedidoValido ? estiloFrota : customStylesErro}
                                                        placeholder="Selecione"
                                                        noOptionsMessage={() => "Não há mais opções"}
                                                        onChange={(selectedOption) => handlePedidoChange(selectedOption)}
                                                    />
                                                </FormControl>
                                                {!pedidoValido && <div className="error-text">Obrigatório!</div>}
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroPedido(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Classificação:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            placeholder='0,00'
                                            style={{ width: '152px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, classificacao: e.target.value }));
                                                if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                    setValues(current => ({ ...current, icms: '0' }));
                                                } else {
                                                    if (taxaIcms === undefined) {
                                                        setValues(current => ({ ...current, icms: icmsBackup.icms }));
                                                    }
                                                }
                                            }}
                                            value={valorMask(values.classificacao)}
                                            disabled={false} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Cliente:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '152px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, cliente: e.target.value }));
                                            }}
                                            value={values.cliente}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Embarcador:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '152px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, embarcador: e.target.value }));
                                                setValidator(current => ({ ...current, validatorEmbarcador: "true" }));
                                            }}
                                            value={values.embarcador}
                                            disabled={true} />
                                    </div>
                                </div>
                                {
                                    loadingSelectCliente ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Destinatário:</p>
                                                <FormControl sx={{ width: 280 }}>
                                                    <Select
                                                        value={valuesSelect.destinatario}
                                                        isDisabled={props.desabilitar}
                                                        options={opcoesDestinatario}
                                                        formatOptionLabel={labelDestinatario}
                                                        styles={estiloFrota}
                                                        placeholder="Selecione"
                                                        noOptionsMessage={() => "Não há mais opções"}
                                                        onChange={(selectedOption) => {
                                                            if (selectedOption) {
                                                                setValuesSelect(current => ({ ...current, destinatario: selectedOption }));
                                                                setValuesSelect(current => ({ ...current, labelDestinatario: `${selectedOption.nomeFantasia} | ${selectedOption.apelido ? selectedOption.apelido : 'Favor, cadastrar apelido de Cliente'}` }))
                                                                if (selectedOption.apelido === null) {
                                                                    setValues(current => ({ ...current, destinatario: selectedOption.nomeFantasia }));
                                                                } else {
                                                                    setValues(current => ({ ...current, destinatario: selectedOption.apelido }));
                                                                }
                                                                setValues(current => ({ ...current, destinatarioId: selectedOption.idCliente }));
                                                                setValidator(current => ({ ...current, validatorDestinatario: "true" }));
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            <div className="coluna-2-emb">
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Km:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '152px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, km: e.target.value }));
                                            }}
                                            value={values.km}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Frete Motorista:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '126.5px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, freteMotorista: e.target.value }));
                                            }}
                                            value={valorMask(values.freteMotorista)} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Pedágio:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '126.5px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, pedagio: e.target.value }));
                                                if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                    setValues(current => ({ ...current, icms: '0' }));
                                                } else {
                                                    if (taxaIcms === undefined) {
                                                        setValues(current => ({ ...current, icms: icmsBackup.icms }));
                                                    }
                                                }
                                            }}
                                            value={valorMask(values.pedagio)} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Frete Fiscal:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '126.5px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, freteFiscal: e.target.value }));
                                                setValidator(current => ({ ...current, validatorFreteFiscal: "true" }));
                                                if (dadosContextoEmbarqueRedux === "frota") {
                                                    calcularIcms10(e.target.value);
                                                } else {
                                                    if (taxaIcms === 0 || taxaIcms === 0.00 || taxaIcms === 0.0 || taxaIcms === "0,00" || taxaIcms === "0,0") {
                                                        setValues(current => ({ ...current, icms: '0' }));
                                                    } else {
                                                        if (taxaIcms != undefined) {
                                                            calcularIcms(e.target.value);
                                                        } else {
                                                            setValues(current => ({ ...current, icms: icmsBackup.icms }));
                                                        }
                                                    }
                                                }
                                            }}
                                            value={values.freteFiscal}
                                            disabled={disabledFreteFiscal} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">ICMS:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '126.5px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, icms: e.target.value }));
                                                setValidator(current => ({ ...current, validatorIcms: "true" }));
                                            }}
                                            value={values.icms}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            dadosContextoEmbarqueRedux === 'templateEmbarque' ? (
                                <></>
                            ) : (
                                <>
                                    <div className="cadastro-principal">
                                        <p className="dados-embarque-text">Datas:</p>
                                    </div>
                                    <div className="container-select-emb">
                                        <div className="coluna-1-emb">
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Previsão de Viagem:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className={previsaoEmbarqueValido ? "input-emb" : "input-emb-erro"}
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, previsaoEmbarque: e.target.value }));
                                                            setPrevisaoEmbarqueValido(true);
                                                            setValidator(current => ({ ...current, validatorPrevisaoEmbarque: "true" }));
                                                            setPrevisaoEmbarque(e.target.value);
                                                        }}
                                                        value={values.previsaoEmbarque} />
                                                    {!previsaoEmbarqueValido && <div className="error-text">Obrigatório!</div>}
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Dt.hr Chegada Viagem:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, dataHoraChegadaEmbarque: e.target.value }));
                                                        }}
                                                        value={values.dataHoraChegadaEmbarque} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Dt.hr Embarcado:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, dataHoraEmbarcado: e.target.value }));
                                                        }}
                                                        value={values.dataHoraEmbarcado} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="coluna-2-emb">
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Previsão de Entrega:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, previsaoEntrega: e.target.value }));
                                                            setPrevisaoEntrega(e.target.value);
                                                        }}
                                                        value={values.previsaoEntrega} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Dt.hr Chegada Entrega:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, dataHoraChegadaEntrega: e.target.value }));
                                                        }}
                                                        value={values.dataHoraChegadaEntrega} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Dt.hr Entregue:</p>
                                                    <input
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '208px' }}
                                                        onChange={(e) => {
                                                            setValues(current => ({ ...current, dataHoraEntregue: e.target.value }));
                                                        }}
                                                        value={values.dataHoraEntregue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className="cadastro-principal">
                            <p className="dados-embarque-text">Dados Veículo:</p>
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-1-emb">
                                {
                                    loadingSelectVeiculo ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Placa do Cavalo:</p>
                                                <FormControl sx={{ width: 150 }}>
                                                    <Select
                                                        value={valuesSelect.placaCavalo}
                                                        options={opcoesCavalo}
                                                        formatOptionLabel={labelPlaca}
                                                        styles={estiloFrota}
                                                        placeholder="Selecione"
                                                        noOptionsMessage={() => "Não há mais opções"}
                                                        onChange={(selectedOption) => {
                                                            if (selectedOption) {
                                                                if (selectedOption.motorista2 != null && selectedOption.motorista2 != "") {
                                                                    setVerificaMorista2(false);
                                                                    setListaMotoristas(selectedOption.motorista);
                                                                    setListaMotoristas2(selectedOption.motorista2);
                                                                } else {
                                                                    setVerificaMorista2(true);
                                                                    setListaMotoristas(selectedOption.motorista);
                                                                    setValues(current => ({ ...current, motorista: selectedOption.motorista }));
                                                                }
                                                                if (selectedOption.veiculoMotorista.bloqueado === true) {
                                                                    setTextoAviso("O motorista selecionado está bloqueado pelo motivo: " + selectedOption.veiculoMotorista.motivo);
                                                                    setOpenToastAviso(true);
                                                                }
                                                                setValues((current) => ({
                                                                    ...current,
                                                                    veiculoId: selectedOption.idVeiculo,
                                                                    placaCavalo: selectedOption.placaCavalo,
                                                                    placaCarreta: selectedOption.placaCarreta,
                                                                    placaCarreta2: selectedOption.placaCarreta2,
                                                                    proprietario: selectedOption.proprietario,
                                                                    motorista: selectedOption.motorista,
                                                                }));
                                                                setValuesSelect(current => ({ ...current, placaCavalo: selectedOption }));
                                                                setValidator((current) => ({
                                                                    ...current,
                                                                    validatorPlacaCavalo: "true",
                                                                    validatorPlacaCarreta: "true",
                                                                    validatorPlacaCarreta2: "true",
                                                                    validatorProprietario: "true",
                                                                    validatorMotorista: "true",
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroVeiculo(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '256px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, proprietario: e.target.value }));
                                                setValidator(current => ({ ...current, validatorProprietario: "true" }));
                                            }}
                                            value={values.proprietario}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Placa da Carreta:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '170px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, placaCarreta: e.target.value }));
                                                setValidator(current => ({ ...current, validatorPlacaCarreta: "true" }));
                                            }}
                                            value={values.placaCarreta}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                        {
                                            verificaMotorista2 ? (
                                                <input
                                                    type="text"
                                                    className="input-emb"
                                                    style={{ width: '250px' }}
                                                    onChange={(e) => {
                                                        setValues(current => ({ ...current, motorista: e.target.value }));
                                                    }}
                                                    value={values.motorista}
                                                    disabled={verificaMotorista2} />
                                            ) : (
                                                <Select
                                                    options={[
                                                        { value: listaMotoristas, label: listaMotoristas },
                                                        { value: listaMotoristas2, label: listaMotoristas2 },
                                                    ]}
                                                    styles={estiloFrota}
                                                    value={{ value: values.motorista, label: values.motorista }}
                                                    isDisabled={verificaMotorista2}
                                                    onChange={(selectedOption) => {
                                                        setValues((current) => ({ ...current, motorista: selectedOption.value }));
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Placa Segunda Carreta:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '170px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, placaCarreta2: e.target.value }));
                                                setValidator(current => ({ ...current, validatorPlacaCarreta2: "true" }));
                                            }}
                                            value={values.placaCarreta2}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cadastro-principal">
                            <p className="dados-embarque-text">Dados Financeiros:</p>
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-1-emb">
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Adicional Empresa:</p>
                                        <input
                                            type="text"
                                            placeholder='0,00'
                                            className="input-emb"
                                            style={{ width: '222px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, adicionalEmpresa: e.target.value }));
                                            }}
                                            value={valorMask(values.adicionalEmpresa)} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Adicional Motorista:</p>
                                        <input
                                            type="text"
                                            placeholder='0,00'
                                            className="input-emb"
                                            style={{ width: '222px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, adicionalMotorista: e.target.value }));
                                                calcular(e.target.value);
                                            }}
                                            value={valorMask(values.adicionalMotorista)} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Adiantamento:</p>
                                        <input
                                            type="text"
                                            placeholder='0,00'
                                            className="input-emb"
                                            style={{ width: '222px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, adiantamento: e.target.value }));
                                            }}
                                            value={valorMask(values.adiantamento)} />
                                    </div>
                                </div>
                                <div className={"coluna-emb"}>
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Aceite:</p>
                                        <FormControl sx={{ width: 150 }}>
                                            <Select
                                                value={valuesSelect.aceite}
                                                options={opcoesFrota}
                                                formatOptionLabel={labelFrota}
                                                styles={estiloFrota}
                                                placeholder="Selecione"
                                                noOptionsMessage={() => "Não há mais opções"}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        setValues((current) => ({
                                                            ...current,
                                                            aceite: selectedOption.opcao,
                                                        }));
                                                        setValuesSelect(current => ({ ...current, aceite: selectedOption }));
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Saldo:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            placeholder='0,00'
                                            style={{ width: '222px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, saldo: e.target.value }));
                                            }}
                                            value={valorMask(values.saldo)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-select-emb">
                            {entrega && entrega.map((entrega, index) => {
                                return (
                                    <div style={{ marginTop: '20px' }} key={index}>
                                        <h5>Entrega {index + 1}</h5>
                                        <div className="coluna-1-emb">
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Rota:</p>
                                                    <FormControl sx={{ width: 150 }}>
                                                        <Select
                                                            name="nomeRota"
                                                            placeholder="Selecione"
                                                            styles={estiloFrota}
                                                            options={listaRotas.map((data) => ({
                                                                value: data.nome,
                                                                label: `${data.nome}
                                                                            @ ${data.cidadeDestinoRota.cidade}
                                                                            - ${data.cidadeDestinoRota.abreviacao}
                                                                            - ${data.cidadeOrigemRota.cidade}
                                                                            - ${data.cidadeOrigemRota.abreviacao}`,
                                                            }))}

                                                            formatOptionLabel={labelRotaEntrega}
                                                            value={{ value: entrega.nomeRota, label: entrega.nomeRota }}
                                                            isDisabled={props.desabilitar}
                                                            onChange={(selectedOption) => handleRotaChange(selectedOption, index)}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Cidade Origem:</p>
                                                    <input
                                                        disabled={true}
                                                        name="cidadeOrigemRota"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '222px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.cidadeOrigemRota} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Estado Origem:</p>
                                                    <input
                                                        disabled={true}
                                                        name="estadoOrigemRota"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '222px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.estadoOrigemRota} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Cidade Destino:</p>
                                                    <input
                                                        disabled={true}
                                                        name="cidadeDestinoRota"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '222px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.cidadeDestinoRota} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Estado Destino:</p>
                                                    <input
                                                        disabled={true}
                                                        name="estadoDestinoRota"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '222px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.estadoDestinoRota} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="coluna-2-emb">
                                            {
                                                loadingSelectCliente ? (
                                                    <LoadingBar />
                                                ) : (
                                                    <div className={destinatarioValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                        <div>
                                                            <p className="input-label-emb" htmlFor="descricao">Destinatário:</p>
                                                            <SelectEmbarqueV2
                                                                classeCss={210}
                                                                onChange={(e) => {
                                                                    setDestinatarioValido(true);
                                                                    setValuesSelect(current => ({ ...current, destinatario: e }));
                                                                    setValues(current => ({ ...current, destinatario: e.value }));
                                                                    setValidator(current => ({ ...current, validatorDestinatario: "true" }));
                                                                    handleInputChange({
                                                                        target: {
                                                                            name: 'destinatario',
                                                                            value: e.value,
                                                                        }
                                                                    }, index);
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setDisable(true);
                                                                    setOpenCadastroCliente(true);
                                                                }}
                                                                onInfoIconClick={setIdAtual}
                                                                id="idCliente"
                                                                pequeno={true}
                                                                value={{
                                                                    value: entrega.destinatario,
                                                                    label: entrega.destinatario,
                                                                }}
                                                                erro={!destinatarioValido}
                                                                titulo="Destinatario"
                                                                dadosSelect={listaClientes}
                                                                referenciaId="nomeFantasia"
                                                                referenciaItem="nomeFantasia"
                                                                disabled={props.desabilitar} />
                                                            {!destinatarioValido && <div className="error-text">Obrigatório!</div>}
                                                        </div>
                                                        <NewIcon
                                                            onClick={(e) => {
                                                                setOpenCadastroCliente(true); setIdAtual(null); setDisable(false);
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Valor:</p>
                                                    <input
                                                        name="valor"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.valor} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Tarifa:</p>
                                                    <input
                                                        name="tarifa"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.tarifa} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Peso:</p>
                                                    <input
                                                        name="peso"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.peso}
                                                        placeholder='0,00'
                                                    />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">CTE:</p>
                                                    <input
                                                        name="cte"
                                                        type="text"
                                                        className="input-emb"
                                                        style={{ width: '190px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.cte} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="coluna-2-emb">
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="dataHoraChegadaEntrega">Dt.hr Chegada:</p>
                                                    <input
                                                        name="dataHoraChegadaEntrega"
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '176px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.dataHoraChegadaEntrega} />
                                                </div>
                                            </div>
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="dataHoraEntregue">Dt.hr Entregue:</p>
                                                    <input
                                                        name="dataHoraEntregue"
                                                        type="datetime-local"
                                                        className="input-emb"
                                                        style={{ width: '176px' }}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        value={entrega.dataHoraEntregue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-item-entrega">
                                            <Tooltip title="Remover Entrega">
                                                <DeleteIcon className="trash-v2" onClick={() => removeEntrega(index)}></DeleteIcon>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-3-emb">
                                <CustomButtonV2
                                    classCss="custom-button-entrega"
                                    classText="button-texto-entrega"
                                    titulo="Adicionar Entrega"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => { adicionarEntrega(); }}
                                />
                            </div>
                        </div>
                        <div className="cadastro-principal">
                            <p className="dados-embarque-text">Geral:</p>
                        </div>
                        <div className="container-select-emb">
                            <div className="coluna-1-emb">
                                {
                                    loadingSelectEmbalagem ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Embalagem:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={225}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, embalagem: e }));
                                                        setValues(current => ({ ...current, embalagem: e.value }));
                                                        setValues(current => ({ ...current, embalagemId: e.id }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroEmbalagem(true);
                                                    }}
                                                    clearSelection={clearEmbalagem}
                                                    onInfoIconClick={setIdAtual}
                                                    id="idEmbalagem"
                                                    pequeno={true}
                                                    value={valuesSelect.embalagem}
                                                    titulo="Embalagem"
                                                    dadosSelect={listaEmbalagens}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroEmbalagem(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    loadingSelectMercadoria ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Mercadoria:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={225}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, mercadoria: e }));
                                                        setValues(current => ({ ...current, mercadoria: e.value }));
                                                        setValues(current => ({ ...current, mercadoriaId: e.id }));
                                                        setValidator(current => ({ ...current, validatorMercadoria: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroMercadoria(true);
                                                    }}
                                                    clearSelection={clearMercadoria}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idMercadoria"
                                                    value={valuesSelect.mercadoria}
                                                    titulo="Mercadoria"
                                                    dadosSelect={listaMercadoria}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroMercadoria(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Load:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '170px' }}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, load: e.target.value }));
                                            }}
                                            value={values.load} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Margem:</p>
                                        <input
                                            type="text"
                                            className="input-emb"
                                            style={{ width: '170px' }}
                                            disabled={true}
                                            value={values.margem} />
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div>
                                        <p className="input-label-emb" htmlFor="descricao">Usuário Responsável:</p>
                                        <SelectEmbarqueV2
                                            classeCss={175}
                                            onChange={(e) => {
                                                setValuesSelect(current => ({ ...current, usuarioResponsavel: e }));
                                                setValues(current => ({ ...current, usuarioResponsavel: e.value }));
                                                setValues(current => ({ ...current, usuarioResponsavelId: e.id }));
                                                setValidator(current => ({ ...current, validatorUsuarioResponsavel: "true" }));
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDisable(true);
                                                setOpenCadastroMercadoria(true);
                                            }}
                                            onInfoIconClick={setIdAtual}
                                            pequeno={true}
                                            id="idUsuario"
                                            value={valuesSelect.usuarioResponsavel}
                                            titulo="Usuario"
                                            dadosSelect={listaUsuarios}
                                            referenciaId="usuario"
                                            referenciaItem="usuario"
                                            info={false} />
                                    </div>
                                </div>
                            </div>
                            <div className="coluna-3-emb">
                                {
                                    loadingSelectMeioPagamento ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb-2" htmlFor="descricao">Dados Meio Pegamento:</p>
                                                <p className="input-label-emb" htmlFor="descricao">Meio Pagamento:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={161}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, meioPagamento: e }));
                                                        setValues(current => ({ ...current, meioPagamento: e.value }));
                                                        setValues(current => ({ ...current, meioPagamentoId: e.id }));
                                                        setValidator(current => ({ ...current, validatorMeioPagamento: "true" }));
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDisable(true);
                                                        setOpenCadastroMeioPagamento(true);
                                                    }}
                                                    clearSelection={clearMeioPagamento}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idMeioPagamento"
                                                    value={valuesSelect.meioPagamento}
                                                    titulo="Meio de Pagamento"
                                                    dadosSelect={listaMeioPagamento}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroMeioPagamento(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    loadingSelectMeioPagamento ? (
                                        <LoadingBar />
                                    ) : (
                                        <div className="coluna-emb">
                                            <div>
                                                <p className="input-label-emb" htmlFor="descricao">Meio Pagamento 2:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={161}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, meioPagamento2: e }));
                                                        setValues(current => ({ ...current, meioPagamento2: e.value }));
                                                        setValues(current => ({ ...current, meioPagamento2Id: e.id }));
                                                    }}
                                                    clearSelection={clearMeioPagamento2}
                                                    onInfoIconClick={setIdAtual}
                                                    pequeno={true}
                                                    id="idMeioPagamento"
                                                    value={valuesSelect.meioPagamento2}
                                                    titulo="Meio de Pagamento 2"
                                                    dadosSelect={listaMeioPagamento}
                                                    referenciaId="nome"
                                                    referenciaItem="nome" />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroMeioPagamento(true); setIdAtual(null); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <div className="coluna-emb">
                                    <div className="flex-column">
                                        <input
                                            type="checkbox"
                                            checked={values.comprovante}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, comprovante: e.target.checked }));
                                            }}
                                        ></input>
                                        <p className="input-label-emb" htmlFor="descricao">Comprovante</p>
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div className="flex-column">
                                        <input
                                            type="checkbox"
                                            checked={values.checkInFeito}
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, checkInFeito: e.target.checked }));
                                            }}
                                        ></input>
                                        <p className="input-label-emb" htmlFor="descricao">Check-in</p>
                                    </div>
                                </div>
                                <div className="coluna-emb">
                                    <div className="flex-column">
                                        <input
                                            type="checkbox"
                                            checked={values.cancelado}
                                            onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    setVerificaMotivo(false);
                                                }
                                                else {
                                                    setVerificaMotivo(true);
                                                }
                                                setValues(current => ({ ...current, cancelado: e.target.checked }));
                                            }}
                                        ></input>
                                        <p className="input-label-emb" htmlFor="descricao">Cancelado</p>
                                    </div>
                                </div>
                                {
                                    values.cancelado ? (
                                        loadingSelectMotivo ? (
                                            <LoadingBar />
                                        ) : (
                                            <div className="coluna-emb">
                                                <div>
                                                    <p className="input-label-emb" htmlFor="descricao">Motivo:</p>
                                                    <SelectEmbarqueV2
                                                        classeCss={202}
                                                        onChange={(e) => {
                                                            setValuesSelect(current => ({ ...current, motivo: e }));
                                                            setValues(current => ({ ...current, motivo: e.value }));
                                                            setValues(current => ({ ...current, motivoId: e.id }));
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setDisable(true);
                                                            setOpenCadastroMotivo(true);
                                                        }}
                                                        onInfoIconClick={setIdAtual}
                                                        pequeno={true}
                                                        id="idMotivo"
                                                        disabled={verificaMotivo}
                                                        value={valuesSelect.motivo}
                                                        titulo="Motivo"
                                                        dadosSelect={listaMotivo}
                                                        referenciaId="motivo"
                                                        referenciaItem="motivo" />
                                                </div>
                                                <NewIcon
                                                    onClick={(e) => {
                                                        setOpenCadastroMotivo(true); setIdAtual(null); setDisable(false);
                                                    }}
                                                />
                                            </div>
                                        )
                                    ) : null
                                }
                            </div>
                            <div className="coluna-3-emb" style={{ marginBottom: '10px' }}>
                                {
                                    props.dados ? (
                                        <CustomButtonV2
                                            classCss="custom-button-cadastrar"
                                            classText="button-texto-cadastrar"
                                            icone="iconeSave"
                                            titulo="Salvar"
                                            cor="#0946B8"
                                            disabled={loadingCadastro}
                                            valor={values}
                                            onClick={() => {
                                                if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                    editarTemplate();
                                                    setValues({});
                                                    setValuesSelect({});
                                                } else {
                                                    editar();
                                                }
                                            }}
                                        />
                                    ) : (
                                        <CustomButtonV2
                                            classCss="custom-button-cadastrar"
                                            classText="button-texto-cadastrar"
                                            icone="iconeSave"
                                            titulo="Cadastrar"
                                            disabled={loadingCadastro}
                                            cor="#0946B8"
                                            valor={values}
                                            onClick={
                                                () => {
                                                    if (dadosContextoEmbarqueRedux === 'templateEmbarque') {
                                                        cadastrarTemplate();
                                                    } else {
                                                        cadastrar();
                                                        if (validator.validatorEmpresa === "true" && validator.validatorFilial === "true" && validator.validatorPedido === "true" && validator.validatorPrevisaoEmbarque === "true" &&
                                                            validator.validatorStatus === "true" && validator.validatorPlacaCavalo === "true" && validator.validatorPlacaCarreta === "true" &&
                                                            validator.validatorPlacaCarreta2 === "true" && validator.validatorProprietario === "true" && validator.validatorMotorista === "true" && validator.validatorIcms === "true" &&
                                                            validator.validatorFreteFiscal === "true" && validator.validatorMeioPagamento === "true" &&
                                                            validator.validatorEmbarcador === "true" && validator.validatorDestinatario === "true" && validator.validatorMercadoria === "true") {
                                                            setValues({});
                                                            setValuesSelect({});
                                                        }
                                                    }
                                                }}
                                        />
                                    )
                                }
                                <CustomButtonV2
                                    classCss="custom-button-cancelar"
                                    classText="button-texto-cancelar"
                                    titulo="Cancelar"
                                    cor="#0946B8"
                                    valor={values}
                                    onClick={() => {
                                        setValues({});
                                        setValuesSelect({});
                                        setDadosRequestControllerRedux("Cadastro False");
                                        setEditando(false);
                                        setTabelaRedux({});
                                    }}
                                />
                            </div>
                            {
                                props.editando && dadosContextoEmbarqueRedux != 'templateEmbarque' && (
                                    <>
                                        <TabelaOcorrencias colunas={dadosTabelaRedux.OcorrenciaEmbarque} dados={dadosOcorrenciaEmbarques}
                                            setDeletarOcorrencia={setDeletarOcorrencia} setEditarOcorrencia={setEditarOcorrencia} />
                                        <div className="container-select-emb">
                                            {ocorrenciaEmbarques && ocorrenciaEmbarques.map((ocorrencia, index) => {
                                                return (
                                                    <div style={{ marginTop: '20px' }} key={index}>
                                                        <h5>Ocorrência</h5>
                                                        <div className="coluna-2-emb">
                                                            {
                                                                loadingSelectOcorrencia ? (
                                                                    <LoadingBar />
                                                                ) : (
                                                                    <div className={ocorrenciaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Ocorrência:</p>
                                                                            <SelectEmbarqueV2
                                                                                classeCss={250}
                                                                                onChange={(e) => {
                                                                                    setOcorrenciaValido(true);
                                                                                    setValuesSelect(current => ({ ...current, ocorrencia: e }));
                                                                                    setValues(current => ({ ...current, ocorrencia: e.value }));
                                                                                    if (e.value === 'Avaria') {
                                                                                        setValidator(current => ({ ...current, validatorSubTipoOcorrencia: "false" }));
                                                                                        setTipoOcorrenciaValido(false);
                                                                                    } else {
                                                                                        setTipoOcorrenciaValido(true);
                                                                                    }
                                                                                    setValidator(current => ({ ...current, validatorOcorrencia: "true" }));
                                                                                    handleOcorrenciaInputChange({
                                                                                        target: {
                                                                                            name: 'ocorrenciaId',
                                                                                            value: e.id,
                                                                                        }
                                                                                    }, index);
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setDisable(true);
                                                                                    setOpenCadastroOcorrencia(true);
                                                                                }}
                                                                                onInfoIconClick={setIdAtual}
                                                                                id="idOcorrencia"
                                                                                pequeno={true}
                                                                                value={{
                                                                                    value: values.ocorrencia,
                                                                                    label: values.ocorrencia,
                                                                                }}
                                                                                titulo="Ocorrência"
                                                                                dadosSelect={listaOcorrencias}
                                                                                referenciaId="tipo"
                                                                                erro={!ocorrenciaValido}
                                                                                referenciaItem="tipo"
                                                                                disabled={props.desabilitar} />
                                                                            {!ocorrenciaValido && <div className="error-text">Obrigatório!</div>}
                                                                        </div>
                                                                        <NewIcon
                                                                            onClick={(e) => {
                                                                                setOpenCadastroOcorrencia(true); setIdAtual(null); setDisable(false);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                loadingSelectTipoOcorrencia ? (
                                                                    <LoadingBar />
                                                                ) : (

                                                                    values.ocorrencia === 'Avaria' && (
                                                                        <div className={tipoOcorrenciaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Sub Tipo Ocorrência:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={250}
                                                                                    onChange={(e) => {
                                                                                        setTipoOcorrenciaValido(true);
                                                                                        setValuesSelect(current => ({ ...current, tipoOcorrencia: e }));
                                                                                        setValues(current => ({ ...current, tipoOcorrencia: e.value }));
                                                                                        const listaSubTipoOcorrenciasCopia = [...listaSubTipoOcorrencias2];
                                                                                        const teste = listaSubTipoOcorrenciasCopia.filter(item => item.tipoOcorrenciaId === e.id);
                                                                                        setLoadingSelectSubTipoOcorrencia(true);
                                                                                        setListaSubTipoOcorrencias(teste);
                                                                                        handleOcorrenciaInputChange({
                                                                                            target: {
                                                                                                name: 'tipoOcorrenciaId',
                                                                                                value: e.id,
                                                                                            }
                                                                                        }, index);
                                                                                        setTimeout(() => {
                                                                                            setLoadingSelectSubTipoOcorrencia(false);
                                                                                        }, 200);

                                                                                        setValuesSelect(current => ({ ...current, subTipoOcorrencia: null }));
                                                                                        setValues(current => ({ ...current, subTipoOcorrencia: null }));
                                                                                        setValidator(current => ({ ...current, validatorSubTipoOcorrencia: "true" }));
                                                                                        handleOcorrenciaInputChange({
                                                                                            target: {
                                                                                                name: 'subTipoOcorrenciaId',
                                                                                                value: null,
                                                                                            }
                                                                                        }, index);
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroTipoOcorrencia(true);
                                                                                    }}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idTipoOcorrencia"
                                                                                    pequeno={true}
                                                                                    value={{
                                                                                        value: values.tipoOcorrencia,
                                                                                        label: values.tipoOcorrencia,
                                                                                    }}
                                                                                    titulo="Tipo Ocorrência"
                                                                                    dadosSelect={listaTipoOcorrencias}
                                                                                    referenciaId="nome"
                                                                                    erro={!tipoOcorrenciaValido}
                                                                                    referenciaItem="nome"
                                                                                    disabled={props.desabilitar} />
                                                                                {!tipoOcorrenciaValido && <div className="error-text">Obrigatório!</div>}
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroTipoOcorrencia(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )

                                                                )
                                                            }
                                                            {/* {
                                                                loadingSelectSubTipoOcorrencia ? (
                                                                    <LoadingBar />
                                                                ) : (
                                                                    <div className={subTipoOcorrenciaValido ? "coluna-emb" : "coluna-emb-erro"}>
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="descricao">Sub Tipo Ocorrência:</p>
                                                                            <SelectEmbarqueV2
                                                                                classeCss={250}
                                                                                onChange={(e) => {
                                                                                    setSubTipoOcorrenciaValido(true);
                                                                                    setValuesSelect(current => ({ ...current, subTipoOcorrencia: e }));
                                                                                    setValues(current => ({ ...current, subTipoOcorrencia: e.value }));
                                                                                    setValidator(current => ({ ...current, validatorSubTipoOcorrencia: "true" }));
                                                                                    handleOcorrenciaInputChange({
                                                                                        target: {
                                                                                            name: 'subTipoOcorrenciaId',
                                                                                            value: e.id,
                                                                                        }
                                                                                    }, index);
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setDisable(true);
                                                                                    setOpenCadastroSubTipoOcorrencia(true);
                                                                                }}
                                                                                onInfoIconClick={setIdAtual}
                                                                                id="idSubTipoOcorrencia"
                                                                                pequeno={true}
                                                                                value={{
                                                                                    value: values.subTipoOcorrencia,
                                                                                    label: values.subTipoOcorrencia,
                                                                                }}
                                                                                titulo="Destinatario"
                                                                                dadosSelect={listaSubTipoOcorrencias}
                                                                                referenciaId="nome"
                                                                                erro={!subTipoOcorrenciaValido}
                                                                                referenciaItem="nome"
                                                                                disabled={props.desabilitar || !values.tipoOcorrencia} />
                                                                            {!subTipoOcorrenciaValido && <div className="error-text">Obrigatório!</div>}
                                                                        </div>
                                                                        <NewIcon
                                                                            onClick={(e) => {
                                                                                setOpenCadastroSubTipoOcorrencia(true); setIdAtual(null); setDisable(false);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            } */}
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    <p className="input-label-emb" htmlFor="dataOcorrencia">Dt.Ocorrência:</p>
                                                                    <input
                                                                        name="dataOcorrencia"
                                                                        type="datetime-local"
                                                                        className={dataOcorrenciaValido ? "input-emb" : "input-emb-erro"}
                                                                        style={{ width: '185px' }}
                                                                        onChange={(e) => {
                                                                            handleOcorrenciaInputChange(e, index);
                                                                            setDataOcorrenciaValido(true);
                                                                        }}
                                                                        value={ocorrencia.dataOcorrencia} />
                                                                    {!dataOcorrenciaValido && <div className="error-text">Obrigatório!</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-2-emb">
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                    <input
                                                                        name="observacao"
                                                                        type="text"
                                                                        className={observacaoOcorrenciaValido ? "input-emb" : "input-emb-erro"}
                                                                        style={{ width: '500px' }}
                                                                        onChange={(e) => {
                                                                            handleOcorrenciaInputChange(e, index);
                                                                            setObservacaoOcorrenciaValido(true);
                                                                        }}
                                                                        value={ocorrencia.observacao} />
                                                                    {!observacaoOcorrenciaValido && <div className="error-text">Obrigatório!</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="grid-item-entrega">
                                                            <Tooltip title="Remover Ocorrência">
                                                                <DeleteIcon className="trash-v2" onClick={() => { removeOcorrencia(index); setCadastroOcorrencia(false); setEditandoOcorrencia(false); }}></DeleteIcon>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div></>
                                )
                            }
                            {
                                props.editando && (
                                    <div className="container-select-emb">
                                        {!cadastroOcorrencia && !editandoOcorrencia && dadosContextoEmbarqueRedux != 'templateEmbarque' && (
                                            <div className="coluna-3-emb">
                                                <CustomButtonV2
                                                    classCss="custom-button-entrega"
                                                    classText="button-texto-entrega"
                                                    titulo="Adicionar Ocorrência"
                                                    cor="#0946B8"
                                                    valor={values}
                                                    onClick={() => {
                                                        adicionarOcorrencia();
                                                        setCadastroOcorrencia(true);
                                                        setOcorrenciaValido(false);
                                                        setSubTipoOcorrenciaValido(false);
                                                        setObservacaoOcorrenciaValido(false);
                                                        setDataOcorrenciaValido(false);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {cadastroOcorrencia && !editandoOcorrencia && (
                                            <div className="coluna-3-emb">
                                                <CustomButtonV2
                                                    classCss="custom-button-entrega"
                                                    classText="button-texto-entrega"
                                                    titulo="Cadastrar Ocorrência"
                                                    cor="#0946B8"
                                                    valor={values}
                                                    onClick={() => { cadastrarOcorrencias(); }}
                                                />
                                            </div>
                                        )}
                                        {editandoOcorrencia && (
                                            <div className="coluna-3-emb">
                                                <CustomButtonV2
                                                    classCss="custom-button-entrega"
                                                    classText="button-texto-entrega"
                                                    titulo="Editar Ocorrência"
                                                    cor="#0946B8"
                                                    valor={values}
                                                    onClick={() => { editarOcorrencia(); setEditandoOcorrencia(false); }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                        <div>
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroPedido="sim"
                                open={openCadastroPedido}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroPedido}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroUnidadeEmbarque="sim"
                                open={openCadastroUnidadeEmbarque}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroUnidadeEmbarque}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroFilial="sim"
                                open={openCadastroFilial}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroFilial}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroEmpresaSub="sim"
                                open={openCadastroEmpresa}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroEmpresa}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroVeiculo="sim"
                                open={openCadastroVeiculo}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroVeiculo}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroMercadoria="sim"
                                open={openCadastroMercadoria}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroMercadoria}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroEmbalagem="sim"
                                open={openCadastroEmbalagem}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroEmbalagem}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroStatus="sim"
                                open={openCadastroStatus}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroStatus}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroCliente="sim"
                                open={openCadastroCliente}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroCliente}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroMotivo="sim"
                                open={openCadastroMotivo}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroMotivo}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroMeioPagamento="sim"
                                open={openCadastroMeioPagamento}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroMeioPagamento}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroOcorrencia="sim"
                                open={openCadastroOcorrencia}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroOcorrencia}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroTipoOcorrencia="sim"
                                open={openCadastroTipoOcorrencia}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroTipoOcorrencia}
                            />
                            <ModalCadastro
                                titulo="Cadastro"
                                tituloConfirmar="OK"
                                cadastroSubTipoOcorrencia="sim"
                                open={openCadastroSubTipoOcorrencia}
                                idAtual={idAtual}
                                desabilitar={disable}
                                onClose={handleCloseCadastroSubTipoOcorrencia}
                            />
                            <ModalConfirmacao
                                titulo="Deletar"
                                texto="Deseja Deletar?"
                                tituloConfirmar="Confirmar"
                                tituloCancelar="Cancelar"
                                open={open}
                                onClose={handleClose}
                                onClick={deletaOcorrencia}
                            />
                            <ModalInformacao
                                open={props.open}
                            />
                            <ModalPrevisaoTempo
                                open={openPrevisaoTempo}
                                listaPrevisaoTempo={listaPrevisaoTempo}
                                handleClosePrevisao={handleClosePrevisaoTempo}
                            />
                        </div>
                        <div>
                            <ToastSucesso
                                texto={textoSucesso}
                                open={openToastSucesso}
                                onClose={handleCloseToastSucesso}
                            />
                            <ToastErro
                                texto={textoErro}
                                open={openToastErro}
                                onClose={handleCloseToastErro}
                            />
                            <ToastAviso
                                texto={textoAviso}
                                open={openToastAviso}
                                onClose={handleCloseToastAviso}
                            />
                        </div>
                    </div>
                ) : (
                    <LoadingComponent />
                )
            }
        </motion.div >
    );
}

export default EmbarqueStepperV2;